// Use this import if you want to use "env.js" file
// const {
//   REACT_APP_API_URL,
//   REACT_APP_TIMEOUT,
//   REACT_APP_AUTH_URL,
//   REACT_APP_MOCK_API_URL,
//   REACT_APP_JEST_URL,
// } = process.env;
// console.log(process.env.REACT_APP_API_URL, REACT_APP_API_URL);

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  /**
   * The URL of the api.
   */
  url: string | undefined;

  auth: string | undefined;

  /**
   * Milliseconds before we timeout the request.
   */
  timeout: number;
  withCredentials: boolean | undefined;
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: process.env.REACT_APP_API_URL,
  auth: process.env.REACT_APP_AUTH_URL,
  timeout: process.env.REACT_APP_TIMEOUT ? parseInt(process.env.REACT_APP_TIMEOUT) : 3000,
  withCredentials: true,
};

export const MOCK_API_CONFIG: ApiConfig = {
  url: process.env.REACT_APP_MOCK_API_URL,
  auth: process.env.REACT_APP_AUTH_URL,
  timeout: process.env.REACT_APP_TIMEOUT ? parseInt(process.env.REACT_APP_TIMEOUT) : 3000,
  withCredentials: false,
};

export const JEST_API_CONFIG: ApiConfig = {
  url: process.env.REACT_APP_JEST_URL,
  auth: process.env.REACT_APP_AUTH_URL,
  timeout: process.env.REACT_APP_TIMEOUT ? parseInt(process.env.REACT_APP_TIMEOUT) : 3000,
  withCredentials: true,
};
