import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Report
 *
 * Report을 설명하세요.
 */
export const Report = types
  .model('Report')
  // --------------------------------------------------------------------------
  .props({
    reportSid: types.maybeNull(types.optional(types.number, 0)),
    roomId: types.maybeNull(types.optional(types.number, 0)),
    fileId: types.maybeNull(types.optional(types.number, 0)),
    metDt: types.maybeNull(types.optional(types.string, '')),
    place: types.maybeNull(types.optional(types.string, '')),
    agenda: types.maybeNull(types.optional(types.string, '')),
    spec: types.maybeNull(types.optional(types.string, '')),
    reportData: types.maybeNull(types.optional(types.string, '')),
    pdfPassword: types.optional(types.string, ''),
    regDt: types.maybeNull(types.string),
    updDt: types.maybeNull(types.string),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model report --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IReport;
      (Object.keys(newProps) as TReportKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TReport = Instance<typeof Report>;
type TReportSnapshot = SnapshotOut<typeof Report>;

export interface IReport extends TReport {}
export type TReportKeys = keyof TReportSnapshot & string;
export interface IReportSnapshot extends TReportSnapshot {}
export const createReport = () => types.optional(Report, {} as TReport);
