import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { CodeItemModel, ICodeItemModel } from './CodeItem';

export const CodeListModel = types
  .model('CodeListModel', {
    name: types.identifier,
    list: types.array(types.late(() => CodeItemModel)),
  })
  .extend(withEnvironment)
  .views((self) => ({}))
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICodeListModel;
      (Object.keys(newProps) as TCodeListModelKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },

    setName: (name: string) => {
      self.name = name;
    },
    
    setItems : (items: ICodeItemModel[]) => {
      self.list.replace(items);
    }
  }))
;


type TCodeListModel = Instance<typeof CodeListModel>;
type TCodeListModelSnapshot = SnapshotOut<typeof CodeListModel>;
export interface ICodeListModel extends TCodeListModel {}
export type TCodeListModelKeys = keyof TCodeListModelSnapshot & string;
export interface ICodeListModelSnapshot extends TCodeListModelSnapshot {}
