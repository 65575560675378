import { observer } from 'mobx-react-lite';
import { useStores } from '../../../models/root-store/root-store-context';
import { IconButton, Stack, useTheme } from '@mui/material';
import { IParticipant } from 'src/models';
import { ReactComponent as IcoMicOn } from 'src/assets/icons/ico-mic_none.svg';
import { ReactComponent as IcoMicoff } from 'src/assets/icons/ico-mic_off.svg';
import { ReactComponent as IcoVideoOn } from 'src/assets/icons/ico-videocam.svg';
import { ReactComponent as IcoLockOpen } from 'src/assets/icons/ico-lockOpen.svg';
import { ReactComponent as IcoLockClose } from 'src/assets/icons/ico-lockClose.svg';
import { ReactComponent as IcoVideoOff } from 'src/assets/icons/ico-videocam_off.svg';
import ThumbUpAltSharpIcon from '@mui/icons-material/ThumbUpAltSharp';
import { useRef } from 'react';
import { useLocales } from 'src/locales';
import Logout from '@mui/icons-material/Logout';
import Iconify from 'src/components/iconify/Iconify';
/**
 * ## Participants 설명
 *
 */
interface Props {
  participant: IParticipant;
  detaildata: any;
  layout?: (cuid: string) => void;
  type?: string;
  pinMode?: boolean;
}

export const ParticipantsDetail = observer(
  ({ participant, detaildata, layout, type, pinMode }: Props) => {
    const { translate } = useLocales();
    const rootStore = useStores();
    const theme = useTheme();
    const myCUID = sessionStorage.getItem('myCUID');
    const { participantStore } = rootStore;
    const scrollRef = useRef<HTMLDivElement>(null);
    const isMe = myCUID === participant.cuid ? ' ' + translate(`meeting.me`).toString() + ' ' : '';
    const itemAudio = participant.rights.find((item) => {
      return item === 'AUDIO';
    });
    const streamAudio = participant.stream?.activities.find((item) => {
      return item === 'AUDIO';
    });
    const itemVideo = participant.rights.find((item) => {
      return item === 'VIDEO';
    });
    const streamVideo = participant.stream?.activities.find((item) => {
      return item === 'VIDEO';
    });
    const { REACT_APP_API_URL } = process.env;
    const micClick = async (cuid: any, type: any) => {
      if (detaildata.ownerYn === 'Y') {
        let data: any;
        if (type === 'mic') {
          data = {
            cuid: cuid,
            right: 'AUDIO',
          };
        } else {
          data = {
            cuid: cuid,
            right: 'VIDEO',
          };
        }
        const accessToken = localStorage.getItem('accessToken');
        const response = await fetch(`${REACT_APP_API_URL}/web/hostAction/toggleRight`, {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json, text/javascript, */*; q=0.01',
            Authorization: 'Bearer ' + accessToken,
          },
          method: 'POST',
          body: JSON.stringify(data),
        });
        const jsonData = await response.json();
        const responseData = JSON.parse(jsonData.responseData);
        participantStore.updateParticipant({
          id: responseData.uid,
          uid: responseData.uid,
          cuid: responseData.cuid,
          rights: responseData.rights,
        } as IParticipant);
      }
    };

    const onPinAction = async (cuid: any) => {
      layout && layout(cuid);
    };

    const onResign = () => {
      alert('강퇴 기능');
    };

    return (
      <>
        {/* 관리자 시점 */}
        {isMe === '' && detaildata.ownerYn === 'Y' && (
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            style={{ height: '24px', right: '0px' }}
          >
            {/* 음성 */}
            {/* 권한있고 오디오가 꺼져있을때*/}
            {itemAudio === 'AUDIO' && streamAudio !== 'AUDIO' && (
              <IconButton
                edge="end"
                aria-label="mic-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'mic');
                }}
              >
                <IcoMicOn fill={'#919EAB'} />
              </IconButton>
            )}
            {/* 권한있고 오디오가 켜져있을때 */}
            {itemAudio === 'AUDIO' && streamAudio === 'AUDIO' && (
              <IconButton
                edge="end"
                aria-label="mic-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'mic');
                }}
              >
                <IcoMicOn fill={'#33FF33'} />
              </IconButton>
            )}
            {/* 제어시 */}
            {itemAudio !== 'AUDIO' && (
              <IconButton
                edge="end"
                aria-label="mic-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'mic');
                }}
              >
                <IcoMicoff fill={'#FF5630'} />
              </IconButton>
            )}

            {/* 비디오 */}
            {/* 권한 있고 오디오가 꺼져 있을때 */}
            {itemVideo === 'VIDEO' && streamVideo !== 'VIDEO' && (
              <IconButton
                edge="end"
                aria-label="video-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'video');
                }}
              >
                <IcoVideoOn fill={'#919EAB'} />
              </IconButton>
            )}
            {/* 권한 있고 오디오가 켜져 있을때 */}
            {itemVideo === 'VIDEO' && streamVideo === 'VIDEO' && (
              <IconButton
                edge="end"
                aria-label="video-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'video');
                }}
              >
                <IcoVideoOn fill={'#33FF33'} />
              </IconButton>
            )}
            {/* 제어시 */}
            {itemVideo !== 'VIDEO' && (
              <IconButton
                edge="end"
                aria-label="video-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'video');
                }}
              >
                <IcoVideoOff fill={'#FF5630'} />
              </IconButton>
            )}
            {type === 'tile2' && (
              <IconButton
                edge="end"
                aria-label="video-on-off"
                onClick={() => {
                  onPinAction(participant?.cuid);
                }}
              >
                <Iconify
                  icon={'iconoir:pin-solid'}
                  color={pinMode ? theme.palette.primary.main : theme.palette.grey[400]}
                />
                {/* {pinMode &&
                <IcoLockOpen fill={'#FF5630'} />
              }
              {!pinMode &&
                <IcoLockClose fill={'#33FF33'} />
              } */}
              </IconButton>
            )}

            {/* <IconButton
            onClick={onResign}
            size="small"
            aria-haspopup="true"
          >
            <Logout fontSize="small" color="error" />
          </IconButton> */}
          </Stack>
        )}
        {/* 게스트 시점 */}
        {detaildata.ownerYn === 'N' && isMe === '' && (
          <Stack
            spacing={1}
            direction={'row'}
            alignItems={'center'}
            style={{ height: '24px', right: '0px' }}
          >
            {/* 오디오 꺼져있을때 */}
            {streamAudio !== 'AUDIO' && (
              <IconButton
                edge="end"
                aria-label="mic-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'mic');
                }}
              >
                <IcoMicOn fill={'#919EAB'} />
              </IconButton>
            )}
            {/* 오디오 켜져 있을때 */}
            {streamAudio === 'AUDIO' && (
              <IconButton
                edge="end"
                aria-label="mic-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'mic');
                }}
              >
                <IcoMicOn fill={'#33FF33'} />
              </IconButton>
            )}
            {/* 비디오 꺼져있을때 */}
            {streamVideo !== 'VIDEO' && (
              <IconButton
                edge="end"
                aria-label="video-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'video');
                }}
              >
                <IcoVideoOn fill={'#919EAB'} />
              </IconButton>
            )}
            {/* 비디오 켜져 있을때 */}
            {streamVideo === 'VIDEO' && (
              <IconButton
                edge="end"
                aria-label="video-on-off"
                onClick={() => {
                  micClick(participant?.cuid, 'video');
                }}
              >
                <IcoVideoOn fill={'#33FF33'} />
              </IconButton>
            )}
            {type === 'tile2' && (
              <IconButton
                edge="end"
                aria-label="video-on-off"
                onClick={() => {
                  onPinAction(participant?.cuid);
                }}
              >
                <Iconify
                  icon={'iconoir:pin-solid'}
                  color={pinMode ? theme.palette.primary.main : theme.palette.grey[400]}
                />
                {/* {pinMode && <IcoLockOpen fill={'#FF5630'} />}
                {!pinMode && <IcoLockClose fill={'#33FF33'} />} */}
              </IconButton>
            )}
          </Stack>
        )}
      </>
    );
  },
);
export default ParticipantsDetail;
