import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # PushAlarm
 *
 * PushAlarm을 설명하세요.
 */
export const PushAlarm = types
  .model('PushAlarm')
  // --------------------------------------------------------------------------
  .props({
    sendDt: types.maybeNull(types.string),
    subject: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    isRead: types.optional(types.boolean, false),
    category: types.maybeNull(types.string),
    count: types.optional(types.number, 0),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model alarm --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPushAlarm;
      (Object.keys(newProps) as TPushAlarmKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TPushAlarm = Instance<typeof PushAlarm>;
type TPushAlarmSnapshot = SnapshotOut<typeof PushAlarm>;

export interface IPushAlarm extends TPushAlarm {}
export type TPushAlarmKeys = keyof TPushAlarmSnapshot & string;
export interface IPushAlarmSnapshot extends TPushAlarmSnapshot {}
export const createPushAlarm = () => types.optional(PushAlarm, {} as TPushAlarm);
