import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { DatePicker, LocalizationProvider, jaJP, koKR } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { parseISO } from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useLocales } from 'src/locales';

interface Props {
  getData: (data: any) => void;
}

const StatisticsSearchArea = observer(({ getData }: Props) => {
  const { translate } = useLocales();
  const locale = localStorage.getItem('i18nextLng');
  const theme = useTheme();
  const defaultValues = {
    start: moment().format('YYYY-MM-DD'),
    end: moment().format('YYYY-MM-DD'),
  };
  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;
  const onSubmit = async () => {
    if (_.isEmpty(errors)) {
      getData(getValues());
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        width={'100%'}
        direction={'row'}
        sx={{
          alignItems: 'center',
          textAlign: 'left',
          flex: 1,
          gap: 2,
        }}
      >
        <Typography variant="subtitle1">
          {translate('conferenceHistory.period').toString()}
        </Typography>
        <Stack
          direction={'row'}
          alignItems={'center'}
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
            '& input': {
              py: 1,
            },
            '[data-shrink="false"]': {
              transform: 'translate(15px, 8px)',
            },
            '& .MuiTextField-root': {
              width: '100%',
            },
          }}
        >
          <Controller
            name="start"
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <LocalizationProvider
                  dateFormats={
                    locale !== 'en'
                      ? {
                          monthAndYear: `yyyy${translate(
                            'myCalendar.year',
                          ).toString()} MM${translate('myCalendar.month').toString()}`,
                        }
                      : {}
                  }
                  dateAdapter={AdapterDateFns}
                  localeText={
                    locale === 'ko'
                      ? koKR.components.MuiLocalizationProvider.defaultProps.localeText
                      : locale === 'ja'
                      ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
                      : undefined
                  }
                >
                  <DatePicker
                    value={new Date(getValues('start'))}
                    dayOfWeekFormatter={(day) => {
                      return translate(`myCalendar.${day}`).toString();
                    }}
                    format={'yyyy-MM-dd'}
                    maxDate={parseISO(moment().toISOString())}
                    onChange={(e: any) => {
                      setValue('start', moment(e).format('YYYY-MM-DD'));
                    }}
                    defaultValue={parseISO(moment().format('YYYY-MM-DD'))}
                    slotProps={{
                      layout: {
                        sx: {
                          '& .MuiTypography-root[aria-label="Sunday"]': {
                            color: 'red',
                          },
                          '& .MuiDateCalendar-root button[aria-colindex="1"][aria-selected="false"]':
                            {
                              color: 'red',
                            },
                          '& .Mui-disabled[aria-colindex="1"]': {
                            opacity: 0.5,
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              );
            }}
          />
          to
          <Controller
            name="end"
            render={({ field, fieldState: { error } }) => {
              return (
                <LocalizationProvider
                  dateFormats={
                    locale !== 'en'
                      ? {
                          monthAndYear: `yyyy${translate(
                            'myCalendar.year',
                          ).toString()} MM${translate('myCalendar.month').toString()}`,
                        }
                      : {}
                  }
                  dateAdapter={AdapterDateFns}
                  localeText={
                    locale === 'ko'
                      ? koKR.components.MuiLocalizationProvider.defaultProps.localeText
                      : locale === 'ja'
                      ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
                      : undefined
                  }
                >
                  <DatePicker
                    value={new Date(getValues('end'))}
                    dayOfWeekFormatter={(day) => {
                      return translate(`myCalendar.${day}`).toString();
                    }}
                    format={'yyyy-MM-dd'}
                    // minDate={parseISO(getValues('start'))}
                    maxDate={parseISO(moment().toISOString())}
                    onChange={(e: any) => {
                      setValue('end', moment(e).format('YYYY-MM-DD'));
                    }}
                    defaultValue={parseISO(moment().format('YYYY-MM-DD'))}
                    slotProps={{
                      layout: {
                        sx: {
                          '& .MuiTypography-root[aria-label="Sunday"]': {
                            color: 'red',
                          },
                          '& .MuiDateCalendar-root button[aria-colindex="1"][aria-selected="false"]':
                            {
                              color: 'red',
                            },
                          '& .Mui-disabled[aria-colindex="1"]': {
                            opacity: 0.5,
                          },
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
              );
            }}
          />
        </Stack>
        <Stack direction={'row'} alignItems={'center'}>
          <IconButton
            onClick={() => {
              reset();
              setValue('start', moment().format('YYYY-MM-DD'));
              setValue('end', moment().format('YYYY-MM-DD'));
            }}
          >
            <Iconify
              icon={'ph:x-bold'}
              // icon={'grommet-icons:power-reset'}
              sx={{
                // mr: pxToRem(10),
                color: '#999',
              }}
            />
          </IconButton>
          <IconButton
            //   sx={{ minWidth: pxToRem(25), height: pxToRem(25) }}
            onClick={handleSubmit(onSubmit)}
          >
            <Iconify
              icon={'ri:search-line'}
              sx={{
                // mr: pxToRem(10),
                color: theme.palette.secondary.main, //'#999',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
});

export default StatisticsSearchArea;
