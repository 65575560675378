import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState, createRef } from 'react';
import {
  useTheme,
  Container,
  Typography,
  Stack,
  Button,
  Avatar,
  Paper,
  Popover,
  Dialog,
  IconButton,
} from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import MeetingTotalSetting from './MeetingTotalSetting';
import { useLocation } from 'react-router';
import { ReactComponent as IcoClose } from 'src/assets/icons/ico-close.svg';
import { useLocales } from 'src/locales';
import { useStores } from 'src/models';
interface Props {
  handleModalSettingClose: (flag: any) => void;
  fullWidth: any;
  fullScreen: any;
  anchorSet: any;
  returnVideoValue: (
    audioInput: any,
    audioOutput: any,
    videoInput: any,
    videoSize: any,
    settingVideoMic: any,
    settingVideoVol: any,
  ) => void;
  videoMic: any;
  videoVol: any;
  view: any;
  toggleMic: any;
  handleToggleMic: (message: any) => void;
  onToggleMic: any;
  mobile?: any;
  deviceChange?: any;
}

let videostate: any = {};
var aftervideostate: any;
let settingVideoMic: any = 0.7;
let settingVideoVol: any = 0.5;
const streamList: any[] = [];
export const ModalSetting = observer(
  ({
    handleModalSettingClose,
    fullWidth,
    fullScreen,
    anchorSet,
    returnVideoValue,
    videoMic,
    videoVol,
    view,
    toggleMic,
    handleToggleMic,
    onToggleMic,
    mobile,
    deviceChange,
  }: Props) => {
    const { translate } = useLocales();
    const isMobile = useResponsive('down', 'sm');
    const [mediaError, setMediaError] = useState<any>(null);
    const theme = useTheme();
    const rootStore = useStores();
    const { responseStore, errorAlertStore, pushAlarmStore } = rootStore;
    const video = createRef<HTMLVideoElement>();
    const [audioInput, setAudioInput] = useState<string | null>(null);
    const [audioOutput, setAudioOutput] = useState<string | null>(null);
    const [videoInput, setVideoInput] = useState<string | null>(null);
    const [audioInputs, setAudioInputs] = useState<{ label: string; value: string }[]>([]);
    const [audioOutputs, setAudioOutputs] = useState<{ label: string; value: string }[]>([]);
    const [videoInputs, setVideoInputs] = useState<{ label: string; value: string }[]>([]);
    const [videoSize, setVideoSize] = useState<'720p' | '360p'>('360p');
    const [frist, setFrist] = useState(0);
    const [stream, setStream] = useState<any>();
    const AudioCtx = window.AudioContext;
    const [reSetView, setReSetView] = useState('VIDEO');
    const [buttonStatus, setButtonStatus] = useState(false);
    const [testState, setTestState] = useState(false);
    const openSet = Boolean(anchorSet);
    const { state } = useLocation();

    const videoResolutions = {
      '720p': {
        width: 1280,
        height: 720,
      },
      '360p': {
        width: 640,
        height: 360,
      },
    };

    useEffect(() => {
      if (videoMic !== undefined) {
        settingVideoMic = videoMic;
        console.log('videoVol', videoVol);
        if (videoVol !== undefined) {
          settingVideoVol = videoVol;
        } else {
          settingVideoVol = 0.7;
        }
      }
      navigator.mediaDevices.enumerateDevices().then(gotDevices);
    }, [deviceChange]);

    useEffect(() => {
      if (testState === true) {
        onMediaDevices(undefined, true);
      } else if (audioInput !== null && settingVideoMic !== undefined) {
        onMediaDevices();
      }
    }, [audioInput, audioOutput, videoInput]);

    const onButtonValue = (flag: boolean) => {
      if (flag === true && testState) {
        onMediaDevices();
      }
    };

    const onTestState = (flag: boolean) => {
      setTestState(flag);
    };

    useEffect(() => {
      if (audioInputs !== null) {
        const userInfo = JSON.parse(localStorage.getItem('user') || '');
        if (
          userInfo.authCd === 'GUEST' ||
          userInfo.authCd === '8' ||
          userInfo.authCd === '9' ||
          userInfo.authCd === null
        ) {
          setAudioInput(localStorage.getItem('audioInput'));
          setVideoInput(localStorage.getItem('videoInput'));
          setAudioOutput(localStorage.getItem('audioOutput'));
          if (localStorage.getItem('videoSize') === '720p') {
            setVideoSize('720p');
          } else {
            setVideoSize('360p');
          }
          if (state.modalVideoMic !== null && state.modalVideoMic !== undefined) {
            settingVideoMic = state.modalVideoMic;
            console.log('state.modalVideoVol', state.modalVideoVol);
            settingVideoVol = state.modalVideoVol;
          }
        } else {
          onVideoSetting();
        }
      }
    }, [audioInputs]);

    const onVideoSetting = async () => {
      if (frist > 0) {
        const userInfo = JSON.parse(localStorage.getItem('user') || '');
        if (
          userInfo.authCd === 'GUEST' ||
          userInfo.authCd === '8' ||
          userInfo.authCd === '9' ||
          userInfo.authCd === null
        ) {
        } else {
          setTimeout(async () => {
            const { REACT_APP_API_URL } = process.env;
            const accessToken = localStorage.getItem('accessToken');
            const response = await fetch(`${REACT_APP_API_URL}/web/env`, {
              headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json, text/javascript, */*; q=0.01',
                Authorization: 'Bearer ' + accessToken,
              },
              method: 'GET',
            });
            const jsonData = await response.json();
            if (jsonData.error) {
              responseStore.setResponseInfo(jsonData);
              errorAlertStore.setErrorAlertFromResponse();
            } else {
              for (var i = 0; videoInputs.length > i; i++) {
                if (videoInputs[i].value === jsonData.responseData.deviceVideoId) {
                  setVideoInput(jsonData.responseData.deviceVideoId);
                }
              }
              for (var i = 0; audioInputs.length > i; i++) {
                if (audioInputs[i].value === jsonData.responseData.deviceAudioId) {
                  setAudioInput(jsonData.responseData.deviceAudioId);
                }
              }
              for (var i = 0; audioOutputs.length > i; i++) {
                if (audioOutputs[i].value === jsonData.responseData.deviceSpeakerId) {
                  setAudioOutput(jsonData.responseData.deviceSpeakerId);
                }
              }
              state.modalVideoVol = jsonData.responseData.micLevel;
              state.modalVideoMic = jsonData.responseData.volLevel;
              setVideoSize(jsonData.responseData.resolution);
            }
          }, 100);
        }
      } else {
        setFrist(frist + 1);
      }
    };

    const onMediaDevices = (sound?: any, flag?: any) => {
      const video = document.getElementById('videoSetting') as HTMLVideoElement;
      if (video) {
        video.srcObject = null;
      }
      if (stream) {
        stream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
      if (streamList) {
        for (let i = 0; streamList.length > i; i++) {
          streamList[i].getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
      let audioOption: any;
      let videoOption: any;

      audioOption = {
        // echoCancellation: true,
        // autoGainControl: true,
        // noiseSuppression: true,
        deviceId: audioInput,
        sampleRate: pushAlarmStore.getSampleRateData() ? pushAlarmStore.getSampleRateData() : 48000,
        echoCancellation: {exact: true},
        autoGainControl: {exact :false},
        noiseSuppression: {exact: false}, 
        highpassFilter: {exact :false},
      };
      videoOption = {
        deviceId: videoInput,
        width: videoResolutions[videoSize].width,
        height: videoResolutions[videoSize].height,
      };

      const constraints = {
        audio: audioOption,
        video: videoOption,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          videostate.localStream = stream;
          let _stream = stream;
          const data: any = {};
          if (stream.getAudioTracks().length !== 0) {
            data.aCtx = new AudioCtx({
              sampleRate: pushAlarmStore.getSampleRateData()
                ? pushAlarmStore.getSampleRateData()
                : 48000,
            });
            data.gainNode = data.aCtx.createGain();
            if (sound === undefined) {
              data.gainNode.gain.value = settingVideoMic;
            } else {
              data.gainNode.gain.value = sound;
            }
            data.analyser = data.aCtx.createAnalyser();
            data.aSrc = data.aCtx.createMediaStreamSource(stream);
            data.aSrc.connect(data.gainNode);
            data.gainNode.connect(data.analyser);
            data.aDest = data.aCtx.createMediaStreamDestination();
            data.analyser.connect(data.aDest);
            _stream = data.aDest.stream;
            stream.getVideoTracks().forEach(function (track) {
              _stream.addTrack(track);
            });
          }
          videostate.data = data;
          videostate.stream = _stream;
          aftervideostate = videostate;
          const video = document.getElementById('videoSetting') as HTMLVideoElement;
          if (video) {
            video.srcObject = videostate.stream;
          }
          streamList.push(stream);
          gotStream(stream);
          if (flag === true) {
            if (toggleMic === true) {
              handleToggleMic(false);
              onToggleMic();
            }
          }
          video.addEventListener('play', () => {
            let VideoVol = document.getElementById('videoSetting') as HTMLVideoElement;
            if (flag === true) {
              VideoVol.volume = 0.5;
            } else {
              VideoVol.volume = 0;
            }
          });
        })
        .catch(function (err) {
          console.log('err');
        });
    };

    useEffect(() => {
      const video: any = document.getElementById('videoSetting');
      if (video) {
        if (typeof video.sinkId !== 'undefined' && audioOutput !== null) {
          video
            .setSinkId(audioOutput)
            .then(() => {
              console.log(`Success, audio output device attached: ${audioOutput}`);
            })
            .catch((error: any) => {
              let errorMessage = error;
              if (error.name === 'SecurityError') {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              setAudioInput('');
            });
        } else {
          console.warn('Browser does not support output device selection.');
        }
      }
    }, [audioOutput]);

    const gotStream = (_stream: any) => {
      setStream((stream: any) => _stream);
    };

    const gotDevices = async (deviceInfos: any) => {
      const aInputs: any[] = [];
      const aOutputs: any[] = [];
      const vInputs: any[] = [];
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        const option = document.createElement('option');
        option.value = deviceInfo.deviceId;

        if (deviceInfo.kind === 'audioinput') {
          option.text = deviceInfo.label || `microphone ${aInputs.length + 1}`;
          aInputs.push(option);
        } else if (deviceInfo.kind === 'audiooutput') {
          option.text = deviceInfo.label || `speaker ${aOutputs.length + 1}`;
          aOutputs.push(option);
        } else if (deviceInfo.kind === 'videoinput') {
          option.text = deviceInfo.label || `camera ${vInputs.length + 1}`;
          vInputs.push(option);
        }
      }
      setAudioInputs((audioInputs) => aInputs);
      setAudioOutputs((audioOutputs) => aOutputs);
      setVideoInputs((videos) => vInputs);
      setAudioInput((audioInput) => (aInputs.length > 0 ? aInputs[0].value : null));
      setAudioOutput((audioOutput) => (aOutputs.length > 0 ? aOutputs[0].value : null));
      setVideoInput((videoInput) => (vInputs.length > 0 ? vInputs[0].value : null));
    };

    const returnVideoInputData = (videoInput: string | null, value: any, label: any) => {
      setVideoInput((videoInput) => value);
    };
    const returnVideoSizeData = (videoSize: string | null, value: any, label: any) => {
      setVideoSize((videoSize) => label);
    };

    const returnAudioInputData = (audioInput: string | null, value: any, label: any) => {
      setAudioInput((audioInput) => value);
    };
    const returnAudioOutputData = (audioOutput: string | null, value: any, label: any) => {
      setAudioOutput((audioOutput) => value);
    };

    const returnAudioInputStream = (Inputstate: any | null, type: string | null | undefined) => {
      state.modalVideoMic = Inputstate.toFixed(1) * 1;
      settingVideoMic = Inputstate.toFixed(1) * 1;
      if (type === 'Slider') {
      } else {
        onMediaDevices(Inputstate.toFixed(1) * 1, true);
      }
    };

    const returnAudioOutputStream = (Outputstate: any | null, type: string | null | undefined) => {
      state.modalVideoVol = Outputstate.toFixed(1) * 1;
      settingVideoVol = Outputstate.toFixed(1) * 1;
      if (type === 'Slider') {
      } else {
        onMediaDevices(Outputstate.toFixed(1) * 1, true);
      }
    };

    const onReSetView = (type: any) => {
      setReSetView(type);
    };

    const onClose = () => {
      const video = document.getElementById('videoSetting') as HTMLVideoElement;
      if (video) {
        video.srcObject = null;
      }
      if (stream) {
        stream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
      if (streamList) {
        for (let i = 0; streamList.length > i; i++) {
          streamList[i].getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
      handleModalSettingClose(null);
    };

    const onButtonStatus = (flag: any) => {
      setButtonStatus(flag);
    };

    return (
      <>
        <Dialog
          open={openSet}
          maxWidth={'md'}
          fullWidth
          scroll="body"
          PaperProps={{
            sx: {
              borderRadius: 4,
            },
          }}
        >
          <Popover
            anchorEl={anchorSet}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSet}
            style={{
              transform: 'translateY(-20px)',
            }}
            onClose={onClose}
          >
            <Typography
              variant="h4"
              sx={{
                background: theme.palette.background.neutral,
                p: 2,
                alignItems: 'center',
              }}
            >
              {translate(`meeting.videoSetting`).toString()}
              <IconButton
                onClick={onClose}
                sx={{ position: 'absolute', top: 8, right: 8 }}
                color={'inherit'}
                disabled={buttonStatus}
              >
                <IcoClose stroke={'#637381'} />
              </IconButton>
            </Typography>
            <Paper>
              <Container
                sx={{
                  display: 'flex',
                  flex: 1,
                  textAlign: 'left',
                  py: 2,
                }}
              >
                {mediaError === null ? (
                  <Box
                    sx={{
                      borderRadius: 2,
                      backgroundImage: 'url(/assets/ico-avatar.svg)',
                      backgroundPosition: '50% 50%',
                      backgroundRepeat: 'no-repeat',
                      backgroundColor: '#000',
                      height: fullScreen,
                      width: fullWidth,
                    }}
                  >
                    <video
                      ref={video}
                      id={'videoSetting'}
                      autoPlay={true}
                      controls={false}
                      playsInline
                      style={{
                        height: fullScreen,
                        width: fullWidth,
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flex: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: theme.palette.background.neutral,
                      borderRadius: 2,
                    }}
                  >
                    <Stack
                      spacing={2}
                      sx={{
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar
                        sx={{
                          alignSelf: 'center',
                        }}
                      ></Avatar>
                      <Typography variant={'body2'}>{mediaError}</Typography>
                    </Stack>
                  </Box>
                )}
              </Container>
              {aftervideostate && (
                <MeetingTotalSetting
                  audioInputs={audioInputs}
                  audioInput={audioInput}
                  audioOutput={audioOutput}
                  audioOutputs={audioOutputs}
                  aftervideostate={aftervideostate}
                  videoInputs={videoInputs}
                  videoInput={videoInput}
                  videoSize={videoSize}
                  returnVideoInputData={returnVideoInputData}
                  returnVideoSizeData={returnVideoSizeData}
                  returnAudioInputData={returnAudioInputData}
                  returnAudioOutputData={returnAudioOutputData}
                  returnAudioInputStream={returnAudioInputStream}
                  returnAudioOutputStream={returnAudioOutputStream}
                  anchorSet={anchorSet}
                  videoMic={settingVideoMic}
                  videoVol={settingVideoVol}
                  view={view}
                  reSetView={onReSetView}
                  onButtonStatus={onButtonStatus}
                  buttonStatus={buttonStatus}
                  onTestState={onTestState}
                  onButtonValue={onButtonValue}
                  toggleMic={toggleMic}
                  mobile={mobile}
                />
              )}
              <Stack
                display={'flex'}
                justifyContent={'flex-end'}
                direction={'row'}
                sx={{ marginRight: 2, marginBottom: 2 }}
                gap={2}
              >
                <Button
                  variant="soft"
                  size={isMobile ? 'medium' : 'large'}
                  sx={{ px: 4 }}
                  disabled={buttonStatus}
                  onClick={() => {
                    const video = document.getElementById('videoSetting') as HTMLVideoElement;
                    if (video) {
                      video.srcObject = null;
                    }
                    if (stream) {
                      stream.getTracks().forEach((track: any) => {
                        track.stop();
                      });
                    }
                    if (streamList) {
                      for (let i = 0; streamList.length > i; i++) {
                        streamList[i].getTracks().forEach((track: any) => {
                          track.stop();
                        });
                      }
                    }
                    handleModalSettingClose(null);
                  }}
                >
                  {translate(`meeting.cancel`).toString()}
                </Button>
                <Button
                  variant="contained"
                  size={isMobile ? 'medium' : 'large'}
                  sx={{ px: 4 }}
                  id={'setting'}
                  disabled={buttonStatus}
                  onClick={() => {
                    const video = document.getElementById('videoSetting') as HTMLVideoElement;
                    if (video) {
                      video.srcObject = null;
                    }
                    if (stream) {
                      stream.getTracks().forEach((track: any) => {
                        track.stop();
                      });
                    }
                    if (streamList) {
                      for (let i = 0; streamList.length > i; i++) {
                        streamList[i].getTracks().forEach((track: any) => {
                          track.stop();
                        });
                      }
                    }
                    if (audioInput) {
                      localStorage.setItem('audioInput', audioInput);
                    }
                    if (videoInput) {
                      localStorage.setItem('videoInput', videoInput);
                    }
                    if (audioOutput) {
                      localStorage.setItem('audioOutput', audioOutput);
                    }
                    if (videoSize) {
                      localStorage.setItem('videoSize', videoSize);
                    }
                    console.log('settingVideoVol', settingVideoVol);
                    returnVideoValue(
                      audioInput,
                      audioOutput,
                      videoInput,
                      videoSize,
                      settingVideoMic,
                      settingVideoVol,
                    );
                    handleModalSettingClose(null);
                  }}
                >
                  {translate(`meeting.apply`).toString()}
                </Button>
              </Stack>
            </Paper>
          </Popover>
        </Dialog>
      </>
    );
  },
);

export default ModalSetting;
