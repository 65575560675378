import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import {
  Button,
  Container,
  FormControlLabel,
  FormLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import FormProvider from 'src/components/hook-form';
import CTextField from 'src/components/forms/CTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import _ from 'lodash';
import CAlert from 'src/components/CAlert';
import { useAuthContext } from 'src/auth/useAuthContext';
import CHeader from 'src/components/CHeader';
import useResponsive from 'src/hooks/useResponsive';
import { HEADER } from 'src/config-global';
import Logout from '@mui/icons-material/Logout';
import { useLocales } from 'src/locales';
import { useNavigate } from 'react-router';
import { sendReactNativeMessage } from 'src/utils/common';
import { useStores } from 'src/models';

type userInfoType = {
  pushYn: string;
  locale: string;
  displayName: string;
  password: string | null;
  newPassword: string | null;
  deviceToken: string;
};

/**
 * ## MyInfo 설명
 *
 */
export const MyInfo = observer(() => {
  const { REACT_APP_API_URL } = process.env;
  const rootStore = useStores();
  const { responseStore } = rootStore;
  const auth = useAuthContext();
  const { translate } = useLocales();
  const navigate = useNavigate();
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();

  const MyInfoSchema = Yup.object({
    /**기존 비밀번호 입력 validation*/
    password: Yup.string()
      .nullable()
      .when('newPassword', (newPassword, field) =>
        newPassword
          ? field.required('')
          : field.transform((value: string) => (value === '' ? null : value)),
      ),
    /**새로운 비밀번호 입력 validation*/
    newPassword: Yup.string()
      .nullable()
      .test('password', translate('myPage.validation.match').toString(), (value: any) => {
        if (!value) {
          return true;
        }
        const hasUppercase = /[A-Z]/.test(value);
        const hasLowercase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSpecialChar = /[^A-Za-z0-9]/.test(value);

        return (
          (hasUppercase ? 1 : 0) +
            (hasLowercase ? 1 : 0) +
            (hasNumber ? 1 : 0) +
            (hasSpecialChar ? 1 : 0) >=
          3
        );
      })
      .min(8, translate('myPage.validation.min').toString())
      .trim()

      .transform((value) => (value === '' ? null : value)),
    /**새로운 비밀번호 확인 validation*/
    newPassword_confirm: Yup.string()
      .nullable()
      .trim()
      .transform((value) => (value === '' ? null : value))
      .when('newPassword', (newPassword, field) => (newPassword ? field.required('') : field))
      .equals([Yup.ref('newPassword')], translate('myPage.validation.equal').toString()),
  });

  // localstorage user정보 파싱 후 defaultValue setting
  const userInfo = JSON.parse(localStorage.getItem('user') || '');
  const [locale, setLocale] = useState(userInfo.locale);
  const [pushYn, setPushYn] = useState(userInfo.pushYn);
  const defaultValues = {
    displayName: userInfo.displayName ? userInfo.displayName : userInfo.loginId,
    password: '',
    newPassword: '',
    locale: locale,
    pushYn: pushYn,
  };

  const methods = useForm<userInfoType>({
    mode: 'all',
    resolver: yupResolver(MyInfoSchema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    formState: { errors },
  } = methods;

  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isErrorAlert, setIsErrorAlert] = useState(false);
  const [isExpiredAlert, setIsExpiredAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  const user = JSON.parse(localStorage.getItem('user') || '');

  const onSubmit = async (data: userInfoType) => {
    if (_.isEmpty(errors)) {
      const newData = { ...data, deviceToken: 'token1234', locale: locale, pushYn: pushYn };

      await fetch(`${REACT_APP_API_URL}/web/user/updateUserInfo`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}` || '',
        },
        method: 'POST',
        body: JSON.stringify(newData),
      }).then(async (res) => {
        const temp: any = await res.json();
        if (temp.responseData === 'success') {
          localStorage.setItem('user', JSON.stringify({ ...user, locale: locale, pushYn: pushYn }));
          localStorage.setItem('i18nextLng', locale);
          sendReactNativeMessage({
            type: 'i18nextLng',
            payload: {
              locale: locale,
            },
          });
          // window.location.reload(); // 페이지 새로고침 -> 캘린더 언어적용
          if (getValues('password')) {
            auth.logout();
          }
        } else {
          setAlertMsg(translate(`error.${temp.error}`).toString());
          if (temp.error === 'user030') {
            responseStore.getResponseResult({
              error: temp.error,
              exceptionDetail: temp.exceptionDetail,
            });
          }
          if (temp.error === 'common001') {
            setIsExpiredAlert(true);
          } else {
            setIsErrorAlert(true);
          }
        }
      });
    }
  };

  const headerOptions: any = {
    showMainIcon: 'back',
    title: translate(`myPage.mainTitle`).toString(),
    showHomeIcon: true,
  };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Stack
          direction="column"
          spacing={2}
          sx={{
            p: 0,
            m: '0 !important',
            pb: {
              xs: HEADER.H_MOBILE + 'px',
              md: HEADER.H_MAIN_DESKTOP + 'px',
            },
          }}
        >
          {isMobile ? (
            <CHeader {...headerOptions} />
          ) : (
            <Typography variant={'h5'} sx={{ pt: 4, pb: 2 }}>
              {translate(`myPage.mainTitle`).toString()}
            </Typography>
          )}
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Stack
                sx={{
                  display: 'flex',
                  width: '100%',
                  maxWidth: 'sm',
                  [theme.breakpoints.down('sm')]: {
                    '& .MuiList-root': {
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    },
                    '& label': { minWidth: 0, ml: 2 },
                  },
                }}
              >
                <List sx={{ gap: 1 }}>
                  <Label>{translate(`myPage.name`).toString()}</Label>
                  <ListItem
                    sx={{
                      gap: 2,
                      '& :before': { display: 'none' },
                      '& :after': { display: 'none' },
                    }}
                  >
                    <CTextField name={'displayName'} label={''} variant={'standard'} readonly />
                  </ListItem>
                  <Label>{translate(`myPage.changePw`).toString()}</Label>
                  <ListItem
                    sx={{
                      gap: 2,
                    }}
                  >
                    <CTextField
                      type={'password'}
                      name={'password'}
                      label={translate(`myPage.currentPw`).toString()}
                      variant={'outlined'}
                      placeholder={translate(`myPage.enterCurrentPw`).toString()}
                    />
                  </ListItem>
                  <ListItem
                    sx={{
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        width: '100%',
                        '& .MuiFormHelperText-root': { height: 2, m: 0.5 },
                      }}
                    >
                      <CTextField
                        type={'password'}
                        name={'newPassword'}
                        label={translate(`myPage.newPw`).toString()}
                        variant={'outlined'}
                        placeholder={translate(`myPage.requirements`).toString()}
                      />
                    </Box>
                  </ListItem>
                  <ListItem
                    sx={{
                      gap: 2,
                      '& .MuiFormHelperText-root': { height: 2, m: 0.5 },
                    }}
                  >
                    <CTextField
                      type={'password'}
                      name={'newPassword_confirm'}
                      label={translate(`myPage.confirmPw`).toString()}
                      variant={'outlined'}
                      placeholder={translate(`myPage.reEnterPw`).toString()}
                    />
                  </ListItem>
                  <Label>{translate(`myPage.language`).toString()}</Label>
                  <ListItem
                    sx={{
                      gap: 2,
                    }}
                  >
                    <RadioGroup
                      name={'locale'}
                      sx={{ flexDirection: 'row' }}
                      onChange={(e: any) => {
                        setLocale(e.target.value);
                      }}
                      value={locale}
                      defaultChecked
                      defaultValue={locale}
                    >
                      <FormControlLabel
                        value={'ja'}
                        control={<Radio />}
                        label={translate('myPage.japanese').toString()}
                      />
                      <FormControlLabel
                        value={'en'}
                        control={<Radio />}
                        label={translate('myPage.english').toString()}
                      />
                      {/* {
                        //일본용 -주석처리 해서 배포
                        <FormControlLabel
                          value={'ko'}
                          control={<Radio />}
                          label={translate('myPage.korean').toString()}
                        />
                      } */}
                    </RadioGroup>
                  </ListItem>
                  <Label>{translate(`myPage.receiveStatus`).toString()}</Label>
                  <ListItem sx={{ gap: 2 }}>
                    <RadioGroup
                      name={'pushYn'}
                      sx={{ flexDirection: 'row' }}
                      onChange={(e: any) => {
                        setPushYn(e.target.value);
                      }}
                      value={pushYn}
                      defaultChecked
                      defaultValue={pushYn}
                    >
                      <FormControlLabel
                        value={'Y'}
                        control={<Radio />}
                        label={translate(`myPage.receiveY`).toString()}
                      />
                      <FormControlLabel
                        value={'N'}
                        control={<Radio />}
                        label={translate(`myPage.receiveN`).toString()}
                      />
                    </RadioGroup>
                  </ListItem>
                </List>
                <Box sx={{ textAlign: 'right', p: 2 }}>
                  <Button onClick={auth.logout} variant={'outlined'} sx={{ mr: 1 }}>
                    <Logout fontSize="small" sx={{ mr: 0.5 }} />
                    {translate(`myPage.logout`).toString()}
                  </Button>
                  <Button
                    onClick={() => {
                      setAlertMsg(translate(`myPage.sveAlert`).toString());
                      setIsAlertOpen(true);
                    }}
                    variant={'contained'}
                  >
                    {translate(`myPage.save`).toString()}
                  </Button>
                </Box>
              </Stack>
            </Box>
          </FormProvider>
        </Stack>
      </Container>
      <CAlert
        open={isAlertOpen}
        content={alertMsg}
        hasCancel
        callBack={handleSubmit(onSubmit)}
        handleClose={() => setIsAlertOpen(false)}
      />
      {isErrorAlert && (
        <CAlert
          open={isErrorAlert}
          content={alertMsg}
          handleClose={() => {
            setIsAlertOpen(false);
            setIsErrorAlert(false);
          }}
        />
      )}
      {isExpiredAlert && (
        <CAlert
          open={isExpiredAlert}
          content={alertMsg}
          hasCancel
          callBack={() => {
            auth.logout();
            window.location.replace('/');
          }}
          handleClose={() => {
            setIsExpiredAlert(false);
          }}
        />
      )}
    </>
  );
});

const Label = styled(FormLabel)(({ theme }) => ({
  minWidth: 130,
  whiteSpace: 'nowrap',
  marginBottom: 8,
  color: '#919EAB',
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

export default MyInfo;
