import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createRoom } from '../room/Room';
import { createUser } from '../user/User';

/**
 * # Chat
 *
 * Chat을 설명하세요.
 */
export const Chat = types
  .model('Chat')
  // --------------------------------------------------------------------------
  .props({
    sent: types.optional(types.string, ''),
    displayName: types.optional(types.string, ''),
    message: types.maybeNull(types.optional(types.string, '')),

    id: types.optional(types.number, 0),
    from: types.maybeNull(createUser()),
    // fromUser: types.maybeNull(createUser()),
    toRoom: types.maybeNull(createRoom()),
    toUser: types.maybeNull(createUser()),
    fromName: types.maybeNull(types.optional(types.string, '')),
    status: types.maybeNull(types.optional(types.string, '')),
    self: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model chat --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IChat;
      (Object.keys(newProps) as TChatKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TChat = Instance<typeof Chat>;
type TChatSnapshot = SnapshotOut<typeof Chat>;

export interface IChat extends TChat {}
export type TChatKeys = keyof TChatSnapshot & string;
export interface IChatSnapshot extends TChatSnapshot {}
export const createChat = () => types.optional(Chat, {} as TChat);
