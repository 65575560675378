import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { createUser } from "../user/User";

/**
 * # Stream
 *
 * Stream을 설명하세요.
 */
export const Stream = types
  .model("Stream")
  // --------------------------------------------------------------------------
  .props({
    activities: types.optional(types.array(types.string), []),
    cam: types.maybeNull(types.number),
    uid: types.optional(types.string, ''),
    cuid: types.optional(types.string, ''),
    type: types.optional(types.string, ''),
    sessionId: types.optional(types.string, ''),
    height: types.maybeNull(types.number),
    level: types.maybeNull(types.number),
    mic: types.maybeNull(types.number),
    self: types.optional(types.boolean, false),
    user: createUser(),
    width: types.maybeNull(types.number),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model stream --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IStream;
      (Object.keys(newProps) as TStreamKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TStream = Instance<typeof Stream>;
type TStreamSnapshot = SnapshotOut<typeof Stream>

export interface IStream extends TStream { }
export type TStreamKeys = keyof TStreamSnapshot & string;
export interface IStreamSnapshot extends TStreamSnapshot { }
export const createStream = () =>
  types.optional(Stream, {

  } as TStream);
