// import { Withdrawal } from './../../screens/user/withdrawal/Withdrawal';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CodeApi } from 'src/services/code/Code';
import {
  TGetCodeGroupResult,
  TGetCodesResult,
  TGetCodeDataResult,
  TGetRoomTypeResult,
} from 'src/services/code/CodeTypes';
import { Code, ICode, createCode } from '../code/Code';

import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { CodeGroup, ICodeGroup } from '../code-group/CodeGroup';
import { CodeData, ICodeData } from '../code-data/CodeData';

/**
 * # CodeStore
 *
 * CodeStore을 설명하세요.
 */
export const CodeStore = types
  .model('CodeStore')
  // --------------------------------------------------------------------------
  .props({
    codes: types.optional(types.array(Code), []),
    codeGroup: types.optional(types.array(CodeGroup), []),
    codeData: types.optional(types.array(types.string), []),
    code: createCode(),
    roomType: types.optional(types.array(CodeData), []),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * codes을 교체
     *
     * @param `codes` 새로운 모델의 배열
     */
    setCodes: (codes: ICode[]) => {
      self.codes.replace(codes);
    },

    setCodeGroup: (codeGroup: ICodeGroup[]) => {
      self.codeGroup.replace(codeGroup);
    },

    setCodeData: (codeData: any) => {
      self.codeData.replace(codeData);
    },

    setRoomType: (roomType: ICodeData[]) => {
      self.roomType.replace(roomType);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Codes를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      const codeApi: CodeApi = new CodeApi(self.environment.api);
      const result: TGetCodesResult = await codeApi.gets();
      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          self.setCodes(result.codes);
        } else {
          console.error(result.kind);
        }
      }
    },
    getsCodeGroup: async () => {
      const codeApi: CodeApi = new CodeApi(self.environment.api);
      const result: TGetCodeGroupResult = await codeApi.getsCodeGroup();
      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          self.setCodeGroup(result.codeGroup);
        } else {
          console.error(result.kind);
        }
      }
    },

    // getsPhonePrefix: async () => {
    //   const codeApi: CodeApi = new CodeApi(self.environment.api);
    //   const result: TGetCodeDataResult = await codeApi.getsPhonePrefix();
    //   if (result.kind === 'ok') {
    //     self.setCodeData(result.codeData);
    //     console.log("result.codeData : ", result.codeData);
    //     console.log("codeData : ", CodeData)
    //   } else {
    //     console.error(result.kind);
    //   }
    // },

    /** 그룹 코드 목록 조회 */
    getGlobalGroupCode: async (globalGroupTypeCd: number) => {
      const codeApi: CodeApi = new CodeApi(self.environment.api);
      const result: TGetCodeGroupResult = await codeApi.getGlobalGroupCode(globalGroupTypeCd);

      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          self.setCodeGroup(result.codeGroup);
        } else {
          console.error(result.kind);
        }
      }
    },

    /** 그룹 코드 목록 조회 */
    getRoomType: async () => {
      const codeApi: CodeApi = new CodeApi(self.environment.api);
      const result: TGetRoomTypeResult = await codeApi.getRoomType();
      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          if (result.roomType) {
            self.setRoomType(result.roomType);
          }
        } else {
          console.error(result.kind);
        }
      }
    },
  }));

// --------------------------------------------------------------------------
type TCodeStore = Instance<typeof CodeStore>;
type TCodeStoreSnapshot = SnapshotOut<typeof CodeStore>;

export interface ICodeStore extends TCodeStore {}
export type TCodeStoreKeys = keyof TCodeStoreSnapshot & string;
export interface ICodeStoreSnapshot extends TCodeStoreSnapshot {}
export const createCodeStore = () => types.optional(CodeStore, {} as TCodeStore);
