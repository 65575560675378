import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import RefreshIcon from '@mui/icons-material/Refresh';

export interface selectOptions {
  code: string | number | boolean | null;
  value: string;
  pcode: string | number | null;
}

export const defaultOption: selectOptions = {
  code: '',
  value: '',
  pcode: '',
};

interface CTextFieldProps {
  id?: string;
  label: string;
  name: string;
  hasFocus?: boolean;
  placeholder?: string;
  disabled?: boolean;
  readonly?: boolean;
  multiline?: boolean;
  multilineHeight?: number;
  type?: 'password' | 'number' | 'email' | '';
  autoComplete?: string;
  onChangeCallback?: Function;
  onEnterCallback?: Function;
  onFocusCallback?: Function;
  selectClearFunction?: Function;
  unitString?: string;
  code?: boolean;
  options?: selectOptions[];
  raw?: string | number | null;
  color?: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
  variant?: 'standard' | 'filled' | 'outlined' | undefined;
  required?: boolean;
  help?: boolean;
  className?: string;
  sx?: any;
  endAdornment?: any;
  startAdornment?: any;
  resizeType?: `none` | `both` | `horizontal` | `vertical` | `block` | `inline`;
  maxLength?: number | null;
}

/**
 * ## 기본 사용법
 *
 * > 단일 값을 입력하는 셀렉트 박스입니다.기본 텍스트 필드입니다.
 *
 */
const CTextField = observer(function CTextFieldProps({
  id = Math.random().toString(),
  name,
  label,
  hasFocus = false,
  placeholder = '',
  disabled = false,
  readonly = false,
  multiline = false,
  multilineHeight = 150,
  type = '',
  autoComplete = 'on',
  onChangeCallback,
  onEnterCallback,
  onFocusCallback,
  selectClearFunction,
  unitString,
  code,
  options,
  raw,
  color,
  variant = 'standard',
  required = false,
  help = true,
  className,
  sx,
  endAdornment,
  startAdornment,
  resizeType = 'vertical',
  maxLength,

  ...other
}: CTextFieldProps) {
  const { control, setValue, clearErrors } = useFormContext();
  const height = multiline ? multilineHeight : 'auto';

  const [pwdVisible, setPwdVisible] = useState<boolean>(false);

  const [inputType, setInputType] = useState<'password' | 'number' | 'email' | ''>(type);

  const theme = useTheme();

  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (type === 'password') {
      if (pwdVisible) {
        setInputType('');
      } else {
        setInputType('password');
      }
    }
  }, [pwdVisible]);

  function checkNumber(event: any) {
    if (event.target.value >= 0 && event.target.value <= 9) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (ref.current) {
      hasFocus ? ref.current.focus() : ref.current.blur();
    }
  }, [hasFocus])

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onBlur, onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl sx={{
            flexDirection: 'column', width: '100%'
          }}>
            <TextField
              id={id}
              inputRef={ref}
              label={label}
              size="small"
              variant={variant}
              type={inputType}
              placeholder={placeholder}
              disabled={disabled}
              color={color}
              required={required}
              InputProps={{
                startAdornment: startAdornment || (<></>),
                endAdornment: endAdornment || (
                  <>
                    {type === 'password' && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="password visibility"
                          onClick={() => setPwdVisible(!pwdVisible)}
                        >
                          {pwdVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>
                      </InputAdornment>
                    )}
                    {selectClearFunction && (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="selected Refresh"
                          onClick={() => {
                            selectClearFunction();
                            clearErrors(name);
                          }}
                        >
                          <RefreshIcon />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
                readOnly: readonly,
                className: className,
              }}
              inputProps={{
                'aria-label': label,
                readOnly: readonly,
                style: {
                  flex: 1,
                  width: '100%',
                  resize: resizeType,
                  height: height,
                  overflow: 'auto',
                  ...sx,
                },
                autoComplete: { autoComplete },
                maxLength,
              }}
              value={
                raw
                  ? raw
                  : value
                    ? options
                      ? code
                        ? options.filter((values) => values.code === value.code)[0]
                          ? options.filter((values) => values.code === value.code)[0].value
                          : defaultOption.value
                        : options.filter((values) => values.code === value)[0]
                          ? options.filter((values) => values.code === value)[0].value
                          : defaultOption.value
                      : unitString
                        ? value + ' ' + unitString
                        : value
                    : ''
              }
              // defaultValue={defaultValue}
              onBlur={onBlur}
              onChange={(e) => {
                if (type === 'number' && e.target.value === '') {
                  onChange({ target: { name, value: null } });
                  onChangeCallback && onChangeCallback(e);
                } else {
                  onChange({ target: { name, value: e.target.value } });
                  onChangeCallback && onChangeCallback({ target: { name, value: e.target.value } });
                }
              }}
              onKeyDown={(e: any) => {
                onEnterCallback && onEnterCallback(e);
              }}
              onFocus={(e: any) => {
                onFocusCallback && onFocusCallback();
              }}
              multiline={multiline}
              error={!!error}
              // helperText={() => {
              // }}
              {...other}
            />
            {help === true && (
              <FormHelperText error variant="filled">
                {error?.message}
              </FormHelperText>
            )}
          </FormControl >
        );
      }}
    />
  );
});

export default CTextField;
