import { Icon } from '@iconify/react';
import ReportIcon from '@iconify/icons-ic/report';
import { Box, Button, Card, Stack, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DialogAnimate } from 'src/components/animate';
import { useStores } from 'src/models/root-store/root-store-context';
import { useLocales } from 'src/locales';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useEffect, useRef } from 'react';

// ----------------------------------------------------------------------

export default observer(function ErrorAlert() {
  const { errorAlertStore, responseStore } = useStores();

  const { translate } = useLocales();
  const navigate = useNavigate();
  const auth = useAuthContext();

  const closeHandler = () => {
    const code = errorAlertStore.duplicateAlert.code || errorAlertStore.errorAlert.code;
    errorAlertStore.resetErrorAlert();

    if (
      code.toString() === 'common001' ||
      code.toString() === 'common003' ||
      code.toString() === 'user030'
    ) {
      auth.logout();
      // navigate('/')
      window.location.href = '/';
    }
  };

  const prevCode = useRef('');

  useEffect(() => {
    const code = errorAlertStore.errorAlert.code;
    // alert('code' + code);
    // prevCode.current = prevCode.current === 'user030' ? prevCode.current : code;
    if (code === 'common001' || code === 'common003') {
      setTimeout(() => {
        auth.logout();
        navigate('/');
        errorAlertStore.resetErrorAlert();
      }, 0);
    }
  }, [errorAlertStore.errorAlert.code]);

  return (
    <DialogAnimate
      open={
        errorAlertStore.duplicateAlert
          ? errorAlertStore.duplicateAlert.open
          : errorAlertStore.errorAlert.open
      }
      onClose={() => {}}
      maxWidth={'md'}
      sx={{ maxHeight: 'md', '.MuiDialog-container > .MuiBox-root': { alignItems: 'center' } }}
    >
      <Card>
        <Stack direction="row" sx={{ justifyContent: 'center', margin: '40px', mb: 0 }}>
          <Box
            component={Icon}
            icon={ReportIcon}
            sx={{ fontSize: '6rem', color: 'rgb(255, 72, 66)' }}
          />
        </Stack>
        <Stack direction="row" sx={{ justifyContent: 'center', mb: '10px' }}>
          <Typography variant="h4" sx={{ marginTop: 1, fontWeight: '600', opacity: 0.9 }}>
            [{errorAlertStore.duplicateAlert.code || errorAlertStore.errorAlert.code}] ERROR
          </Typography>
        </Stack>
        <TextField
          defaultValue={translate(
            `error.${
              errorAlertStore.duplicateAlert
                ? errorAlertStore.duplicateAlert.code
                : errorAlertStore.errorAlert.code
            }`,
          )}
          label="Message"
          color="error"
          inputProps={{
            style: {
              minHeight: '100px',
              fontSize: '1rem',
              maxHeight: '200px',
            },
            readOnly: true,
          }}
          sx={{ width: '96%', ml: '2%', mt: 1 }}
          multiline
        />
        <Box sx={{ p: 2, width: '100%', textAlign: 'right' }}>
          <Button variant="contained" color="error" onClick={closeHandler}>
            {/* {BUTTON_LABEL.CLOSE} */}
            {translate('meeting.ok').toString()}
          </Button>
        </Box>
      </Card>
    </DialogAnimate>
  );
});
