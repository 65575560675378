import { ApiResponse } from "apisauce";
import { Api } from "../api/api";
import { getGeneralApiProblem } from "../api/api-problem";
import { IPagination } from '../../models/extensions/with-pagination';
import { IMeeting } from '../../models';
import {
  TSearchMeetingResult,
  TFindMeetingResult,
  TUpdateMeetingResult,
  TDeleteMeetingResult,
  TGetMeetingResult,
  TGetMeetingsResult,
  TPostMeetingResult,
  TPostResult,
  TSaveLogResult
 } from "./MeetingTypes";

  /**
  * # Meeting Api 서비스
  *
  * 서비스의 설명을 작성하세요.
  *
  * ## 사용방법
  *
  * ```ts
  * const service = new MeetingApi();
  *
  * ```
  */
 export class MeetingApi {
  private BASE_URL = '/meeting';
  private api: Api;

  constructor(api: Api) {
    this.api = api;
    this.api.setup();
  }

  async search(options?: Partial<IPagination>): Promise<TSearchMeetingResult> {
    try {
      const url = `${this.BASE_URL}`;
      const params: Record<string, any> = {};
      if (options?.page) params.page = options.page;
      if (options?.size) params.size = options.size;
      if (options?.offset) params.offset = options.offset;
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IMeeting[] = response.data.data;
      const pagination: IPagination = response.data.pagination;
      return { kind: "ok", data, pagination};

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async find(id:number): Promise<TFindMeetingResult> {
    try {
      const url = `${this.BASE_URL}/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IMeeting = response.data.data;
      return { kind: "ok", data };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async gets(): Promise<TGetMeetingsResult> {
    try {
      const url = '/meeting';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const meetings: IMeeting[] = response.data.results;
      return { kind: "ok", meetings };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async get(id:number): Promise<TGetMeetingResult> {
    try {
      const url = `/meeting/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get( url, params );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const meeting: IMeeting = response.data.results;
      return { kind: "ok", meeting };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async post(): Promise<TPostMeetingResult> {
    try {
      const url = '/meeting';
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const meeting: IMeeting = response.data.results;
      return { kind: "ok", meeting };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async JoinRoom(data:any): Promise<TPostResult> {
    try {
      const url = '/web/room/join';
      const payload = {roomId:data.roomId,cuid:data.cuid};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
      let meeting: string ;
      if(response.data.responseData){
        meeting= response.data.responseData;
      }else{
        meeting= response.data.error;
      }
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: "ok", res : meeting };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }  
  
  async removeClient(data:any): Promise<TPostResult> {
    try {
      const url = '/web/room/removeClient';
      const payload = {cuid:data.cuid};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
      const meeting: string  = response.data.responseData;
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: "ok", res : meeting };
    } catch (e) {
      return { kind: "bad-data" };
    }
  }  

  
  async joinAccept(data:any): Promise<TPostResult> {
    try {
      const url = '/web/room/join/accept';
      const payload = {roomId:data.roomId,cuidList:data.cuidList};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
      const meeting: string  = response.data.responseData;
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      return { kind: "ok", res : meeting };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }  

  async joinReject(data:any): Promise<TPostResult> {
    try {
      const url = '/web/room/join/reject';
      const payload = {roomId:data.roomId,cuidList:data.cuidList};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
      const meeting: string  = response.data.responseData;
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: "ok", res : meeting };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }  

  async leaveRoom(data:any): Promise<TDeleteMeetingResult> {
    try {
      const url = '/web/room/leave';
      const payload = {cuid:data.cuid};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: "ok"};

    } catch (e) {
      return { kind: "bad-data" };
    }
  }  

  async closeRoom(data:any): Promise<TDeleteMeetingResult> {
    try {
      const url = '/web/room/close';
      const payload = {cuid:data.cuid};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );
      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }
      return { kind: "ok"};

    } catch (e) {
      return { kind: "bad-data" };
    }
  }  

  async update(id:number): Promise<TUpdateMeetingResult> {
    try {
      const url = `${this.BASE_URL}/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.put( url, payload );

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IMeeting = response.data.data;
      return { kind: "ok", data };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  async delete(id:number): Promise<TDeleteMeetingResult> {
    try {
      const url = `${this.BASE_URL}/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete( url, payload );

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      return { kind: "ok" };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }

  /**
   * 로그저장
   * @param content :기기정보, 네트워크정보, 브라우저정보, 사용자 정보 를 JSON string 형태로
   * @returns 
   */
  async saveLog(content:any): Promise<TSaveLogResult> {
    try {
      const url = `/web/log`;
      const payload = {content:content};
      const response: ApiResponse<any> = await this.api.apisauce.post( url, payload );

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      return { kind: "ok", res: response.data.responseData };

    } catch (e) {
      return { kind: "bad-data" };
    }
  }
}