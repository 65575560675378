import { useEffect, useState, useRef } from 'react';
//
import Scrollbar from '../../../../components/scrollbar';
import Lightbox from '../../../../components/lightbox';
//
import ChatMessageItem from './ChatMessageItem';
import { IChatSnapshot, useStores } from 'src/models';

// ----------------------------------------------------------------------

type Props = {
  messages: IChatSnapshot[];
  newChatMsg: IChatSnapshot;
  chatReset: VoidFunction;
};

export default function ChatMessageList({ messages: initialMessages, newChatMsg }: Props) {
  const { conferenceStore } = useStores();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IChatSnapshot[]>(initialMessages);
  const [intialize, setIntialize] = useState(false);
  const [selectedImage, setSelectedImage] = useState<number>(-1);

  useEffect(() => {
    // setMessages(initialMessages);
    scrollRef.current?.scrollIntoView();
    scrollMessagesToBottom();
    // conferenceStore.setChat([...conferenceStore.chat, ...messages]);
  }, [messages]);
  useEffect(() => {
    setMessages(conferenceStore.chat);
    scrollMessagesToBottom();
    setIntialize(true);
    // conferenceStore.setChat([...conferenceStore.chat, ...messages]);
  }, [conferenceStore.chat]);

  useEffect(() => {
    // messages.push(newChatMsg);
    // 새로운 메시지를 배열에 추가
    if (newChatMsg.id !== undefined && intialize) {
      setMessages((prevMessages) => [...prevMessages, newChatMsg]);
      setTimeout(() => {
        scrollMessagesToBottom();
      }, 10);
    }
  }, [newChatMsg]);

  const scrollMessagesToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };
  // const imagesLightbox = conversation.messages
  //   .filter((messages) => messages.contentType === 'image')
  //   .map((messages) => ({ src: messages.body }));

  // const handleOpenLightbox = (imageUrl: string) => {
  //   const imageIndex = imagesLightbox.findIndex((image) => image.src === imageUrl);
  //   setSelectedImage(imageIndex);
  // };

  // const handleCloseLightbox = () => {
  //   setSelectedImage(-1);
  // };

  return (
    <>
      <Scrollbar
        scrollableNodeProps={{
          ref: scrollRef,
        }}
        sx={{ p: 3, height: 1 }}
      >
        {messages &&
          messages.map((message, i) => (
            <ChatMessageItem
              key={message.id + i}
              message={message}
              // onOpenLightbox={() => handleOpenLightbox(message.body)}
            />
          ))}
      </Scrollbar>

      {/* <Lightbox
        index={selectedImage}
        slides={imagesLightbox}
        open={selectedImage >= 0}
        close={handleCloseLightbox}
      /> */}
    </>
  );
}
