import { ApiResponse } from 'apisauce';
import { ICode, ICodeData, ICodeStore } from 'src/models';
import { Api } from 'src/services/api/api';
import { getGeneralApiProblem } from 'src/services/api/api-problem';
import { ICodeGroup } from '../../models';
import { ICodeSnapshot } from './../../models/code/Code';
import {
  TGetCodeGroupResult,
  TGetCodesResult,
  TGetCodeDataResult,
  TGetRoomTypeResult,
} from './CodeTypes';
import { IResponseSnapshot } from 'src/models/response/Response';

/**
 * # Code Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new CodeApi();
 *
 * ```
 */
type TGetsParams = {
  globalGroupTypeCd: {};
} & ICodeSnapshot;

export class CodeApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
    this.api.setup();
  }

  async gets(): Promise<TGetCodesResult> {
    try {
      const url = '/common/v1/code';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const codes: ICode[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', codes, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**회원탈퇴 이유 조회*/
  async getsCodeGroup(): Promise<TGetCodeGroupResult> {
    try {
      const params = { globalGroupTypeCd: 900501 };
      const url: string = `/common/v1/global/group/code`;
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const codeGroup: ICodeGroup[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', codeGroup, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /** 휴대폰번호 앞자리 조회 */
  // async getsPhonePrefix(): Promise<TGetCodeDataResult> {
  //   try {
  //     const params = {};
  //     const url: string = `/common/v1/phone/prefix`;
  //     const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const codeData: ICodeStore[] = response.data.data;
  //     return { kind: 'ok', codeData };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  /** 그룹 코드 목록 조회 */
  async getGlobalGroupCode(globalGroupTypeCd: number): Promise<TGetCodeGroupResult> {
    try {
      const url: string = `/common/v1/global/group/code`;
      const params = { globalGroupTypeCd: globalGroupTypeCd };
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const codeGroup: ICodeGroup[] = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', codeGroup, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getRoomType(): Promise<TGetRoomTypeResult> {
    try {
      const url: string = `/web/code?type=ROOM_TYPE`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const roomType: ICodeData[] = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', roomType, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
