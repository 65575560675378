import { Outlet } from 'react-router-dom';
import useOffSetTop from '../../hooks/useOffSetTop';
import { HEADER } from '../../config-global';
import { Box, Container, Stack, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

export default function SimpleLayout() {
  const isOffset = useOffSetTop(HEADER.H_MOBILE);

  const theme = useTheme();

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          p: 0,
        }}
      >
        <Box
          id="simple-layout"
          component="main"
          sx={{
            flex: 1,
            width: '100%',
            height: '100%',
            minWidth: 345,
            textAlign: 'center',
            justifyContent: 'center',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  );
}
