import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import {
  useTheme,
  Typography,
  Stack,
  Select,
  MenuItem,
  InputLabel,
  List,
  ListItem,
  FormLabel,
  styled,
  Button,
  Divider,
  FormControl,
} from '@mui/material';
import {
  DateTimePicker,
  DateTimeValidationError,
  PickersDay,
  PickersDayProps,
  // LocalizationProvider,
  jaJP,
  koKR,
} from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import CSwitch from 'src/components/forms/CSwitch';
import { CallApiToStore } from 'src/utils/common';
import { IConferenceSnapshot, IMeetingMemberSnapshot } from 'src/models';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { string } from 'yup';
import { useNavigate } from 'react-router';
import { parseISO } from 'date-fns';
import ConferParticipants from './ConferParticipants';
import CAlert from 'src/components/CAlert';
import FormProvider from 'src/components/hook-form';
import _ from 'lodash';
import ErrorMessage from 'src/components/ErrorMessage';
import { toJS } from 'mobx';
import { useLocales } from 'src/locales';
import CTextField from 'src/components/forms/CTextField';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { Dayjs } from 'dayjs';

interface Props {
  handleClose?: VoidFunction;
  startDate?: string;
  endDate?: string;
  getData: VoidFunction;
  path?: string;
  data?: IConferenceSnapshot;
  getDetail?: any;
}

/**
 * ## CreateConference 설명
 *
 */
export const ConferenceCreate = observer(
  ({ handleClose, startDate, endDate, getData, path, data, getDetail }: Props) => {
    const rootStore = useStores();
    const { conferenceStore, loadingStore, responseStore, codeStore } = rootStore;
    const theme = useTheme();
    const navigate = useNavigate();
    const { translate } = useLocales();

    const host = JSON.parse(localStorage.getItem('user') || '');

    const [startDt, setStartDt] = useState(
      startDate
        ? moment(startDate).format('YYYY-MM-DD HH:mm')
        : data
        ? moment(data.start).format('YYYY-MM-DD HH:mm')
        : moment().format('YYYY-MM-DD HH:mm'),
    );
    const [endDt, setEndDt] = useState(
      endDate
        ? moment(endDate).format('YYYY-MM-DD HH:mm')
        : data
        ? moment(data.end).format('YYYY-MM-DD HH:mm')
        : moment().add(1, 'hour').format('YYYY-MM-DD HH:mm'),
    );

    const ConferenceSchema = Yup.object().shape({
      title: string().required(''),
      meetingMembers: Yup.array()
        .of(
          Yup.object().shape({
            // user: object().shape({
            //   displayName: string().required('이름을 입력해주세요'),
            // }),
            loginId: string().nullable(),
            displayName: string().required('').nullable(),
            email: string()
              .required('')
              .nullable()
              .test('is-duplicate', '', () => {
                const emails = meetingMembers.map((v) => {
                  return v.email;
                });
                function isDuplicate(arr: any[]) {
                  const isDup = arr.some(function (x) {
                    return arr.indexOf(x) !== arr.lastIndexOf(x);
                  });

                  return isDup;
                }
                const result = isDuplicate(emails);
                return !result;
              }), // email 중복체크
            // .test('is-duplicate', '중복된 이메일이 있습니다', function (value) {
            //   // 이메일 중복 체크를 위한 컨텍스트 설정
            //   const meetingMembers = this.parent; // 부모 객체에서 meetingMembers 배열 가져오기
            //   const emails = meetingMembers.map((v: IMeetingMemberSnapshot) => v.email);
            //   const emailCount = emails.filter((email: string) => email === value).length;

            //   // 중복된 이메일인 경우 오류 발생
            //   if (emailCount > 1) {
            //     return false;
            //   }

            //   return true;
            // }), // email 중복체크

            facilityCd: string().nullable(),
            authCd: string().nullable(),
          }),
        )
        // .min(1, '참석자를 추가해주세요')
        .required(''),
      room: Yup.object().shape({
        type: string().required(''),
        allowRecording: string().required(''),
      }),
    });

    const [meetingMembers, setMeetingMembers] = useState<IMeetingMemberSnapshot[]>(
      data && data.meetingMembers.length > 0
        ? [
            ...data.meetingMembers.map((v) => {
              console.log('data.meetingMembers.length', data.meetingMembers.length);
              if (v.user.displayName !== (null || undefined)) {
                v.displayName = v.user.displayName;
              } else {
                v.displayName = '';
              }
              return v;
            }),
          ]
        : [{} as IMeetingMemberSnapshot],
    );

    const [roomType, setRoomType] = useState(path === 'edit' && data ? data.room.type : 'NORMAL');
    useEffect(() => {
      setValue('room.type', roomType);
    }, [roomType]);

    const methods = useForm<IConferenceSnapshot>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
        ...data,
        start: undefined,
        end: undefined,
        room: {
          type: roomType, //path === 'edit' && data ? data.room.type : 'NORMAL',
          allowRecording: true,
        },
        meetingMembers: [
          ...meetingMembers.map((v) => {
            if (v.user?.loginId !== (null || undefined)) {
              v.loginId = v.user.loginId;
              //   // data?.meetingMembers.map((m) => {
              //   //   m.loginId;
              //   // });
              // } else {
              //   // v.loginId = '';
            }
            if (v.user?.facility !== (null || undefined)) {
              v.facility = v.user.facility;
            }
            if (v.user?.auth !== (null || undefined)) {
              v.auth = v.user.auth;
            }
            if (v.user?.facilityCd !== (null || undefined)) {
              v.facilityCd = v.user.facilityCd;
            }
            if (v.user?.authCd !== (null || undefined)) {
              v.authCd = v.user.authCd;
            }
            // console.log(v);
            return v;
          }),
        ], //|| [{} as IMeetingMemberSnapshot],
      },

      resolver: yupResolver(ConferenceSchema),
    });

    const {
      register,
      setValue,
      getValues,
      handleSubmit,
      clearErrors,
      reset,
      watch,
      formState: { errors },
    } = methods;

    const addParticipant = () => {
      clearErrors();
      const newMeetingMember: any = {
        loginId: undefined,
        displayName: '',
        email: '',
        facilityCd: undefined,
        authCd: undefined,
      };
      // const newMeetingMember: any = createMeetingMember();
      setMeetingMembers([...meetingMembers, newMeetingMember]);
    };
    const removeParticipant = (index: number) => {
      // clearErrors(`meetingMembers[${index}].user.displayName`);
      // clearErrors(`meetingMembers[${index}].email`);
      clearErrors('meetingMembers');
      // reset(`updatedParticipants[${index}][${_name}]`);
      // reset(`meetingMembers[${index}]`);
      // reset(MeetingMember);

      const updatedParticipants = meetingMembers.filter((_, i) => i !== index);

      setValue('meetingMembers', updatedParticipants);
      setMeetingMembers(updatedParticipants);
    };

    const handleParticipantChange = (e: any, _name: string, index: number) => {
      clearErrors('meetingMembers');
      const { name, value } = e.target;
      const updatedParticipants: any[] = [
        ...meetingMembers,
        // .map((v) => {
        //   if (v.user.displayName !== (null || undefined)) {
        //     v.displayName = v.user.displayName;
        //   }
        //   return v;
        // }),
      ];
      updatedParticipants[index][_name] = value;
      setMeetingMembers(updatedParticipants);
    };

    const handleParticipantAddChange = (value: any, index: number) => {
      clearErrors('meetingMembers');
      const updatedParticipants: any[] = [
        ...meetingMembers,
        // meetingMembers.map((v) => {
        //   if(v.loginId){
        //     return

        //   }else{
        //     if (v.user.displayName !== (null || undefined)) {
        //       v.displayName = v.user.displayName;
        //   }}
        //   // return v;
        // }),
      ];

      for (var i = 0; value.length > i; i++) {
        if (i === 0) {
          updatedParticipants[index] = value[i];
        } else if (i === 1) {
          updatedParticipants[meetingMembers.length] = value[i];
          // meetingMembers.push(value[i]);
        } else {
          updatedParticipants[meetingMembers.length + i - 1] = value[i];
        }
      }
      setMeetingMembers(updatedParticipants);
    };

    // const checkEmailDuplicate = (email: string, index: number) => {
    //   const duplicates = meetingMembers
    //     .filter((_, i) => i !== index)
    //     .some(member => member.email === email);

    //   if (duplicates) {
    //     setEmailErrors(prevErrors => [
    //       ...prevErrors.slice(0, index),
    //       '이미 사용 중인 이메일입니다.',
    //       ...prevErrors.slice(index + 1),
    //     ]);
    //   } else {
    //     setEmailErrors(prevErrors => [
    //       ...prevErrors.slice(0, index),
    //       '',
    //       ...prevErrors.slice(index + 1),
    //     ]);
    //   }
    // };

    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [isAuthAlertOpen, setIsAuthAlertOpen] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');

    const [error, setError] = useState<DateTimeValidationError | null>(null);

    const errorMessage = useMemo(() => {
      switch (error) {
        case 'minDate': {
          return translate('error.appointment021').toString(); //'종료일시는 시작일시보다 이를 수 없습니다.';
        }
        case 'minTime': {
          return translate('error.appointment021').toString(); //'종료일시는 시작일시보다 이를 수 없습니다.';
        }
        case 'invalidDate': {
          return translate('invalidDate').toString(); //'유효하지 않은 날짜입니다.';
        }
        default: {
          return '';
        }
      }
    }, [error]);

    useEffect(() => {
      console.log('🪄 ~ errors:', errors);
    }, [errors]);

    // if (path === 'create') {
    //   setInterval(() => setStartDt(moment().format('YYYY-MM-DD HH:mm')), 1000);
    // } else if (moment() >= moment(startDt)) {
    //   setInterval(() => setStartDt(moment().format('YYYY-MM-DD HH:mm')), 1000);
    // }

    const ServerDay = (props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) => {
      const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

      const isSunday = // 현재 월 중 일요일
        props.day.toDate().getMonth() === new Date().getMonth() &&
        props.day.toDate().getDay() === 0;

      // const isSelected =
      //   !props.outsideCurrentMonth && list.indexOf(props.day.format('YYYY-MM-DD')) >= 0;
      // let cnt = isSelected ? list.filter((r) => r === props.day.format('YYYY-MM-DD')).length : 0;

      return (
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
          sx={{ color: isSunday ? 'red' : '' }}
        />
      );
    };

    const onSubmit = async () => {
      // const tmp = [
      //   ...meetingMembers.map((v) => {
      //     console.log(v);
      //     v.user.displayName = meetingMembers.user.displayName;
      //   }),
      // ];
      // console.log(tmp);
      try {
        // const displayName = meetingMembers.filter((p) => {
        //   return p.user.displayName;
        //   // p.email = p.email;
        // });

        // console.log(meetingMembers);

        if (_.isEmpty(errors)) {
          const conferenceInfo = {
            start: startDt,
            end: endDt,
            title: getValues('title'),
            meetingMembers: meetingMembers,
            room: {
              name: getValues('title'),
              type: getValues('room.type'),
              allowRecording: getValues('room.allowRecording'),
            },
          };
          if (path === 'edit') {
            await CallApiToStore(
              conferenceStore.update({ appointmentId: data?.id, ...conferenceInfo } as any),
              'api',
              loadingStore,
            ).then(() => {
              if (responseStore.responseInfo.error) {
                setAlertMsg(translate(`error.${responseStore.responseInfo.error}`).toString());
                setIsAlertOpen(true);
              } else {
                handleClose && handleClose();
                getDetail(data?.id);
                getData();
              }
            });
          } else {
            await CallApiToStore(
              conferenceStore.createConference(conferenceInfo as IConferenceSnapshot),
              'api',
              loadingStore,
            ).then(() => {
              if (responseStore.responseInfo.error) {
                setAlertMsg(translate(`error.${responseStore.responseInfo.error}`).toString());
                setIsAlertOpen(true);
              } else {
                handleClose && handleClose();
                getData();
                if (path === 'create') {
                  navigate('/conference/meeting', {
                    state: { ...toJS(conferenceStore.conference), ownerYn: 'Y' },
                  });
                }
              }
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      CallApiToStore(codeStore.getRoomType(), 'api', loadingStore).then(() => {
        if (codeStore.roomType.length < 1) {
          setAlertMsg(translate('conference.alert.roomTypeAuth').toString());
          setIsAuthAlertOpen(true);
        }
      });
    }, []);

    return (
      <>
        <Typography variant="h5">
          {path === 'edit'
            ? translate('conference.editReservation').toString()
            : path === 'create'
            ? translate('conference.makeReservation').toString()
            : translate('conference.reservation').toString()}
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack flex={1} spacing={1} sx={{ mt: 2 }}>
            <Divider />
            <Typography variant="subtitle1">
              {translate('conferenceReservation.info').toString()}
            </Typography>
            <List sx={{ p: 0 }}>
              <Stack>
                <Controller
                  name="title"
                  defaultValue={data?.title || ''}
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <CTextField
                        {...field}
                        label={translate('conferenceReservation.title').toString()}
                        variant={'outlined'}
                        placeholder={translate('meeting.report.enterTitle').toString()}
                        sx={{
                          '& input': { py: 1, px: 1.5 },
                          '[dataShrink="false"]': {
                            transform: 'translate(15px, 8px)',
                          },
                        }}
                      />
                    );
                  }}
                />
              </Stack>
              <ListItem sx={path === 'create' ? { display: 'none' } : { p: 0, mt: 1 }}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  alignItems={'center'}
                  mt={1}
                  justifyContent={'space-between'}
                  sx={{
                    '& .MuiInputBase-input': {
                      py: '8px !important',
                      width: '100%',
                    },
                    [theme.breakpoints.down('sm')]: {
                      '&.MuiStack-root': { flexDirection: 'column', width: '100%' },
                      '& .uiListItem-root': { width: '100%' },
                      '& .MuiFormControl-root': { width: '100%' },
                    },
                  }}
                >
                  <Controller
                    name="start"
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <LocalizationProvider
                          dateFormats={
                            host.locale !== 'en'
                              ? {
                                  monthAndYear: `yyyy${translate(
                                    'myCalendar.year',
                                  ).toString()} MM${translate('myCalendar.month').toString()}`,
                                }
                              : {}
                          }
                          dateAdapter={AdapterDateFns}
                          localeText={
                            host.locale === 'ko'
                              ? koKR.components.MuiLocalizationProvider.defaultProps.localeText
                              : host.locale === 'ja'
                              ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
                              : undefined
                          }
                        >
                          <DateTimePicker
                            {...field}
                            dayOfWeekFormatter={(day) => {
                              return translate(`myCalendar.${day}`).toString();
                            }}
                            label={translate('conferenceReservation.start').toString()}
                            format="yyyy-MM-dd HH:mm"
                            ampm={false}
                            ampmInClock={false}
                            onChange={(e: any) => {
                              // if (path === 'create') {
                              //   setInterval(
                              //     () => setStartDt(moment().format('YYYY-MM-DD HH:mm')),
                              //     5000,
                              //   );
                              // } else {
                              // if (moment() >= moment(e)) {
                              //   setInterval(
                              //     () => setStartDt(moment().format('YYYY-MM-DD HH:mm')),
                              //     5000,
                              //   );
                              // } else {
                              setStartDt(moment(e).format('YYYY-MM-DD HH:mm'));
                              // }
                              // }
                            }}
                            minDate={moment().toDate()}
                            defaultValue={
                              path !== 'create'
                                ? path === 'edit'
                                  ? parseISO(moment(data?.start).toISOString())
                                  : parseISO(moment(startDate).toISOString())
                                : parseISO(startDt || moment().toISOString())
                            }
                            readOnly={path === 'create'}
                            closeOnSelect={false}
                            slots={
                              {
                                // day: ServerDay,
                              }
                            }
                            slotProps={{
                              layout: {
                                sx: {
                                  '& .MuiTypography-root[aria-label="Sunday"]': {
                                    color: 'red',
                                  },
                                  '& .MuiDateCalendar-root button[aria-colindex="1"][aria-selected="false"]':
                                    {
                                      color: 'red',
                                    },
                                  '& .Mui-disabled[aria-colindex="1"]': {
                                    opacity: 0.5,
                                  },
                                },
                              },
                              textField: {
                                variant: 'outlined',
                                sx: {
                                  '&. MuiStack-root': { width: '100%' },
                                },
                              },
                              digitalClockSectionItem: {
                                sx: {
                                  // width: '40px',
                                  px: 0,
                                  m: 0,
                                },
                              },
                            }}
                            sx={{
                              '& .MuiList-padding': {
                                width: '80px !important',
                              },
                              '& ul': {
                                width: '80px !important',
                              },
                              '& .MuiMultiSectionDigitalClock-root': {
                                width: '80px !important',
                              },
                            }}
                          />
                        </LocalizationProvider>
                      );
                    }}
                  />
                  <Box sx={{ [theme.breakpoints.down('sm')]: { display: 'none' } }}>
                    <span> to</span>
                  </Box>
                  <LocalizationProvider
                    dateFormats={
                      host.locale !== 'en'
                        ? {
                            monthAndYear: `yyyy${translate(
                              'myCalendar.year',
                            ).toString()} MM${translate('myCalendar.month').toString()}`,
                          }
                        : {}
                    }
                    dateAdapter={AdapterDateFns}
                    localeText={
                      host.locale === 'ko'
                        ? koKR.components.MuiLocalizationProvider.defaultProps.localeText
                        : host.locale === 'ja'
                        ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
                        : undefined
                    }
                  >
                    <DateTimePicker
                      dayOfWeekFormatter={(day) => {
                        return translate(`myCalendar.${day}`).toString();
                      }}
                      label={translate('conferenceReservation.end').toString()}
                      format="yyyy-MM-dd HH:mm"
                      ampm={false}
                      ampmInClock={false}
                      onChange={(e: any) => {
                        setEndDt(moment(e).format('YYYY-MM-DD HH:mm'));
                      }}
                      // disablePast
                      minDateTime={moment(startDt).toDate()}
                      defaultValue={
                        path === 'edit'
                          ? parseISO(moment(data?.end).toISOString())
                          : endDt
                          ? parseISO(endDt)
                          : parseISO(moment().add(1, 'h').toISOString())
                        // path === 'edit'
                        //   ? parseISO(moment(data?.end).toISOString())
                        //   : parseISO(endDt || moment().add(1, 'h').toISOString())
                      }
                      closeOnSelect={false}
                      onError={(newError) => setError(newError)}
                      slotProps={{
                        layout: {
                          sx: {
                            '& .MuiTypography-root[aria-label="Sunday"]': {
                              color: 'red',
                            },
                            '& .MuiDateCalendar-root button[aria-colindex="1"][aria-selected="false"]':
                              {
                                color: 'red',
                              },
                            '& .Mui-disabled[aria-colindex="1"]': {
                              opacity: 0.5,
                            },
                          },
                        },
                        //   textField: {
                        //     helperText: errorMessage,
                        //   },
                      }}
                      sx={{
                        [theme.breakpoints.down('sm')]: {
                          '&.MuiTextField-root': { marginTop: '16px', marginLeft: '0' },
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </ListItem>
              {/* <DateRangePicker
                format="yyyy-MM-dd HH:mm"
                defaultValue={[
                  path === 'edit'
                    ? parseISO(moment(data?.start).toISOString())
                    : parseISO(endDt || moment().toISOString()),
                  path === 'edit'
                    ? parseISO(moment(data?.end).toISOString())
                    : parseISO(endDt || moment().add(1, 'h').toISOString()),
                ]}
                
                calendars={1}
                slotProps={{}}
                // viewRenderers={{ day: null }}
                onChange={(e: any) => {
                  console.log(e);
                }}
                sx={{
                  '& input': {
                    py: 1,
                  },
                  '[data-shrink="false"]': {
                    transform: 'translate(15px, 8px)',
                  },
                }}
              /> */}

              {/* {errorMessage && ( */}
              {path !== 'create' && (
                <Typography variant="caption" color={theme.palette.error.main} sx={{ ml: '14px' }}>
                  {errorMessage}
                </Typography>
              )}
              {/* )} */}
              {/* <ListItem sx={{ p: 0, py: 1 }}>
                <Label></Label>
                <Stack
                  direction={'row'}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'end',
                  }}
                >
                  <FormControlLabel
                    name={'repeat'}
                    value={'Y'}
                    control={<Checkbox />}
                    label={'되풀이 회의'}
                  />
                </Stack>
              </ListItem> */}
              <ListItem
                alignItems="center"
                sx={{
                  gap: 2,
                  p: 0,
                  mt: 1,
                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 0,
                    marginTop: '16px',
                  },
                }}
              >
                {/* <Stack direction={'row'} alignItems={'center'}> */}

                {/* </Stack> */}
                {/* </ListItem>
                <ListItem alignItems="center" sx={{ p: 0, mt: 1 }}> */}
                <Box
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      '&.MuiBox-root': { display: 'flex', width: '100%', alignItems: 'center' },
                      ' .MuiInputBase-root': { flexGrow: '1' },
                      '& .MuiFormControl-root': { width: '100%' },
                    },
                  }}
                >
                  {/* <FormLabel>방타입</FormLabel> */}
                  {/* <CSelect
                        name={'type'}
                        label={'방타입'}
                        variant={'outlined'}
                        placeholder={'방타입을 선택하세요'}
                        options={typeOptions}
                        onChangeCallback={(e: any) => {
                          setValue('type', e.target.value);
                        }}
                      /> */}
                  <FormControl>
                    <InputLabel
                      id="select-label"
                      sx={{
                        '&.Mui-focused': { color: theme.palette.text.primary },
                      }}
                    >
                      {translate('conferenceReservation.type').toString()}
                    </InputLabel>
                    <Select
                      name={'room.type'}
                      label="방타입"
                      labelId="select-label"
                      placeholder={'방타입을 선택하세요'}
                      defaultValue={path === 'edit' && data ? data.room.type : roomType}
                      onChange={(e) => {
                        console.log(e);
                        setValue('room.type', e.target.value);
                        setRoomType(e.target.value);
                      }}
                      sx={{
                        // ml: 1,
                        '& .MuiSelect-select': {
                          minWidth: '100px',
                          py: '8px !important',
                        },
                        [theme.breakpoints.down('sm')]: {
                          ' &.MuiFormControl-root': { width: '100%' },
                        },
                      }}
                    >
                      {/* <MenuItem value={''}>{translate('select').toString()}</MenuItem> */}
                      {codeStore.roomType.map((v, i) => {
                        return (
                          <MenuItem key={'room-type' + i} value={v.cdNm}>
                            {v.cdDispNm}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
                <Stack direction={'row'} alignItems={'center'}>
                  <FormLabel>
                    {translate('conferenceReservation.allowRecording').toString()}
                  </FormLabel>
                  <CSwitch
                    name={'room.allowRecording'}
                    size="medium"
                    // defaultValue={path === 'edit' && data ? data.room.allowRecording : true}
                    // onChangeCallback={(e: any) => console.log(e)}
                    label={''}
                    sx={{ '& .MuiSwitch-root': { m: 0 } }}
                  />
                </Stack>
              </ListItem>
            </List>
            <Divider sx={{ my: 1 }} />

            <Typography variant="subtitle1" sx={{ mr: 1 }}>
              {translate('conferenceReservation.host').toString()}
            </Typography>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={1}
              sx={{
                whiteSpace: 'nowrap',
                [theme.breakpoints.down('sm')]: {
                  '&': { flexDirection: 'column', alignItems: 'flex-start' },
                },
              }}
            >
              {/* <Box sx={{ display: 'flex', flexDirection: 'row' }}> */}
              <Typography>
                {/* <FormLabel sx={{ mr: 1 }}>
                    {translate('conferenceReservation.name').toString()} :
                  </FormLabel> */}
                {path === 'edit' ? data?.owner.displayName : host.displayName}
              </Typography>
              {/* </Box> */}
            </Stack>
            <Stack
              direction={'row'}
              alignItems={'center'}
              gap={2}
              sx={{
                whiteSpace: 'nowrap',
                [theme.breakpoints.down('sm')]: {
                  '&': { flexDirection: 'column', alignItems: 'flex-start' },
                },
              }}
            >
              <Typography>
                <FormLabel sx={{ mr: 1 }}>
                  {translate('conferenceReservation.facility').toString()} :
                </FormLabel>
                {path === 'edit'
                  ? data?.owner.facility?.facilityNm
                  : host.facility?.facilityNm
                  ? host.facility.facilityNm
                  : translate('conferenceReservation.none').toString()}
              </Typography>
              <Typography>
                <FormLabel sx={{ mr: 1 }}>
                  {translate('conferenceReservation.auth').toString()} :
                </FormLabel>
                {path === 'edit'
                  ? data?.owner.auth?.authNm
                  : host.auth?.authNm
                  ? host.auth.authNm
                  : translate('conferenceReservation.none').toString()}
              </Typography>
            </Stack>
            <Divider sx={{ my: 2 }} />
          </Stack>
          <Box
            flex={1}
            position={'relative'}
            sx={{
              overflowX: 'hidden',
              overflowY: 'auto',
            }}
            mt={2}
          >
            <ConferParticipants // 참석자
              setValue={setValue}
              watch={watch}
              errors={errors}
              meetingMembers={meetingMembers}
              addParticipant={addParticipant}
              removeParticipant={removeParticipant}
              handleParticipantChange={handleParticipantChange}
              handleParticipantAddChange={handleParticipantAddChange}
              // getRecentUser={getRecentUser(roomType)}
              roomType={roomType}
            />
          </Box>

          <Box flex={1} sx={{ height: '100%' }}>
            <ErrorMessage errors={errors} type="min" name="meetingMembers" />
            <ErrorMessage errors={errors} type="required" name="meetingMembers" />

            <Divider />
            <Stack direction={'row'} spacing={1} sx={{ justifyContent: 'flex-end', py: 2 }}>
              <Button
                variant={'contained'}
                onClick={handleClose}
                sx={{ background: theme.palette.grey[400] }}
              >
                {translate('meeting.cancel').toString()}
              </Button>
              <Button
                type="submit"
                variant={'contained'}
                onClick={() => console.log('meetingMembers', meetingMembers)}
              >
                {path === 'edit'
                  ? translate('meeting.report.edit').toString()
                  : path === 'create'
                  ? translate('waiting.start').toString()
                  : translate('meeting.submit').toString()}
              </Button>
            </Stack>
          </Box>
        </FormProvider>
        <CAlert open={isAlertOpen} content={alertMsg} handleClose={() => setIsAlertOpen(false)} />
        {isAuthAlertOpen && (
          <CAlert
            open={isAuthAlertOpen}
            content={alertMsg}
            handleClose={() => {
              handleClose && handleClose();
              setIsAuthAlertOpen(false);
            }}
          />
        )}
      </>
    );
  },
);

const Label = styled(FormLabel)(({ theme }) => ({
  minWidth: 100,
  whiteSpace: 'nowrap',
  // marginBottom: 8,
  color: '#919EAB',
}));

export default ConferenceCreate;
