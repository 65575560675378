import { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Alert,
  Box,
  Dialog,
  Button,
  Typography,
  useTheme,
  Paper,
  IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import Iconify from '../../../components/iconify';
import FormProvider, { RHFTextField } from '../../../components/hook-form';
import { useStores } from 'src/models';
import { observer } from 'mobx-react-lite';
import CAlert from 'src/components/CAlert';
import { pxToRem } from 'src/theme/typography';
import base64 from 'base-64';
// import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { ReactComponent as Logo } from 'src/assets/images/sipaeon.svg';
import { useSettings } from 'src/hooks/useSettings';
import Draggable from 'react-draggable';
import { isMobile } from 'react-device-detect';
import FindUser from './find-user/FindUser';
import { useLocales } from 'src/locales';
import TwoFactorAuth from './TwoFactorAuth';
import ConcurrentConnection from './ConcurrentConnection';
import { sendReactNativeMessage } from 'src/utils/common';
import CSelectCode from 'src/components/forms/CSelectCode';

// ----------------------------------------------------------------------

type FormValuesProps = {
  facilityCd: string;
  loginId: string;
  password: string;
  afterSubmit?: string;
};
type Props = {
  handleClose?: VoidFunction;
};
type RedirectLocationState = {
  redirectTo: Location;
};

let loginFacilityCd: string = '';
let loginLoginId: string = '';
let loginPassword: string = '';

const Login = observer(({ handleClose }: Props) => {
  const { REACT_APP_API_URL } = process.env;
  const rootStore = useStores();
  const { translate } = useLocales();
  const { responseStore, errorAlertStore } = rootStore;
  const theme = useTheme();
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const { state: locationState } = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [open2FA, setOpen2FA] = useState(false);
  const [openCC, setOpenCC] = useState(false);
  const [watisToken, setWatisToken] = useState('');
  const [adminMode, setAdminMode] = useState(true);
  const { redirectTo } = (locationState as RedirectLocationState) || {
    pathname: '',
    search: '',
  };

  useEffect(() => {
    let tmpLoginFacilityCd = localStorage.getItem('loginFacilityCd');
    let tmpLoginid = localStorage.getItem('loginLoginId');
    let tmpLoginPassword = localStorage.getItem('loginPassword');

    if (window.location.href.indexOf('spaeonPage=c3BhZW9u') !== -1) {
      setAdminMode(false);
      setValue('facilityCd', '00000');
      if (loginFacilityCd !== '00000') {
        setValue('loginId', '');
        setValue('password', '');
      }
    } else {
      if (tmpLoginFacilityCd) {
        loginFacilityCd = tmpLoginFacilityCd;
        setValue('facilityCd', loginFacilityCd);
      }
      if (tmpLoginFacilityCd === '00000') {
        localStorage.setItem('loginFacilityCd', '');
        setValue('facilityCd', '');
      }
      if (tmpLoginid) {
        loginLoginId = tmpLoginid;
        setValue('loginId', loginLoginId);
      }
      if (tmpLoginPassword) {
        loginPassword = tmpLoginPassword;
        setValue('password', loginPassword);
      }
    }
  }, []);

  const { themeMode, onChangeThemeMode } = useSettings();
  useEffect(() => {
    onChangeThemeMode('light');
  }, []);

  const LoginSchema = Yup.object().shape({
    facilityCd: Yup.string().trim().nullable(), //.required('시설코드를 입력해주세요'),
    loginId: Yup.string().trim().required(''),
    password: Yup.string().trim().required(''),
  });

  const defaultValues = {
    facilityCd: '',
    loginId: '',
    password: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    clearErrors,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const [openFindUser, setOpenFindUser] = useState(false);
  const [selectOptions, setSelectOptions] = useState([]);
  const getFacility = async () => {
    try {
      await fetch(`${REACT_APP_API_URL}/web/watis/facility`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then(async (res) => {
        const json: any = await res.json();
        if (json.responseData) {
          const tmp = json.responseData.map((v: any) => {
            return { code: v.hospid, value: v.hospnm };
          });
          setSelectOptions(tmp);
          localStorage.setItem('facility', JSON.stringify(tmp));
        }
      });
      await fetch(`${REACT_APP_API_URL}/web/watis/auth`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
      }).then(async (res) => {
        const json: any = await res.json();
        if (json.responseData) {
          const tmp = json.responseData.map((v: any) => {
            return { code: v.dtcode, value: v.codenm };
          });
          localStorage.setItem('auth', JSON.stringify(tmp));
        }
      });
    } catch (error) {
      console.error(error);
      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };
  useEffect(() => {
    getFacility();
  }, []);

  const [errorMsg, setErrorMsg] = useState('');

  const onSubmit = async (data: FormValuesProps) => {
    localStorage.setItem('loginId', base64.encode(getValues('loginId')));
    localStorage.removeItem('auth-errors');

    try {
      await login(data.facilityCd, data.loginId, data.password);
      if (redirectTo && redirectTo.pathname !== '') {
        navigate(redirectTo.pathname + redirectTo.search, { replace: true, state: null });
      } else {
        if (sessionStorage.getItem('loginType') === '2FA') {
          setWatisToken(sessionStorage.getItem('watisToken') || '');
          setOpen2FA(true);
        } else if (sessionStorage.getItem('loginType') === 'CC') {
          setWatisToken(sessionStorage.getItem('watisToken') || '');
          setOpenCC(true);
        } else {
          navigate('/', { replace: true, state: null });
          setErrorMsg(translate(`error.${responseStore.responseInfo.error}`).toString() || '');
        }
      }
    } catch (error) {
      console.error(error);

      reset();

      setError('afterSubmit', {
        ...error,
        message: error.message,
      });
    }
  };

  const getCnt = useRef(0);
  const getOS = () => {
    const os = localStorage.getItem('os');
    setTimeout(() => {
      if (!os || (os == '' && getCnt.current < 5)) {
        getCnt.current++;
        sendReactNativeMessage({
          type: 'getMobileInfo',
          payload: null,
        });
        getOS();
      }
    }, 1000);
  };
  useEffect(() => {
    getOS();
  }, []);

  return (
    <Stack
      display={'flex'}
      height={'100%'}
      flex={1}
      sx={{
        m: '0 auto',
        maxWidth: theme.breakpoints.values.md,
        minHeight: '100vh',
        overflow: 'hidden',
        justifyContent: 'center',
      }}
    >
      <Logo style={{ alignSelf: 'center' }} />
      <Stack
        sx={{
          p: 4,
          alignSelf: 'center',
          width: '100%',
          maxWidth: '350px',
          [theme.breakpoints.up('md')]: { maxWidth: '480px' },
        }}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack flex={1} height={'100%'} justifyContent={'center'}>
            <Stack spacing={pxToRem(16)} sx={{ width: '100%' }}>
              {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}
              {adminMode === true && (
                <CSelectCode
                  variant="outlined"
                  label={translate('login.selectFacility').toString()}
                  name={'facilityCd'}
                  options={selectOptions}
                  defaults={{
                    code: '',
                    value: translate('conferenceReservation.none').toString(),
                  }}
                />
              )}
              <RHFTextField
                name={'loginId'}
                type={'text'}
                label={translate('login.enterId').toString()}
                variant={'outlined'}
                onFocus={() => clearErrors()}
                onKeyUp={(e: any) => {
                  e.target.value = e.target.value.replace(/\s/gi, '');
                }}
              />

              <RHFTextField
                name={'password'}
                label={translate('login.enterPw').toString()}
                type={showPassword ? 'text' : 'password'}
                variant={'outlined'}
                onFocus={() => clearErrors()}
                onKeyUp={(e: any) => {
                  e.target.value = e.target.value.replace(/\s/gi, '');
                }}
              />
            </Stack>

            <Typography
              variant="subtitle2"
              color={theme.palette.error.main}
              mt={2}
              height={'20px'}
              textAlign={'center'}
            >
              {errorMsg ? errorMsg : ''}
            </Typography>

            <LoadingButton
              fullWidth
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{
                mt: 2,
                bgcolor: 'primary.main',
                borderRadius: 2,
              }}
            >
              {translate('login.login').toString()}
            </LoadingButton>
            <Box my={pxToRem(30)} display={'flex'} justifyContent={'center'}>
              <Button
                variant={'text'}
                color="inherit"
                onClick={() => {
                  setOpenFindUser(true);
                }}
                sx={{ fontWeight: 400, p: 0 }}
              >
                {translate('login.findIdPw').toString()}
              </Button>
            </Box>
            <Button
              fullWidth
              color="primary"
              size="large"
              variant={'outlined'}
              onClick={() => {
                navigate(PATH_AUTH.loginUnprotected, { state: null });
              }}
              sx={{ mt: 1, borderRadius: 2 }}
            >
              {translate('login.loginUnprotected').toString()}
            </Button>
          </Stack>
        </FormProvider>
      </Stack>

      {openFindUser && (
        <Dialog
          open={openFindUser}
          onClose={(e: any, reason: string) => {
            reason !== 'backdropClick' && setOpenFindUser(false);
          }}
          maxWidth={'sm'}
          fullWidth
          PaperComponent={(props) => {
            return isMobile ? (
              <Paper {...props} />
            ) : (
              <Draggable bounds="parent" cancel="form">
                <Paper {...props} />
              </Draggable>
            );
          }}
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
        >
          <IconButton
            onClick={() => setOpenFindUser(false)}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton>
          <FindUser />
        </Dialog>
      )}
      {open2FA && (
        <Dialog
          open={open2FA}
          onClose={(e: any, reason: string) => {
            reason !== 'backdropClick' && setOpen2FA(false);
          }}
          maxWidth={'sm'}
          fullWidth
          // PaperComponent={(props) => {
          //   return isMobile ? (
          //     <Paper {...props} />
          //   ) : (
          //     <Draggable bounds="parent" cancel="form">
          //       <Paper {...props} />
          //     </Draggable>
          //   );
          // }}
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
        >
          <IconButton
            onClick={() => setOpen2FA(false)}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton>
          <TwoFactorAuth watisToken={watisToken} />
        </Dialog>
      )}
      {openCC && (
        <Dialog
          open={openCC}
          onClose={(e: any, reason: string) => {
            reason !== 'backdropClick' && setOpenCC(false);
          }}
          maxWidth={'sm'}
          fullWidth
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
        >
          {/* <IconButton
            onClick={() => setOpenCC(false)}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton> */}
          <ConcurrentConnection
            watisToken={watisToken}
            handleClose={() => {
              setOpenCC(false);
            }}
          />
        </Dialog>
      )}
    </Stack>
  );
});

export default Login;
