import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"
import { Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover, Rating, Select, Typography, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useEffect } from "react";
import { useLocales } from "src/locales";
import { Stack } from "@mui/system";


type Props = {
  videoInputs: { label: string; value: string }[];
  videoInput: string | null;
  videoSize: string;
  returnVideoInputData: (
    videoInput: string | null,
    value: any,
    label: any
  ) => void;
  returnVideoSizeData: (
    videoSize: string | null,
    value: any,
    label: any
  ) => void;
  mobile?: any;
};
/**
 * ## Setting 설명
 *
 */
export const MicSetting = observer(({
  videoInputs,
  videoInput,
  videoSize,
  returnVideoInputData,
  returnVideoSizeData,
  mobile
}: Props
) => {
  const { translate } = useLocales();
  const videoResolutions = {
    '720p': {
      width: 1280,
      height: 720,
    },
    '360p': {
      width: 640,
      height: 360,
    },
  };

  return (
    <>
      {mobile === false &&
        <Stack>
          <Typography variant={'subtitle2'} sx={{ p: 2, pb: 0 }}>
            {translate(`waiting.video`).toString()}
          </Typography>
          <MenuList dense>
            {videoInputs.map(({ label, value }) => {
              return (
                <MenuItem
                  key={`video-input-${value}`}
                  onClick={() => {
                    returnVideoInputData(videoInput, value, label);
                  }}
                >
                  {videoInput === value ? (
                    <>
                      <ListItemIcon sx={{ mr: 0 }}>
                        <Iconify icon={'gg:check'} />
                      </ListItemIcon>
                      {label}
                    </>
                  ) : (
                    <ListItemText inset>{label}</ListItemText>
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
          <Divider />
        </Stack>
      }

      <Typography variant={'subtitle2'} sx={{ p: 2, pb: 0 }}>
        {translate(`waiting.resolution`).toString()}
      </Typography>
      <MenuList dense>
        {Object.keys(videoResolutions).map((label: any, value) => {
          return (
            <MenuItem
              key={`video-input-${label}`}
              onClick={() => {
                returnVideoSizeData(videoSize, value, label);
              }}
            >
              {videoSize === label ? (
                <>
                  <ListItemIcon sx={{ mr: 0 }}>
                    <Iconify icon={'gg:check'} />
                  </ListItemIcon>
                  {label}
                </>
              ) : (
                <ListItemText inset>{label}</ListItemText>
              )}
            </MenuItem>
          );
        })}
      </MenuList>
    </>
  );
});

export default MicSetting;