import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import { useStores } from "../../../models/root-store/root-store-context"
import { Button, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popover, Rating, Select, Slider, Stack, Typography, useTheme } from '@mui/material';
import Iconify from 'src/components/iconify';
import Label from 'src/components/label';
import { debounce } from 'lodash';
import { useLocales } from "src/locales";

type Props = {
  audioInputs: { label: string; value: string }[];
  audioInput: string | null;
  audioOutputs: { label: string; value: string }[];
  audioOutput: string | null;
  aftervideostate: any | null;
  anchorSet: HTMLButtonElement | null;
  view: string
  returnAudioInputData: (
    audioInput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioOutputData: (
    audioOuput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioInputStream: (
    Inputstate: any | null
  ) => void;
  returnAudioOutputStream: (
    Outputstate: any | null
  ) => void;
  videoMic: any;
  videoVol: any;
};
/**
 * ## AudioSetting 설명
 *
 */
export const AudioSetting = observer(({
  audioInputs,
  audioInput,
  audioOutput,
  audioOutputs,
  anchorSet,
  aftervideostate,
  returnAudioInputData,
  returnAudioOutputData,
  returnAudioInputStream,
  returnAudioOutputStream,
  view,
  videoMic,
  videoVol
}: Props
) => {
  const rootStore = useStores();
  const [input, setInput] = useState<number>(0);
  const [output, setOutput] = useState<number>(0);
  const { translate } = useLocales();
  var currentMenu: any;
  const [anchorController, setAnchorController] = useState<HTMLButtonElement | null>(null);
  const openController = Boolean(anchorController);
  const [browserName, setBrowserName] = useState('Safari');
  const handleControllerSet = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorController(anchorSet);
  };
  useEffect(() => {
    const userAgent = window.navigator.userAgent;

    // 브라우저 종류 확인
    if (userAgent.includes('Edg')) {
      setBrowserName('Edge');
    } else if (userAgent.includes('Chrome')) {
      setBrowserName('Chrome');
    } else if (userAgent.includes('Firefox')) {
      setBrowserName('Firefox');
    } else if (userAgent.includes('Safari')) {
      setBrowserName('Safari');
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      setBrowserName('Opera');
    }
  }, []);
  return (
    <>
      <Typography variant={'subtitle2'} sx={{ p: 2, pb: 0 }}>
        {translate(`waiting.mic`).toString()}
      </Typography>
      <MenuList dense>
        {audioInputs.map(({ label, value }) => {
          return (
            <MenuItem
              key={`audio-input-${value}`}
              onClick={() => {
                returnAudioInputData(audioInput, value, label);
              }}
            >
              {audioInput === value ? (
                <>
                  <ListItemIcon sx={{ mr: 0 }}>
                    <Iconify icon={'gg:check'} />
                  </ListItemIcon>
                  {label}
                </>
              ) : (
                <ListItemText inset>{label}</ListItemText>
              )}
            </MenuItem>
          );
        })}
      </MenuList>
      {browserName !== 'Safari' &&
        <Stack>
          <Divider />
          <Typography variant={'subtitle2'} sx={{ p: 2, pb: 0 }}>
            {translate(`waiting.speaker`).toString()}
          </Typography>
          <MenuList dense>
            {audioOutputs.map(({ label, value }) => {
              return (
                <MenuItem
                  key={`audio-output-${value}`}
                  onClick={() => {
                    returnAudioOutputData(audioOutput, value, label)
                  }}
                >
                  {audioOutput === value ? (
                    <>
                      <ListItemIcon sx={{ mr: 0 }}>
                        <Iconify icon={'gg:check'} />
                      </ListItemIcon>
                      {label}
                    </>
                  ) : (
                    <ListItemText inset>{label}</ListItemText>
                  )}
                </MenuItem>
              );
            })}
          </MenuList>
        </Stack>
      }
    </>
  );
});

export default AudioSetting;