// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOT_PATH = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOT_PATH,
  login: path(ROOT_PATH, 'login'),
  verify: path(ROOT_PATH, 'verify'),
  register: path(ROOT_PATH, 'register'),
  findId: path(ROOT_PATH, 'find-user'),
  findPassword: path(ROOT_PATH, 'find-password'),
  newPassword: path(ROOT_PATH, 'new-password'),
  resetPassword: path(ROOT_PATH, 'reset-password'),
  loginUnprotected: path(ROOT_PATH, 'login-unprotected'),
  registerUnprotected: path(ROOT_PATH, 'register-unprotected'),
  existId: path(ROOT_PATH, 'exist-id'),
};

export const PATH_PAGE = {
  page403: '403',
  page404: '404',
  page500: '500',
  comingSoon: 'coming-soon',
  maintenance: 'maintenance',
};

export const PATH_ROOT = {
  root: ROOT_PATH,
  permissionDenied: path(ROOT_PATH, 'permission-denied'),
  blank: path(ROOT_PATH, 'blank'),
  user: {
    root: path(ROOT_PATH, 'user'),
    edit: (name: string) => path(ROOT_PATH, `user/edit`),
    mypage: path(ROOT_PATH, 'user/mypage'),
  },
  setting: {
    root: path(ROOT_PATH, 'setting'),
    alarm: path(ROOT_PATH, 'setting/alarm'),
    terms: path(ROOT_PATH, 'setting/terms'),
    version: path(ROOT_PATH, 'setting/version'),
    logout: path(ROOT_PATH, 'setting/logout'),
  },
};
