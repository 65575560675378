import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import { useStores } from "../models/root-store/root-store-context"
import { useTheme } from '@mui/material';
import { requestPermission } from 'src/utils/common';
// import { onMessageListener } from 'src/App';
import { Toaster, toast } from 'react-hot-toast';

/**
 * ## Notification 설명
 *
 */
export const NotificationScreen = observer(() => {

  const rootStore = useStores();
  const { loadingStore } = rootStore;
  const theme = useTheme();

  const [notification, setNotification] = useState({ title: '', body: '' });

  const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

  // useEffect(() => {
  //   if (!isMobile) {
  //     requestPermission();
  //   }
  //   const unsubscribe = onMessageListener().then((payload: any) => {
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     });
  //     toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
  //       duration: 5000,
  //       position: 'top-right', // section of the browser page
  //     });
  //   });
  //   return () => {
  //     unsubscribe.catch((err) => console.log('failed: ', err));
  //   };
  // }, []);
  return (
    <div>
      <Toaster />
    </div>
  );
});

export default NotificationScreen;