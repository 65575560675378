import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Container, Stack, Typography, IconButton, Divider, Box } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import MyAlarmContents from './MyAlarmContents';
import { CallApiToStore, sendReactNativeMessage } from 'src/utils/common';
import Iconify from 'src/components/iconify/Iconify';
import { HEADER } from 'src/config-global';
import CHeader from 'src/components/CHeader';
import { Trans } from 'react-i18next';
import { useLocales } from 'src/locales';
import { useScroll } from 'framer-motion';
import { isAndroid, isIOS } from 'react-device-detect';
import { debounce } from 'lodash';
import Button from 'src/theme/overrides/Button';

type Props = {
  handleClose: VoidFunction;
};
/**
 * ## MyPushAlarm 설명
 *
 */
export const MyAlarm = observer(({ handleClose }: Props) => {
  const isMobile = useResponsive('down', 'md');
  const { translate } = useLocales();
  const rootStore = useStores();
  const { pushAlarmStore: store, loadingStore } = rootStore;

  const headerOptions: any = {
    showMainIcon: 'back',
    title: translate('pushNotification.mainTitle').toString(),
    showHomeIcon: true,
  };

  const getAlarms = async (counts?: number) => {
    setPending((pending) => (pending = true));
    await CallApiToStore(store.gets(counts), 'api', loadingStore).then(() => {
      setTimeout(() => {
        setPending((pending) => (pending = false));
      }, 100);
    });
  };

  const listener = async (event?: any) => {
    let data: any;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      console.log('e', event, e);
    }
    if (data.type === 'pushNoti') {
      CallApiToStore(store.appendPushAlarms(), 'api', loadingStore)
        .then(async () => {
          sendReactNativeMessage({
            type: 'clearAppBadge',
            payload: null,
          });
          store.setCounts(0);
          await CallApiToStore(store.readAlarm(), 'api', loadingStore);
        })
        .then(async () => {
          await CallApiToStore(store.getAlarmCounts(), 'api', loadingStore);
        });
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (window.ReactNativeWebView) {
      if (isAndroid) {
        document.addEventListener('message', listener);
      }
      if (isIOS) {
        window.addEventListener('message', listener);
      }
    }
    return () => {
      if (isAndroid) {
        document.removeEventListener('message', listener);
      }
      if (isIOS) {
        window.removeEventListener('message', listener);
      }
    };
  }, [listener]);

  /**
   * infinite scroll
   */

  const [scrlTop, setScrlTop] = useState<any>();
  const [scrlHeight, setScrlHeight] = useState<any>();
  const [clntHeight, setClntHeight] = useState<any>();
  const [scrlRef, setScrlRef] = useState<any>();

  const drawerPaperEl = document.querySelector('.MuiDrawer-paper');
  drawerPaperEl?.addEventListener('scroll', () => {
    setScrlTop(drawerPaperEl.scrollTop);
    setScrlHeight(drawerPaperEl.scrollHeight);
    setClntHeight(drawerPaperEl.clientHeight);
    setScrlRef(scrlTop / (scrlHeight - clntHeight));
  });

  const addPage = async (): Promise<any> => {
    // const last = store.pagination.totalElements % store.pagination.size >= store.pagination.page;
    // const last = store.pagination.totalElements / store.pagination.size <= store.pagination.page;
    const lastPage = Math.ceil(store.pagination.totalElements / store.pagination.size);

    // return new Promise((resolve, reject) => {
    //   if (!last) {
    //     store.pagination.setProps({
    //       page: store.pagination.page + 1,
    //     });
    //     resolve(store.pagination.page);
    //   } else {
    //     return;
    //     reject('last page');
    //   }
    // });
    if (store.pagination.page < lastPage) {
      store.pagination.setProps({
        page: store.pagination.page + 1,
      });
      return store.pagination.page;
    } else {
      throw new Error('last page');
    }
  };

  const handleScroll = () => {
    const scrollPosition = drawerPaperEl && drawerPaperEl.scrollTop;
    const scrollHeight = drawerPaperEl && drawerPaperEl.scrollHeight;
    const clientHeight = drawerPaperEl && drawerPaperEl.clientHeight;
    const scrollPercentage = (scrollPosition || 0) / ((scrollHeight || 0) - (clientHeight || 0));

    if (scrollPercentage > 0.8 && !loadingStore.loading) {
      addPage()
        .then(() => {
          getAlarms();
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const { scrollYProgress, scrollY } = useScroll();
  isMobile
    ? useEffect(() => {
        scrollYProgress.on('change', (v) => {
          // console.log('scrollYProgress.on ~ v', v);
          if (v > 0.8) {
            if (!loadingStore.loading) {
              addPage()
                .then(() => {
                  getAlarms(store.pushAlarms.length);
                })
                .catch((e) => {
                  console.error(e);
                });
            }
          }
        });

        return () => {
          store.pagination.setProps({ page: 1 });
        };
      }, [console, store, scrollYProgress])
    : // useEffect(() => {
      //     // console.log('🪄 ~ :useEffect ~ scrlRef:', scrlRef);
      //     if (scrlRef > 0.8) {
      //       if (!loadingStore.loading) {
      //         addPage()
      //           .then(() => {
      //             getAlarms();
      //           })
      //           .catch((e) => {
      //             console.error(e);
      //           });
      //       }
      //     }
      //     return () => {
      //       store.pagination.setProps({ page: 1 });
      //     };
      //   }, [drawerPaperEl, scrlRef]);
      useEffect(() => {
        drawerPaperEl?.addEventListener('scroll', handleScroll);
        return () => {
          drawerPaperEl?.removeEventListener('scroll', handleScroll);
          // store.pagination.setProps({ page: 1 });
        };
      }, [drawerPaperEl, loadingStore.loading]);

  const [pending, setPending] = useState(true);

  useEffect(() => {
    store.setCounts(0);
    store.resetPushAlarms();
    getAlarms();

    sendReactNativeMessage({
      type: 'clearAppBadge',
      payload: null,
    });

    document.removeEventListener('visibilitychange', () => {});
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible' && window.location.href.includes('/my/alarm')) {
        sendReactNativeMessage({
          type: 'clearAppBadge',
          payload: null,
        });
      }
      return () => document.removeEventListener('visibilitychange', () => {});
    });
    return () => document.removeEventListener('visibilitychange', () => {});
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <Container
            maxWidth={false}
            sx={{
              display: 'flex',
              flex: 1,
              height: 'auto',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Stack spacing={2} sx={{ flex: 1 }}>
              <CHeader {...headerOptions} />
              <Stack
                direction="column"
                spacing={2}
                sx={{
                  p: 0,
                  m: '0 !important',
                  pb: {
                    xs: HEADER.H_MOBILE + 'px',
                    md: HEADER.H_MAIN_DESKTOP + 'px',
                  },
                }}
              >
                {pending && (
                  <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                    <Trans i18nKey={'loading'} />
                  </Typography>
                )}
                {!pending && store.pushAlarms.length < 1 ? (
                  <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                    <Trans i18nKey={'error.nodata'} />
                  </Typography>
                ) : (
                  <MyAlarmContents isMobile={true} />
                )}
              </Stack>
            </Stack>
          </Container>
        </>
      ) : (
        <>
          <Box position={'sticky'} top={0} sx={{ backgroundColor: 'Background' }} zIndex={99}>
            <Stack
              direction={'row'}
              p={3}
              pr={2}
              alignItems={'center'}
              flexDirection={'row-reverse'}
              justifyContent={'space-between'}
            >
              <IconButton onClick={handleClose} sx={{ zIndex: 99 }}>
                <Iconify icon={'ic:round-close'} />
              </IconButton>
              <Typography variant={'h5'}>
                {translate('pushNotification.mainTitle').toString()}
              </Typography>
            </Stack>
            <Divider sx={{ width: '100%' }} />
          </Box>
          {pending && (
            <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
              <Trans i18nKey={'loading'} />
            </Typography>
          )}
          {!pending && store.pushAlarms.length < 1 ? (
            <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
              <Trans i18nKey={'error.nodata'} />
            </Typography>
          ) : (
            <MyAlarmContents isMobile={false} />
          )}
        </>
      )}
    </>
  );
});

export default MyAlarm;
