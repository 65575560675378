import { withRootStore } from '../extensions/with-root-store';
import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createErrorAlert, IErrorAlert } from '../error-alert/ErrorAlert';
import { withEnvironment } from '../extensions/with-environment';
import { IErrorAlertSnapshot } from '../error-alert/ErrorAlert';
import { toJS } from 'mobx';
import { SNACKBAR_MSG } from '../../components/settings/constEnum';

export const ErrorAlertStore = types
  .model('ErrorAlertStore')
  .props({
    errorAlert: createErrorAlert(),
    duplicateAlert: createErrorAlert(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  .views((self) => ({}))
  .actions((self) => ({
    setErrorAlertFromResponse: () => {
      const detail =
        self.rootStore.responseStore.responseInfo.detail ||
        self.rootStore.responseStore.responseInfo;
      // console.log('msg', toJS(detail));
      const errorAlert: IErrorAlertSnapshot = {
        open: true,
        code: detail ? detail.error || '' : '100',
        message: detail ? detail.exceptionDetail || '' : SNACKBAR_MSG.common.error,
      };
      if (errorAlert.code.toString() !== 'user030') {
        self.errorAlert = errorAlert as IErrorAlert;
      } else {
        self.duplicateAlert = errorAlert as IErrorAlert;
      }
    },
    resetErrorAlert: () => {
      self.errorAlert.open = false;
      self.errorAlert.code = '';
      self.errorAlert.message = '';
      self.duplicateAlert.open = false;
      self.duplicateAlert.code = '';
      self.duplicateAlert.message = '';
    },
  }))
  .actions((self) => ({}));

type TErrorAlertStore = Instance<typeof ErrorAlertStore>;
type TErrorAlertStoreSnapshot = SnapshotOut<typeof ErrorAlertStore>;

export interface IErrorAlertStore extends TErrorAlertStore {}
export type TErrorAlertStoreKeys = keyof TErrorAlertStoreSnapshot & string;
export interface IErrorAlertStoreSnapshot extends TErrorAlertStoreSnapshot {}
export const createErrorAlertStore = () => types.optional(ErrorAlertStore, {} as TErrorAlertStore);
