// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ko = {
  loading: '로딩중...',
  error: {
    100: '알수없는 에러가 발생하였습니다',
    400: '세션이 종료되었습니다',
    401: 'error 401',
    403: 'error 403',
    404: 'error 404',
    500: 'error 500',
    nodata: '데이터가 없습니다',
    webcam: '웹캠 사용 권한을 거부했습니다.',

    common001: '세션이 만료되었습니다.로그인을 진행해주세요.',
    common002: '잘못된 세션 xml값 입니다.',
    common003: '세션이 만료되었습니다.로그인을 진행해주세요.',
    common004: '비회원은 권한이 없습니다.',
    common005: '주최자만 허용됩니다.',
    common006: '회의에 참가하지 않은 회원은 접근 할 수 없습니다.',
    common007: '관리자만 허용됩니다.',
    common008: '접근권한이 없습니다.',
    user001: 'ID는 필수값입니다.',
    user002: '잘못된 패스워드 입니다.',
    user003: 'activeHash는 필수값입니다.',
    user004: 'activeHash값이 유효하지 않습니다.',
    user005: '회의실 ID는 필수값입니다.',
    user006: '이름은 필수값입니다.',
    user007: '이메일은 필수값입니다.',
    user008: '회의실 ID값이 유효하지 않습니다.',
    user009: '이메일값이 유효하지 않습니다.',
    user010: 'ID값이 유효하지 않습니다.',
    user011: '현재 비밀번호가 잘못되었습니다.',
    user012: '신규 비밀번호는 필수값입니다.',
    user013: '기존 비밀번호와 신규 비밀번호는 같을 수 없습니다.',
    user014: '초대받지않은 비회원 정보입니다.',
    user015: '존재하지않는 유저 정보입니다.',
    user016: '회의실 ID는 필수값입니다.',
    user017: '시설은 필수값입니다.',
    user018: '기기정보는 필수 값 입니다.',
    user019: '패스워드가 유효하지 않습니다.',
    user020: '해당 정보의 사용자가 존재하지 않습니다.',
    user021: '참석자 중 해당 방 타입에 권한이 없는 참석자가 존재합니다.',
    user022: '로그인을 너무 많이 시도했습니다 잠시 후 다시 시도해주세요.',
    user023: 'TempToken는 필수값입니다.',
    user024: '인증번호가 유효하지 않습니다.',
    user025: '이메일이 등록되지 않아 사용할 수 없습니다.',
    user026: 'watisToken값이 유효하지 않습니다.',
    user027: 'watisToken값이 유효하지 않습니다.',
    user028: '인증번호는 필수값입니다.',
    user029: 'mobileInfo는 필수값입니다.',
    user030: '새로운 로그인으로 인해 접속이 종료되었습니다.',
    user400: 'Bad Request',
    appointment001: '잘못된 ownerYn 값입니다.',
    appointment002: '회의실 ID는 필수값입니다.',
    appointment003: '비회원은 접근 불가능 합니다.',
    appointment004: '존재하지 않는 회의입니다.',
    appointment005: '주최자만 회의를 삭제 할 수 있습니다.',
    appointment006: '회의실 ID가 유효하지 않습니다.',
    appointment007: '참석자는 필수값 입니다.',
    appointment008: '시작시간은 현재시간과 같거나 큰 값만 올 수 있습니다.',
    appointment009: '종료시간은 현재시간보다 큰 값만 올 수 있습니다.',
    appointment010: '회의타입은 필수값 입니다.',
    appointment011: '주최자만 회의를 수정 할 수 있습니다.',
    appointment012: '시작시간은 필수값입니다.',
    appointment013: '종료시간은 필수값입니다.',
    appointment014: '회의제목은 필수값입니다.',
    appointment015: '녹화가능여부는 필수값입니다.',
    appointment016: '참석자정보가 비어있습니다.',
    appointment017: '비회원의 email은 필수값입니다.',
    appointment018: '비회원의 displayName은 필수값입니다.',
    appointment019: '날짜 유형에는 연간 , 월간 , 일간 중 선택할 수 있습니다.',
    appointment020: '조회기간은 전부 선택해야합니다.',
    appointment021: '종료시간은 시작시간보다 큰 값이 와야합니다.',
    appointment022: 'dateType은 필수값입니다.',
    appointment023: 'dateType과 다른 날짜 형식입니다.',
    appointment024: '일치하지 않는 회의 타입입니다.',
    appointment025: '회원 참석자 중 존재하지 않는 회원이 있습니다.',
    appointment026: 'facilityCd는 필수값 입니다.',
    appointment027: 'ID는 필수값 입니다.',
    appointment028: '환자/보호자의 직종은 필수값 입니다.',
    appointment029: '환자/보호자의 시설은 필수값 입니다.',
    file001: 'fileId는 필수값입니다.',
    file002: 'fileId가 유효하지 않습니다.',
    file003: '주최자만 파일을 삭제 할 수 있습니다.',
    file004: '비회원은 권한이 없습니다.',
    file005: '참여하지 않은 회의의 자료입니다',
    file006: '회의에 참가하지 않은 회원은 자료에 접근 할 수 없습니다.',
    file007: '회의에 참여중인 사용자만 파일을 업로드 할 수 있습니다.',
    file008: '업로드할 파일이 존재하지 않습니다.',
    file009: '녹화파일 저장이 진행 중 입니다 잠시 후 다시 열람해주세요.',
    report001: '회의실 ID는 필수값입니다.',
    report002: '보고서 작성은 주최자만 가능합니다.',
    report003: '회의실 ID가 유효하지 않습니다.',
    report004: 'pdf비밀번호는 필수값 입니다.',
    report005: '보고서가 존재하지 않습니다.',
    report006: '비회원은 권한이 없습니다.',
    report007: '참여하지 않은 회의의 보고서 입니다.',
    report008: '회의에 참가하지 않은 회원은 보고서에 접근 할 수 없습니다.',
    report009: 'reportSid는 필수값입니다.',
    report010: 'reportSid가 유효하지 않습니다.',
    room001: 'cuid는 필수값입니다.',
    room002: 'cuid가 유효하지 않습니다.',
    room003: '회의에 참여하지 않은 클라이언트 입니다.',
    room004: '접근 권한이 없습니다.',
    room005: '회의실 ID가 유효하지 않습니다.',
    room006: 'cuidList는 필수값입니다.',
    room007: '회의실 ID는 필수값입니다.',
    room008: '회의실 ID가 비회원 로그인에서 요청한 값하고 일치하지 않습니다.',
    room009: '종료된 회의 입니다',
    whiteBoard001: 'whiteBoardSid는 필수값입니다.',
    whiteBoard002: '회의에 참여하지 않은 클라이언트 입니다.',
    whiteBoard003: 'whiteBoardSid가 유효하지 않습니다.',
    chat001:
      '현재 화상회의에 입장이 수락되지 않았습니다.주최자가 수락해야만 채팅메시지를 가져올 수 있습니다.',
    chat002: '파일 생성 중 오류가 발생하였습니다.',
    chat003: '회의실 ID는 필수값입니다.',
    hostAction001: 'cuid는 필수값입니다.',
    hostAction002: 'right는 필수값입니다.',
    hostAction003: '잘못된 접근 입니다.',
    hostAction004: 'isOn은 필수값 입니다.',
    hostAction005: 'right 필수값 입니다.',
    hostAction006: 'cuid값이 유효하지 않습니다.',
    push001: 'systemName은 필수값입니다.',
    push002: 'userId는 필수값입니다.',
    push003: 'category는 필수값입니다.',
    push004: 'ownerId는 필수값입니다.',
    push005: 'deviceToken는 필수값입니다.',
    push006: 'pushMsg는 필수값입니다.',
    push007: '푸시 외부키가 존재하지 않습니다.',
    watis001: '회의타입은 필수값 입니다.',
    watis002: '직종은 필수값 입니다.',
    watis003: '시설은 필수값입니다.',
    watis004: '시설,ID,seqNno는 필수값입니다.',
    watis005: '재발송할 푸시이력이 존재하지 않습니다.',
    invitation001: 'code는 필수값입니다.',
    invitation002: 'code가 유효하지 않습니다.',
    invitation003: '회의실 ID는 필수값입니다.',
    invitation004: '참석자는 필수값입니다.',
    invitation005: '회의실 ID가 유효하지않습니다.',
    invitation006: '주최자만 초대할 수 있습니다.',
    invitation007: '만료된 초대 입니다.',
  },
  invalidDate: '유효하지 않은 날짜입니다.',
  total: '전체',
  user: `user`,
  list: `list`,
  edit: `edit`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  create: `create`,
  profile: `profile`,
  account: `account`,
  details: `details`,
  calendar: `calendar`,
  select: '선택하세요',
  noSelection: '선택안함',
  all: '전체',
  myCalendar: {
    week: '주',
    year: '년',
    day: '일',
    month: '월',
    previous: '이전',
    next: '다음',
    today: '오늘',
    agenda: '회의목록',
    enter: '입장',
    Su: '일',
    Mo: '월',
    Tu: '화',
    We: '수',
    Th: '목',
    Fr: '금',
    Sa: '토',
  },
  participant: `참석자`,
  type: {
    NORMAL: `온라인일반회의`,
    TREAT: `온라인진료`,
    CONFERENCE: `온라인컨퍼런스`,
  },

  login: {
    selectFacility: '시설을 선택하세요',
    enterId: 'ID를 입력하세요',
    enterPw: 'PW를 입력하세요',
    login: '로그인',
    findIdPw: '아이디・패스워드 찾기',
    checkEmail: '이메일을 확인해주세요',
    findId: {
      mainTitle: '아이디 찾기',
      enterEmail: '이메일을 입력하세요',
      send: '이메일로 아이디 전송',
      alert: '입력하신 이메일로 아이디가 발송되었습니다.',
    },
    findPassword: {
      mainTitle: '패스워드 찾기',
      enterId: '로그인 아이디를 입력하세요',
      send: '이메일로 패스워드재설정 링크 전송',
      alert: '전송된 이메일로 패스워드재설정을 진행해주세요.',
    },
    loginUnprotected: '비회원 로그인',
    tfa: '2차인증',
    enterCertiNo: '메일로 전송 된 인증번호를 입력해주세요.',
    duplicateLogin: '접속중인 계정입니다.',
    terminate: '기존 연결을 종료하고 다시 접속하시겠습니까?',
  },

  loginUnprotected: {
    enterAppointmentId: '회의실 ID를 입력하세요',
    enterName: '화상회의에서 사용할 이름을 입력하세요',
    participate: '참여',
    sendInvitation: '초대장 발송',
    login: '회원 로그인',
    sended: '입력하신 이메일로 초대장이 재발송 되었습니다.',
  },

  conference: {
    mainTitle: '화상회의',
    reservation: '회의예약',
    makeReservation: '회의개설',
    editReservation: '회의정보 수정',
    searchArea: {
      period: '기간',
      daily: '일별',
      monthly: '월별',
      yearly: '연간',
      type: '타입',
      conferType: {
        NORMAL: `온라인일반회의`,
        TREAT: `온라인진료`,
        CONFERENCE: `온라인컨퍼런스`,
      },
      byHost: '주관자별',
      byParticipants: '참석자별',
      byplace: '보고서 장소별',
      byagenda: '보고서 안건별',
      byreportData: '보고서 내용별',
      enterYourSearchTerm: '검색어를 입력하세요',
    },
    table: {
      date: '일시',
      host: '주최자',
      meetingTime: '회의시간',
      report: '보고서',
      recordingFile: '녹화파일',
      shareFile: '공유문서',
      chat: '채팅',
      participate: '회의참가',
    },
    alert: {
      inviteFailed: '참석자 초대에 실패하였습니다.',
      reservationFailed: '이미 지난 시간에는 회의예약을 할 수 없습니다.',
      chatDownload: '의 채팅을 다운로드 하시겠습니까?',
      roomTypeAuth: '회의 개설 권한이 없습니다.',
    },
  },
  conferenceReservation: {
    mainTitle: '회의 예약',
    info: '회의정보',
    title: '회의제목',
    start: '시작일시',
    end: '종료일시',
    type: '회의타입',
    allowRecording: '녹화가능여부',
    host: '주최자',
    name: '이름',
    facility: '시설',
    auth: '직종',
    participants: '참석자',
    viewDetails: '상세보기',
    nonMembers: '비회원',
    addParticipant: '참석자 추가',
    email: '이메일',
    searchUser: '사용자 검색',
    search: '검색',
    none: '없음',
  },
  searchUser: {
    mainTitle: '회의 상세 정보',
    selectFacility: '시설을 선택하세요',
    selectAuth: '직종을 선택하세요',
    selectGender: '성별을 선택하세요',
    enterName: '이름을 입력하세요',
    enterbirth: '생일을 입력하세요',
    enterCellno: '핸드폰 번호를 입력하세요',
    enterEmail: '이메일을 입력하세요',
    recentUser: '최근 초대 사용자',
    nameKanji: '이름(한자)',
    nameKana: '이름(가나)',
    gender: '성별',
    birth: '생년월일',
    mobile: '휴대폰번호',
    address: '주소',
    patanm: '환자이름(한자)',
    patajm: '환자이름(가나)',
    srchRslt: '검색결과',
  },
  conferenceDetail: {
    mainTitle: '회의 상세 정보',
    host: '주최자',
    conferId: '회의 ID',
    date: '날짜 및 시간',
    browserInfo: '브라우저 정보',
    unknown: '알 수 없음',
    participate: '회의 참가',
    startMeeting: '회의 시작',
    editAlert: '이미 시작됐거나 지난 회의는 수정할 수 없습니다.',
    deleteAlert: '해당 회의를 삭제하시겠습니까?',
    true: '가능',
    false: '불가능',
  },
  conferenceHistory: {
    mainTitle: '화상회의이력',
    period: '조회기간',
    date: '조회날짜',
  },

  participantAlertHistory: {
    mainTitle: '참석자알람이력',
    title: '회의명',
    participantNm: '참석자명',
    sendDt: '알람송신시간',
    reSend: '알람 재전송',
    read: '읽음',
    unread: '재전송',
    sended: 'PUSH알람을 전송했습니다.',
  },
  statistics: {
    mainTitle: '통계',
  },
  dashBoard: {
    mainTitle: '대시보드',
    count: '건수 ',
    todaysMeeting: {
      title: '당일 회의 진행상황판',
      onGoingTotal: '진행중인토탈건수',
      noOnGoingTotal: '진행중인 토탈 건수가 없습니다',
      onGoingNow: '현재 진행중인건수',
      noOnGoingNow: '현재 진행중인 건수가 없습니다',
      scheduled: '당일 예정 건수',
      noScheduled: '당일 예정중인 건수가 없습니다',
      noExist: '가 없습니다.',
    },
    meetingsInProgress: {
      title: '당일 진행중 회의',
      noInProgress: '현재 진행중인 회의가 없습니다.',
      table: {
        conferId: '회의 ID',
        start: '개시시간',
        runtime: '경과시간',
        host: '주최자',
        title: '주제',
        participant: '참석자',
      },
    },
    systemUsage: {
      title: '당일 시스템 사용 상황',
      incoming: '받은 트래픽 속도',
      outgoing: '보낸 트래픽 속도',
      total: '총 트래픽 속도',
      average: '평균 트래픽 속도',
    },
  },

  myPage: {
    mainTitle: '마이페이지',
    name: '화상회의 내 이름',
    changePw: '패스워드 변경',
    currentPw: '현재 패스워드',
    enterCurrentPw: '현재 패스워드를 입력하세요',
    newPw: '신규 패스워드',
    requirements: '대문자+소문자+숫자+문자 중 3가지 혼합 8자리 이상',
    confirmPw: '패스워드 확인',
    reEnterPw: '신규 패스워드를 한번 더 입력하세요',
    validation: {
      match: '패스워드는 대 소문자, 숫자, 문자 중 3가지 이상 포함되어야합니다.',
      min: '패스워드는 8자리 이상 입력해주세요.',
      equal: '비밀번호가 일치하지 않습니다.',
    },
    language: '언어선택',
    japanese: '일본어',
    english: '영어',
    korean: '한국어',
    receiveStatus: 'PUSH 수신 여부',
    receiveY: '수신',
    receiveN: '수신 안함',
    logout: '로그아웃',
    save: '변경사항 저장',
    sveAlert: '현재 변경사항을 저장 하시겠습니까?',
  },

  pushNotification: { mainTitle: '알람수신이력' },

  waiting: {
    inviteAlert: '주최자가 귀하를 허용하면 회의에 참여할 수 있습니다',
    videoYn: '비디오ON/OFF',
    audioYn: '오디오ON/OFF',
    mic: '마이크 선택',
    speaker: '스피커 선택',
    video: '비디오 선택',
    resolution: '카메라 해상도',
    leave: '나가기',
    end: '회의종료',
    start: '회의시작',
    acceptMsg: '주최자가 회의 참여를 승인 하였습니다',
    participate: '회의참여',
  },

  meeting: {
    me: '나',
    member: '회원',
    nonMember: '비회원',
    audioVideoOff: '오디오/비디오가 OFF상태 입니다',
    join: '사용자가 회의에 참여하고 싶어 합니다',
    denyAll: '모두 거부',
    acceptAll: '모두 수락',
    deny: '거부',
    accept: '수락',
    denyAlert: '거절 하시겠습니까?',
    autoRecord: '자동 녹화 진행 하시겠습니까?',
    participant: '참석자',
    record: '녹화',
    chat: '채팅',
    enterMsg: '여기에 메세지를 입력하세요',
    whiteboard: '화이트보드',
    screenShare: '화면공유',
    fileShare: '문서공유',

    file: '파일을 내려 놓거나 선택해주세요',
    title: '회의주제',
    pdfPw: 'pdf비밀번호',
    enterPdfPw: 'pdf비밀번호를 입력해주세요',
    end: '회의종료',
    spkrMicTest: '스피커 및 마이크 테스트',
    test: '테스트',
    apply: '적용',
    videoSetting: '비디오 환경 설정',
    invite: '회의 초대하기',
    invitePrtcpnt: '참석자 초대',
    inviteInfo: '초대정보',
    ptcpntNm: '참석자명',
    invitationLink: '초대링크',
    copy: '복사',
    ok: '확인',

    meeting: '미팅',
    videoOn: '비디오ON',
    videoOff: '비디오OFF',
    audioOn: '오디오ON',
    audioOff: '오디오OFF',
    y: '예',
    n: '아니오',
    submit: '확인',
    cancel: '취소',
    video: '비디오',
    audio: '오디오',
    outputLevel: '출력 레벨',
    volume: '볼륨',

    mic: '마이크',
    speaker: '스피커',
    share: '공유',

    report: {
      mainTitle: '보고서',
      enterTitle: '회의주제를 입력하세요',
      place: '장소',
      history: '내역',
      edit: '수정',
      save: '저장',
      ok: '확인',
      contents: '회의 내용',
      shareFiles: '공유파일',
      item: '안건',
      recordYn: '녹화여부',
      host: '주최자',
      date: '회의 일시',
      updDt: '수정 일시',
      regDt: '등록 일시',
      pdfDownload: 'PDF 다운',
      enterContent: '내용을 입력해주세요',
      more: '더보기',
    },

    alert: {
      nonRecording: '녹화가 불가능한 회의입니다',
      name: '참석자명을 입력하세요',
      email: '참석자 이메일을 입력하세요',
      screenSharing: '화면 공유 진행 중 입니다',
      denied: '주최자가 회의 참여를 거절 하였습니다',
      copied: '클립보드에 링크가 복사 되었습니다.',
      noWebcam: '웹캠이 존재하지 않습니다.',
      webcamInUse: '웹캠이 이미 사용중입니다.',
      webcamTrack: '웹캠이 필요한 트랙을 제공하지 않습니다',
      webcamAccess: '웹캠 사용 권한을 거부 하였습니다',
      mediaTrack: '미디어 트랙이 요청되지 않았습니다',
      fileDelete: '파일을 삭제 하시곘습니까?',
      reportDelete: '보고서를 삭제 하시곘습니까?',
      endAlert: '회의를 종료 하시겠습니까?',
      leaveAlert: '회의를 나가시겠습니까?',
      ended: '회의가 종료 되었습니다.',
      micMsg: '마이크테스트 진행 시 화상회의 오디오가 OFF 됩니다. 진행하시겠습니까?',
      volMsg: '스피커테스트 진행 시 화상회의 오디오가 OFF 됩니다. 진행하시겠습니까?',
      whiteboardEdit: '화이트보드 수정사항이 있습니다.',
      videoMaximum: '비디오 최대 허용인원수를 초과했습니다.',
      participantsMaximum: '화상회의 최대 허용인원수를 초과했습니다.',
      websocket: '에러가 발생하였습니다. 관리자에게 문의해 주세요.',
    },
  },
};

export default ko;
