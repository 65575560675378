import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { Button, Tab, Tabs, useTheme } from '@mui/material';
import AudioSetting from '../setting/AudioSetting';
import VideoSetting from '../setting/VideoSetting';
import { useEffect, useState } from 'react';
import { useLocales } from 'src/locales';
import SpeakerSetting from './SpeakerSetting';
import { TabContext, TabPanel } from '@mui/lab';

type Props = {
  audioInputs: { label: string; value: string }[];
  audioInput: string | null;
  audioOutputs: { label: string; value: string }[];
  audioOutput: string | null;
  aftervideostate: any | null;
  videoInputs: { label: string; value: string }[];
  videoInput: string | null;
  videoSize: string;
  anchorSet: HTMLButtonElement | null;
  view: string
  returnAudioInputData: (
    audioInput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioOutputData: (
    audioOuput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioInputStream: (
    Inputstate: any | null,
    type?: string | null | undefined
  ) => void;
  returnAudioOutputStream: (
    Outputstate: any | null,
    type?: string | null | undefined
  ) => void;
  returnVideoInputData: (
    videoInput: string | null,
    value: any,
    label: any
  ) => void;
  returnVideoSizeData: (
    videoSize: string | null,
    value: any,
    label: any
  ) => void;
  videoMic?: any;
  videoVol?: any;
  reSetView: (
    value: any,
  ) => void;
  onButtonStatus?: (
    flag: any
  ) => void;
  buttonStatus?: any;
  onTestState?: (
    flag: any
  ) => void;
  onButtonValue?: (
    flag: any
  ) => void;
  toggleMic?: any;
  mobile?: any;
};
/**
 * ## MettingTotalSetting 설명
 *
 */
export const MettingTotalSetting = observer(({
  audioInputs,
  audioInput,
  audioOutput,
  audioOutputs,
  anchorSet,
  aftervideostate,
  videoInputs,
  videoInput,
  videoSize,
  returnAudioInputData,
  returnAudioOutputData,
  returnAudioInputStream,
  returnAudioOutputStream,
  returnVideoInputData,
  returnVideoSizeData,
  view,
  videoMic,
  videoVol,
  reSetView,
  onButtonStatus,
  buttonStatus,
  onTestState,
  onButtonValue,
  toggleMic,
  mobile
}: Props
) => {

  const [tabValue, setTabValue] = useState('VIDEO');
  const [mode, setMode] = useState('VIDEO');
  const { translate } = useLocales();
  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    if (newValue !== 'SPEAKER') {
      onButtonValue && onButtonValue(true);
      onTestState && onTestState(false);
    }
  };

  const ownerNavConfig = [
    {
      title: translate(`meeting.video`).toString(),
      value: 'VIDEO',
    },
    {
      title: translate(`meeting.audio`).toString(),
      value: 'AUDIO',
    },
    {
      title: translate(`meeting.spkrMicTest`).toString(),
      value: 'SPEAKER',
    },
  ];

  useEffect(() => {
    reSetView(tabValue);
  }, [tabValue])

  return (
    <>
      <Box>
        <TabContext value={tabValue}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            sx={{ px: 2, pt: 1, borderBottom: '1px solid ' + theme.palette.divider }}
          >
            {ownerNavConfig.map((item: any, i: number) => {
              if(mobile===true && item.value==='AUDIO'){
                return null;
              }
              if(mobile===true && item.value==='SPEAKER'){
                return null;
              }
              return (
                <Tab
                  key={'meetingControlButtons-' + i}
                  id={'meetingControlButtons' + i}
                  value={item.value}
                  label={item.title}
                  sx={{ mr: 0 }}
                />
              );
            })}
          </Tabs>
          {mobile === false &&
            <TabPanel value={'AUDIO'} sx={{ height: '100%', p: 0, pb: 1 }}>
              <AudioSetting
                audioInputs={audioInputs}
                audioInput={audioInput}
                audioOutput={audioOutput}
                audioOutputs={audioOutputs}
                aftervideostate={aftervideostate}
                returnAudioInputData={returnAudioInputData}
                returnAudioOutputData={returnAudioOutputData}
                returnAudioInputStream={returnAudioInputStream}
                returnAudioOutputStream={returnAudioOutputStream}
                anchorSet={anchorSet}
                view={view}
                videoMic={videoMic}
                videoVol={videoVol}
              />
            </TabPanel>
          }
          <TabPanel value={'VIDEO'} sx={{ height: '100%', p: 0, pb: 1 }}>
            <VideoSetting
              videoInputs={videoInputs}
              videoInput={videoInput}
              videoSize={videoSize}
              returnVideoInputData={returnVideoInputData}
              returnVideoSizeData={returnVideoSizeData}
              mobile={mobile}
            />
          </TabPanel>
          {mobile === false &&
            <TabPanel value={'SPEAKER'} sx={{ height: '100%', p: 0, pb: 1 }}>
              <SpeakerSetting
                audioInputs={audioInputs}
                audioInput={audioInput}
                audioOutput={audioOutput}
                audioOutputs={audioOutputs}
                aftervideostate={aftervideostate}
                returnAudioInputData={returnAudioInputData}
                returnAudioOutputData={returnAudioOutputData}
                returnAudioInputStream={returnAudioInputStream}
                returnAudioOutputStream={returnAudioOutputStream}
                anchorSet={anchorSet}
                view={view}
                videoMic={videoMic}
                videoVol={videoVol}
                onButtonStatus={onButtonStatus}
                buttonStatus={buttonStatus}
                onTestState={onTestState}
                toggleMic={toggleMic}
              />
            </TabPanel>
          }
        </TabContext>
      </Box>
    </>
  );
});

export default MettingTotalSetting;