import { TabContext, TabPanel } from '@mui/lab';
import { Tab, Tabs, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import FindForm from './FindForm';
import { useLocales } from 'src/locales';

const FindUser = observer(() => {
  const theme = useTheme();
  const { translate } = useLocales();
  const [value, setValue] = useState('tab1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Stack
        justifyContent={'space-between'}
        sx={{ flex: 1, height: '100%', scrollMarginTop: '100px' }}
      >
        <TabContext value={value}>
          <Stack
            display={'flex'}
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              flex: 1,
              mb: 1,
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              aria-label="find id tabs"
              sx={{
                fontVariant: 'Kor_16_b',
                '& .MuiTab-root': {
                  m: '0 !important',
                },
              }}
            >
              <Tab
                label={translate('login.findId.mainTitle').toString()}
                sx={{
                  '&:not(.Mui-selected)': {
                    color: theme.palette.grey[400],
                  },
                }}
                value={'tab1'}
              />
              <Tab
                label={translate('login.findPassword.mainTitle').toString()}
                sx={{
                  '&:not(.Mui-selected)': {
                    color: theme.palette.grey[400],
                  },
                }}
                value={'tab2'}
              />
            </Tabs>
          </Stack>
          <TabPanel value={'tab1'} sx={{ height: '100%' }}>
            <FindForm type={'findId'} />
          </TabPanel>
          <TabPanel value={'tab2'} sx={{ height: '100%' }}>
            <FindForm type={'findPassword'} />
          </TabPanel>
        </TabContext>
      </Stack>
    </>
  );
});

export default FindUser;
