import * as React from 'react';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import {
  DataGridPro,
  GridCellEditStopParams,
  GridCellEditStopReasons,
  GridColDef,
  GridColumns,
  GridDensity,
  GridFilterModel,
  GridLinkOperator,
  gridPageCountSelector,
  gridPageSelector,
  GridPagination,
  gridRowCountSelector,
  GridRowModel,
  GridSelectionModel,
  GridValidRowModel,
  MuiEvent,
  useGridApiContext,
  useGridSelector,
  koKR,
  jaJP,
} from '@mui/x-data-grid-pro';

import { toJS } from 'mobx';
import { alpha, Box, fontSize, Stack, SxProps } from '@mui/system';
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro';
import { IPagination } from '../models/pagination/Pagination';
import { useLocales } from 'src/locales';

const CDataGridStyled = styled(DataGridPro)(({ theme }) => ({
  border: 0,
  color: theme.palette.text.secondary,
  fontFamily: ['Roboto', 'sans-serif'].join(','),
  // userSelect: 'none',
  '@media print': {
    '.MuiDataGrid-main': {
      color: 'rgba(0, 0, 0, 0.87)',
      background: '#FFFFFF',
      fontSize: '12pt',
    },
  },
  '& .MuiDataGrid-toolbarContainer': {
    padding: '8px 8px 8px 16px',
    // color: theme.palette.severity.
  },
  '& .MuiDataGrid-cell:hover': {
    color: theme.palette.text.primary,
  },
  WebkitFontSmoothing: 'auto',
  letterSpacing: 'normal',
  '& .MuiDataGrid-columnsContainer': {},
  '& .MuiDataGrid-iconSeparator': {
    height: 0,
  },
  '& .MuiDataGrid-columnHeaders': {
    borderTop: `1px solid ${alpha(theme.palette.text.primary, 0.6)}`,
    borderBottom: `1px solid ${alpha(theme.palette.text.primary, 0.6)}`,
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: `1px solid ${alpha(theme.palette.text.disabled, 0.4)}`, //#323c45',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.4)} `, //'1px solid #323c45',
  },
  '& .MuiDataGrid-cell': {
    color: theme.palette.text.primary,
    // background: theme.palette.background.paper
  },
  '& .MuiPaginationItem-root': {
    borderRadius: 0,
  },
  // '& .data-grid-row-Critical': {
  //   background: theme.palette.severity.critical,
  //   '& .MuiDataGrid-cellContent': {
  //     color: theme.palette.grey[0],
  //   },
  //   '& .MuiDataGrid-cell': {
  //     color: theme.palette.grey[0],
  //   },
  //   '& .MuiCheckbox-root': {
  //     color: theme.palette.grey[200],
  //   }
  // },
  // '& .data-grid-row-Critical:hover': {
  //   background: alpha(theme.palette.severity.critical, 0.8),
  // },
  // '& .data-grid-row-Major': {
  //   background: theme.palette.severity.major
  // },
  // '& .data-grid-row-Major:hover': {
  //   background: alpha(theme.palette.severity.major, 0.8),
  // },
  // '& .data-grid-row-Minor': {
  //   background: theme.palette.severity.minor
  // },
  // '& .data-grid-row-Minor:hover': {
  //   background: alpha(theme.palette.severity.minor, 0.8),
  // },
  // '& .data-grid-row-Warning': {
  //   background: theme.palette.severity.warning
  // },
  // '& .data-grid-row-Warning:hover': {
  //   background: alpha(theme.palette.severity.warning, 0.8),
  // },
  // '& .data-grid-row-Information': {
  //   background: theme.palette.severity.information
  // },
  // '& .data-grid-row-Information:hover': {
  //   background: alpha(theme.palette.severity.information, 0.8),
  // },
}));

const filterPanelProps = {
  filterPanel: {
    linkOperators: [GridLinkOperator.And],
    columnsSort: 'asc',
    filterFormProps: {
      linkOperatorInputProps: {
        variant: 'outlined',
        size: 'small',
      },
      columnInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto' },
      },
      operatorInputProps: {
        variant: 'outlined',
        size: 'small',
        sx: { mt: 'auto' },
      },
      valueInputProps: {
        InputComponentProps: {
          variant: 'outlined',
          size: 'small',
        },
      },
      deleteIconProps: {
        sx: {
          '& .MuiSvgIcon-root': { color: '#d32f2f' },
        },
      },
    },
    sx: {
      // Customize inputs using css selectors
      '& .MuiDataGrid-filterForm': { p: 2 },
      // '& .MuiDataGrid-filterForm:nth-child(even)': {
      //   backgroundColor: '#444',
      // },
      '& .MuiDataGrid-filterFormLinkOperatorInput': { mr: 2 },
      '& .MuiDataGrid-filterFormColumnInput': { mr: 2, width: 150 },
      '& .MuiDataGrid-filterFormOperatorInput': { mr: 2 },
      '& .MuiDataGrid-filterFormValueInput': { width: 200 },
    },
  },
};
interface ICDataGridProps {
  store?: any;
  columns: any[];
  rows: string | any[];
  rowId: any;
  paging?: boolean;
  checkbox?: boolean;
  onPageSizeChange?: (page: number) => void;
  onPageChange?: (page: number) => void;
  onCellDoubleClick?: (e: any) => void;
  onRowDoubleClick?: (e: any) => void;
  autoPageSize?: boolean;
  density?: GridDensity;
  disableSelectionOnClick?: boolean;
  components?: {};
  componentsProps?: {};
  pagingType?: 'default' | 'custom';

  processRowUpdate?: (
    row: GridRowModel,
    oldRow: GridRowModel,
  ) => Promise<GridRowModel> | GridRowModel;
  handleProcessRowUpdateError?: (error: Error) => void;
  isRowSelectable?: (params: any) => boolean;
  onSelectionModelChange?: (selection: any) => void;
  selectionModel?: GridSelectionModel;
  initialState?: {};
  rowClassName?: ((params: any) => string) | undefined;
  rowHeight?: number | undefined;
  autoHeight?: boolean;
  sx?: SxProps;
  apiRef?: React.MutableRefObject<GridApiPro>;
  filterModel?: GridFilterModel;
  myPagination?: IPagination;
}

export const CDataGrid = ({
  store,
  columns = [],
  rows,
  rowId,
  paging = true,
  checkbox = false,
  onPageSizeChange,
  onPageChange,
  onCellDoubleClick,
  onRowDoubleClick,
  autoPageSize = false,
  density = 'compact',
  disableSelectionOnClick = false,
  components,
  componentsProps,
  pagingType = 'custom',
  processRowUpdate,
  handleProcessRowUpdateError,
  isRowSelectable,
  onSelectionModelChange,
  selectionModel,
  initialState,
  rowClassName,
  rowHeight = undefined,
  autoHeight = false,
  sx,
  apiRef,
  filterModel,
  myPagination,
}: ICDataGridProps) => {
  const theme = useTheme();

  const list = store && typeof rows === 'string' ? toJS(store[rows]) : rows;

  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    const totalElements = useGridSelector(apiRef, gridRowCountSelector);
    return (
      <Pagination
        page={page + 1}
        count={pageCount}
        color={'primary'}
        size={'medium'}
        shape={'rounded'}
        variant={'outlined'}
        showFirstButton
        defaultPage={6}
        siblingCount={0}
        boundaryCount={2}
        // @ts-expect-error
        renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          apiRef.current.setPage(value - 1);
        }}
        sx={{ p: 2 }}
      />
    );
  }

  const CutsomGridFooter = () => {
    const { translate } = useLocales();
    const apiRef = useGridApiContext();
    const type = pagingType;
    const paging =
      pagingType === 'custom' && !autoPageSize ? (
        CustomPagination()
      ) : (
        <GridPagination
          sx={{ background: theme.palette.background.paper }}
          onResize={undefined}
          onResizeCapture={undefined}
        />
      );
    const totalElements = useGridSelector(apiRef, gridRowCountSelector);
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ p: 2, mt: 0.5, mr: 2, color: theme.typography.body2 }}>
          {translate('total').toString()} {totalElements.toLocaleString()}
        </Box>
        <Box sx={{}}>{paging}</Box>
      </Box>
    );
  };

  // const processRowUpdate = React.useCallback(
  //   async (newRow: GridRowModel) => {
  //     Make the HTTP request to save in the backend
  //     const response = await mutateRow(newRow);
  //     setSnackbar({ children: 'User successfully saved', severity: 'success' });
  //     return response;
  //   },
  //   [mutateRow],
  // );

  // const handleProcessRowUpdateError = React.useCallback((error: Error) => {
  //   setSnackbar({ children: error.message, severity: 'error' });
  // }, []);

  const ROWS_PER_PAGE = [25, 50, 100];

  let heightProp = {};
  if (rowHeight && rowHeight > 0) {
    heightProp = { rowHeight: rowHeight };
  }

  const locale = localStorage.getItem('i18nextLng');

  return (
    <>
      {paging === true ? (
        <CDataGridStyled
          apiRef={apiRef}
          theme={theme}
          columns={columns as GridColumns<GridValidRowModel>}
          rows={list}
          getRowId={(row) => row[rowId]}
          pagination
          page={store && (myPagination ? myPagination.page - 1 : store.pagination.page - 1)}
          pageSize={store && (myPagination ? myPagination.size : store.pagination.size)}
          rowCount={
            store
              ? myPagination
                ? myPagination.totalElements
                : store.pagination.totalElements
              : list.length
          }
          rowsPerPageOptions={ROWS_PER_PAGE}
          paginationMode={!autoPageSize ? 'server' : 'client'}
          onPageSizeChange={store ? onPageSizeChange : (page: number) => {}}
          onPageChange={store ? onPageChange : (page: number) => {}}
          autoPageSize={true}
          checkboxSelection={checkbox}
          {...heightProp}
          density={density}
          disableSelectionOnClick={disableSelectionOnClick}
          experimentalFeatures={{ newEditingApi: true }}
          editMode={'row'}
          components={{
            ...components,
            Pagination:
              pagingType === 'custom' && !autoPageSize ? CustomPagination : GridPagination,
            Footer: CutsomGridFooter,
          }}
          componentsProps={{
            ...filterPanelProps,
            ...componentsProps,
          }}
          onRowDoubleClick={onRowDoubleClick}
          onCellDoubleClick={onCellDoubleClick}
          onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
            if (params.reason === GridCellEditStopReasons.cellFocusOut) {
              event.defaultMuiPrevented = true;
            }
          }}
          isRowSelectable={isRowSelectable}
          getRowClassName={rowClassName}
          disableIgnoreModificationsIfProcessingProps
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectionModel}
          initialState={initialState}
          autoHeight={autoHeight}
          filterModel={filterModel}
          sx={{
            ...sx,
          }}
          localeText={
            locale === 'ko'
              ? koKR.components.MuiDataGrid.defaultProps.localeText
              : locale === 'ja'
              ? jaJP.components.MuiDataGrid.defaultProps.localeText
              : undefined
          }
        />
      ) : (
        /** ------------------------------------------------------------------------------------------------ */
        <CDataGridStyled
          apiRef={apiRef}
          theme={theme}
          columns={columns as GridColumns<GridValidRowModel>}
          rows={list}
          getRowId={(row) => row[rowId]}
          // rowCount={100}
          autoPageSize={false}
          hideFooter={true}
          hideFooterSelectedRowCount={true}
          hideFooterPagination={true}
          checkboxSelection={checkbox}
          {...heightProp}
          density={density}
          disableSelectionOnClick={disableSelectionOnClick}
          components={{
            ...components,
          }}
          componentsProps={{
            ...filterPanelProps,
            ...componentsProps,
          }}
          onRowDoubleClick={onRowDoubleClick}
          onCellDoubleClick={onCellDoubleClick}
          onCellEditStop={(params: GridCellEditStopParams, event: MuiEvent) => {
            if (params.reason === GridCellEditStopReasons.cellFocusOut) {
              event.defaultMuiPrevented = true;
            }
          }}
          isRowSelectable={isRowSelectable}
          getRowClassName={rowClassName}
          disableIgnoreModificationsIfProcessingProps
          processRowUpdate={processRowUpdate}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          onSelectionModelChange={onSelectionModelChange}
          selectionModel={selectionModel}
          initialState={initialState}
          autoHeight={autoHeight}
          filterModel={filterModel}
          sx={{
            ...sx,
          }}
          localeText={
            locale === 'ko'
              ? koKR.components.MuiDataGrid.defaultProps.localeText
              : locale === 'ja'
              ? jaJP.components.MuiDataGrid.defaultProps.localeText
              : undefined
          }
        />
      )}
    </>
  );
};
