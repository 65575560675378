import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # AcceptMeeting
 *
 * AcceptMeeting 설명하세요.
 */
export const AcceptMeeting = types
  .model('AcceptMeeting')
  // --------------------------------------------------------------------------
  .props({
    cuid: types.optional(types.string, ''),
    roomId: types.maybeNull(types.number),
    displayName: types.optional(types.string, ''),
    isMember: types.optional(types.boolean, false),
    email: types.optional(types.string, ''),
    authCd: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model meeting --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAcceptMeeting;
      (Object.keys(newProps) as TAcceptMeetingKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAcceptMeeting = Instance<typeof AcceptMeeting>;
type TAcceptMeetingSnapshot = SnapshotOut<typeof AcceptMeeting>;

export interface IAcceptMeeting extends TAcceptMeeting {}
export type TAcceptMeetingKeys = keyof TAcceptMeetingSnapshot & string;
export interface IAcceptMeetingSnapshot extends TAcceptMeetingSnapshot {}
export const createMeeting = () => types.optional(AcceptMeeting, {} as TAcceptMeeting);
