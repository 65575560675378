// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ja = {
  loading: '読み込み中···',
  error: {
    100: 'Unknown Error',
    400: 'セッションが終了しました。',
    401: 'error 401',
    403: 'error 403',
    404: 'error 404',
    500: 'error 500',
    nodata: 'データがありません',
    webcam: 'ウェブカメラの使用許可を拒否しました。',

    common001: 'セッションが満了しました。再度ログインしてください。',
    common002: '間違ったセッションxml値です。',
    common003: 'セッションが満了しました。再度ログインしてください。',
    common004: '非会員は権限がありません。',
    common005: '会議主催者のみ可能です。',
    common006: '会議に参加していない会員はアクセスできません。',
    common007: '管理者のみ可能です。',
    common008: 'アクセス権限がありません。',
    user001: 'ログインIDは必須です。',
    user002: 'パスワードが間違ってます。',
    user003: 'active Hashは必須です。',
    user004: 'activeHashの値が無効です。',
    user005: '会議室IDは必須です。',
    user006: '名は必須です。',
    user007: 'メールアドレス は必須です。',
    user008: '会議室IDが有効ではありません。',
    user009: 'メールアドレスが有効ではありません。',
    user010: 'ログインIDが有効ではありません。',
    user011: '現在のパスワードが間違っています。',
    user012: '新規パスワードは必須です。',
    user013: '既存のパスワードと同じです。違うパスワードを入力してください。',
    user014: '招待されてないため、会議室に入場できません。',
    user015: '入力したIDでは登録情報がありません。',
    user016: '会議室IDは必須です。',
    user017: '施設は必須です。',
    user018: 'デバイス情報は必ず入力してください。 ',
    user019: 'パスワードが間違ってます。',
    user020: 'IDが間違ってます。',
    user021: '該当する部屋タイプの権限を持っていない参加者が存在します。', //参加者の中に選択された会議
    user022: '複数回のログイン失敗がありました。しばらくログインできません。',
    user023: 'TempTokenは必ず入力してください。',
    user024: '認証番号が有効ではありません。',
    user025: 'メールアドレスが登録されていないため、使用できません。',
    user026: 'watisTokenが有効ではありません。',
    user027: 'watisTokenが有効ではありません。',
    user028: '認証番号は必ず入力してください。',
    user029: 'mobileInfoは必ず入力してください。',
    user030: '新たなログインによりログアウトされました。',
    user400: 'Bad Request',
    appointment001: '間違ったownerYnの値です',
    appointment002: '会議室IDは必須です。',
    appointment003: '非会員はアクセスできません',
    appointment004: '存在しない会議です',
    appointment005: '主催者だけが会議を削除することができます',
    appointment006: '会議室IDが有効ではありません。',
    appointment007: '参加者は必ず入力してください。',
    appointment008: '開始時間は現在時間と同じか未来でなければなりません。',
    appointment009: '終了時間は開始時間より未来に設定してください。',
    appointment010: '会議タイプは必ず入力してください。',
    appointment011: '主催者だけが会議を修正することができます',
    appointment012: '開始時間は必ず入力してください。',
    appointment013: '終了時間は必ず入力してください。',
    appointment014: '会議タイトルは必ず入力してください。',
    appointment015: '録画可能可否 は必須です。',
    appointment016: 'userInfoが空です',
    appointment017: '非会員のemailは必須です',
    appointment018: '非会員のdisplayNameは必須です',
    appointment019: '日付パターンは年、月、日の中から選択できます',
    appointment020: '照会期間は全て選択する必要があります',
    appointment021: '終了時間を開始時間より未来に設定してください。',
    appointment022: '日付パターンは必須です',
    appointment023: '日付パターンと異なる日付形式です',
    appointment024: '一致しない会議タイプです',
    appointment025: 'メンバー参加者の中に存在しないメンバーがいます',
    appointment026: '施設は必須です。',
    appointment027: 'IDは必須です',
    appointment028: '患者/保護者のauthCdは必須です',
    appointment029: '患者/保護者の施設は必須です。',
    file001: 'fileIdは必須です',
    file002: 'fileIdが無効です',
    file003: '主催者のみファイル削除可能です。',
    file004: '非会員は権限がありません',
    file005: '参加していない会議のファイルです',
    file006: '会議に参加していないメンバーはファイルにアクセスできません',
    file007: '会議に参加しているユーザーだけがファイルをアップロードすることができます',
    file008: 'アップロードするファイルが存在しません',
    file009: '録画ファイルの保存が進行中です。しばらくしてから再度閲覧してください。',
    report001: '会議室IDは必須です。',
    report002: '報告書作成は主催者のみ可能です',
    report003: 'roomIdが無効です',
    report004: 'pdfPasswordは必須です',
    report005: '報告書が存在しません',
    report006: '非会員は権限がありません',
    report007: '参加していない会議の報告書です',
    report008: '会議に参加していないメンバーは報告書にアクセスできません',
    report009: 'reportSid は必須です',
    report010: 'reportSid が無効です',
    room001: 'cuidは必須です',
    room002: 'cuidが無効です',
    room003: '会議に参加していないクライアントです',
    room004: 'アクセス権限がありません',
    room005: 'roomId が無効です',
    room006: 'cuidList は必須です',
    room007: 'roomId は必須です',
    room008: 'roomIdが非会員ログインで要求した値と一致しません',
    room009: '終了した会議です',
    whiteBoard001: 'whiteBoardSidは必須です',
    whiteBoard002: '会議に参加していないクライアントです',
    whiteBoard003: 'whiteBoardSidが無効です',
    chat001:
      '現在,ビデオ会議への参加が承認されていません。主催者が承認して初めてチャットメッセージを取得することができます',
    chat002: 'ファイルの作成中にエラーが発生しました',
    chat003: '会議室IDは必須です。',
    hostAction001: 'cuidは必須です',
    hostAction002: 'rightは必須です',
    hostAction003: 'アクセスが許可されていません。',
    hostAction004: 'isOn は必須です',
    hostAction005: 'rightは必須です',
    hostAction006: 'cuid 値が無効です',
    push001: 'systemNameは必須です',
    push002: 'userIdは必須です',
    push003: 'categoryは必須です',
    push004: 'ownerIdは必須です',
    push005: 'deviceTokenは必須です',
    push006: 'pushMsgは必須です',
    push007: 'プッシュ外部キーが存在しません。',
    watis001: '会議タイプは必須です',
    watis002: '職種は必須です',
    watis003: '施設は必須です。',
    watis004: '施設、ID、seqNnoは必須項目です。',
    watis005: '再送信するプッシュ履歴が存在しません。',
    invitation001: 'codeは必須です',
    invitation002: 'codeが無効です',
    invitation003: '会議室IDは必須です。',
    invitation004: '参加者は必須です',
    invitation005: 'roomIdが無効です',
    invitation006: '主催者だけが招待することができます',
    invitation007: '有効期限切れの招待状です。',
  },
  invalidDate: '無効な日付です',
  total: '合計',
  user: 'ユーザー',
  list: 'リスト',
  edit: '編集',
  post: '投稿',
  mail: 'メール',
  chat: 'チャット',
  create: '作成',
  profile: 'プロフィール',
  account: 'アカウント',
  details: '詳細',
  calendar: 'カレンダー',
  select: '選択してください',
  noSelection: '選択なし',
  all: '職員全体',
  myCalendar: {
    week: '週',
    year: '年',
    day: '日',
    month: '月',
    previous: '前',
    next: '次',
    today: '今日',
    agenda: '議題',
    enter: '入場',
    Su: '日',
    Mo: '月',
    Tu: '火',
    We: '水',
    Th: '木',
    Fr: '金',
    Sa: '土',
  },
  participant: '参加者',
  type: {
    NORMAL: '一般会議',
    TREAT: 'オンライン診療',
    CONFERENCE: 'オンラインカンファレンス',
  },

  login: {
    selectFacility: '施設を選択してください。',
    enterId: 'IDを入力してください。',
    enterPw: 'パスワードを入力してください。',
    login: 'ログイン',
    findIdPw: 'ID・パスワードを忘れた場合',
    checkEmail: 'メールを確認してください',
    findId: {
      mainTitle: 'IDを忘れた場合',
      enterEmail: 'メールアドレスを入力してください。',
      send: 'メールアドレスにID転送',
      alert: '入力したメールにIDを発送しました。',
    },
    findPassword: {
      mainTitle: 'パスワードを忘れた場合',
      enterId: 'ログインIDを入力してください。',
      send: 'メールにパスワード変更リンク転送',
      alert: '転送されたメールを確認して、パスワードを再設定してください。',
    },
    loginUnprotected: '非会員ログイン',
    tfa: '二段階認証',
    enterCertiNo: 'メールに送信された認証番号を入力してください。',
    duplicateLogin: 'ログイン中のアカウントです。',
    terminate: '既存の接続をログアウトし再度ログインしますか？',
  },

  loginUnprotected: {
    enterAppointmentId: '会議室IDを入力してください。',
    enterName: '会議時使用する名を入力してください。',
    participate: '参加',
    sendInvitation: '招待状発送',
    login: '会員ログイン',
    sended: '入力したメールに招待状を再発送しました。',
  },

  conference: {
    mainTitle: '画像会議',
    reservation: '会議予約',
    makeReservation: '会議開設',
    editReservation: '会議変更',
    searchArea: {
      period: '期間',
      daily: '日別',
      monthly: '月別',
      yearly: '年間',
      type: 'タイプ',
      conferType: {
        NORMAL: `一般会議`,
        TREAT: `オンライン診療`,
        CONFERENCE: `オンラインカンファレンス`,
      },
      byHost: '主催者別',
      byParticipants: '参加者別',
      byplace: '報告書 場所別',
      byagenda: '報告書 議題別',
      byreportData: '報告書 内容別',
      enterYourSearchTerm: '検査語を入力してください。',
    },
    table: {
      date: '日時',
      host: '主催者',
      meetingTime: '会議時間',
      report: '報告書',
      recordingFile: '録画ファイル',
      shareFile: '共有文書',
      chat: 'チャット',
      participate: '会議参加',
    },
    alert: {
      inviteFailed: '参加者招待に失敗しました。',
      reservationFailed: '過去の時間で会議を予約することは不可能です。',
      chatDownload: 'のチャット内容をダウンロードしますか？',
      roomTypeAuth: '会議を開催する権限がありません。',
    },
  },
  conferenceReservation: {
    mainTitle: '会議予約',
    info: '会議情報',
    title: '会議議題',
    start: '開始日',
    end: '終了日',
    type: '会議タイプ',
    allowRecording: '録画可能可否',
    host: '会議主催者',
    name: '氏名',
    facility: '施設',
    auth: '職種',
    participants: '参加者',
    viewDetails: '詳細',
    nonMembers: '非会員',
    addParticipant: '参加者追加',
    email: '電子メール',
    searchUser: '使用者検索',
    search: '検索',
    none: 'なし',
  },
  searchUser: {
    mainTitle: '会議詳細情報',
    selectFacility: '施設を選択してください。',
    selectAuth: '職種を選択してください。',
    selectGender: '性別を選択してください。',
    enterName: '氏名を入力してください。',
    enterbirth: '生年月日を入力してください。',
    enterCellno: '携帯電話番号を入力してください。',
    enterEmail: 'メールアドレスを入力してください。',
    recentUser: '最近招待した使用者',
    nameKanji: '氏名（漢字）',
    nameKana: '氏名（カナ）',
    gender: '性別',
    birth: '生年月日',
    mobile: '携帯番号',
    address: '住所',
    patanm: '患者名(漢字)',
    patajm: '患者名(カナ)',
    srchRslt: '検索結果',
  },
  conferenceDetail: {
    mainTitle: '会議詳細情報',
    host: '会議主催者',
    conferId: '会議ID',
    date: '日付及び時間',
    browserInfo: 'ブラウザー情報',
    unknown: '不明',
    participate: '会議参加',
    startMeeting: '会議開始',
    editAlert: '既に開始されたか過去の会議は編集できません。',
    deleteAlert: 'この会議を削除しますか？',
    true: '可能',
    false: '不可能',
  },
  conferenceHistory: {
    mainTitle: '会議履歴',
    period: '照会期間',
    date: '照会日付',
  },

  participantAlertHistory: {
    mainTitle: '招待履歴',
    title: '会議名',
    participantNm: '参加者名',
    sendDt: 'アラーム送信時間',
    reSend: 'アラーム再転送',
    read: '既読',
    unread: '再転送',
    sended: 'プッシュアラーム転送しました',
  },
  statistics: {
    mainTitle: '統計',
  },
  dashBoard: {
    mainTitle: 'ダッシュボード',
    count: '件数 ',
    todaysMeeting: {
      title: '当日会議進行状況',
      onGoingTotal: '全体会議件数',
      noOnGoingTotal: '予定された会議はありません。',
      onGoingNow: '現在進行中件数',
      noOnGoingNow: '進行中の会議はありません。',
      scheduled: '当日予定件数',
      noScheduled: '当日予定された会議はありません。',
      noExist: 'はありません。',
    },
    meetingsInProgress: {
      title: '当日進行中件数',
      noInProgress: '当日進行中の会議はありません。',
      table: {
        conferId: '会議ID',
        start: '開始時間',
        runtime: '経過時間',
        host: '主催者',
        title: '会議議題',
        participant: '参加者',
      },
    },
    systemUsage: {
      title: '当日システム使用状況',
      incoming: '受信したトラフィック速度',
      outgoing: '送信トラフィック速度',
      total: '総トラフィック速度',
      average: '平均トラフィック速度',
    },
  },

  myPage: {
    mainTitle: 'マイページ',
    name: '会議中の自分の名前',
    changePw: 'パスワード変更',
    currentPw: '現在のパスワード',
    enterCurrentPw: '現在のパスワードを入力してください。',
    newPw: '新規パスワード',
    requirements: '大文字、小文字、数字、特殊文字中3種以上を使用して8桁以上',
    confirmPw: 'パスワード確認',
    reEnterPw: '新規パスワードをもう一度入力してください。',
    validation: {
      match: 'パスワードには大文字、小文字、数字、記号のうち少なくとも3つを含める必要があります。',
      min: 'パスワードは8文字以上で入力してください。',
      equal: 'パスワードが一致しません。',
    },
    language: '言語選択',
    japanese: '日本語',
    english: '英語',
    korean: '韓国語',
    receiveStatus: 'プッシュアラーム受信有無',
    receiveY: '受信',
    receiveN: '受信しない',
    logout: 'ログアウト',
    save: '変更内容保存',
    sveAlert: '現在の変更事項を保存しますか？',
  },

  pushNotification: { mainTitle: '受信履歴' },

  waiting: {
    inviteAlert: '主催者の参加許可を待っています。',
    videoYn: 'ビデオON/OFF',
    audioYn: '音声ON/OFF',
    mic: 'マイク選択',
    speaker: 'スピーカ選択',
    video: 'ビデオ選択',
    resolution: 'カメラ解像度',
    leave: '退出',
    end: '会議終了',
    start: '会議開始',
    acceptMsg: '主催者が会議参加を許可しました。',
    participate: '会議参加',
  },

  meeting: {
    me: '私',
    member: '会員',
    nonMember: '非会員',
    audioVideoOff: 'ビデオ/音声がOFF状態です。',
    join: '使用者からの会議に参加希望があります。',
    denyAll: '全て拒否',
    acceptAll: '全て許可',
    deny: '拒否',
    accept: '許可',
    denyAlert: '拒否しますか？',
    autoRecord: '自動録画を開始しますか？',
    participant: '参加者',
    record: '録画',
    chat: 'チャット',
    enterMsg: 'ここにメッセージを入力してください。',
    whiteboard: 'ホワイトボード',
    screenShare: '画面共有',
    fileShare: '文書共有',
    file: 'ファイルをドロップダウン又は選択してください。',
    title: '会議議題',
    pdfPw: 'PDF パスワード',
    enterPdfPw: 'PDF パスワードを入力してください。',
    end: '会議終了',
    spkrMicTest: 'スピーカ及びマイクテスト',
    test: 'テスト',
    apply: '適用',
    videoSetting: 'ビデオ環境設定',
    invite: '会議招待',
    invitePrtcpnt: '参加者招待',
    inviteInfo: '招待情報',
    ptcpntNm: '参加者名',
    invitationLink: '招待リンク',
    copy: 'コピー',
    ok: '確認',

    meeting: 'ミーティング',
    videoOn: 'ビデオオン',
    videoOff: 'ビデオオフ',
    audioOn: 'オーディオオン',
    audioOff: 'オーディオオフ',
    y: 'はい',
    n: 'いいえ',
    submit: '確認',
    cancel: 'キャンセル',
    video: 'ビデオ',
    audio: 'オーディオ',
    outputLevel: '出力レベル',
    volume: '音量',

    mic: 'マイク',
    speaker: 'スピーカ',
    share: '共有',

    report: {
      mainTitle: '報告書',
      enterTitle: '会議のトピックを入力してください',
      place: '場所',
      history: '履歴',
      edit: '編集',
      save: '保存',
      ok: '確認',
      contents: '会議の内容',
      shareFiles: '共有ファイル',
      item: '議題',
      recordYn: '録画の有無',
      host: '主催者',
      date: '会議日時',
      updDt: '更新日時',
      regDt: '登録日時',
      pdfDownload: 'PDFダウンロード',
      enterContent: '内容を入力してください',
      more: 'もっと表示',
    },

    alert: {
      nonRecording: '録画不可の会議です',
      name: '参加者氏名を入力してください。',
      email: '参加者メールアドレスを入力してください。',
      screenSharing: '画面共有をしています',
      denied: '主催者が会議参加を拒否しました。',
      copied: 'リンクをコピーしました。',
      noWebcam: 'ウェブカメラが存在しません。',
      webcamInUse: 'ウェブカメラが既に使用中です。',
      webcamTrack: '現在のウェブカメラは使用できません。',
      webcamAccess: 'ウェブカメラの使用権限を拒否しました。',
      mediaTrack: 'メディアトラックが要請されていません。',
      fileDelete: 'ファイルを削除しますか？',
      reportDelete: '報告書を削除しますか？',
      endAlert: '会議を終了しますか？',
      leaveAlert: '会議を退出しますか？',
      ended: '会議が終了しました。',
      micMsg: 'マイクテストを実行すると画像会議のオーディオが OFFになります。実行しますか？',
      volMsg: 'スピーカーテストを実行すると画像会議のオーディオが OFFになります。実行しますか？',
      whiteboardEdit: 'ホワイトボードに修正内容があります。',
      videoMaximum: '画像会議ビデオ参加許容人数を超えました。',
      participantsMaximum: '画像会議参加許容人数を超えました。',
      websocket: 'エラーが発生しました。管理者にお問い合わせください。',
    },
  },
};

export default ja;
