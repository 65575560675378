import { Button, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from 'src/locales';

type Props = {
  watisToken: string;
  handleClose: VoidFunction;
  isInvitation?: boolean;
  invitationInfo?: any;
  isSSO?: boolean;
};
const ConcurrentConnection = observer(
  ({ watisToken, isInvitation, isSSO, invitationInfo, handleClose }: Props) => {
    const { compulsionLogin } = useAuthContext();
    const { translate } = useLocales();
    const navigate = useNavigate();

    const CCInfo =
      sessionStorage.getItem('CCInfo') && JSON.parse(sessionStorage.getItem('CCInfo') || '');

    return (
      <Stack>
        <Typography variant={'h6'} mb={2}>
          {translate('login.duplicateLogin').toString()}
        </Typography>
        <Typography variant={'subtitle2'} mb={2}>
          {translate('login.terminate').toString()}
        </Typography>

        <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
          <Button
            size="medium"
            variant={'outlined'}
            color="primary"
            onClick={() => {
              handleClose();
              navigate('/');
            }}
            sx={{ fontWeight: 400 }}
          >
            {translate('meeting.cancel').toString()}
          </Button>
          <Button
            size="medium"
            variant={'contained'}
            color="primary"
            onClick={() => {
              compulsionLogin(watisToken).then(() => {
                // console.log('CCInfo', CCInfo);
                // console.log('invitationInfo', invitationInfo);
                if (isInvitation === true) {
                  navigate('/conference/meeting', {
                    state: {
                      ...CCInfo,
                      room: {
                        id: invitationInfo.room.id,
                      },
                      id: invitationInfo.id,
                      owner: {
                        displayName: invitationInfo.owner.displayName,
                      },
                      title: invitationInfo.title,
                    },
                  });
                }
                if (isSSO === true) {
                  navigate('/');
                }
              });
            }}
            sx={{ fontWeight: 400 }}
          >
            {translate('meeting.ok').toString()}
          </Button>
        </Stack>
      </Stack>
    );
  },
);

export default ConcurrentConnection;
