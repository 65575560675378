import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import { Button, Dialog, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from 'src/locales';
import ConcurrentConnection from '../user/login/ConcurrentConnection';

/**
 * ## AuthTemp 설명
 *
 */
export const AuthTemp = observer(() => {
  const rootStore = useStores();
  const { loadingStore } = rootStore;
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const { logout, initialize } = useAuthContext();
  const searchParams = new URLSearchParams(document.location.search);
  const token = searchParams.get('TempToken');
  const url = searchParams.get('RedirectUrl');
  const [message, setMessage] = useState('');
  const [alertFlag, setAlertFlag] = useState(false);
  const { translate } = useLocales();
  const [openCC, setOpenCC] = useState(false);
  const [watisToken, setWatisToken] = useState('');
  const [tempInfo, setTempInfo] = useState({});

  useEffect(() => {
    if (token) {
      fetch(REACT_APP_API_URL + '/web/auth/temp?TempToken=' + token, {})
        .then(async (response) => {
          const res = await response.json();
          const json = res.responseData;
          console.log('🌈 ~ useEffect ~ json:', json);
          if (json === undefined) {
            if (res.error) {
              setMessage(translate(`error.${res.error}`).toString());
              setAlertFlag(true);
            } else {
              // const user = localStorage.getItem('user');
              // if (user === null) {
              //   logout();
              //   navigate('/login-unprotected', { replace: true, state: null });
              // } else {
              // navigate('/');
              // }
              if (url) {
                if (url.includes('http')) {
                  window.location.href = url;
                } else {
                  window.location.href = `https://${url}`;
                }
              } else {
                window.history.back();
              }
            }
          } else if (!!json.isExists) {
            setOpenCC(true);
            setWatisToken(json.watisToken);
          } else {
            const user = {
              displayName: json.displayName,
              locale: json.locale,
              pushYn: json.pushYn,
              accessToken: json.accessToken,
              timezone: json.timezone,
              loginId: json.loginId,
              auth: json.auth,
              authCd: json.authCd,
              isAdmin: json.isAdmin,
            };
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('accessToken', user.accessToken);
            // initialize();
            localStorage.getItem('user') && window.location.replace('/');
            if (url) {
              if (url.includes('http')) {
                sessionStorage.setItem('redirectUrl', url);
              } else {
                sessionStorage.setItem('redirectUrl', `https://${url}`);
              }
            }
            // navigate('/home');
          }
        })
        .catch((e) => {
          // const user = localStorage.getItem('user');
          // if (user === null) {
          //   logout();
          //   navigate('/login-unprotected', { replace: true, state: null });
          // } else {
          //   navigate('/');
          // }
          if (url) {
            if (url.includes('http')) {
              window.location.href = url;
            } else {
              window.location.href = `https://${url}`;
            }
          } else {
            window.history.back();
          }
        });
    }
  }, []);

  return (
    <>
      {alertFlag === true && (
        <Paper
          sx={{
            p: 4,
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            border: '1px solid',
          }}
        >
          <Stack gap={2}>
            <Typography variant="h4">{message}</Typography>
            <Stack display={'flex'} justifyContent={'center'} direction={'row'} gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  // const user = localStorage.getItem('user');
                  // console.log('user', user);
                  // if (user === null) {
                  //   logout();
                  //   navigate('/login-unprotected', { replace: true, state: null });
                  //   setAlertFlag(false);
                  // } else {
                  //   // navigate('/');
                  //   setAlertFlag(false);
                  // }
                  if (url) {
                    if (url.includes('http')) {
                      window.location.href = url;
                    } else {
                      window.location.href = `https://${url}`;
                    }
                  } else {
                    window.history.back();
                  }
                }}
              >
                {translate(`meeting.ok`).toString()}
              </Button>
            </Stack>
          </Stack>
        </Paper>
      )}
      {openCC && (
        <Dialog
          open={openCC}
          onClose={(e: any, reason: string) => {
            reason !== 'backdropClick' && setOpenCC(false);
          }}
          maxWidth={'sm'}
          fullWidth
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
        >
          <ConcurrentConnection
            watisToken={watisToken}
            isSSO={true}
            handleClose={() => {
              setOpenCC(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
});

export default AuthTemp;
