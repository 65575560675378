import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import {
  useTheme,
  Typography,
  Stack,
  List,
  ListItem,
  FormLabel,
  styled,
  Button,
  Dialog,
  Divider,
  IconButton,
  Paper,
} from '@mui/material';
import CTextField from 'src/components/forms/CTextField';
import FormProvider from '../../components/hook-form/FormProvider';
import { useForm } from 'react-hook-form';
import Iconify from 'src/components/iconify';
import SearchUser from './SearchUser';
import { CallApiToStore } from 'src/utils/common';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useAuthContext } from 'src/auth/useAuthContext';
import { IConferenceSnapshot, IMeetingMemberSnapshot } from 'src/models';
import CAlert from 'src/components/CAlert';
import { useLocales } from 'src/locales';

interface Props {
  handleClose?: VoidFunction;
  data: IConferenceSnapshot;
  getDetail?: (id: number) => Promise<void>;
}

/**
 * ## CreateConference 설명
 *
 */
export const ConferenceInvite = observer(({ handleClose, data, getDetail }: Props) => {
  const { translate } = useLocales();
  const rootStore = useStores();
  const { conferenceStore, loadingStore } = rootStore;
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;
  const { user, isAuthenticated } = useAuthContext();

  const [openSearchUser, setOpenSearchUser] = useState(false);

  const InviteSchema = Yup.object().shape({
    displayName: Yup.string().required('').nullable(),
    email: Yup.string().required('').nullable().email(translate('login.checkEmail').toString()),
    loginId: Yup.string().nullable(),
    facilityCd: Yup.string().nullable(),
    authCd: Yup.string().nullable(),
  });

  const methods = useForm<IMeetingMemberSnapshot>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {},
    resolver: yupResolver(InviteSchema),
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = methods;

  // const [participants, setParticipants] = useState<any[]>(
  //   data.meetingMembers.length < 1
  //     ? [{ userId: '', displayName: '', email: '' }]
  //     : data.meetingMembers.filter((v) => {
  //         console.log('v', v);

  //         return {
  //           userId: 1234, //v.user.id ? v.user.id : '',
  //           displayname: v.user.displayName ? v.user.displayName : '',
  //           email: v.email,
  //         };
  //       }),
  // );
  // const addParticipant = () => {
  //   const newMeetingMembers: any = { userId: '', displayName: '', email: '' };
  //   setParticipants([...participants, newMeetingMembers]);
  // };
  // const removeParticipant = (index: number) => {
  //   const updatedParticipants = participants.filter((_, i) => i !== index);
  //   setParticipants(updatedParticipants);
  // };
  // const handleParticipantChange = (e: any, index: number) => {
  //   const { name, value } = e.target;
  //   const updatedParticipants: any = [...participants];
  //   updatedParticipants[index][name] = value;
  //   setParticipants(updatedParticipants);
  // };

  const [meetingMember, setMeetingMember] = useState<IMeetingMemberSnapshot>({
    displayName: '',
    email: '',
    loginId: '',
    authCd: '',
    auth: { authNm: '', authCd: '' },
    facilityCd: '',
    facility: { facilityNm: '', facilityCd: '' },
  } as IMeetingMemberSnapshot);

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const onSubmit = async () => {
    try {
      const temp: any = {
        roomId: data.room.id,
        meetingMember: getValues(),
      };
      await CallApiToStore(conferenceStore.invitation(temp), 'api', loadingStore).then(() => {
        conferenceStore.invitationLink ? setIsCopyOpen(true) : setIsAlertOpen(true);
      });
    } catch (e) {
      console.error(e);
    }
  };

  const [isCopyOpen, setIsCopyOpen] = useState(false);

  const handleCopyClipBoard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      alert(translate('meeting.alert.copied').toString());
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="h5">
          {/* 참석자 초대 */}
          {translate('meeting.invitePrtcpnt').toString()}
        </Typography>
        <Stack sx={{ py: 4 }}>
          <Divider />
          <Stack spacing={1} sx={{ mt: 4 }}>
            <List>
              <Stack gap={1}>
                <ListItem sx={{ p: 0 }}>
                  {/* <Label>참석자명</Label> */}
                  <Stack
                    direction={'row'}
                    width={'100%'}
                    gap={1}
                    alignItems={'center'}
                    // sx={{
                    //   '& input': {
                    //     boxShadow: '0 0 0 100px #222222 inset',
                    //   },
                    // }}
                  >
                    <CTextField
                      name="displayName"
                      label={translate('conferenceReservation.name').toString()}
                      variant="outlined"
                      placeholder={translate('meeting.alert.name').toString()}
                      sx={{ width: '100%', '& input': { py: 1, px: 1.5 } }}
                      readonly={!!getValues('facility')}
                    />
                    <Button variant="outlined" size="small" onClick={() => setOpenSearchUser(true)}>
                      {translate('conferenceReservation.search').toString()}
                    </Button>
                  </Stack>
                </ListItem>

                <ListItem sx={{ p: 0, '& .MuiFormHelperText-root': { height: 2, m: 0.5 } }}>
                  {/* <Label>Email</Label> */}
                  <CTextField
                    name="email"
                    type="email"
                    label={translate('conferenceReservation.email').toString()}
                    variant="outlined"
                    placeholder={translate('meeting.alert.email').toString()}
                    sx={{
                      width: '100%',
                      '& input': { py: 1, px: 1.5 },
                    }}
                  />
                </ListItem>

                {/* <Stack direction={'row'} spacing={1} sx={{ justifyContent: 'flex-end' }}>
                  <Button variant="outlined" size="small" onClick={() => reset()}>
                    삭제
                  </Button>
                  <Button variant="outlined" size="small" onClick={() => setOpenSearchUser(true)}>
                    검색
                  </Button>
                </Stack> */}
                {getValues('facility') && (
                  <ListItem sx={{ gap: 1 }}>
                    <Typography>
                      ID :{' '}
                      {getValues('loginId')
                        ? getValues('loginId')
                        : translate('conferenceReservation.nonMembers').toString()}
                    </Typography>
                    <Typography>
                      {translate('conferenceReservation.facility').toString()} :{' '}
                      {getValues('facility.facilityNm')}
                    </Typography>
                    <Typography>
                      {translate('conferenceReservation.auth').toString()} :{' '}
                      {getValues('auth.authNm')}
                    </Typography>
                    <IconButton onClick={() => reset()} sx={{ ml: 'auto' }}>
                      <Iconify
                        icon={'grommet-icons:power-reset'}
                        sx={{
                          color: '#999',
                        }}
                      />
                    </IconButton>
                  </ListItem>
                )}
              </Stack>
            </List>
          </Stack>
        </Stack>
        <Divider />
        <Stack direction={'row'} spacing={1} sx={{ justifyContent: 'flex-end', py: 2 }}>
          <Button
            variant={'contained'}
            onClick={handleClose}
            sx={{ background: theme.palette.grey[400] }}
          >
            {translate('meeting.cancel').toString()}
          </Button>
          <Button variant={'contained'} onClick={handleSubmit(onSubmit)}>
            {translate('meeting.invite').toString()}
          </Button>
        </Stack>
      </FormProvider>

      {isCopyOpen && (
        <Dialog
          key={'invite-meetingMembers'}
          open={isCopyOpen}
          maxWidth={'md'}
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
              height: 'auto',
            },
          }}
          onClose={() => {
            setIsCopyOpen(false);
          }}
        >
          {conferenceStore.invitationLink && (
            <>
              <Typography variant="h5">{translate('meeting.inviteInfo').toString()}</Typography>
              <Divider sx={{ my: 2 }} />
              <ListItem sx={{ p: 0 }}>
                <Label>{translate('meeting.ptcpntNm').toString()}</Label>
                <Typography>{getValues('displayName')}</Typography>
              </ListItem>
              <ListItem sx={{ p: 0 }}>
                <Label>{translate('meeting.invitationLink').toString()}</Label>
                <Stack direction={'row'} alignItems={'center'} gap={1}>
                  <Typography>{conferenceStore.invitationLink}</Typography>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      conferenceStore.invitationLink &&
                        handleCopyClipBoard(conferenceStore.invitationLink);
                    }}
                  >
                    {translate('meeting.copy').toString()}
                  </Button>
                </Stack>
              </ListItem>
              <Divider sx={{ my: 2 }} />
              <Button
                variant="contained"
                onClick={() => {
                  getDetail && getDetail(data.id);
                  setIsCopyOpen(false);
                  reset();
                }}
                sx={{ width: '50px', alignSelf: 'end' }}
              >
                {translate('meeting.ok').toString()}
              </Button>
            </>
          )}
        </Dialog>
      )}

      {openSearchUser && (
        <Dialog
          key={'search-user'}
          open={openSearchUser}
          maxWidth={'md'}
          fullWidth
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
              height: 'auto',
            },
          }}
          onClose={() => {
            setOpenSearchUser(false);
          }}
        >
          <SearchUser
            path="meeting"
            roomType={data.room.type}
            setValue={setValue}
            handleClose={() => {
              setOpenSearchUser(false);
            }}
          />
        </Dialog>
      )}
      {isAlertOpen && (
        <CAlert
          open={isAlertOpen}
          handleClose={() => setIsAlertOpen(false)}
          content={translate('meeting.alert.inviteFailed').toString()}
        />
      )}
    </>
  );
});

const Label = styled(FormLabel)(({ theme }) => ({
  minWidth: 100,
  whiteSpace: 'nowrap',
  // marginBottom: 8,
  color: '#919EAB',
}));

export default ConferenceInvite;
