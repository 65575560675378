import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # ShareFile
 *
 * ShareFile을 설명하세요.
 */
export const ShareFile = types
  .model('ShareFile')
  // --------------------------------------------------------------------------
  .props({
    id: types.number,
    name: types.string,
    hash: types.maybeNull(types.string),
    parentId: types.maybeNull(types.number),
    roomId: types.maybeNull(types.number),
    groupId: types.maybeNull(types.number),
    ownerId: types.maybeNull(types.number),
    size: types.maybeNull(types.number),
    externalId: types.maybeNull(types.string),
    externalType: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    width: types.maybeNull(types.number),
    height: types.maybeNull(types.number),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model shareFile --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IShareFile;
      (Object.keys(newProps) as TShareFileKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TShareFile = Instance<typeof ShareFile>;
type TShareFileSnapshot = SnapshotOut<typeof ShareFile>;

export interface IShareFile extends TShareFile {}
export type TShareFileKeys = keyof TShareFileSnapshot & string;
export interface IShareFileSnapshot extends TShareFileSnapshot {}
export const createShareFile = () => types.optional(ShareFile, {} as TShareFile);
