import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # OccupationalCount
 *
 * OccupationalCount을 설명하세요.
 */
export const OccupationalCount = types
  .model('OccupationalCount')
  // --------------------------------------------------------------------------
  .props({
    // doctor: types.optional(types.number, 0),
    // nurse: types.optional(types.number, 0),
    // technologists: types.optional(types.number, 0),
    // clerk: types.optional(types.number, 0),
    // careManager: types.optional(types.number, 0),
    // visitNurse: types.optional(types.number, 0),
    // admin: types.optional(types.number, 0),
    name: types.optional(types.string, ''),
    count: types.optional(types.number, 0),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model occupationalCount --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOccupationalCount;
      (Object.keys(newProps) as TOccupationalCountKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOccupationalCount = Instance<typeof OccupationalCount>;
type TOccupationalCountSnapshot = SnapshotOut<typeof OccupationalCount>;

export interface IOccupationalCount extends TOccupationalCount {}
export type TOccupationalCountKeys = keyof TOccupationalCountSnapshot & string;
export interface IOccupationalCountSnapshot extends TOccupationalCountSnapshot {}
export const createOccupationalCount = () =>
  types.optional(OccupationalCount, {} as TOccupationalCount);
