import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Button, Divider, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { debounce } from 'lodash';

import HistoryIcon from '@mui/icons-material/RefreshOutlined';
import ZoomOutIcon from '@mui/icons-material/RemoveCircleOutline';
import ZoomInIcon from '@mui/icons-material/AddCircleOutline';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import Iconify from 'src/components/iconify';
import SelectIcon from '@mui/icons-material/NearMeOutlined';
import PencilIcon from '@mui/icons-material/Create';
import PaletteIcon from '@mui/icons-material/PaletteOutlined';
import TextIcon from '@mui/icons-material/TextFieldsOutlined';
import ShapeIcon from '@mui/icons-material/InterestsOutlined';
import TrashIcon from '@mui/icons-material/DeleteForeverOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import RectangleIcon from '@mui/icons-material/Rectangle';
import RemoveSharpIcon from '@mui/icons-material/RemoveSharp';
import { HEADER } from 'src/config-global';
import axios from 'axios';
import Logout from '@mui/icons-material/Logout';
/**
 * ## WhiteBoard 설명
 *
 */
export const WhiteBoard = observer((props: any) => {
  const rootStore = useStores();
  const { loadingStore, responseStore, errorAlertStore } = rootStore;
  const { REACT_APP_API_URL } = process.env;
  const theme = useTheme();

  const { editor, onReady } = useFabricJSEditor();

  const [intialize, setIntialize] = useState(false);
  const [whiteboardSid, setWhiteboardSid] = useState();
  const [whiteboardSeq, setWhiteboardSeq] = useState<number | 0>();
  const [history, setHistory] = useState<any[]>([]);
  const [strokeColor, setStrokeColor] = useState('#35363a');
  const [strokeSize, setStrokeSize] = useState(1);
  const [fillColor, setFillColor] = useState('#35363a');
  const [drawingMode, setDrawingMode] = useState<boolean | null>(false);
  const [selectFunction, setSelectFunction] = useState<string | null>(null);
  const [selectShapeFunction, setSelectShapeFunction] = useState<string | null>(null);
  const [selectPaletteFunction, setSelectPaletteFunction] = useState<string | null>('black');
  const [selectStrokeFunction, setSelectStrokeFunction] = useState<string | null>('1');

  const [fristLeft, setFristLeft] = useState(70);
  const [secondLeft, setSecondLeft] = useState(70);
  const [thirdLeft, setThirdLeft] = useState(70);

  let isDragging: boolean;
  let selection: boolean;
  let lastPosX: number;
  let lastPosY: number;
  let viewportTransform: any;
  let prevDrawingMode: boolean | undefined;

  const wrapper = document.getElementById('meetingWrapper');
  const [config, setConfig] = useState({
    canvasWidth: wrapper?.offsetWidth || '500',
    canvasHeight: wrapper ? wrapper?.offsetHeight - HEADER.H_MAIN_DESKTOP * 2 : '500',
  });

  const colors = {
    default: '#999999',
    active: '#000000',
  };
  const [selectIcon, setSelectIcon] = useState('');
  const [zoomValue, setZoomValue] = useState(100);
  const [objectLength, setObjectLength] = useState(0);
  const [openShape, setOpenShape] = useState(false);
  const [openPalette, setOpenPalette] = useState(false);
  const [openStroke, setOpenStroke] = useState(false);

  /**
   * add event listener
   */
  useEffect(() => {
    if (!editor || !fabric) {
      return;
    }

    editor.canvas.on('mouse:wheel', function (opt) {
      var delta = opt.e.deltaY;
      var zoom = editor.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      editor.canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      setZoomValue(Math.round(zoom * 100));
    });

    editor.canvas.on('mouse:down', function (opt) {
      var evt = opt.e;

      if (selectFunction === 'trash') {
        console.log('');
        removeSelectedObject();
        return;
      }
      if (evt.ctrlKey === true) {
        // prevDrawingMode = editor.canvas.isDrawingMode;
        editor.canvas.isDrawingMode = false;
        isDragging = true;
        selection = false;
        lastPosX = evt.clientX;
        lastPosY = evt.clientY;
      }
    });

    editor.canvas.on('mouse:move', function (opt) {
      if (isDragging) {
        var e = opt.e;
        var vpt = editor.canvas.viewportTransform;
        if (vpt) {
          vpt[4] += e.clientX - lastPosX;
          vpt[5] += e.clientY - lastPosY;
        }
        editor.canvas.requestRenderAll();
        lastPosX = e.clientX;
        lastPosY = e.clientY;
      }
    });

    editor.canvas.on('object:added', function (opt) {
      // console.log('💬 ', 'added', opt.target?.toJSON());
      if (opt.target) {
        const obj: any = opt.target;
        setTimeout(() => {
          if (!obj.id) {
            obj.set('id', 'roomId-' + props.roomId + '-' + Date.now());
            obj.toJSON = (function (toJSON) {
              return function () {
                return fabric.util.object.extend(toJSON.call(obj), {
                  id: obj.id,
                });
              };
            })(obj.toJSON);
            sendMessage({
              type: 'added',
              data: getObjectFromId(obj.id)?.toJSON(),
            });
            saveData();
          }
        }, 10);
      }
    });

    editor.canvas.on('object:removed', function (opt) {
      const obj: any = opt.target;
      if (obj.id) {
        sendMessage({
          type: 'removed',
          data: { id: obj.id },
        });
        saveData();
      } else {
        console.log('💬 ', '아이디가 없거나 그룹');
      }
    });

    editor.canvas.on('object:modified', function (opt) {
      console.log('💬 ', 'modified', opt.target?.toJSON());
      const obj: any = opt.target;
      const json: any = opt.target?.toJSON();
      if (json.type === 'activeSelection') {
        saveData('modified');
      } else {
        if (obj.id) {
          sendMessage({
            type: 'modified',
            data: getObjectFromId(obj.id)?.toJSON(),
          });
          saveData();
        } else {
          console.log('💬 ', '아이디가 없다');
        }
      }
    });

    editor.canvas.on('mouse:up', function (opt) {
      if (viewportTransform) {
        editor.canvas.setViewportTransform(viewportTransform);
      }

      if (selectFunction === 'text') {
        if (!editor.canvas.getActiveObject()) {
          addText();
          const t = editor.canvas._objects[editor.canvas._objects.length - 1];
          // t.setOptions({fontSize:strokeSize*16});
          editor.canvas.setActiveObject(t);
          const obj = editor.canvas.getActiveObject();
          editor.setStrokeColor(strokeColor);

          if (obj) {
            // obj.width = 70*strokeSize;
            obj.width = 70;
            obj.top = opt.e.clientY - obj.getBoundingRect().height;
            obj.left = opt.e.clientX - obj.width / 2;
          }
        }
      }
      if (selectShapeFunction === 'circle') {
        if (!editor.canvas.getActiveObject()) {
          onAddCircle();
          const t = editor.canvas._objects[editor.canvas._objects.length - 1];
          t.strokeWidth = strokeSize;
          editor.canvas.setActiveObject(t);
          editor.setStrokeColor(strokeColor);
          const obj = editor.canvas.getActiveObject();
          if (obj) {
            obj.width = 40;
            obj.top = opt.e.clientY - obj.getBoundingRect().height;
            obj.left = opt.e.clientX - obj.width / 2;
          }
        }
      }
      if (selectShapeFunction === 'rectangle') {
        if (!editor.canvas.getActiveObject()) {
          onAddRectangle();
          const t = editor.canvas._objects[editor.canvas._objects.length - 1];
          t.strokeWidth = strokeSize;
          editor.canvas.setActiveObject(t);
          editor.setStrokeColor(strokeColor);
          const obj = editor.canvas.getActiveObject();
          if (obj) {
            obj.width = 40;
            obj.top = opt.e.clientY - obj.getBoundingRect().height;
            obj.left = opt.e.clientX - obj.width / 2;
          }
        }
      }
      if (selectShapeFunction === 'line') {
        if (!editor.canvas.getActiveObject()) {
          onAddLine();
          const t = editor.canvas._objects[editor.canvas._objects.length - 1];
          t.strokeWidth = strokeSize;
          editor.canvas.setActiveObject(t);
          editor.setStrokeColor(strokeColor);
          const obj = editor.canvas.getActiveObject();
          if (obj) {
            obj.width = 40;
            obj.top = opt.e.clientY - obj.getBoundingRect().height;
            obj.left = opt.e.clientX - obj.width / 2;
          }
        }
      }
      isDragging = false;
      selection = true;
    });
    updateObjectLength();
    editor.canvas.renderAll();

    return () => {
      editor.canvas.off('mouse:wheel');
      editor.canvas.off('mouse:down');
      editor.canvas.off('mouse:move');
      editor.canvas.off('mouse:up');
      editor.canvas.off('object:added');
      editor.canvas.off('object:removed');
      editor.canvas.off('object:modified');
    };
  }, [editor]);

  const getObjectFromId = (id: string) => {
    if (!editor || !fabric) return;
    var currentObjects = editor.canvas.getObjects().filter((r: any) => {
      if (r.id) {
        return r.id === id;
      }
    });
    if (currentObjects.length > 0) {
      return currentObjects[0];
    } else {
      return null;
    }
  };

  // const addBackground = () => {
  //   if (!editor || !fabric) {
  //     return;
  //   }
  //   if (editor) {
  //     fabric.Image.fromURL(
  //       "https://thegraphicsfairy.com/wp-content/uploads/2019/02/Anatomical-Heart-Illustration-Black-GraphicsFairy.jpg",
  //       (image) => {
  //         editor.canvas.setBackgroundImage(
  //           image,
  //           editor.canvas.renderAll.bind(editor.canvas)
  //         );
  //       }
  //     );
  //   };
  // }

  // useEffect(() => {
  //   if (!editor || !fabric) {
  //     return;
  //   }
  //   // editor.canvas.setWidth(config.canvasWidth);
  //   // editor.canvas.setHeight(config.canvasHeight);
  //   // addBackground();
  //   editor.canvas.renderAll();
  // }, [editor?.canvas.backgroundImage]);

  /**
   * intialize
   */
  useEffect(() => {
    if (!editor || !fabric || intialize) {
      return;
    }

    //@ts-ignore
    window.editor = editor;

    //@ts-ignore
    window.fabric = fabric;

    // 아이디 속성 추가
    fabric.Object.prototype.toJSON = (function (toJSON) {
      return function () {
        // @ts-ignore
        return fabric.util.object.extend(toJSON.call(this), {
          //@ts-ignore
          id: this.id,
        });
      };
    })(fabric.Object.prototype.toJSON);

    editor.canvas.setWidth(config.canvasWidth);
    editor.canvas.setHeight(config.canvasHeight);
    setIntialize(true);
    if (localStorage.getItem('accessToken') !== null) {
      fetch(`${REACT_APP_API_URL}/web/wb`, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ roomId: props.roomId }),
      })
        .then(async (r) => {
          const res = await r.json();
          if (res.error) {
            responseStore.setResponseInfo(res);
            errorAlertStore.setErrorAlertFromResponse();
          }
          setWhiteboardSid(res.responseData[0].whiteBoardSid);
          setWhiteboardSeq(res.responseData[0].whiteboardSeq);
          if (res.responseData[0].whiteBoardData) {
            let json = JSON.parse(res.responseData[0].whiteBoardData);
            editor.canvas.loadFromJSON(json, () => {
              if (drawingMode) {
                editor.canvas.isDrawingMode = drawingMode;
              }
            });
            editor.canvas.renderAll();
          } else {
            setIntialize(true);
          }
        })
        .catch((e) => {
          console.log('🌈 ~ useEffect ~ e:', e);
        });
    }
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        const selected = editor.canvas.getActiveObjects();
        if (selected.length !== 0) {
          if (selected[0].type === 'text') {
            if (selected[0].hoverCursor === null) {
              selected.map((obj, i) => {
                setTimeout(
                  (obj) => {
                    editor.canvas.remove(obj);
                  },
                  i * 50,
                  obj,
                );
              });
              editor.canvas.discardActiveObject();
            }
          } else {
            selected.map((obj, i) => {
              setTimeout(
                (obj) => {
                  editor.canvas.remove(obj);
                },
                i * 50,
                obj,
              );
            });
            editor.canvas.discardActiveObject();
          }
        }
      }
    });

    return () => {
      window.removeEventListener('keydown', () => {});
    };
  }, [editor]);

  useEffect(() => {
    changeDrawingMode(false);
    setSelectFunction('drawingModeFalse');
    const wrapper = document.getElementById('meetingWrapper');
    window.addEventListener(
      'resize',
      debounce(() => {
        setConfig({
          ...config,
          canvasWidth: wrapper?.offsetWidth || '100%',
          canvasHeight: wrapper ? wrapper?.offsetHeight - HEADER.H_MAIN_DESKTOP * 2 : '500',
        });
        if (editor) {
          editor.canvas.setWidth(config.canvasWidth);
          editor.canvas.setHeight(config.canvasHeight);
          editor.canvas.renderAll();
        }
      }, 100),
    );

    console.log(123456789,wrapper);
    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  /**
   * functions ---------------------------------------------------------------------------------------------------------------------------
   */

  const updateObjectLength = () => {
    if (!editor || !fabric) return;
    setTimeout(() => {
      setObjectLength((objectLength) => (objectLength = editor.canvas._objects.length));
    }, 100);
  };

  const toggleSize = () => {
    if (!editor || !fabric) return;

    editor.canvas.freeDrawingBrush.width === 12
      ? (editor.canvas.freeDrawingBrush.width = 5)
      : (editor.canvas.freeDrawingBrush.width = 12);
  };

  const toggleDraw = () => {
    if (!editor || !fabric) return;
    editor.canvas.isDrawingMode = !editor.canvas.isDrawingMode;
  };
  const undo = () => {
    if (!editor || !fabric) return;
    if (editor.canvas._objects.length > 0) {
      setHistory([...history, editor.canvas._objects.pop()]);
    }
    editor.canvas.renderAll();
    updateObjectLength();
  };
  const redo = () => {
    if (!editor || !fabric) return;
    if (history.length > 0) {
      editor.canvas.add(history.pop());
    }
    updateObjectLength();
  };

  const clear = () => {
    if (!editor || !fabric) return;
    editor.canvas._objects.splice(0, editor.canvas._objects.length);
    setHistory([]);
    editor.canvas.renderAll();
    updateObjectLength();
    // sendMessage({ type: 'clear' });
  };

  const removeSelectedObject = () => {
    if (!editor || !fabric) return;
    const obj = editor.canvas.getActiveObject();
    if (obj !== null) {
      sendData('remove', obj.toJSON());
      editor.canvas.remove(obj);
      updateObjectLength();
    }
  };

  const onColor = (color: any) => {
    if (!editor || !fabric) return;
    editor.canvas.freeDrawingBrush.color = color;
    setStrokeColor(color);
  };

  const onStroke = (stroke: number) => {
    if (!editor || !fabric) return;
    setStrokeSize(stroke);
    editor.canvas.freeDrawingBrush.width = stroke;
  };

  const onAddLine = () => {
    if (!editor || !fabric) return;
    editor.addLine();
    updateObjectLength();
  };

  const onAddCircle = () => {
    if (!editor || !fabric) return;
    editor.addCircle();
    updateObjectLength();
  };

  const onAddRectangle = () => {
    if (!editor || !fabric) return;
    editor.addRectangle();
    updateObjectLength();
  };

  const addText = () => {
    if (!editor || !fabric) return;
    editor.addText('inset text');
    updateObjectLength();
  };

  const zoomOut = () => zoom();
  const zoomIn = () => zoom(true);
  const zoomReset = () => {
    if (!editor || !fabric) return;
    editor.canvas.zoomToPoint({ x: 0, y: 0 }, 1);
    setZoomValue(100);
    // editor.canvas.zoomToPoint({ x: Number(editor.canvas.width) / 2, y: Number(editor.canvas.height) / 2 }, 1);
    // editor.canvas.zoomToPoint({ x: editor.canvas.getCenter().top, y: editor.canvas.getCenter().left }, 1);
  };

  const zoom = (isIn: boolean = false) => {
    if (!editor || !fabric) return;
    var delta = isIn ? -100 : 100;
    var zoom = editor.canvas.getZoom();
    zoom *= 0.999 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    editor.canvas.zoomToPoint(
      { x: Number(config.canvasWidth) / 2, y: Number(config.canvasHeight) / 2 },
      zoom,
    );
    setZoomValue(Math.round(zoom * 100));
  };

  const exportSVG = () => {
    if (!editor || !fabric) return;
    const svg = editor.canvas.toSVG();
    console.info(svg);
  };

  const [prevData, setPrevData] = useState('');
  const sendData = async (type: string = 'add', json: any) => {
    if (json && (JSON.stringify(json) !== prevData || type === 'remove')) {
      const data = {
        type: type,
        whiteBoardSid: whiteboardSid,
        whiteBoardData: JSON.stringify(json),
      };
      if (localStorage.getItem('accessToken') !== null) {
        await axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/web/wb/edit`,
          headers: {
            Authorization: `Bearer ` + localStorage.getItem('accessToken'),
            'Content-type': 'application/json',
          },
          data: data,
        })
          .then((r: any) => {
            console.log('r', r);
            setPrevData(JSON.stringify(json));
            if (r.data.error) {
              responseStore.setResponseInfo(r.data);
              errorAlertStore.setErrorAlertFromResponse();
            }
          })
          .catch((e: any) => {
            console.log('🌈 ~ useEffect ~ e:', e);
          });
      }
    }
  };

  useEffect(() => {
    if (!editor || !fabric) return;

    if (props.message) {
      try {
        // console.log('🌈 ~ useEffect ~ props.message.data:', props.message.data)
        let obj: any;
        const type = props.message.type;
        console.log('🌈 ~ useEffect ~ type:', type);
        const data = props.message.data;
        if (type === 'added') {
          if (data.type === 'path') {
            obj = new fabric.Path(JSON.stringify(data.path), { ...data, fill: 'transparent' });
          } else if (data.type === 'text') {
            obj = new fabric.Text(data.text, data);
          } else if (data.type === 'rect') {
            obj = new fabric.Rect(data);
          } else if (data.type === 'circle') {
            obj = new fabric.Circle(data);
          } else if (data.type === 'line') {
            obj = new fabric.Line(
              [data.left, data.top, data.left + data.width, data.top + data.height],
              data,
            );
          }
          editor.canvas.add(obj);
        } else if (type === 'removed') {
          obj = getObjectFromId(data.id);
          obj && editor.canvas.remove(obj);
        } else if (type === 'modified') {
          if (data.type === 'line') {
            data.x1 = data.left;
            data.x2 = data.left + data.width;
            data.y1 = data.top;
            data.y2 = data.top + data.height;
            obj = getObjectFromId(data.id);
            obj && obj.set(data);
          } else {
            obj = getObjectFromId(data.id);
            obj && obj.set(data);
          }
        } else if (type === 'undo') {
          undo();
        } else if (type === 'redo') {
          redo();
        } else if (type === 'activeSelection') {
          if (localStorage.getItem('accessToken') !== null) {
            fetch(`${REACT_APP_API_URL}/web/wb`, {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken') || '',
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ roomId: props.roomId }),
            })
              .then(async (r) => {
                const res = await r.json();

                let json = JSON.parse(res.responseData[0].whiteBoardData);
                for (let i = 0; json.objects.length > i; i++) {
                  console.log('json.objects.length', json.objects[i].id);
                  obj = getObjectFromId(json.objects[i].id);
                  obj && obj.set(json.objects[i]);
                }
                setTimeout(() => {
                  editor.canvas.renderAll();
                }, 100);
              })
              .catch((e) => {
                console.log('🌈 ~ useEffect ~ e:', e);
              });
          }
        }
        editor.canvas.renderAll();
      } catch (e) {
        console.log('🌈 ~ whiteboard update error :', e);
      }
    }
  }, [props.message]);

  const sendMessage = async (json: any) => {
    props.sendMessage({
      type: 'whiteBoard',
      data: json,
    });
  };

  const saveData = async (type?: any) => {
    if (!editor || !fabric) return;

    const json = editor.canvas.toJSON();

    json.objects.map((o: any, i: number) => {
      o.id = 'roomId-' + props.roomId + '-' + i;
    });

    let data = {};
    if (whiteboardSeq === undefined) {
      data = {
        whiteboardSeq: 0,
        whiteBoardData: JSON.stringify(json),
      };
    } else {
      data = {
        whiteboardSeq: whiteboardSeq,
        whiteBoardData: JSON.stringify(json),
      };
    }

    if (localStorage.getItem('accessToken') !== null) {
      await axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/web/wb/edit`,
        headers: {
          Authorization: `Bearer ` + localStorage.getItem('accessToken'),
          'Content-type': 'application/json',
        },
        data: data,
      })
        .then((r: any) => {
          if (r.data.error) {
            responseStore.setResponseInfo(r.data);
            errorAlertStore.setErrorAlertFromResponse();
          }
          if (type && type === 'modified') {
            sendMessage({
              type: 'activeSelection',
              data: json,
            });
            console.log('🌈 ~ whiteboard group saved.');
          }
          console.log('🌈 ~ whiteboard saved.');
        })
        .catch((e: any) => {
          console.log('🌈 ~ whiteboard save failed :', e);
        });
    }
  };

  const changeDrawingMode = (flag: boolean) => {
    setDrawingMode(flag);
    if (editor) {
      editor.canvas.isDrawingMode = flag;
    }
  };

  useEffect(() => {
    let list: any = 0;
    if (openShape) {
      list = list + 1;
      setFristLeft(list * 70);
    }
    if (openPalette) {
      list = list + 1;
      setSecondLeft(list * 70);
    }
    if (openStroke) {
      list = list + 1;
      setThirdLeft(list * 70);
    }
  }, [openShape, openPalette, openStroke]);

  const changeFunction = (type: string) => {
    if (type === 'shape') {
      changeDrawingMode(false);
      setDrawingMode(null);
      setSelectFunction(type);
      if (selectFunction !== 'shape') {
        if (!openShape) {
          setOpenShape(true);
        } else {
          setOpenShape(false);
        }
      }
    } else if (type === 'palette') {
      if (!openPalette) {
        setOpenPalette(true);
      } else {
        setOpenPalette(false);
      }
    } else if (type === 'stroke') {
      if (!openStroke) {
        setOpenStroke(true);
      } else {
        setOpenStroke(false);
      }
    } else if (type === 'text') {
      setDrawingMode(null);
      changeDrawingMode(false);
      setSelectFunction(type);
      setOpenShape(false);
      setSelectShapeFunction(null);
    } else if (type === 'drawingModeTrue') {
      setOpenShape(false);
      changeDrawingMode(true);
      setSelectShapeFunction(null);
      setSelectFunction(type);
    } else if (type === 'drawingModeFalse') {
      setOpenStroke(false);
      setOpenPalette(false);
      setOpenShape(false);
      changeDrawingMode(false);
      setSelectShapeFunction(null);
      setSelectFunction(type);
    } else {
      setOpenStroke(false);
      setOpenPalette(false);
      setOpenShape(false);
      setSelectShapeFunction(null);
      changeDrawingMode(false);
      setSelectFunction(type);
    }

    if (editor) {
      if (type === 'trash') {
        editor.canvas.hoverCursor = 'not-allowed';
      } else {
        editor.canvas.hoverCursor = 'move';
      }
    }
  };
  const changeShapeFunction = (type: string) => {
    setSelectShapeFunction(type);
    if (editor) {
      if (type === 'trash') {
        editor.canvas.hoverCursor = 'not-allowed';
      } else {
        editor.canvas.hoverCursor = 'move';
      }
    }
  };
  const changePaletteFunction = (type: string) => {
    setSelectPaletteFunction(type);
  };
  const changeStrokeFunction = (type: string) => {
    setSelectStrokeFunction(type);
  };
  const onClose = () => {
    props.setNavState(
      (navState: any) =>
        (navState = {
          ...navState,
          ['controlButton_3']: navState['controlButton_3'] ? !navState['controlButton_3'] : true,
        }),
    );
    props.onWhiteBoard();
  };

  return (
    <>
      <Box
        id="canvas-wrapper"
        sx={{
          position: 'absolute',
          top: HEADER.H_MAIN_DESKTOP + 'px',
          left: 0,
          zIndex: 200,
          width: `${config.canvasWidth}px`,
          height: `${config.canvasHeight}px`,
          background: '#FFFFFF',
        }}
      >
        <Stack
          direction={'row'}
          sx={{
            position: 'absolute',
            top: 20,
            right: 20,
            // height: 60,
            textAlign: 'right',
            background: '#FFFFFF',
            borderRadius: 1,
            border: '1px solid #EEEEEE',
            boxShadow: '4px 4px 8px #0000001a',
            zIndex: 300,
            justifyContent: 'space-around',
            alignItems: 'center',
            p: 1,
          }}
        >
          <IconButton
            onClick={() => {
              undo();
              sendMessage({ type: 'undo' });
            }}
          >
            <HistoryIcon
              sx={{
                color: editor && objectLength > 0 ? colors.active : colors.default,
                transform: 'scaleX(-1)',
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              redo();
              sendMessage({ type: 'redo' });
            }}
          >
            <HistoryIcon
              sx={{ color: editor && history.length > 0 ? colors.active : colors.default }}
            />
          </IconButton>
          <Divider
            orientation={'vertical'}
            variant={'middle'}
            sx={{ borderWidth: '.5px', height: 16 }}
          />
          <IconButton onClick={zoomOut}>
            <ZoomOutIcon sx={{ color: colors.active }} />
          </IconButton>
          <Button variant={'text'} onClick={zoomReset}>
            <Typography variant={'Eng_14_b'} color={colors.active}>
              {zoomValue}%
            </Typography>
          </Button>
          <IconButton onClick={zoomIn}>
            <ZoomInIcon sx={{ color: colors.active }} />
          </IconButton>
          {/* <Button
            variant={'contained'}
            size={'small'}
            color={'error'}
            onClick={onClose}
            sx={{ ml: 1 }}
          >
            <Trans i18nKey={'close'} />
          </Button> */}
          <IconButton color={'error'} onClick={onClose}>
            {/* <CloseIcon /> */}
            {/* <CloseRounded /> */}
            <Logout fontSize="small" />
          </IconButton>
        </Stack>
        <Stack
          spacing={1}
          sx={{
            position: 'absolute',
            left: 20,
            top: '50%',
            transform: 'translateY(-50%)',
            background: '#FFFFFF',
            borderRadius: 1,
            border: '1px solid #EEEEEE',
            boxShadow: '2px 2px 3px #0000001a',
            zIndex: 300,
            justifyContent: 'space-around',
            alignItems: 'center',
            p: 1,
            '& .MuiButton-root': {
              minWidth: 24,
              p: 0.5,
            },
          }}
        >
          <Button
            onClick={() => {
              changeDrawingMode(false);
              changeFunction('drawingModeFalse');
            }}
            sx={{
              border:
                selectFunction === 'drawingModeFalse'
                  ? `1px solid ${colors.active}`
                  : '1px solid #FFFFFF',
            }}
          >
            <SelectIcon
              sx={{ color: selectFunction === 'drawingModeFalse' ? colors.active : colors.default }}
            />
          </Button>
          <Button
            onClick={() => {
              changeDrawingMode(true);
              changeFunction('drawingModeTrue');
            }}
            sx={{
              border:
                selectFunction === 'drawingModeTrue'
                  ? `1px solid ${colors.active}`
                  : '1px solid #FFFFFF',
            }}
          >
            <PencilIcon
              sx={{ color: selectFunction === 'drawingModeTrue' ? colors.active : colors.default }}
            />
          </Button>
          <Button
            onClick={() => changeFunction('text')}
            sx={{
              border:
                selectFunction === 'text' ? `1px solid ${colors.active}` : '1px solid #FFFFFF',
            }}
          >
            <TextIcon sx={{ color: selectFunction === 'text' ? colors.active : colors.default }} />
          </Button>
          <Button
            onClick={() => {
              changeFunction('shape');
              changeShapeFunction('circle');
            }}
            sx={{ border: openShape === true ? `1px solid ${colors.active}` : '1px solid #FFFFFF' }}
          >
            <ShapeIcon sx={{ color: openShape === true ? colors.active : colors.default }} />
          </Button>
          <Button onClick={() => changeFunction('palette')}>
            <PaletteIcon sx={{ color: openPalette === true ? colors.active : colors.default }} />
          </Button>
          <Button onClick={() => changeFunction('stroke')}>
            <Iconify
              icon="fluent:line-thickness-20-regular"
              sx={{ color: openStroke === true ? colors.active : colors.default }}
            />
          </Button>
          <Button
            onClick={() => changeFunction('trash')}
            sx={{
              border:
                selectFunction === 'trash' ? `1px solid ${colors.active}` : '1px solid #FFFFFF',
            }}
          >
            <TrashIcon
              sx={{ color: selectFunction === 'trash' ? colors.active : colors.default }}
            />
          </Button>
          {openShape && (
            <Stack
              spacing={1}
              sx={{
                position: 'absolute',
                left: fristLeft,
                top: '50%',
                transform: 'translateY(-50%)',
                background: '#FFFFFF',
                borderRadius: 1,
                border: '1px solid #EEEEEE',
                boxShadow: '2px 2px 3px #0000001a',
                zIndex: 300,
                justifyContent: 'space-around',
                alignItems: 'center',
                p: 1,
                '& .MuiButton-root': {
                  minWidth: 24,
                  p: 0.5,
                },
              }}
            >
              <Button
                onClick={() => {
                  changeShapeFunction('circle');
                }}
                sx={{
                  border:
                    selectShapeFunction === 'circle'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <CircleIcon
                  sx={{ color: selectShapeFunction === 'circle' ? colors.active : colors.default }}
                />
              </Button>
              <Button
                onClick={() => {
                  changeShapeFunction('rectangle');
                }}
                sx={{
                  border:
                    selectShapeFunction === 'rectangle'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <RectangleIcon
                  sx={{
                    color: selectShapeFunction === 'rectangle' ? colors.active : colors.default,
                  }}
                />
              </Button>
              <Button
                onClick={() => {
                  changeShapeFunction('line');
                }}
                sx={{
                  border:
                    selectShapeFunction === 'line'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <RemoveSharpIcon
                  sx={{ color: selectShapeFunction === 'line' ? colors.active : colors.default }}
                />
              </Button>
            </Stack>
          )}
          {openPalette && (
            <Stack
              spacing={1}
              sx={{
                position: 'absolute',
                left: secondLeft,
                top: '50%',
                transform: 'translateY(-50%)',
                background: '#FFFFFF',
                borderRadius: 1,
                border: '1px solid #EEEEEE',
                boxShadow: '2px 2px 3px #0000001a',
                zIndex: 300,
                justifyContent: 'space-around',
                alignItems: 'center',
                p: 1,
                '& .MuiButton-root': {
                  minWidth: 24,
                  p: 0.5,
                },
              }}
            >
              <Button
                onClick={(e) => {
                  onColor('black');
                  changePaletteFunction('black');
                }}
                sx={{
                  border:
                    selectPaletteFunction === 'black'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <CircleIcon sx={{ color: 'black' }} />
              </Button>
              <Button
                onClick={(e) => {
                  onColor('red');
                  changePaletteFunction('red');
                }}
                sx={{
                  border:
                    selectPaletteFunction === 'red'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <CircleIcon sx={{ color: 'red' }} />
              </Button>
              <Button
                onClick={(e) => {
                  onColor('yellow');
                  changePaletteFunction('yellow');
                }}
                sx={{
                  border:
                    selectPaletteFunction === 'yellow'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <CircleIcon sx={{ color: 'yellow' }} />
              </Button>
              <Button
                onClick={(e) => {
                  onColor('green');
                  changePaletteFunction('green');
                }}
                sx={{
                  border:
                    selectPaletteFunction === 'green'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <CircleIcon sx={{ color: 'green' }} />
              </Button>
              <Button
                onClick={(e) => {
                  onColor('blue');
                  changePaletteFunction('blue');
                }}
                sx={{
                  border:
                    selectPaletteFunction === 'blue'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                <CircleIcon sx={{ color: 'blue' }} />
              </Button>
            </Stack>
          )}
          {openStroke && (
            <Stack
              spacing={1}
              sx={{
                position: 'absolute',
                left: thirdLeft,
                top: '50%',
                transform: 'translateY(-50%)',
                background: '#FFFFFF',
                borderRadius: 1,
                border: '1px solid #EEEEEE',
                boxShadow: '2px 2px 3px #0000001a',
                zIndex: 300,
                justifyContent: 'space-around',
                alignItems: 'center',
                p: 1,
                '& .MuiButton-root': {
                  minWidth: 24,
                  p: 0.5,
                },
              }}
            >
              <Button
                onClick={(e) => {
                  onStroke(1);
                  changeStrokeFunction('1');
                }}
                sx={{
                  border:
                    selectStrokeFunction === '1'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                1px
                <ShowChartIcon
                  sx={{ color: selectStrokeFunction === '1' ? colors.active : colors.default }}
                />
              </Button>
              <Button
                onClick={(e) => {
                  onStroke(3);
                  changeStrokeFunction('3');
                }}
                sx={{
                  border:
                    selectStrokeFunction === '3'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                3px
                <ShowChartIcon
                  sx={{ color: selectStrokeFunction === '3' ? colors.active : colors.default }}
                />
              </Button>
              <Button
                onClick={(e) => {
                  onStroke(5);
                  changeStrokeFunction('5');
                }}
                sx={{
                  border:
                    selectStrokeFunction === '5'
                      ? `1px solid ${colors.active}`
                      : '1px solid #FFFFFF',
                }}
              >
                5px
                <ShowChartIcon
                  sx={{ color: selectStrokeFunction === '5' ? colors.active : colors.default }}
                />
              </Button>
            </Stack>
          )}
        </Stack>
        <FabricJSCanvas className="sample-canvas" onReady={onReady} />
      </Box>
    </>
  );
});

export default WhiteBoard;
