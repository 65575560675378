import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # Candidate
 *
 * Candidate을 설명하세요.
 */
export const Candidate = types
  .model("Candidate")
  // --------------------------------------------------------------------------
  .props({
    address: types.maybeNull(types.string),
    candidate: types.optional(types.string, ''),
    sdpMLineIndex: types.maybeNull(types.number),
    sdpMid: types.maybeNull(types.string),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model candidate --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICandidate;
      (Object.keys(newProps) as TCandidateKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCandidate = Instance<typeof Candidate>;
type TCandidateSnapshot = SnapshotOut<typeof Candidate>

export interface ICandidate extends TCandidate { }
export type TCandidateKeys = keyof TCandidateSnapshot & string;
export interface ICandidateSnapshot extends TCandidateSnapshot { }
export const createCandidate = () =>
  types.optional(Candidate, {

  } as TCandidate);
