import { observer } from 'mobx-react-lite';
import { Controller, useFormContext } from 'react-hook-form';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './CEditor.css';
// import ImageResize from '@looop/quill-image-resize-module-react';
import { Editor } from '@toast-ui/react-editor';
import './CEditor-Main.css';
import './CEditor-dark.css';
import { useRef } from 'react';
import { useSettings } from 'src/hooks/useSettings';
import { useLocales } from 'src/locales';
interface CEditorProps {
  /** ** 리액트 훅 폼 필드명** */
  name: string;
  /** **입력 도움말** */
  placeholder?: string;
}

/**
 * ## 기본 사용법
 *
 * > 기본 에디터입니다.
 *
 */
// Quill.register('modules/ImageResize', ImageResize);
const CEditorToast = observer(function CEditorProps({
  name,
  placeholder = '',
  ...other
}: CEditorProps) {
  const { control } = useFormContext();
  const editorRef = useRef<Editor>(null);
  const { themeMode } = useSettings();
  const { translate } = useLocales();
  
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image', 'video'],
      ['clean'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    ImageResize: {
      modules: ['Resize', 'DisplaySize'],
    },
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ];

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return (
          <Editor
            ref={editorRef}
            theme={themeMode}
            placeholder={translate(`meeting.report.enterContent`).toString()}
            previewStyle="vertical" // 미리보기 스타일 지정
            height="300px" // 에디터 창 높이
            initialEditType="wysiwyg" // 초기 입력모드 설정(디폴트 markdown)
            hideModeSwitch={true}
            toolbarItems={[
              // 툴바 옵션 설정
              ['heading', 'bold', 'italic', 'strike'],
              ['hr', 'quote'],
              ['ul', 'ol', 'task', 'indent', 'outdent'],
              ['table', 'image', 'link'],
              ['code', 'codeblock'],
            ]}
            initialValue={value}
            onChange={(e) => {
              onChange({ target: { name, value: editorRef.current?.getInstance().getHTML() } });
            }}
          ></Editor>
        );
      }}
    />
  );
});

export default CEditorToast;
