import { observer } from 'mobx-react-lite';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  Divider,
  FormLabel,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import Iconify from 'src/components/iconify/Iconify';
import { IMeetingMemberSnapshot } from 'src/models';
import SearchUser from './SearchUser';
import CTextField from 'src/components/forms/CTextField';
import { useLocales } from 'src/locales';

interface Props {
  setValue: any;
  watch: any;
  errors: any;
  meetingMembers: IMeetingMemberSnapshot[];
  addParticipant: VoidFunction;
  removeParticipant: Function;
  handleParticipantChange: Function;
  handleParticipantAddChange?: Function;
  roomType: string;
}

export const ConferParticipants = observer(
  ({
    setValue,
    watch,
    errors,
    meetingMembers,
    addParticipant,
    removeParticipant,
    handleParticipantChange,
    handleParticipantAddChange,
    roomType,
  }: Props) => {
    const theme = useTheme();
    const { translate } = useLocales();
    const [openAccordion, setOpenAccordion] = useState(false);
    const [openSearchUser, setOpenSearchUser] = useState(false);
    const [pindex, setPIndex] = useState(0);

    useEffect(() => {
      // console.log('meetingMembers', meetingMembers);

      meetingMembers.map((meetingMember, index) => {
        const name = `meetingMembers[${index}]`;
        setValue(`${name}`, meetingMember);
        // meetingMember.user.loginId && setValue(`${name}.loginId`, meetingMember.user.loginId);
        // meetingMember.user.facilityCd &&
        //   setValue(`${name}.facilityCd`, meetingMember.user.facilityCd);
        // meetingMember.user.authCd && setValue(`${name}.authCd`, meetingMember.user.authCd);
      });
    }, [meetingMembers.length, meetingMembers]);

    return (
      <>
        <Stack flex={1} spacing={1} sx={{ mb: 1 }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            sx={{ pb: 1, background: '#ffffff', zIndex: 99, position: 'sticky', top: 0, left: 0 }}
          >
            <Stack direction={'row'} display={'flex'} alignItems={'center'}>
              <Typography variant="subtitle1">
                {translate('conferenceReservation.participants').toString()}
              </Typography>
              <Button
                disableRipple
                disableTouchRipple
                disableFocusRipple
                onClick={() => {
                  setOpenAccordion(!openAccordion);
                }}
                color="inherit"
              >
                <Typography variant="caption">
                  {translate('conferenceReservation.viewDetails').toString()}
                  {!openAccordion ? ' ▼' : ' ▲'}
                </Typography>
              </Button>
            </Stack>
            <Button
              variant={'contained'}
              size={'small'}
              sx={{ pl: 1, lineHeight: 1 }}
              onClick={addParticipant}
            >
              <Iconify icon={'ic:round-plus'} />
              {translate('conferenceReservation.addParticipant').toString()}
            </Button>
          </Stack>
          <List sx={{ flex: 1, overflowX: 'hidden', overflowY: 'auto', maxHeight: '30vh' }}>
            {meetingMembers.map((meetingMember: any, index: number) => {
              // console.log('1', participant);
              const name = `meetingMembers[${index}]`;
              return (
                <Stack
                  key={'meetingMember-' + index}
                  gap={1}
                  sx={{
                    '& .Mui-focusVisible': { backgroundColor: 'inherit !important' },
                  }}
                >
                  {meetingMembers.length > 1 && index !== 0 && (
                    <Divider sx={{ borderBottom: '0.5px dashed #EEEEEE', mb: 1 }} />
                  )}
                  <Accordion
                    expanded={meetingMember.loginId !== '' && openAccordion}
                    sx={{
                      '&.Mui-expanded': {
                        boxShadow: 'none !important',
                        minHeight: 0,
                        m: 0,
                      },
                    }}
                  >
                    <AccordionSummary
                      sx={{
                        p: 0,
                        cursor: 'inherit !important',
                        '& .MuiAccordionSummary-content': { m: 0 },
                        '&.Mui-expanded': {
                          m: '0 !important',
                          minHeight: 0,
                        },
                        '& .Mui-expanded': {
                          m: '0 !important',
                        },
                      }}
                    >
                      {/* <ListItem
                        sx={{ flex: 1, p: 0, m: 0, mb: 1, '& .MuiList-padding': { p: 0, m: 0 } }}
                      > */}
                      {/* </ListItem>
                        <ListItem sx={{ p: 0 }}> */}
                      {/* <Label>참석자명</Label> */}
                      <Stack
                        direction={'row'}
                        alignItems={'center'}
                        sx={{
                          flex: 1,
                          display: 'flex',
                          [theme.breakpoints.down('sm')]: { alignItems: 'flex-start' },
                        }}
                      >
                        {/* <Button
                          sx={{
                            //   alignItems: 'start',
                            //   justifyContent: 'start',
                            //   px: 0,
                            minWidth: '20px',
                          }}
                          disableRipple
                          disableTouchRipple
                          disableFocusRipple
                          // disabled={!meetingMember.loginId}
                          // onClick={() => {
                          //   setOpenAccordion(!openAccordion);
                          // }}
                        > */}
                        <FormLabel
                          sx={{
                            // cursor: meetingMember.loginId && 'pointer',
                            alignItems: 'start',
                            justifyContent: 'start',
                            textAlign: 'left',
                            mr: 1,
                          }}
                          // onClick={() => {
                          //   setOpenAccordion(!openAccordion);
                          // }}
                        >
                          {index + 1 + '.'}
                          {/* {participant.loginId && '▾'} */}
                        </FormLabel>
                        {/* </Button> */}
                        <Stack
                          direction={'row'}
                          alignItems={'center'}
                          sx={{
                            flex: 1,
                            gap: 1,
                            display: 'flex',
                            [theme.breakpoints.down('sm')]: {
                              flexDirection: 'column',
                            },
                            '& div:first-of-type': { flex: 1 },
                            '& div:last-child': { flex: 1.8 },
                          }}
                        >
                          <CTextField
                            name={`${name}.displayName`}
                            label={translate('conferenceReservation.name').toString()}
                            variant="outlined"
                            placeholder={translate('searchUser.enterName').toString()}
                            onChangeCallback={(e: any) => {
                              handleParticipantChange(e, `displayName`, index);
                            }}
                            readonly={!!meetingMember.facility}
                            sx={{
                              '& input': { py: 1, px: 1.5 },
                            }}
                          />

                          <CTextField
                            name={`${name}.email`}
                            type="email"
                            label={translate('conferenceReservation.email').toString()}
                            variant="outlined"
                            placeholder={translate('searchUser.enterEmail').toString()}
                            onChangeCallback={(e: any) => {
                              handleParticipantChange(e, 'email', index);
                            }}
                            sx={{
                              '& input': { py: 1, px: 1.5 },
                            }}
                          />
                        </Stack>
                        <Stack
                          direction={'row'}
                          alignSelf={'center'}
                          sx={{
                            [theme.breakpoints.down('sm')]: {
                              flexDirection: 'column',
                              gap: 0.5,
                            },
                          }}
                        >
                          <IconButton
                            disabled={meetingMembers.length <= 1}
                            onClick={() => {
                              removeParticipant(index);
                            }}
                          >
                            <Iconify icon={'ph:trash-bold'} />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setPIndex(index);
                              setOpenSearchUser(true);
                            }}
                          >
                            <Iconify icon={'tabler:search'} />
                          </IconButton>
                        </Stack>
                      </Stack>
                      {/* </ListItem> */}
                    </AccordionSummary>
                    {/* {meetingMember.loginId && ( */}
                    <AccordionDetails sx={{ p: 0 }}>
                      <ListItem
                        sx={{
                          p: 0,
                          ml: 3,
                          mb: 1,
                          justifyContent: 'flex-start',
                          alignItems: 'flex-start',
                        }}
                      >
                        <Stack direction={'row'} gap={2}>
                          <Typography>
                            <Label>ID : </Label>
                            {meetingMember.loginId
                              ? meetingMember.loginId
                              : translate('meeting.nonMember').toString()}
                          </Typography>
                          <Typography>
                            <Label>
                              {translate('conferenceReservation.facility').toString()} :{' '}
                            </Label>
                            {meetingMember.facility?.facilityNm
                              ? meetingMember.facility.facilityNm
                              : translate('conferenceReservation.none').toString()}
                          </Typography>
                          <Typography>
                            <Label>{translate('conferenceReservation.auth').toString()} : </Label>
                            {meetingMember.auth?.authNm
                              ? meetingMember.auth.authNm
                              : translate('conferenceReservation.none').toString()}
                          </Typography>
                          {/* <CTextField
                            name={`${name}.loginId`}
                            label="로그인아이디"
                            variant="outlined"
                            // placeholder="이메일을 입력하세요"
                            onChangeCallback={(e: any) => {
                              handleParticipantChange(e, 'loginId', index);
                            }}
                            sx={{
                              '& input': { py: 1, px: 1.5 },
                            }}
                          /> */}
                        </Stack>
                      </ListItem>
                    </AccordionDetails>
                    {/* )} */}
                  </Accordion>
                </Stack>
              );
            })}
            {/* <Box display={'flex'} justifyContent={'right'} mt={2}>
                  <Button variant={'contained'} size={'small'} onClick={addParticipant}>
                    <Iconify icon={'ic:round-plus'} />
                    참석자 추가
                  </Button>
                </Box> */}
          </List>
        </Stack>

        {openSearchUser && (
          <Dialog
            key={'search-user'}
            open={openSearchUser}
            maxWidth={'md'}
            fullWidth
            scroll="body"
            PaperProps={{
              sx: {
                // maxHeight: '95vh',
                borderRadius: 4,
              },
            }}
            onClose={() => {
              setOpenSearchUser(false);
            }}
          >
            <SearchUser
              path=""
              index={pindex}
              meetingMembers={meetingMembers}
              addParticipant={addParticipant}
              removeParticipant={removeParticipant}
              handleParticipantChange={handleParticipantChange}
              handleParticipantAddChange={handleParticipantAddChange}
              roomType={roomType}
              handleClose={() => {
                setOpenSearchUser(false);
              }}
            />
          </Dialog>
        )}
      </>
    );
  },
);

const Label = styled(FormLabel)(() => ({
  minWidth: 100,
  whiteSpace: 'nowrap',
  color: '#919EAB',
}));

// const CTextField = styled(TextField)(() => ({
//   width: '100%',
//   '& input': { py: 1, px: 1.5 },
//   '[data-shrink="false"]': {
//     transform: 'translate(15px, 8px)',
//   },
// }));
export default ConferParticipants;
