import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  FormLabel,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as IcoPDF } from 'src/assets/images/ico-pdf.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IConferenceSnapshot, IReportSnapshot, IShareFileSnapshot } from 'src/models';

import { Viewer } from '@toast-ui/react-editor';
import { CallApiToStore, sendReactNativeMessage } from 'src/utils/common';
import moment from 'moment';
import Draggable from 'react-draggable';
import ReportCreate from './ReportCreate';
import axios from 'axios';
import { useScrollable } from 'src/hooks/useScrollable';
import { load } from 'src/utils/storage';
import { useLocales } from 'src/locales';
import Iconify from 'src/components/iconify/Iconify';
import CAlert from 'src/components/CAlert';
import { isMobile } from 'react-device-detect';

/**
 * ## ReportDetail 설명
 *
 */
interface Props {
  onClose: VoidFunction;
  data: IConferenceSnapshot;
  reportData: IReportSnapshot;
  getReportData: () => Promise<void>;
  getData: () => Promise<void>;
}
export const ReportDetail = observer(
  ({ onClose, data, reportData, getReportData, getData }: Props) => {
    const rootStore = useStores();
    const { conferenceStore, loadingStore, responseStore, errorAlertStore } = rootStore;
    const theme = useTheme();
    const { REACT_APP_API_URL } = process.env;
    const user = JSON.parse(localStorage.user);
    const authCd = user.auth?.authCd ? user.auth.authCd : user.authCd;
    const isSystemAdmin = user.isAdmin === true;
    const [openReportEditor, setOpenReportEditor] = useState(false);
    const { translate } = useLocales();
    const token = localStorage.getItem('accessToken');
    const [os, setOs] = useState<any>();
    useEffect(() => {
      (async () => {
        setOs(await load('os'));
      })();
    }, []);

    const downloadReport = async (reportSid: number) => {
      const name = `${data.title}_${moment().format('YYYY-MM-DD_HH-mm-ss')}.pdf`;
      if (os && (os.isIOS || os.isAndroid)) {
        sendReactNativeMessage({
          type: 'filedown',
          payload: {
            url: `${REACT_APP_API_URL}/web/report/download`,
            token: `${token}`,
            reportSid: reportSid,
            fileName: name,
          },
        });
      } else {
        const config: any = {
          method: 'POST',
          url: `${REACT_APP_API_URL}/web/report/download`,
          headers: {
            Authorization: `Bearer ${token}`,
            // ContentType: 'application/json',
          },
          // responseType: 'blob',
          responseType: 'arraybuffer',
          data: { reportSid: reportSid },
        };
        const response = await axios(config);
        try {
          const json = JSON.parse(new TextDecoder().decode(response.data));
          if (json.error) {
            responseStore.setResponseInfo(json);
            errorAlertStore.setErrorAlertFromResponse();
            return;
          }
        } catch (e) {}
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        link.style.cssText = 'display:none';
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          link.remove();
        }, 500);
      }
    };

    const downloadFile = async (id: number, name: string) => {
      if (os && (os.isIOS || os.isAndroid)) {
        sendReactNativeMessage({
          type: 'filedown',
          payload: {
            url: `${REACT_APP_API_URL}/web/file/download`,
            token: `${token}`,
            fileId: id,
            fileName: name,
          },
        });
      } else {
        const response = await axios({
          method: 'POST',
          url: `${REACT_APP_API_URL}/web/file/download`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // responseType: 'blob',
          responseType: 'arraybuffer',
          data: { fileId: id },
        })
          .then((res) => {
            try {
              const json = JSON.parse(new TextDecoder().decode(res.data));
              if (json.error) {
                responseStore.setResponseInfo(json);
                errorAlertStore.setErrorAlertFromResponse();
                return;
              }
            } catch (e) {}
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.setAttribute('download', name);
            anchor.style.cssText = 'display:none';
            document.body.appendChild(anchor);
            setTimeout(() => {
              anchor.click();
              anchor.remove();
              window.URL.revokeObjectURL(url);
            }, 500);
          })
          .catch((e) => {
            console.log('🌈 ~ downloadFile ~ e:', e);
          });
      }
    };

    const [deleteAlert, setDeleteAlert] = useState(false);
    const deleteReport = async (reportSid: number) => {
      try {
        await CallApiToStore(conferenceStore.deleteReport(reportSid), 'api', loadingStore).then(
          () => {
            if (responseStore.responseInfo.error) {
              console.error(responseStore.responseInfo);
            } else {
              onClose();
              getData && getData();
            }
          },
        );
      } catch (e) {
        console.error(e);
      }
    };

    const dragRef = useRef<HTMLDivElement>(null);
    useScrollable(dragRef, 'x');
    return (
      <>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 99,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          p={2}
          pr={5}
          sx={{
            background: theme.palette.background.neutral,
          }}
        >
          <Typography
            variant="h4"
            sx={{
              alignItems: 'center',
            }}
          >
            {data.title} {translate(`meeting.report.mainTitle`).toString()}
            {!isMobile && (data.ownerYn === 'Y' || !!isSystemAdmin) && (
              <>
                <IconButton sx={{ ml: 2 }} onClick={() => setOpenReportEditor(true)}>
                  <Iconify icon={'ci:note-edit'} />
                </IconButton>
                <IconButton onClick={() => setDeleteAlert(true)}>
                  <Iconify icon={'ph:trash-bold'} />
                </IconButton>
              </>
            )}
          </Typography>
        </Box>
        <DialogContent sx={{ p: 0 }}>
          {/* <Stack my={2} mx={4} gap={1}> */}
          <Accordion sx={{ '&.Mui-expanded': { m: 0 } }}>
            <AccordionSummary sx={{ mx: isMobile ? 0 : 2.5 }}>
              {/* <Stack direction={'row'} display={'flex'} justifyContent={'space-between'}> */}
              <Stack direction={'row'} maxWidth={'500px'}>
                <Label>
                  {translate(`meeting.title`).toString()}(
                  {translate(`meeting.report.item`).toString()})
                </Label>
                <Typography>{reportData.agenda} </Typography>
              </Stack>
              <Typography variant="caption" ml={'auto'}>
                {translate('meeting.report.more').toString()} ▾
              </Typography>
              {/* </Stack> */}
            </AccordionSummary>
            <AccordionDetails sx={{ mx: isMobile ? 1 : 4 }}>
              <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 0 : 2}>
                <Stack direction={'row'}>
                  <Label>{translate(`meeting.report.date`).toString()}</Label>
                  <Typography>{reportData.metDt}</Typography>
                </Stack>
                <Stack direction={'row'}>
                  <Label>{translate(`meeting.report.host`).toString()}</Label>
                  <Typography>{data.owner.displayName}</Typography>
                </Stack>
              </Stack>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={isMobile ? 0 : 2}
                maxWidth={'600px'}
              >
                <Stack direction={'row'}>
                  <Label>{translate(`meeting.report.place`).toString()}</Label>
                  <Typography>
                    {reportData.place || translate('conferenceReservation.none').toString()}
                  </Typography>
                </Stack>
                <Stack direction={'row'}>
                  <Label>{translate(`meeting.report.recordYn`).toString()}</Label>
                  <Typography>{data.room.allowRecording === true ? 'Y' : 'N'}</Typography>
                </Stack>
                {/* <Stack direction={'row'}>
                  <Label>{translate(`meeting.report.history`).toString()}</Label>
                  <Typography>
                    {reportData.spec || translate('conferenceReservation.none').toString()}
                  </Typography>
                </Stack> */}
              </Stack>
              <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 0 : 2}>
                <Stack direction={'row'}>
                  <Label>{translate(`meeting.report.regDt`).toString()}</Label>
                  <Typography>{moment(reportData.regDt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                </Stack>
                <Stack direction={'row'}>
                  <Label>{translate(`meeting.report.updDt`).toString()}</Label>
                  <Typography>{moment(reportData.updDt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                </Stack>
              </Stack>
            </AccordionDetails>
          </Accordion>
          {/* </Stack> */}

          <Divider sx={{ mb: 2 }} />

          {/* <Stack direction={'row'} display={'flex'} alignItems={'center'} mx={4}> */}
          {/* <Label>공유 파일명</Label> */}

          {/* <Stack m={0} p={0} direction={'row'} spacing={1}>
            <Chip
              variant={'outlined'}
              label={'파일_데이터가.pdf'}
              color={'default'}
              sx={{ fontSize: '.875rem' }}
            />
            <Chip
              variant={'outlined'}
              label={'없습니다.pdf'}
              color={'default'}
              sx={{ fontSize: '.875rem' }}
            /> 
            </Stack>
             </Stack> */}
          {data.room.shareFiles && data.room.shareFiles.length > 0 && (
            <>
              <Stack
                direction={isMobile ? 'column' : 'row'}
                display={'flex'}
                alignItems={isMobile ? 'flex-start' : 'center'}
                mx={isMobile ? 2 : 4}
              >
                <Label>{translate(`meeting.report.shareFiles`).toString()}</Label>

                <Stack
                  m={0}
                  p={0}
                  direction={'row'}
                  flexWrap={'wrap'}
                  gap={0.5}
                  sx={{
                    overflowX: 'auto',
                  }}
                >
                  {data.room.shareFiles.map((f: IShareFileSnapshot, i: number) => {
                    return (
                      <Chip
                        key={(f.id, i)}
                        variant={'outlined'}
                        label={f.name}
                        color={'default'}
                        onClick={() => downloadFile(f.id, f.name)}
                        sx={{ fontSize: '.875rem' }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Divider sx={{ my: 2 }} />
            </>
          )}

          <Box
            sx={{
              mt: 2,
              mx: 4,
              maxHeight: 300,
              overflowY: 'auto',
              '& .MuiBox-root': {
                borderRadius: 0,
                borderRight: 0,
                borderLeft: 0,
              },
              '& .ql-toolbar': {
                p: '8px !important',
                px: '32px !important',
              },
            }}
          >
            <Label>{translate(`meeting.report.contents`).toString()}</Label>
            <Viewer initialValue={reportData.reportData ? reportData.reportData : ''} />
          </Box>
          <Stack
            direction={'row'}
            display={'flex'}
            alignItems={'center'}
            py={2}
            mx={4}
            maxWidth={'300px'}
          >
            {/* <CTextField
            variant="outlined"
            type="password"
            label={'pdf비밀번호'}
            name={'pdfPassword'}
          /> */}
          </Stack>

          <Divider />
          {/* </TableBody> */}
          {/* <TableFooter></TableFooter> */}
          {/* </Table> */}

          <Stack direction={'row'} justifyContent={'space-between'} sx={{ px: 4, py: 2 }}>
            <Button
              color={'inherit'}
              onClick={() => reportData.reportSid && downloadReport(reportData.reportSid)}
            >
              <IcoPDF style={{ marginRight: 4 }} />{' '}
              {translate(`meeting.report.pdfDownload`).toString()}
            </Button>

            <Stack direction={'row'} gap={1}>
              {/* {(data.ownerYn === 'Y' || !!isSystemAdmin) && (
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenReportEditor(true);
                }}
              >
                {translate(`meeting.report.edit`).toString()}
              </Button>
            )} */}
              <Button variant={'contained'} onClick={onClose}>
                {translate(`meeting.report.ok`).toString()}
              </Button>
            </Stack>
          </Stack>
          {/* </FormProvider> */}
        </DialogContent>

        {openReportEditor && reportData && (
          <Dialog
            open={openReportEditor}
            maxWidth={'md'}
            fullWidth
            scroll="body"
            PaperComponent={(props) => {
              return (
                <Draggable bounds="parent" cancel="form">
                  <Paper {...props} />
                </Draggable>
              );
            }}
            PaperProps={{
              sx: {
                p: 0,
                borderRadius: 4,
              },
            }}
            onClose={(e, reason) => {
              reason !== 'backdropClick' && setOpenReportEditor(false);
            }}
          >
            <ReportCreate
              path={'edit'}
              onClose={() => setOpenReportEditor(false)}
              reportData={reportData || ({} as IReportSnapshot)}
              data={data}
              getData={getReportData}
            />
          </Dialog>
        )}

        {reportData && deleteAlert && (
          <CAlert
            open={deleteAlert}
            handleClose={() => setDeleteAlert(false)}
            title={reportData.agenda || ''}
            content={translate('meeting.alert.reportDelete').toString()}
            callBack={() => reportData.reportSid && deleteReport(reportData.reportSid)}
            hasCancel
          />
        )}
      </>
    );
  },
);

const Label = styled(FormLabel)(({ theme }) => ({
  // minWidth: 100,
  marginRight: 16,
  whiteSpace: 'nowrap',
  color: '#919EAB',
}));

export default ReportDetail;
