import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { ShareFile } from '../share-file/ShareFile';
import { RecordingFile } from '../recording-file/RecordingFile';

/**
 * # Room
 *
 * Room을 설명하세요.
 */
export const Room = types
  .model('Room')
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.number, 0),
    name: types.maybeNull(types.optional(types.string, '')),
    type: types.optional(types.string, ''),
    allowRecording: types.optional(types.boolean, true),
    hasReport: types.optional(types.boolean, true),
    hasChat: types.optional(types.boolean, true),
    shareFiles: types.maybeNull(types.array(ShareFile)),
    closed: types.optional(types.boolean, false),
    recordingFiles: types.maybeNull(types.array(RecordingFile)),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model room --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRoom;
      (Object.keys(newProps) as TRoomKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRoom = Instance<typeof Room>;
type TRoomSnapshot = SnapshotOut<typeof Room>;

export interface IRoom extends TRoom {}
export type TRoomKeys = keyof TRoomSnapshot & string;
export interface IRoomSnapshot extends TRoomSnapshot {}
export const createRoom = () => types.optional(Room, {} as TRoom);
