import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { Card, IconButton, Stack, Typography, useTheme } from '@mui/material';
import kurentoUtils from 'kurento-utils';
import { IParticipant, useStores } from 'src/models';
import ParticipantsDetail from 'src/screens/conference/participants/ParticipantsDetail';
import useResponsive from 'src/hooks/useResponsive';
import { getIconByAuthCd } from 'src/utils/common';
import Iconify from 'src/components/iconify/Iconify';
/**
 * ## Participant 설명
 *
 */

type Props = {
  participant: IParticipant;
  sendMessage: (message: any) => void;
  onConnectionstatechange: (peerConnection: any, refresh?:VoidFunction, participantCUID?:string, userName?:string) => void;
  setPeer?: (cuid: string, uid: string, peer: any, stream: any) => void;
  videostate?: any;
  ToggleMic?: any;
  ToggleVideo?: any;
  Mestream?: any;
  myStream?: any;
  videoWidth?: any;
  videoHeight?: any;
  videoView?: any;
  mobileYn?: any;
  mMode?: any;
  detaildata?: any;
  onCamDisabled?: any;
  onButtonFlag?: any;
  returnAlertFlag?: (flag: boolean, message: any) => void;
  videoStream?: any;
  myStema?: any;
};
let peer: any;
let video: any;
const streamList: any[] = [];
export const MobileParticipant = observer(
  ({
    participant,
    sendMessage,
    onConnectionstatechange,
    setPeer,
    videostate,
    ToggleMic,
    ToggleVideo,
    Mestream,
    myStream,
    videoWidth,
    videoHeight,
    videoView,
    mobileYn,
    mMode,
    detaildata,
    onCamDisabled,
    onButtonFlag,
    returnAlertFlag,
    videoStream,
    myStema,
  }: Props) => {
    const rootStore = useStores();
    const { pushAlarmStore } = rootStore;
    const isDownMd = useResponsive('down', 'md');
    const videoRef = useRef<any>(); // createRef<HTMLVideoElement>();
    const AudioCtx = window.AudioContext; // || window.webkitAudioContext;
    const myUID = sessionStorage.getItem('myUID');
    const myCUID = sessionStorage.getItem('myCUID');
    let constraints = {
      audio: {
        // noiseSuppression: true,
        sampleRate: pushAlarmStore.getSampleRateData() ? pushAlarmStore.getSampleRateData() : 48000,
        // echoCancellation: true,
        echoCancellation: {exact: true},
        autoGainControl: {exact :false},
        noiseSuppression: {exact: false}, 
        highpassFilter: {exact :false},
      },
      video: {
        width: 1280,
        framerate: 30,
      },
    };

    const refresh = (_participantCUID?:string) => {

      if(_participantCUID){
        if(_participantCUID !== myCUID){
          console.log('재수신 : ', participant.userName, _participantCUID)
          onRecive();
          return;
        }
      }

      if (myStream) {
        myStream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }

      if (streamList) {
        for (let i = 0; streamList.length > i; i++) {
          streamList[i].getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }

      if (videoView === 'video') {
        video = document.getElementById('video-' + participant.uid) as HTMLVideoElement;
        if (video) {
          video.srcObject = null;
        }
      } else if (videoView === 'audio') {
        video = document.getElementById('video-' + participant.uid) as HTMLAudioElement;
        if (video) {
          video.srcObject = null;
        }
      }

      const msg = {
        type: 'kurento',
        id: 'broadcastRestarted',
        uid: myUID,
      };
      ToggleMic(false);
      ToggleVideo(false);
      sendMessage(msg);
      let cnts: any;
      let audioOption: any = false;
      let videoOption: any = false;

      if (participant.stream?.activities.length) {
        for (let i = 0; i < participant.stream?.activities.length; i++) {
          if (participant.stream?.activities[i] === 'AUDIO') {
            audioOption = {
              autoGainControl: true,
              deviceId: videostate.audioInput,
              noiseSuppression: true,
            };
            ToggleMic(true);
          }

          if (participant.stream?.activities[i] === 'VIDEO') {
            if (mMode && mMode !== 0) {
              if (videostate.videoSize === '720p') {
                if (mobileYn === true) {
                  videoOption = {
                    facingMode: { exact: 'user' },
                    width: { ideal: '1280' },
                    height: { ideal: '720' },
                  };
                } else {
                  videoOption = {
                    facingMode: { exact: 'environment' },
                    width: { ideal: '1280' },
                    height: { ideal: '720' },
                  };
                }
              } else {
                if (mobileYn === true) {
                  videoOption = {
                    facingMode: { exact: 'user' },
                    width: { ideal: '640' },
                    height: { ideal: '360' },
                  };
                } else {
                  videoOption = {
                    facingMode: { exact: 'environment' },
                    width: { ideal: '640' },
                    height: { ideal: '360' },
                  };
                }
              }
            } else {
              if (participant.stream?.activities[i] === 'VIDEO') {
                if (videostate.videoSize === '720p') {
                  videoOption = {
                    deviceId: videostate.videoInput,
                    width: { ideal: '1280' },
                    height: { ideal: '720' },
                  };
                } else {
                  videoOption = {
                    deviceId: videostate.videoInput,
                    width: { ideal: '640' },
                    height: { ideal: '360' },
                  };
                }
              }
            }
            ToggleVideo(true);
          }
        }

        cnts = {
          audio: audioOption,
          video: videoOption,
        };
        navigator.mediaDevices
          .getUserMedia(cnts)
          .then((stream) => {
            let video: any = {};
            video.localStream = stream;
            let _stream = stream;
            const data: any = {};
            if (stream.getAudioTracks().length !== 0) {
              data.aCtx = new AudioCtx({
                sampleRate: pushAlarmStore.getSampleRateData()
                  ? pushAlarmStore.getSampleRateData()
                  : 48000,
              });
              data.gainNode = data.aCtx.createGain();
              data.gainNode.gain.value = participant.micLevel;
              data.analyser = data.aCtx.createAnalyser();
              data.aSrc = data.aCtx.createMediaStreamSource(stream);
              data.aSrc.connect(data.gainNode);
              data.gainNode.connect(data.analyser);
              data.aDest = data.aCtx.createMediaStreamDestination();
              data.analyser.connect(data.aDest);
              _stream = data.aDest.stream;
              stream.getTracks().forEach(function (track) {
                _stream.addTrack(track);
              });
            }
            video.data = data;
            video.stream = _stream;
            videoStream(video);
            Mestream(video.stream);
            onSend(video.stream);
            streamList.push(video.stream);
          })
          .catch((err) => {
            sendMessage({
              id: 'devicesAltered',
              uid: participant.uid,
              audio: false,
              video: false,
            });
            mediaError(err);
            ToggleMic(false);
            ToggleVideo(false);
          });
      } else {
        onCamDisabled(false);
        onButtonFlag(false);
      }
    };

    useEffect(() => {
      if (myCUID === participant.cuid) {
        if (participant.stream?.activities.length !== 0) {
          refresh();
        } else {
          onFirstSend();
          ToggleMic(false);
          ToggleVideo(false);
        }
      } else {
        if (participant.stream?.activities.length !== undefined) {
          onRecive();
        } else {
          onNullRecive();
        }
      }
    }, [
      participant.uid,
      participant.stream?.activities.length,
      participant.stream,
      participant.updateData,
      mobileYn,
      mMode,
    ]);
    const onFirstSend = () => {
      if (myStream) {
        myStream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
      if (streamList) {
        for (let i = 0; streamList.length > i; i++) {
          streamList[i].getTracks().forEach((track: any) => {
            track.stop();
          });
        }
      }
      if (videoView === 'video') {
        video = document.getElementById('video-' + participant.uid) as HTMLVideoElement;
        if (video) {
          video.srcObject = null;
        }
      } else if (videoView === 'audio') {
        video = document.getElementById('video-' + participant.uid) as HTMLAudioElement;
        if (video) {
          video.srcObject = null;
        }
      }
      onCamDisabled(false);
      onButtonFlag(false);
      peer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
        {
          localVideo: video,
          videoStream: null,
          mediaConstraints: constraints,
          onicecandidate: onIceCandidate,
        },
        (error) => {
          if (error) {
            return console.error(error);
          }
          peer.generateOffer(offerToReceiveVideo);
        },
      );
      setPeer && setPeer(participant.cuid, participant.uid, peer, participant.stream?.activities);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
    };

    const onSend = (videoState: any) => {
      if (videoView === 'video') {
        video = document.getElementById('video-' + participant.uid) as HTMLVideoElement;
        if (video) {
          video.srcObject = videoState;
        }
      } else if (videoView === 'audio') {
        video = document.getElementById('video-' + participant.uid) as HTMLAudioElement;
        if (video) {
          video.srcObject = videoState;
          onCamDisabled(false);
          onButtonFlag(false);
        }
      }
      video.addEventListener('play', () => {
        onCamDisabled(false);
        onButtonFlag(false);
      });

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
        {
          videoStream: videoState,
          mediaConstraints: constraints,
          onicecandidate: onIceCandidate,
        },
        (error) => {
          if (error) {
            return console.error(error);
          }
          peer.generateOffer(offerToReceiveVideo);
        },
      );
      setPeer && setPeer(participant.cuid, participant.uid, peer, participant.stream?.activities);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
    };

    const onNullRecive = () => {
      const videoElement = videoRef.current;
      if (!videoElement) {
        setTimeout(() => {
          onNullRecive();
        }, 100);
        return;
      }
      if (videoElement) {
        videoElement.srcObject = null;
      }
      peer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
        {
          remoteVideo: null,
          mediaConstraints: null,
          onicecandidate: onIceCandidate,
        },
        (error) => {
          if (error) {
            console.error(error);
          }
        },
      );
      setPeer && setPeer(participant.cuid, participant.uid, peer, participant.stream?.activities);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
      const play = videoElement.play();
      if (play !== undefined) {
        play
          .then(() => {
            videoElement.play();
          })
          .catch((error: any) => {
            console.log('error', error);
          });
      }
    };

    const onRecive = () => {
      const videoElement = videoRef.current;
      if (!videoElement) {
        setTimeout(() => {
          onRecive();
        }, 100);
        console.log('return videoRef.current onrecive');
        return;
      }

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(
        {
          remoteVideo: videoElement,
          mediaConstraints: constraints,
          onicecandidate: onIceCandidate,
        },
        (error) => {
          if (error) {
            console.error(error);
          }
        },
      );
      peer.generateOffer(offerToReceiveVideo);
      setPeer && setPeer(participant.cuid, participant.uid, peer, participant.stream?.activities);
      onConnectionstatechange(peer.peerConnection, refresh, participant.cuid, participant.userName);
      const play = videoElement.play();
      if (play !== undefined) {
        play
          .then(() => {
            videoElement.play();
          })
          .catch((error: any) => {
            console.log('error', error);
          });
      }
      console.log('🌈 ~ 수신 uid : ', participant.uid);
    };

    const offerToReceiveVideo = function (error: any, offerSdp: any) {
      if (error) {
        return console.error('sdp offer error');
      }
      let msg: any = {
        type: 'kurento',
        id: 'broadcastStarted',
        uid: participant.uid,
        sdpOffer: offerSdp,
      };
      if (myCUID !== participant.cuid) {
        msg = {
          type: 'kurento',
          id: 'addListener',
          sender: participant.uid,
          sdpOffer: offerSdp,
        };
      }
      sendMessage(msg);
    };

    const onIceCandidate = (candidate: any, wp: any) => {
      const message = {
        type: 'kurento',
        id: 'onIceCandidate',
        candidate: candidate,
        uid: participant.uid,
        luid: myUID !== participant.uid ? myCUID : participant.uid,
      };
      sendMessage(message);
    };

    const mediaError = (err: any) => {
      const n = err.name;
      rtcAlert(n);
    };

    const rtcAlert = (err: string) => {
      if (err === 'NotFoundError' || err === 'DevicesNotFoundError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.noWebcam');
      } else if (err === 'NotReadableError' || err === 'TrackStartError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.webcamInUse');
      } else if (err === 'OverconstrainedError' || err === 'ConstraintNotSatisfiedError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.webcamTrack');
      } else if (err === 'NotAllowedError' || err === 'PermissionDeniedError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.webcamAccess');
      } else if (err === 'TypeError') {
        return returnAlertFlag && returnAlertFlag(true, 'meeting.alert.mediaTrack');
      } else {
        return 'Unknown error: ' + err;
      }
    };

    const AuthIcon = getIconByAuthCd(participant.authCd);

    return (
      <>
        <Card
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            background: '#000',
            // '&:hover': {
            //   '& .MuiTypography-root, .MuiBox-root': {
            //     display: 'block',
            //   },
            // },
            borderRadius: isDownMd ? 0 : 2,
          }}
        >
          {videoView === 'video' && (
            <Box
              sx={{
                width: videoWidth,
                height: videoHeight,
              }}
              id={participant.uid}
            >
              {(participant.stream?.activities.length === 0 || !participant.stream) && (
                <Stack
                  sx={{
                    width: videoWidth,
                    height: videoHeight,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <AuthIcon />
                </Stack>
              )}
              {myStema === 'Y' && (
                <video
                  ref={videoRef}
                  muted
                  id={'video-' + participant.uid}
                  autoPlay={true}
                  controls={false}
                  playsInline
                  style={{
                    width: videoWidth,
                    height: videoHeight,
                  }}
                />
              )}
              {myStema !== 'Y' && (
                <video
                  ref={videoRef}
                  id={'video-' + participant.uid}
                  autoPlay={true}
                  controls={false}
                  playsInline
                  style={{
                    width: videoWidth,
                    height: videoHeight,
                  }}
                />
              )}
            </Box>
          )}
          {videoView === 'audio' && (
            <Box
              sx={{
                backgroundImage: 'url(/assets/ico-audioImage.svg)',
                backgroundPosition: '50% 50%',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '30px',
                width: videoWidth,
                height: videoHeight,
              }}
              id={participant.uid}
            >
              {myStema === 'Y' && (
                <audio
                  ref={videoRef}
                  id={'video-' + participant.uid}
                  autoPlay={true}
                  controls={false}
                  playsInline
                  style={{
                    width: videoWidth,
                    height: videoHeight,
                  }}
                />
              )}
              {myStema !== 'Y' && (
                <audio
                  ref={videoRef}
                  id={'video-' + participant.uid}
                  autoPlay={true}
                  controls={false}
                  playsInline
                  muted
                  style={{
                    width: videoWidth,
                    height: videoHeight,
                  }}
                />
              )}
            </Box>
          )}
          {myCUID === participant?.cuid ? (
            <Box
              sx={{
                position: 'absolute',
                bottom: 1,
                width: '100%',
                // display: 'none',
              }}
            >
              <IconButton
                onClick={() => {
                  refresh();
                }}
              >
                <Iconify
                  icon={'grommet-icons:power-reset'}
                  sx={{
                    color: '#999',
                  }}
                />
              </IconButton>
            </Box>
          ) : (
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                p: 2,
                background: '#0000007a',
                width: '100%',
                // display: 'none',
              }}
            >
              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{}}
              >
                <Typography>
                  {participant.userName !== '' ? participant.userName : 'guest'}
                  <IconButton
                    onClick={() => {
                      onRecive();
                    }}
                  >
                    <Iconify
                      icon={'grommet-icons:power-reset'}
                      sx={{
                        color: '#999',
                      }}
                    />
                  </IconButton>
                </Typography>
                <ParticipantsDetail participant={participant} detaildata={detaildata} />
              </Stack>
            </Box>
          )}
        </Card>
      </>
    );
  },
);

export default MobileParticipant;
