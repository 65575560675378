// @mui
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { styled } from '@mui/material/styles';
import {
  Button,
  Paper,
  Stack,
  useTheme,
  Grid,
  List,
  ListItem,
  Badge,
  Typography,
  Divider,
  IconButton,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import 'moment-timezone';
import 'moment/locale/ko';
import 'moment/locale/ja';
import './DragAndDrop.css';
import './Calendar.light.css';
import _ from 'lodash';
import { useStores } from 'src/models/root-store/root-store-context';
import { useAuthContext } from 'src/auth/useAuthContext';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { IConferenceSnapshot } from 'src/models';
import moment from 'moment';
import { Dayjs } from 'dayjs';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { useLocales } from 'src/locales';
import { Trans } from 'react-i18next';
import { jaJP } from '@mui/x-date-pickers/locales';
import { koKR } from '@mui/x-date-pickers/locales';
import Logo from 'src/components/logo';
import Iconify from 'src/components/iconify';
import useResponsive from 'src/hooks/useResponsive';
import { toJS } from 'mobx';

interface Props {
  getData: (start?: string, end?: string) => Promise<void>;
  getDetail: (id: number) => {};
  setToday: Dispatch<SetStateAction<string>>;
  onCreate: VoidFunction;
  onReservation: VoidFunction;
  today: string;
  pending: boolean;
}

export const MyCalendarMobile = observer(
  ({ getData, getDetail, setToday, onCreate, onReservation, today, pending = false }: Props) => {
    const rootStore = useStores();
    const theme = useTheme();
    const { translate } = useLocales();
    const { user, isAuthenticated } = useAuthContext();

    const { conferenceStore: store, loadingStore } = rootStore;

    const [value, setValue] = useState<Dayjs | null>(null);
    const [items, setItems] = useState<any[]>([]);
    const [selectDay, setSelectDay] = useState<string | null>(null);

    const redirectUrl = sessionStorage.getItem('redirectUrl');

    const Item = styled(Paper)(({ theme }) => ({
      padding: theme.spacing(0),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    }));

    const list = store.conferences
      .filter((r) => moment(r.start).format('YYYY-MM') === moment(today).format('YYYY-MM'))
      .map((r) => {
        return moment(r.start).format('YYYY-MM-DD');
      });

    const isSm = useResponsive('down', 'sm');

    const ServerDay = (props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) => {
      const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

      const isSunday = // 현재 월 중 일요일
        props.day.toDate().getMonth() === new Date(today).getMonth() &&
        props.day.toDate().getDay() === 0;

      const isSelected =
        !props.outsideCurrentMonth && list.indexOf(props.day.format('YYYY-MM-DD')) >= 0;
      let cnt = isSelected ? list.filter((r) => r === props.day.format('YYYY-MM-DD')).length : 0;

      return (
        <Badge
          variant={isSm ? 'dot' : 'standard'}
          key={props.day.toString()}
          color="primary"
          max={99}
          overlap={'circular'}
          badgeContent={isSm ? undefined : isSelected ? cnt : undefined}
          sx={{
            '& .MuiBadge-badge': {
              display: isSm ? (cnt > 0 ? 'block' : 'none') : 'block',
              fontSize: '0.65rem',
              padding: isSm ? 0 : '2px',
              height: isSm ? '4px' : '14px',
              width: isSm ? '4px' : 'auto',
              minWidth: isSm ? '4px' : '14px',
              // width: isSm ? '4px' : '14px',
              // height: isSm ? '4px' : '14px',
            },
          }}
        >
          <PickersDay
            {...other}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            sx={{ color: isSunday ? 'red' : '' }}
          />
        </Badge>
      );
    };

    useEffect(() => {
      if (isAuthenticated && user) {
        moment.locale(user.locale);
      }
    }, []);

    const sort = (a: IConferenceSnapshot, b: IConferenceSnapshot) => {
      if (moment(a.start) > moment(b.start)) {
        return -1;
      } else if (moment(a.start) === moment(b.start)) {
        return 0;
      } else if (moment(a.start) < moment(b.start)) {
        return 1;
      } else {
        return 0;
      }
    };

    useEffect(() => {
      setItems(
        store.conferences.filter((r: IConferenceSnapshot) => {
          return r.room.closed === false;
        }),
        // .filter(
        //   (r: IConferenceSnapshot) =>
        //     moment(r.start).format('YYYY-MM') === moment(today).format('YYYY-MM'),
        // )
        // .sort(sort),
      );
      // return () => {};
    }, [store.conferences.length]);

    return (
      <>
        <Stack sx={{ flex: 1, width: '100%' }}>
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            sx={{ px: 2, py: 1, position: 'sticky', top: 0, background: '#FFF', zIndex: 1200 }}
          >
            {/* <Box
              onClick={() => {
                // window.location.reload();
                // store.resetConferences(); 
                // getData(moment().format('YYYY-MM'), moment().format('YYYY-MM'));
                // setItems(store.conferences);
                // setToday(moment().format('YYYY-MM-DD'));
                // setSelectDay(null);
              }}
            > */}
            <Logo width={60} sx={{ px: 1, m: 0 }} />
            {/* </Box> */}
            <Stack direction={'row'} spacing={1}>
              <Button
                variant={'outlined'}
                onClick={onReservation}
                size={'small'}
                sx={{ lineHeight: 1 }}
              >
                {translate('conference.reservation').toString()}
              </Button>
              <Button
                variant={'contained'}
                onClick={onCreate}
                size={'small'}
                sx={{ lineHeight: 1 }}
              >
                <Iconify icon={'ic:round-plus'} />
                {translate('conference.makeReservation').toString()}
              </Button>
              {redirectUrl && (
                <IconButton
                  onClick={() => {
                    window.location.href = redirectUrl;
                    sessionStorage.removeItem('redirectUrl');
                  }}
                >
                  <Iconify icon={'ooui:table-move-column-before-rtl'} color={'#999'} />
                </IconButton>
              )}
            </Stack>
          </Stack>
          <Divider />

          {user && (
            <LocalizationProvider
              dateFormats={
                user.locale !== 'en'
                  ? {
                      monthAndYear: `YYYY${translate('myCalendar.year').toString()} MM${translate(
                        'myCalendar.month',
                      ).toString()}`,
                    }
                  : {}
              }
              dateAdapter={AdapterDayjs}
              adapterLocale={user.locale}
              // localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
              localeText={{
                ...(user.locale === 'ko'
                  ? koKR.components.MuiLocalizationProvider.defaultProps.localeText
                  : user.locale === 'ja'
                  ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
                  : undefined),
              }}
            >
              <DateCalendar
                showDaysOutsideCurrentMonth
                fixedWeekNumber={6}
                dayOfWeekFormatter={(day) => {
                  return translate(`myCalendar.${day}`).toString();
                }}
                views={['day']}
                sx={{
                  '& .MuiPickersDay-root:not(.Mui-selected)': {
                    borderColor: theme.palette.primary.main,
                  },
                  background: '#f9f9f9',
                  '& .MuiDayCalendar-header span:first-child': {
                    color: 'red',
                  },
                }}
                onMonthChange={(e: any) => {
                  setValue(null);
                  setSelectDay(null);
                  setToday(e.format('YYYY-MM-DD'));
                  store.resetConferences();
                  setItems([]);
                  getData(e.format('YYYY-MM'), e.format('YYYY-MM'));
                }}
                value={value}
                onChange={(e: any) => {
                  setValue(e);
                  setSelectDay(e.format('YYYY-MM-DD'));
                  setToday(e.format('YYYY-MM-DD'));
                  setItems(
                    (items) =>
                      (items = store.conferences
                        .filter(
                          (r: IConferenceSnapshot) =>
                            moment(r.start).format('YYYY-MM-DD') === e.format('YYYY-MM-DD'),
                        )
                        .sort(sort)),
                  );
                }}
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    list,
                  } as any,
                }}
              />
            </LocalizationProvider>
          )}
          {selectDay && (
            <Typography
              variant={'subtitle2'}
              sx={{ px: 3, py: 1, background: theme.palette.background.neutral, textAlign: 'left' }}
            >
              {moment(selectDay).format('LL')}
            </Typography>
          )}
          <Stack
            direction="column"
            spacing={2}
            sx={{
              overflowY: 'auto',
              overflowX: 'hidden',
              // height: 'calc( 100vh - 354px - 140px )',
              width: '100%',
              px: 3,
              pb: 10,
            }}
          >
            {pending && (
              <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                <Trans i18nKey={'loading'} />
              </Typography>
            )}
            {!pending && items.length < 1 ? (
              <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                <Trans i18nKey={'error.nodata'} />
              </Typography>
            ) : (
              <List>
                {items.map((item: IConferenceSnapshot, i: number) => {
                  return (
                    <ListItem
                      key={'conference-item-' + i}
                      sx={{
                        p: 0,
                        px: 1,
                        borderBottom: '1px solid #EEEEEE',
                        backgroundColor:
                          !item.room.closed && !!item.startedTime
                            ? // theme.palette.background.neutral
                              theme.palette.secondary.lighter
                            : '',
                      }}
                    >
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: '100%',
                          mt: 0,
                          py: 1,
                          '&:last-child': {
                            border: 'none',
                          },
                          '& .MuiPaper-root': {
                            background: 'none',
                          },
                        }}
                      >
                        <Grid item sm={2} xs={2} sx={{}}>
                          <Typography
                            sx={{
                              color: theme.palette.text.secondary,
                              lineHeight: 1,
                              fontWeight: 400,
                              fontSize: '0.75rem',
                            }}
                          >
                            {moment(item.start).format('Do (dd)')}
                            <br />
                            {moment(item.start).format('HH:mm')}
                          </Typography>
                        </Grid>
                        <Grid item sm={8} xs={8} sx={{ lineHeight: 1 }}>
                          <Typography
                            variant={'body1'}
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textAlign: 'left',
                            }}
                          >
                            {item.title}
                          </Typography>
                          <Typography
                            sx={{
                              color: theme.palette.text.secondary,
                              lineHeight: 1,
                              fontWeight: 400,
                              fontSize: '0.75rem',
                            }}
                          >
                            {translate('conferenceDetail.conferId').toString()} : {item.id}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} xs={2} sx={{ textAlign: 'right' }}>
                          <Button
                            variant={'soft'}
                            color={'primary'}
                            size={'small'}
                            sx={{
                              p: 1,
                              minWidth: 50,
                              backgroundColor: item.room.closed ? 'rgba(145, 158, 171, 0.24)' : '',
                              color: item.room.closed ? 'rgba(145, 158, 171, 0.8)' : '',
                            }}
                            onClick={() => {
                              getDetail(item.id);
                            }}
                            // disabled={item.room.closed}
                          >
                            {translate('myCalendar.enter').toString()}
                            {/* <Trans i18nKey={'입장'} /> */}
                          </Button>
                        </Grid>
                      </Grid>
                      {/* </Button> */}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Stack>
        </Stack>
      </>
    );
  },
);

export default MyCalendarMobile;
