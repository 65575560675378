import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPushAlarm, ISentPushAlarm, ISentPushAlarmSnapshot } from '../../models';
import {
  TUpdateAlarmResult,
  TDeleteAlarmResult,
  TGetAlarmsResult,
  TGetAlarmResult,
  TGetCountsResult,
  TReadAlarmResult,
  TGetSentListResult,
  TGetReSendResult,
  TGetSampleRate,
} from './PushAlarmTypes';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { IResponseSnapshot } from 'src/models/response/Response';

/**
 * # Alarm Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new PushAlarmApi();
 *
 * ```
 */
export class PushAlarmApi {
  private BASE_URL = '/alarm';
  private api: Api;

  constructor(api: Api) {
    this.api = api;
    this.api.setup();
  }

  /**
   * 내가 수신한 푸시 알람 이력들을 가져온다.
   * @returns
   */
  async gets(pagination: IPaginationSnapshot, existsCount?: number): Promise<TGetAlarmsResult> {
    try {
      const url = '/web/watis/receivePush';
      const params = { size: pagination.size, page: pagination.page, existsCount: existsCount };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const pushAlarms: IPushAlarm[] = response.data.responseData;
      const paging: IPaginationSnapshot = response.data.paging;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', paging: paging, pushAlarms: pushAlarms, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 참여자 알람이력을 가져온다 ( 내가 송신한 알람이력을 가져온다 )
   * @returns
   */
  async getsSentList(
    pagination: IPaginationSnapshot,
    existsCount?: number,
  ): Promise<TGetSentListResult> {
    try {
      const url = '/web/watis/sendPushList';
      const params = { size: pagination.size, page: pagination.page, existsCount: existsCount };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      // if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
      // }

      const data: ISentPushAlarm[] = response.data.responseData;
      const paging: IPaginationSnapshot = response.data.paging;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, paging: paging, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   *  안읽은 수신 푸시 알람이력 갯수를 가져온다.
   * @returns
   */
  async getAlarmCounts(): Promise<TGetCountsResult> {
    try {
      const url = '/web/watis/receivePush/notCheckCount';
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const data: number = response.data.responseData.count;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async getSampleRate(): Promise<TGetSampleRate> {
    try {
      const url = '/web/appointment/sampleRate';
      const response: ApiResponse<any> = await this.api.apisauce.get(url);

      const data: number = response.data.responseData;
      return { kind: 'ok', data };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async reSend(data: ISentPushAlarmSnapshot): Promise<TGetReSendResult> {
    try {
      const url = '/web/watis/resendPush';
      const params = { seqNno: data.seqNno, facilityCd: data.facilityCd, loginId: data.loginId };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      // const data: any = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async get(id: number): Promise<TGetAlarmResult> {
    try {
      const url = `/alarm/${id}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const alarm: IPushAlarm = response.data.results;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', alarm, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 읽지 않은 알람들을 읽음으로 바꾼다.
   * @returns
   */
  async readAlarm(): Promise<TReadAlarmResult> {
    try {
      const url = '/web/watis/receivePush/check';
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      // const alarm: IPushAlarm = response.data.results;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async update(id: number): Promise<TUpdateAlarmResult> {
    try {
      const url = `${this.BASE_URL}/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.put(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IPushAlarm = response.data.data;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async delete(id: number): Promise<TDeleteAlarmResult> {
    try {
      const url = `${this.BASE_URL}/${id}`;
      const payload = {};
      const response: ApiResponse<any> = await this.api.apisauce.delete(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
