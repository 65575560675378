import { Helmet } from 'react-helmet-async';
import { forwardRef, ReactNode } from 'react';
// material
import { Box, BoxProps, Stack } from '@mui/material';
import { HEADER } from '../config-global';

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  title?: string;
  header?: ReactNode;
  children?: ReactNode;
}

export const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = 'UNTITLED', header, sx = {}, ...other }, ref) => (
    <>
      {/* <Helmet>
        <title>{title}</title>
      </Helmet> */}
      {header}
      <Box
        id="PageContainer"
        ref={ref}
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'flex-start',
          ...sx,
        }}
        {...other}
      >
        {children}
      </Box>
    </>
  ),
);
