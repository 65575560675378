// routes
import { PATH_AUTH, PATH_PAGE } from '../../../routes/paths';
// config
import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';
import { ReactComponent as IcoCam } from 'src/assets/images/ico-cam.svg';
import { ReactComponent as IcoPerson } from 'src/assets/images/ico-person.svg';
import { ReactComponent as IcoBell } from 'src/assets/images/ico-bell.svg';
import { ReactComponent as IcoPeople } from 'src/assets/images/ico-people.svg';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: '화상회의',
    icon: <IcoCam />,
    path: '/',
  },
  {
    title: '마이페이지',
    icon: <IcoPerson />,
    path: '/'
  },
  {
    title: '알림수신이력',
    icon: <IcoBell />,
    path: '/'
  },
  {
    title: '알림이력',
    path: '/',
    icon: <IcoPeople />,
  },
];

export default navConfig;
