import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import { useStores } from "../../../models/root-store/root-store-context"
import { useTheme } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { isValidToken } from 'src/auth/utils';
import { useAuthContext } from 'src/auth/useAuthContext';

/**
 * ## TokenCheck 설명
 *
 */
export const TokenCheck = observer(() => {

  const rootStore = useStores();
  const auth = useAuthContext();
  const params = useParams();
  const navigate = useNavigate();
  const { token = '' } = params;
  const { REACT_APP_API_URL } = process.env;

  useEffect(() => {

    (async () => {
      if (token !== '') {
        try {
          if (isValidToken(token)) {
            await fetch(REACT_APP_API_URL + '/common/v1/user/login/refresh?refreshToken=' + token, {
              method: 'POST'
            }).then(async (res) => {
              const json = await res.json();
              localStorage.removeItem('navOpen');
              localStorage.setItem('accessToken', json.data.access_token);
              localStorage.setItem('refreshToken', json.data.refresh_token);
              await auth.initialize();
              navigate('/', { replace: true });
            }).catch((e) => {
              localStorage.setItem('common_refresh_error', e)
              localStorage.removeItem('navOpen');
              localStorage.removeItem('refreshToken');
              localStorage.removeItem('accessToken');
              navigate('/login', { replace: true });
            });
          } else {
            navigate('/login', { replace: true });
          }
        } catch (e) {
          localStorage.setItem('token-check', e)
          navigate('/login', { replace: true });
        }
      }
    })();

    return () => {
    };
  }, [token]);

  return (
    <>
      <Box sx={{}}>
      </Box>
    </>
  );
});

export default TokenCheck;