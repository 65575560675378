import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { ShareFile } from '../share-file/ShareFile';

/**
 * # RecordingFile
 *
 * RecordingFile을 설명하세요.
 */
export const RecordingFile = types
  .model('RecordingFile')
  // --------------------------------------------------------------------------
  .props({
    end: types.maybeNull(types.optional(types.string, '')),
    externalType: types.maybeNull(types.optional(types.string, '')),
    hash: types.optional(types.string, ''),
    height: types.maybeNull(types.optional(types.number, 0)),
    id: types.optional(types.number, 0),
    interview: types.optional(types.boolean, false),
    name: types.optional(types.string, ''),
    ownerId: types.optional(types.number, 0),
    roomId: types.optional(types.number, 0),
    start: types.optional(types.string, ''),
    status: types.optional(types.string, ''),
    width: types.maybeNull(types.optional(types.number, 0)),
    redirectUrl: types.maybeNull(types.optional(types.string, '')),
    shareFiles: types.maybeNull(types.optional(types.array(ShareFile), [])),
    tag: types.maybeNull(types.optional(types.string, '')),
    type: types.optional(types.string, ''),
    waitModerator: types.optional(types.boolean, false),
    waitRecording: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model recordingFile --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IRecordingFile;
      (Object.keys(newProps) as TRecordingFileKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TRecordingFile = Instance<typeof RecordingFile>;
type TRecordingFileSnapshot = SnapshotOut<typeof RecordingFile>;

export interface IRecordingFile extends TRecordingFile {}
export type TRecordingFileKeys = keyof TRecordingFileSnapshot & string;
export interface IRecordingFileSnapshot extends TRecordingFileSnapshot {}
export const createRecordingFile = () => types.optional(RecordingFile, {} as TRecordingFile);
