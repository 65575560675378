import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IUserSnapshot, createUser } from '../user/User';
import { createAuth } from '../auth/Auth';
import { createFacility } from '../facility/Facility';

/**
 * # MeetingMember
 *
 * MeetingMember을 설명하세요.
 */
export const MeetingMember = types
  .model('MeetingMember')
  // --------------------------------------------------------------------------
  .props({
    email: types.maybeNull(types.string), // 이메일주소
    loginId: types.maybeNull(types.string), // 사용자아이디 _회원 초대시 (displayName이 오지 않을경우 필수로 와야함)
    id: types.maybeNull(types.number), // 사용자아이디 _회원 초대시 (displayName이 오지 않을경우 필수로 와야함)
    displayName: types.maybeNull(types.string), // 회의에 사용할 이름 _비회원 초대시 (userId가 오지 않을경우 필수로 와야함)
    user: createUser(),
    invitationYn: types.maybeNull(types.string),
    facilityCd: types.maybeNull(types.string),
    facility: types.maybeNull(createFacility()),
    authCd: types.maybeNull(types.string),
    auth: types.maybeNull(createAuth()),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model meetingMember --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMeetingMember;
      (Object.keys(newProps) as TMeetingMemberKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMeetingMember = Instance<typeof MeetingMember>;
type TMeetingMemberSnapshot = SnapshotOut<typeof MeetingMember>;

export interface IMeetingMember extends TMeetingMember {}
export type TMeetingMemberKeys = keyof TMeetingMemberSnapshot & string;
export interface IMeetingMemberSnapshot extends TMeetingMemberSnapshot {}
export const createMeetingMember = () => types.optional(MeetingMember, {} as TMeetingMember);
