import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Box, Card, Container, Stack, Typography, useTheme } from '@mui/material';
import OwnerCount from './OwnerCount';
import OwnerCountNull from './OwnerCountNull';
import ProgressList from './progressList';
import SystemInfo from './systemInfo';
import { CallApiToStore } from 'src/utils/common';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import { toJS } from 'mobx';
import { some } from 'lodash';
import { useLocales } from 'src/locales';

/**
 * ## Dashboard 설명
 *
 */
export const Dashboard = observer(() => {
  const rootStore = useStores();
  const { dashBoardStore: store, loadingStore } = rootStore;
  const scrollRef = useRef<HTMLDivElement>(null);
  const { translate } = useLocales();

  const getOwnerCount = async () => {
    await CallApiToStore(store.getOwnerCount(), 'api', loadingStore);
  };
  const getProgressList = async () => {
    await CallApiToStore(store.getProgressList(), 'api', loadingStore);
  };
  const getSystemInfo = async () => {
    await CallApiToStore(store.getSystemInfo(), 'api', loadingStore);
  };

  useEffect(() => {
    getOwnerCount();
    getProgressList();
    getSystemInfo();
  }, [store.ownerCount, store.progressList, store.systemInfo]);

  const ownerCounts = toJS(store.ownerCount);
  const data = [
    {
      name: 'OnGoingTotal',
      title: translate(`dashBoard.todaysMeeting.onGoingTotal`).toString(),
      data: ownerCounts.totalOwnerCount,
    },
    {
      name: 'OnGoingNow',
      title: translate(`dashBoard.todaysMeeting.onGoingNow`).toString(),
      data: ownerCounts.progressOwnerCount,
    },
    {
      name: 'Scheduled',
      title: translate(`dashBoard.todaysMeeting.scheduled`).toString(),
      data: ownerCounts.nonProgressOwnerCount,
    },
  ];

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: 4,
        }}
      >
        <Scrollbar
          scrollableNodeProps={{
            ref: scrollRef,
          }}
          sx={{ height: 1 }}
        >
          <Stack
            spacing={3}
            sx={{
              flex: 1,
              '& .MuiStack-root>:not(style)+:not(style)': {
                marginLeft: '0 !important',
              },
            }}
          >
            <Stack spacing={1}>
              <Typography variant="h5">
                {translate(`dashBoard.todaysMeeting.title`).toString()}
              </Typography>
              <Stack display={'flex'} direction={'row'} gap={2}>
                {data.map((data, index) => (
                  <Card key={index} sx={{ p: 2, width: '100%' }}>
                    <Typography variant="subtitle1">{data.title}</Typography>
                    {!!some(data.data.map((v) => v.count)) && <OwnerCount ownerdata={data} />}
                    {!some(data.data.map((v) => v.count)) && <OwnerCountNull ownerdata={data} />}
                  </Card>
                ))}
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h5">
                {translate(`dashBoard.meetingsInProgress.title`).toString()}
              </Typography>
              <ProgressList />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="h5">
                {translate(`dashBoard.systemUsage.title`).toString()}
              </Typography>
              <SystemInfo />
            </Stack>
          </Stack>
        </Scrollbar>
      </Container>
    </>
  );
});

export default Dashboard;
