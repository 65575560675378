import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import {
  Button,
  Chip,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as IcoPDF } from 'src/assets/images/ico-pdf.svg';
import CloseIcon from '@mui/icons-material/Close';
import { IConferenceSnapshot, IReportSnapshot, IShareFileSnapshot } from 'src/models';
import FormProvider from '../../components/hook-form/FormProvider';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import CTextField from 'src/components/forms/CTextField';
import { CallApiToStore } from 'src/utils/common';
import { DateTimePicker } from '@mui/x-date-pickers';
import { parseISO } from 'date-fns';
import { Editor } from '@toast-ui/react-editor';
import CEditorToast from 'src/components/forms/CEditor-Toast';
import { RHFTextField } from 'src/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useScrollable } from 'src/hooks/useScrollable';
import { useLocales } from 'src/locales';
interface Props {
  onClose: VoidFunction;
  data: IConferenceSnapshot;
  reportData?: IReportSnapshot;
  path?: string;
  getData: () => Promise<void>;
}
/**
 * ## ReportCreate 설명
 *
 */
export const ReportCreate = observer(({ onClose, data, reportData, path, getData }: Props) => {
  const rootStore = useStores();
  const { conferenceStore, responseStore, loadingStore } = rootStore;
  const theme = useTheme();
  const { translate } = useLocales();
  const MyInfoSchema = Yup.object({
    agenda: Yup.string().required(''),
    pdfPassword: Yup.string().required(''),
  });

  const methods = useForm<IReportSnapshot>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      // ...reportData,
      // metDt: moment(data.start).format('YYYY-MM-DD HH:mm'),
      agenda: reportData?.agenda || '',
      place: reportData?.place || '',
      spec: reportData?.spec || '',
      reportData: reportData?.reportData || '',
    },
    resolver: yupResolver(MyInfoSchema),
  });

  const {
    setValue,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const [metDt, setMetDt] = useState(parseISO(moment(data.start).toISOString())); //(parseISO(moment(data?.start).toISOString()));

  const onSubmit = async () => {
    const report = {
      ...getValues(),
      roomId: data.room.id,
      metDt: moment(metDt).format('YYYY-MM-DD HH:mm'),
    };

    try {
      await CallApiToStore(
        conferenceStore.saveReport(report as IReportSnapshot),
        'api',
        loadingStore,
      ).then(() => {
        if (responseStore.responseInfo.error) {
          console.error(responseStore.responseInfo);
        } else {
          onClose();
          getData && getData();
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  const dragRef = useRef<HTMLDivElement>(null);
  useScrollable(dragRef, 'x');

  useEffect(() => {
    setValue('agenda', reportData?.agenda ? reportData.agenda : data.title);
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        sx={{
          background: theme.palette.background.neutral,
          p: 2,
          pr: 5,
          alignItems: 'center',
        }}
      >
        {data.title} {translate(`meeting.report.mainTitle`).toString()}
        {path === 'edit' && `(${translate('meeting.report.edit').toString()})`}
        <IconButton
          aria-label="close"
          onClick={onClose}
          onTouchEnd={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            zIndex: 99,
          }}
        >
          <CloseIcon />
        </IconButton>
      </Typography>
      <DialogContent sx={{ p: 0 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack my={2} mx={4} gap={2}>
            <Label>{translate(`conferenceReservation.info`).toString()}</Label>
            <Stack direction={'row'} display={'flex'} alignItems={'center'} maxWidth={'500px'}>
              {/* <Label>{translate(`meeting.title`).toString()}</Label>
              <Typography ml={2}> {data.title}</Typography> */}
              <RHFTextField
                variant="outlined"
                size="small"
                label={translate(`meeting.title`).toString()}
                placeholder={translate(`meeting.report.enterTitle`).toString()}
                name={'agenda'}
                onChange={(e) => {
                  setValue('agenda', e.target.value);
                }}
                // defaultValue={reportData?.agenda ? reportData.agenda : ''}
              />
            </Stack>
            <Stack
              direction={'row'}
              display={'flex'}
              alignItems={'center'}
              sx={{
                '& .MuiInputBase-input': {
                  py: '8px !important',
                },
              }}
              gap={2}
            >
              <Controller
                name="metDt"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <DateTimePicker
                      {...field}
                      label={translate(`conference.table.date`).toString()}
                      format="yyyy-MM-dd HH:mm"
                      onChange={(e: any) => {
                        console.log(e);
                        setMetDt(parseISO(moment(e).toISOString()));
                      }}
                      defaultValue={
                        path === 'edit'
                          ? parseISO(moment(reportData?.metDt).toISOString())
                          : parseISO(moment(data.start).toISOString())
                      }
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          sx: {
                            '&. MuiStack-root': { width: '100%' },
                          },
                        },
                      }}
                    />
                  );
                }}
              />
              <Label>{translate(`meeting.report.host`).toString()}</Label>
              <Typography>{data.owner.displayName || ''}</Typography>
              {/* <Label>녹화 여부</Label>
              <Typography>{data.room.allowRecording === true ? 'Y' : 'N' || ''}</Typography> */}
              {/* <Label>회의 일시</Label>
            <Typography>{data.conference_date}</Typography> */}
            </Stack>
            <Stack
              direction={'row'}
              display={'flex'}
              alignItems={'center'}
              gap={2}
              maxWidth={'600px'}
            >
              <RHFTextField
                name="place"
                size="small"
                variant="outlined"
                label={translate(`meeting.report.place`).toString()}
                onChange={(e) => {
                  setValue('place', e.target.value);
                }}
                // defaultValue={(reportData?.place && reportData.place) || ''}
              />
              {/* <RHFTextField
                name="spec"
                size="small"
                variant="outlined"
                label={translate(`meeting.report.history`).toString()}
                onChange={(e) => {
                  setValue('spec', e.target.value);
                }}
                // defaultValue={reportData?.spec || ''}
              /> */}
            </Stack>
          </Stack>

          <Divider sx={{ my: 2 }} />

          {data.room.shareFiles && data.room.shareFiles.length > 0 && (
            <>
              <Stack direction={'row'} display={'flex'} alignItems={'center'} mx={4}>
                <Label>{translate(`meeting.report.shareFiles`).toString()}</Label>

                <Stack m={0} p={0} direction={'row'} flexWrap={'wrap'} gap={0.5}>
                  {data.room.shareFiles.map((f: IShareFileSnapshot, i: number) => {
                    return (
                      <Chip
                        key={(f.id, i)}
                        variant={'outlined'}
                        label={f.name}
                        color={'default'}
                        sx={{ fontSize: '.875rem' }}
                      />
                    );
                  })}
                </Stack>
              </Stack>
              <Divider sx={{ my: 2 }} />
            </>
          )}
          <Box
            sx={{
              px: 4,
              maxHeight: 300,
              overflowY: 'auto',
              '& .MuiBox-root': {
                borderRadius: 0,
                borderRight: 0,
                borderLeft: 0,
              },
              '& .ql-toolbar': {
                p: '8px !important',
                px: '32px !important',
              },
            }}
          >
            <CEditorToast placeholder={'회의 내용'} name={'reportData'} />
          </Box>
          {/* <Stack
            direction={'row'}
            display={'flex'}
            alignItems={'center'}
            py={2}
            mx={4}
            maxWidth={'300px'}
            >
            <CTextField
            variant="outlined"
            type="password"
            label={'pdf비밀번호'}
            name={'pdfPassword'}
            />
          </Stack> */}

          {/* <Box
            position={'sticky'}
            bottom={0}
            mt={2}
            sx={{ backgroundColor: '#1e2228 !important', zIndex: 99 }}
          > */}
          <Divider sx={{ mt: 2 }} />

          <Stack direction={'row'} justifyContent={'space-between'} sx={{ px: 4, py: 3 }}>
            <Stack direction={'row'} display={'flex'} alignItems={'center'} maxWidth={'300px'}>
              <CTextField
                required
                name={'pdfPassword'}
                // size="small"
                variant="outlined"
                type="password"
                label={translate(`meeting.pdfPw`).toString()}
                placeholder={translate(`meeting.enterPdfPw`).toString()}
                // onChange={(e) => {
                //   setValue('pdfPassword', e.target.value);
                // }}
              />
            </Stack>
            <Button variant={'contained'} onClick={handleSubmit(onSubmit)}>
              {translate(`meeting.report.save`).toString()}
            </Button>
          </Stack>
          {/* </Box> */}
        </FormProvider>
      </DialogContent>
    </>
  );
});

const Label = styled(FormLabel)(({ theme }) => ({
  minWidth: 100,
  whiteSpace: 'nowrap',
  // marginBottom: 8,
  // marginLeft: 32,
  color: '#919EAB',
}));

const Row = styled(TableRow)(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.divider,
  '& th': {
    minWidth: '100px',
    paddingLeft: 32,
    color: theme.palette.text.secondary,
  },
}));

const Cell = styled(TableCell)(({ theme }) => ({
  padding: '16px',
}));

export default ReportCreate;
