import { observer } from 'mobx-react-lite';
import { useStores } from '../../../models/root-store/root-store-context';
import { useTheme, Stack, Typography, IconButton, Divider } from '@mui/material';
import { ReactComponent as IcoClose } from 'src/assets/icons/ico-close.svg';
import ChatMessageList from './message/ChatMessageList';
import ChatMessageInput from './message/ChatMessageInput';
import { CallApiToStore } from 'src/utils/common';
import { IChatSnapshot, IConferenceSnapshot } from 'src/models';
import { useEffect } from 'react';
import { useLocales } from 'src/locales';

/**
 * ## Chat 설명
 *
 */
interface Props {
  handleClose: VoidFunction;
  data: IConferenceSnapshot;
  chatMsg: IChatSnapshot[];
  newChatMsg: IChatSnapshot;
  getChat: VoidFunction;
  chatReset: VoidFunction;
}
export const Chatting = observer(
  ({ handleClose, data, chatMsg, newChatMsg, getChat, chatReset }: Props) => {
    const rootStore = useStores();
    const { conferenceStore, loadingStore } = rootStore;
    const theme = useTheme();
    const { translate } = useLocales();
    // const myUID = sessionStorage.getItem('myUID');
    // const myCUID = sessionStorage.getItem('myCUID');

    // const [messages, setMessages] = useState([] as IChatSnapshot[]);

    const sendMsg = async (msg: string) => {
      await CallApiToStore(conferenceStore.sendMsg(msg), 'api', loadingStore).then(() => {
        // console.log(msg);
        // setMessages(conferenceStore.chat);
      });
    };

    return (
      <>
        <Stack direction={'row'} justifyContent={'center'} sx={{ position: 'relative', p: 2 }}>
          <Typography variant={'subtitle1'}>{translate(`meeting.chat`).toString()}</Typography>
          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
            color={'inherit'}
          >
            <IcoClose stroke={'#637381'} />
          </IconButton>
        </Stack>
        <Divider />
        <ChatMessageList
          messages={chatMsg as IChatSnapshot[]}
          newChatMsg={newChatMsg}
          chatReset={chatReset}
        />
        <ChatMessageInput
          conversationId={null}
          onSend={(data: string) => {
            sendMsg(data);
            console.log('send message:', data);
          }}
        />
      </>
    );
  },
);

export default Chatting;
