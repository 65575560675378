import { useEffect, useRef, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  AppBar,
  Toolbar,
  Container,
  Link,
  BoxProps,
  Avatar,
  MenuItem,
  Divider,
  ListItemIcon,
  Menu,
  IconButton,
  Stack,
  Badge,
  Typography,
  Select,
  SelectChangeEvent,
  Drawer,
  List,
  ListItem,
} from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config-global';
// routes
// components
import Logo from '../../components/logo';
import Label from '../../components/label';
//
import navConfig from './nav/config-navigation';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import { useAuthContext } from '../../auth/useAuthContext';

import { ReactComponent as IcoBell } from 'src/assets/images/ico-bell.svg';
import { useSettings } from 'src/hooks/useSettings';
import { ReactComponent as IcoAdmin } from 'src/assets/icons/ico-user-admin.svg';
import { ReactComponent as IcoDoctor } from 'src/assets/icons/ico-user-doctor.svg';
import { ReactComponent as IcoNurse } from 'src/assets/icons/ico-user-nurse.svg';
import { ReactComponent as IcoDriver } from 'src/assets/icons/ico-user-driver.svg';
import { ReactComponent as IcoClericalStaff } from 'src/assets/icons/ico-user-clerical-staff.svg';
import { ReactComponent as IcoCareManager } from 'src/assets/icons/ico-user-care-manager.svg';
import { ReactComponent as IcoHomeHealthNurse } from 'src/assets/icons/ico-user-home-health-nurse.svg';
import { ReactComponent as IcoCaregiver } from 'src/assets/icons/ico-user-caregiver.svg';
import { ReactComponent as IcoPatient } from 'src/assets/icons/ico-user-patient.svg';
import { ReactComponent as IcoVisitor } from 'src/assets/icons/ico-user-visitor.svg';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router';
import Iconify from 'src/components/iconify/Iconify';
import { useScrollable } from 'src/hooks/useScrollable';
import { CallApiToStore, getIconByAuthCd } from 'src/utils/common';
import { IPushAlarmSnapshot, useStores } from 'src/models';
import { toJS } from 'mobx';
import { MyAlarm } from 'src/screens';
import { Trans } from 'react-i18next';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export default function Header() {
  const theme = useTheme();
  const { translate } = useLocales();
  const auth = useAuthContext();
  const navigate = useNavigate();
  const { themeMode } = useSettings();
  const rootStore = useStores();
  const { pushAlarmStore: store, loadingStore } = rootStore;
  const isDesktop = useResponsive('up', 'md');
  const isMobile = useResponsive('down', 'sm');
  const user = JSON.parse(localStorage.user);
  const authCd = user.auth?.authCd ? user.auth.authCd : user.authCd;
  const isAdmin = user.auth.authCd === 'A';
  const isSystemAdmin = user.isAdmin === true;

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  const redirectUrl = sessionStorage.getItem('redirectUrl');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [locale, setLocale] = useState(user.locale);

  const handleChange = (event: SelectChangeEvent) => {
    setLocale(event.target.value as string);
    sessionStorage.setItem('locale', locale);
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const getAlarmCounts = async () => {
    await CallApiToStore(store.getAlarmCounts(), 'api', loadingStore);
  };

  const AuthIcon = getIconByAuthCd(authCd);

  useEffect(() => {
    getAlarmCounts();
    // console.log('🪄 ~ Header ~ store.counts:', store.counts);
  }, []);

  const dragRef = useRef<HTMLDivElement>(null);
  useScrollable(dragRef, 'x');
  return (
    <>
      <AppBar
        color="transparent"
        sx={{
          boxShadow: 0,
          background: themeMode === 'light' ? '#f5f5f5' : theme.palette.background.paper,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: HEADER.H_MAIN_DESKTOP,
            },
            transition: theme.transitions.create(['height', 'background-color'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.shorter,
            }),
            ...(isOffset && {
              ...bgBlur({ color: theme.palette.background.default }),
              height: {
                md: HEADER.H_MAIN_DESKTOP - 16,
              },
            }),
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              p: 2,
            }}
          >
            {!isMobile && <Logo sx={{ pl: 0 }} />}

            {isMobile ? (
              // <NavMobile isOffset={isOffset} data={navConfig} />
              <></>
            ) : (
              <>
                <Box
                  ref={dragRef}
                  sx={{
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                      height: 2,
                    },
                    '&::-webkit-scrollbar-thumb:horizontal': {
                      backgroundColor: theme.palette.primary.main,
                      height: 2,
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#EEEEEE',
                    },
                  }}
                >
                  <NavDesktop isOffset={isOffset} data={navConfig} isAdmin={isSystemAdmin} />
                  {/* {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />} */}
                </Box>
                <Stack ml={1} direction={'row'}>
                  {/* <Select
                      variant="standard"
                      value={locale}
                      onChange={handleChange}
                      sx={{
                        justifySelf: 'end',
                        '&:before': {
                          display: 'none',
                        },
                        '&:after': {
                          display: 'none',
                        },
                        '& .MuiSelect-select': {
                          paddingRight: 0,
                        },
                      }}
                    >
                      <MenuItem value={'ja'}>🇯🇵</MenuItem>
                      <MenuItem value={'en'}>🇬🇧</MenuItem>
                      <MenuItem value={'ko'}>🇰🇷</MenuItem>
                    </Select>
                    */}
                  <IconButton onClick={() => setOpenDrawer(true)} size="small" sx={{ ml: 0 }}>
                    <Badge
                      badgeContent={store.counts}
                      // showZero
                      color="error"
                      // overlap="circular"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <IcoBell fill="#919EAB" />
                    </Badge>
                  </IconButton>
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    sx={{ width: '45px', height: '45px' }}
                  >
                    <AuthIcon />
                  </IconButton>
                  {redirectUrl && (
                    <IconButton
                      onClick={() => {
                        window.location.href = redirectUrl;
                        sessionStorage.removeItem('redirectUrl');
                      }}
                    >
                      <Iconify icon={'ooui:table-move-column-before-rtl'} color={'#999'} />
                    </IconButton>
                  )}
                </Stack>
              </>
            )}
          </Box>
        </Toolbar>

        {isOffset && <Shadow />}
      </AppBar>
      {openDrawer && ( // 알림 수신 이력 Drawer
        <Drawer
          // variant="persistent"
          open={openDrawer}
          anchor={'right'}
          onClose={() => setOpenDrawer(false)}
          sx={{
            '& .MuiPaper-root': {
              transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
              maxWidth: '420px',
              minWidth: 300,
            },
            [theme.breakpoints.down('sm')]: { '& .MuiPaper-root': { maxWidth: '100%' } },
          }}
          transitionDuration={{
            appear: 5000,
            // enter: 500,
            // exit: 500,
          }}
        >
          <MyAlarm handleClose={() => setOpenDrawer(false)} />
        </Drawer>
      )}
      {open && ( // 마이페이지 아이콘 popover
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.2))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem sx={{ cursor: 'default' }} focusRipple={false} disableRipple>
            <Stack>
              <Typography>{JSON.parse(localStorage.user).displayName}</Typography>
              <Typography variant="caption" color={'GrayText'}>
                {JSON.parse(localStorage.user).loginId}
              </Typography>
            </Stack>
          </MenuItem>
          <Divider />
          {/* <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem> */}
          <MenuItem onClick={() => navigate('/my/info')}>
            <ListItemIcon>
              <Avatar>
                <AuthIcon />
              </Avatar>
            </ListItemIcon>
            {translate('myPage.mainTitle').toString()}
          </MenuItem>
          {/* <MenuItem onClick={() => navigate('/my/alarm')}>
          <Badge
          badgeContent={2}
          color="error"
          // overlap="circular"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          >
          <IcoBell fill="#919EAB" />
          </Badge>
          알림수신이력
        </MenuItem> */}
          {/* <MenuItem onClick={handleClose}>
            <ListItemIcon>
            <Settings fontSize="medium" />
            </ListItemIcon>
            Settings
          </MenuItem> */}
          <Divider />
          {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
          <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem> */}
          <MenuItem onClick={auth.logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            {translate('myPage.logout').toString()}
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

function Shadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
