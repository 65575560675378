import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import {
  OccupationalCount,
  createOccupationalCount,
} from '../occupational-count/OccupationalCount';

/**
 * # OwnerCount
 *
 * OwnerCount을 설명하세요.
 */
export const OwnerCount = types
  .model('OwnerCount')
  // --------------------------------------------------------------------------
  .props({
    totalOwnerCount: types.array(OccupationalCount),
    progressOwnerCount: types.array(OccupationalCount),
    nonProgressOwnerCount: types.array(OccupationalCount),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model ownerCount --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IOwnerCount;
      (Object.keys(newProps) as TOwnerCountKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TOwnerCount = Instance<typeof OwnerCount>;
type TOwnerCountSnapshot = SnapshotOut<typeof OwnerCount>;

export interface IOwnerCount extends TOwnerCount {}
export type TOwnerCountKeys = keyof TOwnerCountSnapshot & string;
export interface IOwnerCountSnapshot extends TOwnerCountSnapshot {}
export const createOwnerCount = () => types.optional(OwnerCount, {} as TOwnerCount);
