import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { withEnvironment } from '../extensions/with-environment';
import { TGeneralApiResponse } from 'src/services/api/api-problem';
import { withRootStore } from './../extensions/with-root-store';
import { createResponse, IResponse } from './../response/Response';
import { toJS } from 'mobx';
import { DashBoardApi } from 'src/services/dash-board/DashBoard';

/**
 * # ResponseStore
 *
 * ResponseStore을 설명하세요.
 */
export const ResponseStore = types
  .model('ResponseStore')
  // --------------------------------------------------------------------------
  .props({
    responseInfo: createResponse(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  .actions((self) => ({
    setResponseInfo: (info: IResponse) => {
      self.responseInfo = info;
    },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    getResponseResult: (result: any) => {
      self.responseInfo.reset();
      // console.log('🌈 ~ .actions ~ self.responseInfo:', toJS(self.responseInfo))
      if (result.kind === 'ok') {
        self.responseInfo = result.responseInfo as IResponse;
        // console.log('🪄 ~ .actions ~ self.responseInfo.detail:', toJS(self.responseInfo));
        if (self.responseInfo.error) {
          self.responseInfo.detail = {
            error: self.responseInfo.error,
            exceptionDetail: self.responseInfo.exceptionDetail || '',
          };
          self.rootStore.errorAlertStore.setErrorAlertFromResponse();
          return false;
        }
        return true;
      } else {
        self.setResponseInfo(result);

        self.rootStore.errorAlertStore.setErrorAlertFromResponse();
        return false;
      }
    },

    getResponseResultWithOutSnackBar: (result: TGeneralApiResponse) => {
      self.responseInfo.reset();
      if (result.kind === 'ok') {
        self.responseInfo = result.responseInfo as IResponse;
        if (self.responseInfo.error) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },

    errorProcessing: (error: any) => {
      console.log('errorProcessing', error);
      self.responseInfo.reset();
    },

    // setResponse: <T>(resultType: T) => {},
  }));
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
type TResponseStore = Instance<typeof ResponseStore>;
type TResponseStoreSnapshot = SnapshotOut<typeof ResponseStore>;

export interface IResponseStore extends TResponseStore {}
export type TResponseStoreKeys = keyof TResponseStoreSnapshot & string;
export interface IResponseStoreSnapshot extends TResponseStoreSnapshot {}
export const createResponseStore = () => types.optional(ResponseStore, {} as TResponseStore);
