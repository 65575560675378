// @mui
import { Badge, Box, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
//
import { NavProps } from '../types';
import NavList from './NavList';

import { ReactComponent as IcoCam } from 'src/assets/images/ico-cam.svg';
import { ReactComponent as IcoPerson } from 'src/assets/images/ico-person.svg';
import { ReactComponent as IcoBell } from 'src/assets/images/ico-bell.svg';
import { ReactComponent as IcoPeople } from 'src/assets/images/ico-people.svg';
import { ReactComponent as IcoPeopleCheck } from 'src/assets/images/ico-people-check.svg';
import { ReactComponent as IcoHistory } from 'src/assets/images/ico-history.svg';
import { ReactComponent as IcoStatistics } from 'src/assets/images/ico-statistics.svg';
import { ReactComponent as IcoDashboard } from 'src/assets/images/ico-dashboard.svg';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

export default function NavDesktop({ isOffset, data, isAdmin }: NavProps) {
  const navigate = useNavigate();
  const { translate } = useLocales();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { themeMode } = useSettingsContext();

  const fill = {
    default: themeMode === 'dark' ? '#919EAB' : '#999999',
    active: themeMode === 'dark' ? '#FFFFFF' : '#000000',
  };

  return (
    <Stack component="nav" direction="row" spacing={5} sx={{}}>
      <Stack direction={'row'} spacing={8} sx={{ '& *': { transition: 'all 0.2s' } }}>
        {data
          .filter((r) => {
            if (isAdmin === true) {
              return r.isDesktop === true || r.isAdmin === isAdmin;
            } else {
              return r.isDesktop === true && r.isAdmin === isAdmin;
            }
          })
          .map((item: any, index: number) => {
            return (
              <Box
                key={`menu-${index}`}
                sx={{ textAlign: 'center', lineHeight: '1.2', cursor: 'pointer' }}
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <item.icon fill={pathname === item.path ? fill.active : fill.default} />
                <Typography
                  variant={'Kor_12_r'}
                  component={'p'}
                  sx={{ color: pathname === item.path ? fill.active : fill.default }}
                >
                  {/* {item.title} */}
                  {translate(`${item.title}.mainTitle`).toString()}
                </Typography>
              </Box>
            );
          })}
      </Stack>
    </Stack>
  );
}
