import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { User, createUser } from '../user/User';
import { createRoom } from '../room/Room';
import { MeetingMember } from '../meeting-member/MeetingMember';
import { Chat, createChat } from '../chat/Chat';

/**
 * # Conference
 *
 * Conference을 설명하세요.
 */
export const Conference = types
  .model('Conference')
  // --------------------------------------------------------------------------
  .props({
    start: types.optional(types.string, ''), // 회의개설시간
    end: types.optional(types.string, ''), // 회의종료시간
    title: types.optional(types.string, 'Untitled'), // 회의제목
    room: createRoom(), // 방객체
    // name: types.optional(types.string, ''), // 방이름
    // type: types.optional(types.string, ''), // 방타입
    meetingMembers: types.optional(types.array(MeetingMember), []), // 초대할 사람목록
    // inserted: types.optional(types.Date, 0),
    // updated: types.maybeNull(types.Date),
    // deleted: types.optional(types.boolean, false),
    id: types.optional(types.number, 0), // 방아이디
    // name: types.optional(types.string, ''), // 방이름
    // tag: types.maybeNull(types.string),
    comment: types.maybeNull(types.string), // 방코멘트
    // type: types.optional(types.string, ''), // 방타입
    // ispublic: types.optional(types.boolean, false),
    capacity: types.optional(types.number, 0), // 방수용인원
    // appointment: types.optional(types.boolean, false),
    // externalId: types.maybeNull(types.string),
    // externalType: types.maybeNull(types.string),
    // demoRoom: types.optional(types.boolean, false),
    // demoTime: types.maybeNull(types.string),
    // moderated: types.optional(types.boolean, false),
    // waitModerator: types.optional(types.boolean, false),
    // allowUserQuestions: types.optional(types.boolean, false),
    // audioOnly: types.optional(types.boolean, false),
    // closed: types.optional(types.boolean, false),
    // redirectURL: types.maybeNull(types.string),
    // ownerId: types.optional(types.number, 0),
    // waitRecording: types.optional(types.boolean, false),
    // allowRecording: types.optional(types.boolean, false),
    // chatModerated: types.optional(types.boolean, false),
    // hiddenElements: types.optional(types.array(), []),
    // chatOpened: types.optional(types.boolean, false),
    // filesOpened: types.optional(types.boolean, false),
    // moderators: types.maybeNull(types.string),
    // sipEnabled: types.optional(types.boolean, false),
    // confno: types.maybeNull(types.string),
    // pin: types.maybeNull(types.string),
    // groups: types.maybeNull(types.string),
    // files: types.maybeNull(types.string),
    // owner: ,

    // id: types.optional(types.string,''),//		방아이디
    name: types.optional(types.string, ''), //		방이름

    type: types.optional(types.string, ''), //		방타입

    ownerYn: types.maybeNull(types.optional(types.string, 'Y')), //		호스트 여부

    owner: createUser(),
    redirectURL: types.maybeNull(types.optional(types.string, '')),
    runningTime: types.maybeNull(types.optional(types.string, '')),
    // running_time: types.optional(types.string, ''), //회의시간

    conference_date: types.optional(types.string, ''), //일시

    chat: types.maybeNull(types.optional(types.array(Chat), [])), // 채팅
    startedTime: types.maybeNull(types.optional(types.string, '')), // 회의개설시간
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model conference --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IConference;
      (Object.keys(newProps) as TConferenceKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TConference = Instance<typeof Conference>;
type TConferenceSnapshot = SnapshotOut<typeof Conference>;

export interface IConference extends TConference {}
export type TConferenceKeys = keyof TConferenceSnapshot & string;
export interface IConferenceSnapshot extends TConferenceSnapshot {}
export const createConference = () => types.optional(Conference, {} as TConference);

export interface IConferFilter {
  ownerYn?: string;
  memberName?: string; // 환자별
  ownerName?: string; // 주관자별
  place?: string;
  agenda?: string;
  reportData?: string;
  keyword?: string;
  dateType?: string;
  start?: string;
  end?: string;
  page?: number;
  size?: number;
}

export type TConferFilter = IConferFilter;
