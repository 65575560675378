import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SystemInfo
 *
 * SystemInfo을 설명하세요.
 */
export const SystemInfo = types
  .model('SystemInfo')
  // --------------------------------------------------------------------------
  .props({
    rx: types.optional(types.string, ''), // 받은 트래픽 속도
    tx: types.optional(types.string, ''), // 보낸 트래픽 속도
    total: types.optional(types.string, ''), // 총 트래픽 속도
    avg: types.optional(types.string, ''), // 평균 트래픽속도
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model systemInfo --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISystemInfo;
      (Object.keys(newProps) as TSystemInfoKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSystemInfo = Instance<typeof SystemInfo>;
type TSystemInfoSnapshot = SnapshotOut<typeof SystemInfo>;

export interface ISystemInfo extends TSystemInfo {}
export type TSystemInfoKeys = keyof TSystemInfoSnapshot & string;
export interface ISystemInfoSnapshot extends TSystemInfoSnapshot {}
export const createSystemInfo = () => types.optional(SystemInfo, {} as TSystemInfo);
