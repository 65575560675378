import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

/**
 * # Code
 *
 * Code을 설명하세요.
 */
export const Code = types
  .model('Code')
  // --------------------------------------------------------------------------
  .props({
    name: types.optional(types.string,''),
    list: types.optional(types.array(createModelCode()), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model code --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICode;
      (Object.keys(newProps) as TCodeKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCode = Instance<typeof Code>;
type TCodeSnapshot = SnapshotOut<typeof Code>;

export interface ICode extends TCode {}
export type TCodeKeys = keyof TCodeSnapshot & string;
export interface ICodeSnapshot extends TCodeSnapshot {}
export const createCode = () => types.optional(Code, {} as TCode);
