import { RefObject, useCallback, useEffect, useState } from 'react';

export const useScrollable = (ref: RefObject<HTMLElement>, directions: 'x' | 'y' | 'xy' = 'x') => {
  const [[x, y], setPosition] = useState([0, 0]);

  const onDrag = useCallback(
    (event: PointerEvent) => {
      const el = ref.current;
      if (!el) {
        return;
      }
      // 마우스가 드래그 뙬때마다 하는 것은 좀 아닌것 같다.
      // 하지만 마우스 다운에 넣으면 클릭이벤트가 안먹힌다.
      el.setPointerCapture(event.pointerId);

      const { clientX, clientY } = event;
      const dirs = directions.split('');

      setPosition((prev) => {
        const [prevX, prevY] = prev;
        const deltaX = clientX - prevX;
        const deltaY = clientY - prevY;
        if (dirs.includes('x')) {
          el.scrollBy(-deltaX, 0);
        }

        if (dirs.includes('y')) {
          el.scrollBy(0, -deltaY);
        }

        return [clientX, clientY];
      });
    },
    [directions, ref],
  );

  const handlePointerMove = useCallback(
    (event: PointerEvent) => {
      onDrag(event);
    },
    [onDrag],
  );

  const handlePointerUp = useCallback(
    (event: PointerEvent) => {
      const el = ref.current;
      if (!el) {
        return;
      }
      el.releasePointerCapture(event.pointerId);

      el.removeEventListener('pointermove', handlePointerMove);
      el.removeEventListener('pointerup', handlePointerUp);
    },
    [handlePointerMove, ref],
  );

  const handlePointerDown = useCallback(
    (event: PointerEvent) => {
      const el = ref.current;
      if (!el) {
        return;
      }
      el.addEventListener('pointerup', handlePointerUp);
      el.addEventListener('pointermove', handlePointerMove);

      setPosition([event.clientX, event.clientY]);
    },
    [handlePointerMove, handlePointerUp, ref],
  );

  useEffect(() => {
    const el = ref.current;
    if (el) {
      el.addEventListener('pointerdown', handlePointerDown);
      return () => {
        el.removeEventListener('pointerdown', handlePointerDown);
      };
    }
  }, [handlePointerDown, handlePointerMove, handlePointerUp, ref]);
};
