import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Address
 *
 * Address을 설명하세요.
 */
export const Address = types
  .model('Address')
  // --------------------------------------------------------------------------
  .props({
    country: types.maybeNull(types.optional(types.string, 'KR')),
    email: types.maybeNull(types.optional(types.string, '')),

    // additionalname: types.optional(types.string, ''),
    // comment: types.optional(types.string, ''),
    // deleted: types.optional(types.string, ''),
    // fax: types.optional(types.string, ''),
    id: types.maybeNull(types.optional(types.number, 0)),
    // inserted: types.optional(types.string, ''),
    // phone: types.optional(types.string, ''),
    // street: types.optional(types.string, ''),
    // town: types.optional(types.string, ''),
    // updated: types.optional(types.string, ''),
    // zip: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model address --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAddress;
      (Object.keys(newProps) as TAddressKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAddress = Instance<typeof Address>;
type TAddressSnapshot = SnapshotOut<typeof Address>;

export interface IAddress extends TAddress {}
export type TAddressKeys = keyof TAddressSnapshot & string;
export interface IAddressSnapshot extends TAddressSnapshot {}
export const createAddress = () =>
  types.optional(Address, {
    country: 'KO',
  } as TAddress);
