import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../../models/root-store/root-store-context"
import { Button, Paper, Popover, Tab, Tabs, useTheme } from '@mui/material';
import AudioSetting from '../setting/AudioSetting';
import VideoSetting from '../setting/VideoSetting';
import { useEffect, useState } from 'react';
import { useLocales } from 'src/locales';
import { TabContext, TabPanel } from '@mui/lab';
import { bgBlur } from 'src/utils/cssStyles';

type Props = {
  audioInputs: { label: string; value: string }[];
  audioInput: string | null;
  audioOutputs: { label: string; value: string }[];
  audioOutput: string | null;
  aftervideostate: any | null;
  videoInputs: { label: string; value: string }[];
  videoInput: string | null;
  videoSize: string;
  anchorSet: HTMLButtonElement | null;
  view: string
  handleClose: any;
  returnAudioInputData: (
    audioInput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioOutputData: (
    audioOuput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioInputStream: (
    Inputstate: any | null
  ) => void;
  returnAudioOutputStream: (
    Outputstate: any | null
  ) => void;
  returnVideoInputData: (
    videoInput: string | null,
    value: any,
    label: any
  ) => void;
  returnVideoSizeData: (
    videoSize: string | null,
    value: any,
    label: any
  ) => void;
  videoMic?: any;
  videoVol?: any;
  toggleMic?: any;
  toggleVideo?: any;
  mobile?: any;
};
/**
 * ## TotalSetting 설명
 *
 */
export const TotalSetting = observer(({
  audioInputs,
  audioInput,
  audioOutput,
  audioOutputs,
  anchorSet,
  aftervideostate,
  videoInputs,
  videoInput,
  videoSize,
  returnAudioInputData,
  returnAudioOutputData,
  returnAudioInputStream,
  returnAudioOutputStream,
  returnVideoInputData,
  returnVideoSizeData,
  view,
  handleClose,
  videoMic,
  videoVol,
  toggleMic,
  toggleVideo,
  mobile
}: Props
) => {

  const theme = useTheme();
  const [mode, setMode] = useState('');
  const { translate } = useLocales();
  const openSet = Boolean(anchorSet);
  const [tabValue, setTabValue] = useState('');
  const onAudio = () => {
    setMode('AUDIO');
  }
  const onVideo = () => {
    setMode('VIDEO');
  }

  useEffect(() => {
    if (toggleVideo === true) {
      setMode('VIDEO');
    } else {
      setMode('AUDIO');
    }
    if(mobile===true){
      setTabValue('VIDEO');
    }else{
      setTabValue('AUDIO');
    }
  }, [toggleVideo, toggleMic])

  const ownerNavConfig = [
    {
      title: translate(`meeting.audio`).toString(),
      value: 'AUDIO',
      callback: onAudio,
      waitingYn: toggleMic
    },
    {
      title: translate(`meeting.video`).toString(),
      value: 'VIDEO',
      callback: onVideo,
      waitingYn: toggleVideo
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Box>
        <Popover
          anchorEl={anchorSet}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openSet}
          onClose={handleClose}
          style={{
            transform: 'translateY(-20px)',
          }}
        >

          <TabContext value={tabValue}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              sx={{ px: 2, pt: 1, borderBottom: '1px solid ' + theme.palette.divider }}
            >
              {ownerNavConfig.map((item: any, i: number) => {
                if (mobile === true && item.value === 'AUDIO') {
                  return null;
                }
                if (item.waitingYn === true) {
                  return (
                    <Tab
                      key={'meetingControlButtons-' + i}
                      id={'meetingControlButtons' + i}
                      onClick={() => {
                        item.callback();
                      }}
                      value={item.value}
                      label={item.title}
                      sx={{ mr: 0 }}
                    />
                  );
                }
              })}
            </Tabs>
            <TabPanel value={'AUDIO'} sx={{ height: '100%', p: 0, pb: 1 }}>
              <AudioSetting
                audioInputs={audioInputs}
                audioInput={audioInput}
                audioOutput={audioOutput}
                audioOutputs={audioOutputs}
                aftervideostate={aftervideostate}
                returnAudioInputData={returnAudioInputData}
                returnAudioOutputData={returnAudioOutputData}
                returnAudioInputStream={returnAudioInputStream}
                returnAudioOutputStream={returnAudioOutputStream}
                anchorSet={anchorSet}
                view={view}
                videoMic={videoMic}
                videoVol={videoVol}
              />
            </TabPanel>
            <TabPanel value={'VIDEO'} sx={{ height: '100%', p: 0, pb: 1 }}>
              <VideoSetting
                videoInputs={videoInputs}
                videoInput={videoInput}
                videoSize={videoSize}
                returnVideoInputData={returnVideoInputData}
                returnVideoSizeData={returnVideoSizeData}
                mobile={mobile}
              />
            </TabPanel>
          </TabContext>
        </Popover>
      </Box>
    </>
  );
});

export default TotalSetting;