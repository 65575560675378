import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Typography } from '@mui/material';
import moment from 'moment';

interface Props {
  start: any;
}

export const MTime = observer(({ start }: Props) => {
  const [hoursA, setHoursA] = useState('00');
  const [minA, setMinA] = useState('00');
  const [secA, setSecA] = useState('00');
  const [time, setTime] = useState('');

  useEffect(() => {
    setInterval(() => {
      if (start === null) {
        return;
      }
      setTime(
        moment
          .utc(moment.duration(moment().diff(moment(start))).asMilliseconds())
          .format('HH:mm:ss'),
      );
    }, 1000);
  }, [start]);

  return (
    <>
      <Typography variant={'caption'} color={'error'}>
        {/* {hoursA + ':' + minA + ':' + secA} */}
        {time}
      </Typography>
    </>
  );
});

export default MTime;
