import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # CodeData
 *
 * CodeData을 설명하세요.
 */
export const CodeData = types
  .model('CodeData')
  // --------------------------------------------------------------------------
  .props({
    cdNm: types.optional(types.string, ''),
    cdDispNm: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model codeData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    // setProps: (props: { [key: string]: any }) => {
    //   const newProps = { ...self, ...props } as ICodeData;
    //   (Object.keys(newProps) as TCodeDataKeys[]).forEach((key) => {
    //     // @ts-ignore
    //     self[key] = newProps[key];
    //   });
    // },
  }));

// --------------------------------------------------------------------------
type TCodeData = Instance<typeof CodeData>;
type TCodeDataSnapshot = SnapshotOut<typeof CodeData>;

export interface ICodeData extends TCodeData {}
export type TCodeDataKeys = keyof TCodeDataSnapshot & string;
export interface ICodeDataSnapshot extends TCodeDataSnapshot {}
export const createCodeData = () => types.optional(CodeData, {} as TCodeData);
