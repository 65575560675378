import { Card, Stack, Typography } from '@mui/material';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useLocales } from 'src/locales';
import { useStores } from 'src/models';

const SystemInfo = observer(() => {
  const rootStore = useStores();
  const { translate } = useLocales();
  const { dashBoardStore: store } = rootStore;
  const data = toJS(store.systemInfo);

  return (
    <>
      {/* <Card sx={{ p: 2 }}> */}
      <Stack direction={'row'} gap={2} sx={{ textAlign: 'center' }}>
        <Card sx={{ p: 2, flex: 1 }}>
          <Typography variant="subtitle1">
            {translate(`dashBoard.systemUsage.incoming`).toString()}
          </Typography>
          <Typography variant="h3">{data.rx}</Typography>
        </Card>
        <Card sx={{ p: 2, flex: 1 }}>
          <Typography variant="subtitle1">
            {translate(`dashBoard.systemUsage.outgoing`).toString()}
          </Typography>
          <Typography variant="h3">{data.tx}</Typography>
        </Card>
        <Card sx={{ p: 2, flex: 1 }}>
          <Typography variant="subtitle1">
            {translate(`dashBoard.systemUsage.total`).toString()}
          </Typography>
          <Typography variant="h3">{data.total}</Typography>
        </Card>
        <Card sx={{ p: 2, flex: 1 }}>
          <Typography variant="subtitle1">
            {translate(`dashBoard.systemUsage.average`).toString()}
          </Typography>
          <Typography variant="h3">{data.avg}</Typography>
        </Card>
      </Stack>
      {/* </Card> */}
    </>
  );
});

export default SystemInfo;
