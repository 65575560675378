import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Auth
 *
 * Auth을 설명하세요.
 */
export const Auth = types
  .model('Auth')
  // --------------------------------------------------------------------------
  .props({
    authCd: types.maybeNull(types.optional(types.string, '')), //"유저구분 ( 권한 ) 	"
    authNm: types.maybeNull(types.optional(types.string, '')), //유저구분 ( 권한 ) 값
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model auth --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IAuth;
      (Object.keys(newProps) as TAuthKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TAuth = Instance<typeof Auth>;
type TAuthSnapshot = SnapshotOut<typeof Auth>;

export interface IAuth extends TAuth {}
export type TAuthKeys = keyof TAuthSnapshot & string;
export interface IAuthSnapshot extends TAuthSnapshot {}
export const createAuth = () => types.optional(Auth, {} as TAuth);
