import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # Whiteboard
 *
 * Whiteboard을 설명하세요.
 */
export const Whiteboard = types
  .model("Whiteboard")
  // --------------------------------------------------------------------------
  .props({
    whiteBoardSid: types.maybeNull(types.number),
    roomId: types.maybeNull(types.number),
    whiteBoardSeq: types.maybeNull(types.number),
    whiteBoardData: types.maybeNull(types.string),
    useYn: types.maybeNull(types.string),
    regDt: types.maybeNull(types.string),
    regSid: types.maybeNull(types.number),
    updDt: types.maybeNull(types.string),
    updSid: types.maybeNull(types.number)
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model whiteboard --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IWhiteboard;
      (Object.keys(newProps) as TWhiteboardKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TWhiteboard = Instance<typeof Whiteboard>;
type TWhiteboardSnapshot = SnapshotOut<typeof Whiteboard>

export interface IWhiteboard extends TWhiteboard { }
export type TWhiteboardKeys = keyof TWhiteboardSnapshot & string;
export interface IWhiteboardSnapshot extends TWhiteboardSnapshot { }
export const createWhiteboard = () =>
  types.optional(Whiteboard, {

  } as TWhiteboard);
