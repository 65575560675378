import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import {
  Button,
  Container,
  Grid,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CHeader from 'src/components/CHeader';
import { useForm } from 'react-hook-form';
import { IConferenceSnapshot } from 'src/models/conference/Conference';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { HEADER } from 'src/config-global';
import { ISentPushAlarmSnapshot } from 'src/models';
import { useLocales } from 'src/locales';

interface Props {
  pending: boolean;
  reSend: (data: ISentPushAlarmSnapshot) => void;
  headerOptions: any;
}

/**
 * ## MyConferenceM 설명
 *
 */

export const MyParticipantM = observer(({ headerOptions, pending, reSend }: Props) => {
  const rootStore = useStores();
  const { pushAlarmStore: store, loadingStore } = rootStore;
  const theme = useTheme();
  const { translate } = useLocales();
  const methods = useForm<any>({});
  const { formState } = methods;

  /**
   * infinite scroll 구현 필요
   * start, end 로 가저오는 방식에서 paging 으로 변환 필요
   */
  // const addPage = async (): Promise<any> => {
  //   switch (contentIndex.current) {
  //     case 0:
  //       return new Promise((resolve, reject) => {
  //         if (!contentStore.pagination.last) {
  //           contentStore.pagination.setProps({
  //             page: contentStore.pagination.page + 1,
  //           });
  //           resolve(contentStore.pagination.page);
  //         } else {
  //           reject('last page'); // new Error('last page')
  //         }
  //       });

  //       break;
  //     case 1:
  //       return new Promise((resolve, reject) => {
  //         if (!mbtiStore.pagination.last) {
  //           mbtiStore.pagination.setProps({
  //             page: mbtiStore.pagination.page + 1,
  //           });
  //           resolve(mbtiStore.pagination.page);
  //         } else {
  //           reject('last page');
  //         }
  //       });

  //       break;
  //     case 2:
  //       break;
  //   }
  // };

  // const { scrollYProgress, scrollY } = useScroll();
  // useEffect(() => {
  //   scrollYProgress.on('change', (v) => {
  //     if (v > 0.8) {
  //       if (!loadingStore.loading) {
  //         addPage()
  //           .then(() => {
  //             getContents();
  //           })
  //           .catch((e) => { });
  //       }
  //     }
  //   });
  //   return () => {
  //     contentStore.pagination.setProps({ page: 1 });
  //     mbtiStore.pagination.setProps({ page: 1 });
  //   };
  // }, [console, contentStore, mbtiStore, scrollYProgress]);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Stack spacing={2} sx={{ flex: 1 }}>
          <CHeader {...headerOptions} />

          <Stack
            direction="column"
            spacing={2}
            sx={{
              p: 0,
              m: '0 !important',
              pb: {
                xs: HEADER.H_MOBILE + 'px',
                md: HEADER.H_MAIN_DESKTOP + 'px',
              },
            }}
          >
            {pending && (
              <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                <Trans i18nKey={'loading'} />
              </Typography>
            )}
            {!pending && store.sentPushAlarms.length < 1 ? (
              <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                <Trans i18nKey={'error.nodata'} />
              </Typography>
            ) : (
              <List sx={{ p: 0 }}>
                {store.sentPushAlarms.map((item: ISentPushAlarmSnapshot, i: number) => {
                  return (
                    <ListItem
                      key={'conference-item-' + i}
                      sx={{ p: 0, borderBottom: '1px solid #EEEEEE' }}
                    >
                      <Grid
                        container
                        spacing={0}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                          width: '100%',
                          mt: 0,
                          py: 1,
                          '&:last-child': {
                            border: 'none',
                          },
                          '& .MuiPaper-root': {
                            background: 'none',
                          },
                        }}
                      >
                        <Grid item sm={2} xs={2} sx={{}}>
                          <Typography
                            sx={{
                              color: theme.palette.text.secondary,
                              lineHeight: 1,
                              fontWeight: 400,
                              fontSize: '0.75rem',
                            }}
                          >
                            {moment(item.sendDt).format('MM-DD (dd)')}
                            <br />
                            {moment(item.sendDt).format('HH:mm:ss')}
                          </Typography>
                        </Grid>
                        <Grid item sm={8} xs={8} sx={{ lineHeight: 1 }}>
                          <Typography
                            variant={'body1'}
                            sx={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textAlign: 'left',
                            }}
                          >
                            {item.displayName}
                          </Typography>
                          <Typography
                            sx={{
                              color: theme.palette.text.secondary,
                              lineHeight: 1,
                              fontWeight: 400,
                              fontSize: '0.75rem',
                            }}
                          >
                            {translate('participantAlertHistory.title').toString()} : {item.title}
                          </Typography>
                        </Grid>

                        <Grid item sm={2} xs={2} sx={{ textAlign: 'right' }}>
                          {!item.isRead ? (
                            <Button
                              variant={'soft'}
                              color={'primary'}
                              size={'small'}
                              sx={{ p: 1, minWidth: 50 }}
                              onClick={() => {
                                reSend(item);
                              }}
                            >
                              {/* <Trans i18nKey={'재전송'} /> */}
                              {translate('participantAlertHistory.unread').toString()}
                            </Button>
                          ) : (
                            <Button
                              disabled
                              variant={'soft'}
                              color={'primary'}
                              size={'small'}
                              sx={{ p: 1, minWidth: 50 }}
                              // onClick={() => {
                              //   reSend(item);
                              // }}
                            >
                              {/* <Trans i18nKey={'읽음'} /> */}
                              {translate('participantAlertHistory.read').toString()}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Stack>
        </Stack>
      </Container>
    </>
  );
});

export default MyParticipantM;
