import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { Typography, Stack, Box } from '@mui/material';
// components
import { IChatSnapshot } from 'src/models';
import Parser from 'html-react-parser';
import { getIconByAuthCd } from 'src/utils/common';
// ----------------------------------------------------------------------

const CURRENT_USER_ID = 3; //sessionStorage.getItem('myCUID');

type Props = {
  // message: IChatMessage;
  message: IChatSnapshot;

  onOpenLightbox?: (value: string) => void;
};

export default function ChatMessageItem({ message, onOpenLightbox }: Props) {
  // const sender = conversation.participants.find(
  //   (participant) => participant.id === message.senderId
  // );

  const sender = message.from;
  const senderDetails =
    sender?.id === CURRENT_USER_ID
      ? { type: 'me', avatar: getIconByAuthCd('') }
      : {
          avatar: sender?.authCd ? getIconByAuthCd(sender.authCd) : getIconByAuthCd(''),
          name: sender?.name,
        };

  // const currentUser = senderDetails.type === 'me';

  // const isImage = message.contentType === 'image';

  // const firstName = sender?.firstName; //senderDetails.name && senderDetails.name.split(' ')[0];

  return (
    <Stack direction="row" justifyContent={message.self ? 'flex-end' : 'unset'} sx={{ mb: 3 }}>
      {!message.self && (
        // <Avatar
        //   // alt={senderDetails.name}
        //   // src={senderDetails.avatar}
        //   src={'src/assets/icons/ico-user-doctor.svg'}
        //   sx={{ width: 32, height: 32, mr: 2 }}
        // />

        <Box sx={{ width: 32, height: 32, mr: 2 }}>
          <senderDetails.avatar />
        </Box>
      )}

      <Stack spacing={1} alignItems={message.self ? 'flex-end' : 'flex-start'}>
        <Typography
          noWrap
          variant="caption"
          sx={{
            color: 'text.disabled',
            ...(!message.self && {
              mr: 'auto',
            }),
          }}
        >
          {/* {!currentUser && `${message.from?.name},`} &nbsp; */}
          {!message.self && `${message.from?.displayName},`} &nbsp;
          {/* {!currentUser && `${firstName},`} &nbsp; */}
          {/* {formatDistanceToNowStrict(new Date(message.sent), {
            addSuffix: true,
          })} */}
          {message.sent}
        </Typography>

        <Stack
          sx={{
            p: 1.5,
            minWidth: 48,
            maxWidth: 320,
            borderRadius: 1,
            overflow: 'hidden',
            typography: 'body2',
            textAlign: 'left',
            bgcolor: 'background.neutral',
            ...(message.self && {
              color: 'grey.800',
              bgcolor: 'primary.lighter',
            }),
            // ...(isImage && {
            //   p: 0,
            // }),
          }}
        >
          {/* {isImage ? (
            // <Image
            //   alt="attachment"
            //   src={message.body}
            //   onClick={() => onOpenLightbox(message.body)}
            //   sx={{
            //     cursor: 'pointer',
            //     '&:hover': {
            //       opacity: 0.9,
            //     },
            //   }}
            // />
            <></>
          ) : ( */}
          {/* message.body */}
          <Typography width={'100%'} sx={{ wordBreak: 'break-word' }}>
            {Parser(decodeURIComponent(message.message || ''))}
          </Typography>
          {/* )} */}
        </Stack>
      </Stack>
    </Stack>
  );
}
