import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import {
  Avatar,
  Button,
  Dialog,
  Divider,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  List,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as IcoClose } from 'src/assets/icons/ico-close.svg';
import _ from 'lodash';
import Scrollbar from 'src/components/scrollbar';
import CFileUpload from 'src/components/CFileUpload';
import { DropEvent, FileRejection } from 'react-dropzone';
import axios from 'axios';
import FileItem from './file-item/FileItem';
import { useLocales } from 'src/locales';
import { useLocation } from 'react-router';

/**
 * ## Fileshare 설명
 *
 */
interface Props {
  handleClose: VoidFunction;
  ChangeHandleClose?: VoidFunction;
  path?: string;
  ownerYn: string;
  shareFilesRoomId?: number;
  rowData?: any;
}

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

export const Fileshare = observer(
  ({ handleClose, path, ownerYn, shareFilesRoomId, rowData, ChangeHandleClose }: Props) => {
    const rootStore = useStores();
    const { conferenceStore: store, responseStore, errorAlertStore } = rootStore;
    const theme = useTheme();
    const { REACT_APP_API_URL } = process.env;
    const { state } = useLocation();
    const token = localStorage.getItem('accessToken');
    const { translate } = useLocales();
    const scrollRef = useRef<HTMLDivElement>(null);

    const [progress, setProgress] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [deleteId, setDeleteId] = useState(0);
    const [deleteYn, setDeleteYn] = useState(false);
    const [deleteName, setDeleteName] = useState('');

    const onUploadProgress = (progressEvent: any) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setProgress(percent);
      if (percent >= 100) {
        setShowProgress(false);
      }
    };

    const handleDropFile = async <T extends File>(
      acceptedFiles: T[],
      fileRejections: FileRejection[],
      event: DropEvent,
    ) => {
      setProgress(0);
      setUploadMessage('');
      setShowProgress(true);
      const body = new FormData();
      acceptedFiles.map((file) => {
        body.append('files', file);
      });
      if (path === 'grid') {
        body.append('roomId', JSON.stringify(shareFilesRoomId));
      } else {
        body.append('roomId', JSON.stringify(state.room.id));
        body.append('cuid', JSON.stringify(sessionStorage.getItem('myFirstCUID')));
      }
      await axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/web/file/upload`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: body,
        onUploadProgress,
      })
        .then((r) => {
          if (r.data.error) {
            responseStore.setResponseInfo(r.data);
            errorAlertStore.setErrorAlertFromResponse();
          } else {
            store.get(store.conference.id);
          }
        })
        .catch((e) => {
          setUploadMessage('Upload Failed : ' + e.message);
          setTimeout(() => {
            setUploadMessage('');
          }, 3000);
          console.log('🌈 ~ e:', e);
        });
    };

    const onWhetherDelete = async (id: number, name: string) => {
      setDeleteId(id);
      setDeleteName(name);
      setDeleteYn(true);
    };

    const onDelete = async () => {
      let data = {};
      if (path === 'grid') {
        data = { fileIdList: [deleteId] };
      } else {
        data = { fileIdList: [deleteId], cuid: sessionStorage.getItem('myFirstCUID') };
      }
      await axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/web/file/delete`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        // responseType: 'blob',
        data: data,
      })
        .then((res) => {
          if (res.data.error) {
            responseStore.setResponseInfo(res.data);
            errorAlertStore.setErrorAlertFromResponse();
          } else {
            path === 'grid' && store.removeFile(deleteId);
            if (store.conference.room.shareFiles?.length === 0) {
              if (rowData.room.shareFiles.length === store.conference.room.shareFiles?.length) {
                handleClose();
              } else {
                ChangeHandleClose && ChangeHandleClose();
              }
            }
          }
        })
        .catch((e) => {
          console.log('🌈 ~ onDelete ~ e:', e);
        });
    };

    useEffect(() => {
      if (path !== 'grid') {
        store.get(store.conference.id);
      } else {
        if (rowData) {
          store.get(rowData.id);
        }
      }
    }, []);

    const itemProps = {
      ownerYn: ownerYn,
      onWhetherDelete: onWhetherDelete,
    };

    return (
      <>
        <Stack direction={'row'} justifyContent={'center'} sx={{ position: 'relative', p: 2 }}>
          <Typography variant={'subtitle1'} color={theme.palette.text.secondary}>
            {path === 'grid'
              ? translate(`conference.table.shareFile`).toString()
              : translate(`meeting.fileShare`).toString()}
          </Typography>
          <IconButton
            onClick={() => {
              if (path === 'grid') {
                if (rowData.room.shareFiles.length === store.conference.room.shareFiles?.length) {
                  handleClose();
                } else {
                  if (rowData.room.shareFiles.length === 0) {
                    ChangeHandleClose && ChangeHandleClose();
                  } else {
                    handleClose();
                  }
                }
              } else {
                handleClose();
              }
            }}
            sx={{ position: 'absolute', top: 8, right: 8 }}
            color={'inherit'}
          >
            <IcoClose stroke={'#637381'} />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar
          scrollableNodeProps={{
            ref: scrollRef,
          }}
          sx={{ height: 1 }}
        >
          <List dense={true}>
            {store.conference.room.shareFiles?.map((item, i) => {
              return (
                <Fragment key={'file-list-' + i}>
                  {localStorage.getItem('user') && <FileItem {...{ ...itemProps, data: item }} />}
                  {path === 'grid' ? (
                    i + 1 !== store.conference.room.shareFiles?.length && <Divider />
                  ) : (
                    <Divider />
                  )}
                </Fragment>
              );
            })}
            {/* {store.conference.room.shareFiles?.map((item, i) => (
            <Fragment key={'file-list-' + i}>
              <FileItem {...{ ...itemProps, data: item }} />
              {path === 'grid' ? (
                i + 1 !== store.conference.room.shareFiles?.length && <Divider />
              ) : (
                <Divider />
              )}
            </Fragment>
          ))} */}
          </List>
        </Scrollbar>
        {/* {path !== 'grid' && ( */}
        <Stack direction={'column'} sx={{ width: '100%', p: 2 }}>
          {showProgress && <LinearProgressWithLabel value={progress} />}
          {uploadMessage !== '' && (
            <Typography variant={'Eng_12_r'} color={'error'} sx={{ textAlign: 'left', p: 1 }}>
              {uploadMessage}
            </Typography>
          )}
          <CFileUpload files={[]} showPreview={false} onDrop={handleDropFile} />
        </Stack>
        {/* )} */}
        {deleteYn && (
          <Dialog
            open={deleteYn}
            maxWidth={'md'}
            PaperProps={{
              sx: {
                borderRadius: 4,
                p: 4,
              },
            }}
            onClose={(e, reason) => {
              setDeleteYn(false);
            }}
          >
            <Typography variant="subtitle1">
              {deleteName}
              <br />
              {translate(`meeting.alert.fileDelete`).toString()}
            </Typography>
            <Stack
              spacing={2}
              direction={'row'}
              alignItems={'center'}
              sx={{ justifyContent: 'center', mt: 2 }}
            >
              <Button
                variant={'soft'}
                onClick={() => {
                  setDeleteYn(false);
                }}
              >
                {translate(`meeting.n`).toString()}
              </Button>
              <Button
                variant={'contained'}
                onClick={() => {
                  onDelete();
                  setDeleteYn(false);
                }}
              >
                {translate(`meeting.y`).toString()}
              </Button>
            </Stack>
          </Dialog>
        )}
      </>
    );
  },
);

export default Fileshare;
