// routes
import { Api } from 'src/services/api';
import { PATH_AUTH } from '../routes/paths';
// utils
import axios from '../utils/axios';
import { ApiConfig } from '../services/api/api-config';
import moment from 'moment';

// ----------------------------------------------------------------------

export const jwtDecode = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

// ----------------------------------------------------------------------

export const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server

    axios.defaults.headers.Authorization = `Bearer ${accessToken}`;
  } else {
    axios.defaults.headers.Authorization = null;
    axios.defaults.headers.common.Authorization = null;
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export const setRefreshSession = (token: string | null) => {
  if (token) {
    localStorage.setItem('refreshToken', token);
  }
};
