import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Code
 *
 * Code을 설명하세요.
 */
export const CodeItemModel = types
  .model('CodeItemModel')
  // --------------------------------------------------------------------------
  .props({
    code: types.identifierNumber,
    value: types.string,
    pcode: types.number,
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model code --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICodeItemModel;
      (Object.keys(newProps) as TCodeItemModelKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCodeItemModel = Instance<typeof CodeItemModel>;
type TCodeItemModelSnapshot = SnapshotOut<typeof CodeItemModel>;

export interface ICodeItemModel extends TCodeItemModel {}
export type TCodeItemModelKeys = keyof TCodeItemModelSnapshot & string;
export interface ICodeItemModelSnapshot extends TCodeItemModelSnapshot {}
