import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const Page403 = Loadable(lazy(() => import('../screens/common/Page403')));
export const Page404 = Loadable(lazy(() => import('../screens/common/Page404')));
export const Page500 = Loadable(lazy(() => import('../screens/common/Page500')));
export const ComingSoon = Loadable(lazy(() => import('../screens/common/ComingSoon')));
export const Maintenance = Loadable(lazy(() => import('../screens/common/Maintenance')));

export const Login = Loadable(lazy(() => import('../screens/user/login/Login')));
export const LoginUnprotected = Loadable(
  lazy(() => import('../screens/user/login/LoginUnprotected')),
);
export const MyAlarm = Loadable(lazy(() => import('../screens/mypage/my-alarm/MyAlarm')));

export const Terms = Loadable(lazy(() => import('../screens/common/terms/Terms')));
export const Privacy = Loadable(lazy(() => import('../screens/common/privacy/Privacy')));
export const Collect = Loadable(lazy(() => import('../screens/common/collect/Collect')));
