import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Link, BoxProps, Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';
import { ReactComponent as LogoSvg } from 'src/assets/images/sipaeon.svg';
// import { ReactComponent as LogoSvg } from 'src/assets/images/logo.svg';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  width?: number;
  height?: number;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, width = 80, height = 40, ...other }, ref) => {
    const theme = useTheme();

    if (disabledLink) {
      return (
        <Box sx={{ ...sx }}>
          <LogoSvg width={width} />
        </Box>
      );
    }

    return (
      <Box sx={{ ...sx }}>
        <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
          <LogoSvg width={width} height={height} />
        </Link>
      </Box>
    );
  },
);

export default Logo;
