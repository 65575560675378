import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import {
  Button,
  Card,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { selectOptions } from 'src/components/forms/CSelect';
import Participant from './participant/Participant';
import { WebRtcPeer } from 'kurento-utils';
import FormProvider from 'src/components/hook-form/FormProvider';
import { useForm } from 'react-hook-form';
import {
  createParticipant,
  IParticipant,
  IParticipantSnapshot,
} from '../../models/participant/Participant';
import { IUserSnapshot } from 'src/models/user/User';
import { toJS } from 'mobx';

/**
 * ## Home 설명
 *
 */

let ws: any;
export const Home = observer(() => {
  const { REACT_APP_WS_URL, REACT_APP_WS_PING } = process.env;
  const rootStore = useStores();
  const { participantStore, loadingStore } = rootStore;
  const theme = useTheme();

  const defaultValues = {
    userId: 4,
    roomId: 2,
  };

  let users: selectOptions[] = [
    {
      code: 2,
      value: 'jeongsik yoo',
    },
    {
      code: 3,
      value: 'sik yoo',
    },
    {
      code: 4,
      value: 'test yoo',
    },
  ];

  const methods = useForm<any>({
    defaultValues,
  });
  const { register, getValues, setValue } = methods;

  let myUid;

  const websocketUrl = `${REACT_APP_WS_URL}?accessToken=` + localStorage.getItem('accessToken');

  //(location.href.startsWith("https") ? 'wss://' : 'ws://') + location.host + '/web/groupcall';
  let websocketTimer: any;
  let participants: any = {};
  let existingParticipants: any[] = [];
  let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  );

  const [settings, setSettings] = useState({
    video: false,
    audio: true,
  });
  console.log('🌈 ~ setting ~ setting:', settings);

  useEffect(() => {
    const check = setInterval(function () {
      sendMessage({ type: 'ping' });

      fetch(`${REACT_APP_WS_PING}`, { cache: 'no-store' });
    }, 30000);
    websocketCheck();

    // document.addEventListener("visibilitychange", function () {
    //   if (document.visibilityState == "visible" && isMobile == true) {
    //     const myStream = JSON.parse(sessionStorage.getItem("myStream") || '');
    //     if (myStream && participants[myStream.uid] == null) {
    //       reInit();
    //     }
    //   }
    // });

    return () => {
      clearInterval(check);
      if (ws !== null) {
        console.log('소켓 클로즈');
        ws.close();
      }
    };
  }, []);

  const [openModal, setOpenModal] = useState(false);

  const reInit = () => {
    // setOpenModal(true)
    sessionStorage.setItem('reInitData', JSON.stringify(getValues()));
    // window.location.reload();
  };

  const websocketCheck = () => {
    clearTimeout(websocketTimer);
    if (ws == undefined || ws.readyState == ws.CLOSED) {
      // if (ws == undefined) {
      websocketInit();
    }
    websocketTimer = setTimeout(websocketCheck, 1000 * 10); //10초 체크
  };

  const websocketInit = () => {
    const flag = ws !== undefined ? true : false;

    existingParticipants = [];

    ws = new WebSocket(websocketUrl);
    console.log('마이스트림 삭제');
    sessionStorage.removeItem('myStream');
    sessionStorage.setItem('myUID', '');
    sessionStorage.setItem('myCUID', '');

    ws.onopen = function () {
      console.log('websocket open');

      if (sessionStorage.getItem('reInitData') !== null) {
        console.log('sessionStorage.getItem("reInitData")', sessionStorage.getItem('reInitData'));

        const info = JSON.parse(sessionStorage.getItem('reInitData') || '');
        setValue('userId', info.userId);
        setValue('roomId', info.roomId);
        console.log('reInitData', info);
        sessionStorage.removeItem('reInitData');
        enterRoom();
      }
      if (flag) {
        reInit();
      }
    };

    ws.onmessage = (message: any) => {
      // console.info('Received message: ' + message.data);
      let participant: IParticipant;
      if (isJsonString(message.data)) {
        const parsedMessage = JSON.parse(message.data);
        // console.log('parsedMessage ===>', parsedMessage);
        // console.log('parsedMessage.id', parsedMessage.id)
        switch (parsedMessage.id) {
          case 'joinRoomSuccess':
            console.log('🌈 ~ joinRoomSuccess:', parsedMessage);

            sessionStorage.setItem('myCUID', parsedMessage.client.cuid);
            console.log('====================================');
            console.log('myCUID', parsedMessage.client.cuid);
            console.log('🌈 ~ existing ~ :', parsedMessage.existingParticipants);
            console.log('====================================');

            sendMySettings();

            let newClient = {
              type: 'addClient',
              cuid: parsedMessage.client.cuid,
            };

            sendMessage(newClient);
            console.log('parsedMessage.existingParticipants', parsedMessage.existingParticipants);

            parsedMessage.existingParticipants.map((p: any, i: number) => {
              if (p.streams.length > 0) {
                const stream = p.streams[0];
                console.log('🌈 ~ parsedMessage.existingParticipants.map ~ stream:', stream);
                existingParticipants.push({
                  id: stream.uid,
                  uid: stream.uid,
                  cuid: stream.cuid,
                  stream: stream,
                  recive: true,
                } as IParticipant);
              }
            });

            break;
          case 'broadcast':
            onBroadcast(parsedMessage);
            break;
          case 'broadcastStopped':
            onBroadcastStopped(parsedMessage);
            break;
          case 'newStream':
            console.log('newStream uid ', parsedMessage.stream.uid);
            // console.log('newStream cuid ', parsedMessage.stream.cuid)
            console.log('newStream stream', parsedMessage.stream);
            participantStore.addParticipant({
              id: parsedMessage.stream.uid,
              uid: parsedMessage.stream.uid,
              cuid: parsedMessage.stream.cuid,
              stream: parsedMessage.stream,
              recive: true,
            } as IParticipant);
            break;
          case 'newClient':
            // console.log('신규 접속 uid ', parsedMessage.client.uid)
            // console.log('신규 접속 cuid ', parsedMessage.client.cuid)
            // console.log('신규 접속 client ', parsedMessage.client)
            // participant = {
            //   id: parsedMessage.client.id,
            //   uid: parsedMessage.client.uid,
            //   cuid: parsedMessage.client.cuid,
            //   stream: parsedMessage.client,
            // recive: true
            // } as IParticipant;
            // participantStore.addParticipant(participant)

            break;
          case 'videoResponse':
            console.log('🌈 ~ videoResponse :', parsedMessage);
            receiveVideoResponse(parsedMessage);

            break;
          case 'clientLeave':
            onParticipantLeft(parsedMessage);

            break;
          case 'iceCandidate':
            console.log(
              '🌈 ~ iceCandidate ~ parsedMessage.uid:',
              parsedMessage.uid,
              parsedMessage.candidate,
            );
            participantStore.updateParticipant({
              uid: parsedMessage.uid,
              candidate: parsedMessage.candidate,
            } as IParticipant);

            break;
          default:
            console.error('Unrecognized message', parsedMessage);
        }
      }
    };

    ws.onclose = function () {
      console.log('websocket close');
    };
  };

  const onBroadcastStopped = (message: any) => {
    console.log('🌈 ~ onBroadcastStopped ~ message:', message);
    participantStore.broadcastStopped(message.uid);
    // onParticipantLeft(message);
  };

  //시그널링 서버에서 온 메세지가 JSON 문자열인지 확인
  const isJsonString = (str: any) => {
    try {
      const json = JSON.parse(str);
      return typeof json === 'object';
    } catch (e) {
      return false;
    }
  };

  const sendMySettings = () => {
    var message = {
      type: 'av',
      area: 'room',
      settings: {
        chat: {
          muted: true,
          sendOn: 'ctrl',
        },
        video: {
          cam: 0,
          mic: 0,
          width: 640,
          height: 480,
          camDevice: '003b465643d320af4580ab5f545a45e681f5fa028ada302dc2a36cb73a439e24',
          micDevice: 'default',
          confirmMuteOthers: true,
        },
        audio: {
          // echoCancellation: true,
          echoCancellation: {exact: true},
        },
        fixed: {
          enabled: true,
          width: 640,
          height: 480,
        },
        activity: {
          clean: true,
        },
      },
    };
    sendMessage(message);
  };

  const enterRoom = () => {
    if (!ws || ws.readyState === ws.CLOSED) {
      websocketInit();
    }
    const message = {
      type: 'joinRoom',
      roomId: getValues('roomId'),
      userId: getValues('userId'),
    };
    sendMessage(message);
  };

  const leaveRoom = () => {
    const myUID = sessionStorage.getItem('myUID') || '';
    const myCUID = sessionStorage.getItem('myCUID') || '';
    participantStore.participants.map((participant: IParticipant) => {
      participantStore.updateParticipant({
        ...participant,
        isLive: false,
      });
    });
    if (myCUID !== '') {
      sessionStorage.removeItem('reInitData');
      sendMessage({
        id: 'leaveRoom',
      });
      console.log('퇴장 UID : ', myUID);
      console.log('퇴장 CUID : ', myCUID);
    }
    if (ws) {
      ws.close();
    }
    // sessionStorage.clear();
  };

  const onNewParticipant = (request: any) => {
    console.log('🌈 ~ onNewParticipant ~ request:', request);
    participantStore.addParticipant(request as IParticipant);
  };

  const receiveVideoResponse = (result: any) => {
    participantStore.updateParticipantAnswer({
      uid: result.uid,
      sdpAnswer: result.sdpAnswer,
    } as IParticipant);
  };

  const onBroadcast = (msg: any) => {
    const constraints = {
      audio: true,
      video: {
        mandatory: {
          maxWidth: 1280,
          maxFrameRate: 30,
          minFrameRate: 15,
        },
      },
    };
    console.log('입장 : ', getValues('userId') + ' registered in room ' + getValues('roomId'));
    console.log('myUID : ', msg.stream.uid);
    console.log('mySessionID : ', msg.stream.sessionId);

    sessionStorage.setItem('myUID', msg.stream.uid);
    sessionStorage.setItem('mySessionID', msg.stream.sessionId);
    const participant: IParticipant = {
      id: msg.stream.uid,
      uid: msg.stream.uid,
      cuid: msg.stream.cuid,
      stream: msg.stream,
      // existings: existingParticipants,
    } as IParticipant;
    participantStore.addParticipant(participant);
  };

  const onParticipantLeft = (request: any) => {
    console.log('참여자 퇴장 ' + request.uid, request);
    participantStore.removeParticipant(request.uid);
  };

  const onConnectionstatechange = (peerConnection: RTCPeerConnection) => {
    peerConnection.onconnectionstatechange = (evt: any) => {
      console.log('커넥션 상태 :: ' + peerConnection.connectionState);
      switch (peerConnection.connectionState) {
        case 'connected':
          // console.log("Connection to Media server has been established");
          break;
        case 'disconnected':
        case 'failed':
          console.error(`Media server connection for user will try to re-connect`);
          break;
        default:
          // console.info('peerConnection.connectionState', peerConnection.connectionState)
          break;
      }
    };
  };

  const toggleActivity = (obj: any, toggleType: any) => {
    // if ($(obj).val().indexOf("OFF") > -1) {
    //   $(obj).val($(obj).val().replaceAll("OFF", "ON"));
    // } else {
    //   $(obj).val($(obj).val().replaceAll("ON", "OFF"));
    // }
    if (toggleType == null) {
      toggleType = 'VIDEO';
    }
    const msg = { type: 'kurento', id: 'toggleActivity', activity: toggleType };
    sendMessage(msg);
  };

  const sendMessage = (message: any) => {
    const jsonMessage = JSON.stringify(message);

    console.log('🌈 ~ onSendMessage ~ ws:', ws);

    // console.log('Sending message: ' + jsonMessage);
    if (ws && ws.readyState === 1) {
      ws.send(jsonMessage);
    } else {
      console.log('센드 실패..', message);
      console.log('센드 실패..', ws.readyState);
    }
  };

  return (
    <>
      {participantStore.participants.length < 1 ? (
        <Container sx={{ pt: 10, pb: 4 }}>
          <FormProvider methods={methods}>
            <Card sx={{ maxWidth: 450, p: 2 }}>
              <Stack sx={{ flexDirection: 'row', alignItems: 'center' }}>
                <FormControl sx={{ m: 1, minWidth: 180 }}>
                  <InputLabel>계정</InputLabel>
                  <Select
                    label={'계정'}
                    // name={'userId'}
                    variant={'outlined'}
                    defaultValue={defaultValues.userId}
                    fullWidth
                    {...register('userId')}
                  >
                    <MenuItem disabled value="0">
                      <span color="#999999">선택하세요</span>
                    </MenuItem>
                    {users.map((row: any, i: number) => {
                      return (
                        <MenuItem key={`user-${i + 1}`} value={row.code}>
                          {row.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel>방번호</InputLabel>
                  <Select
                    label={'방번호'}
                    // name={'roomId'}
                    variant={'outlined'}
                    defaultValue={defaultValues.roomId}
                    fullWidth
                    {...register('roomId')}
                  >
                    <MenuItem disabled value="0">
                      <span color="#999999">선택하세요</span>
                    </MenuItem>
                    <MenuItem key={`room-1`} value={2}>
                      1
                    </MenuItem>
                    <MenuItem key={`room-2`} value={4}>
                      2
                    </MenuItem>
                    <MenuItem key={`room-3`} value={7}>
                      3
                    </MenuItem>
                  </Select>
                </FormControl>

                <Button variant={'contained'} size={'large'} onClick={enterRoom}>
                  입장
                </Button>
              </Stack>
            </Card>
          </FormProvider>
        </Container>
      ) : (
        <>
          <Container sx={{ py: 10, display: 'flex', height: '100%', flexDirection: 'column' }}>
            <Typography variant={'h4'} sx={{ textAlign: 'center', pb: 3 }}>
              ROOM {getValues('roomId')}
            </Typography>
            <Container
              sx={{
                display: 'flex',
                height: '100%',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Grid container spacing={2}>
                {participantStore.participants.map((participant: IParticipant, i: number) => {
                  return (
                    <Grid key={'participant-' + i} item xs={6} md={4} lg={4}>
                      <Participant
                        participant={participant}
                        sendMessage={sendMessage}
                        onConnectionstatechange={onConnectionstatechange}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Stack direction={'row'} justifyContent={'center'} spacing={1}>
                <Button
                  variant={settings.video === true ? 'contained' : 'soft'}
                  onClick={() => {
                    setSettings((settings) => (settings = { ...settings, video: !settings.video }));
                    var msg = { type: 'kurento', id: 'toggleActivity', activity: 'VIDEO' };
                    sendMessage(msg);
                  }}
                >
                  Video
                </Button>
                <Button
                  variant={settings.audio === true ? 'contained' : 'soft'}
                  onClick={() => {
                    setSettings((settings) => (settings = { ...settings, audio: !settings.audio }));
                    var msg = { type: 'kurento', id: 'toggleActivity', activity: 'AUDIO' };
                    sendMessage(msg);
                  }}
                >
                  Audio
                </Button>
                <Button variant="contained" color={'error'} onClick={leaveRoom}>
                  Leave
                </Button>
              </Stack>
            </Container>
          </Container>
        </>
      )}
    </>
  );
});

export default Home;
