import { useLocation, Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//
import Footer from './Footer';
import Header from './Header';
import { HEADER } from 'src/config-global';
import { useSettings } from 'src/hooks/useSettings';
import { useEffect } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import navConfig from './nav/config-navigation';
import useOffSetTop from 'src/hooks/useOffSetTop';

// ----------------------------------------------------------------------

export default function MyPageLayout() {
  const { pathname } = useLocation();

  const isMobile = useResponsive('down', 'md');

  const { themeMode, onChangeThemeMode } = useSettings();
  useEffect(() => {
    onChangeThemeMode('light');

    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'visible') {
        onChangeThemeMode('light');
      }
    });
  }, []);

  const isHome = pathname === '/';

  const isMeeting = pathname.includes('meeting');
  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (
    <>
      {!isMobile ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
          <Header />
          <Box
            id="mypage-layout"
            component="main"
            sx={{
              flex: 1,
              pt: {
                xs: HEADER.H_MOBILE + 'px',
                md: HEADER.H_MAIN_DESKTOP + 'px',
              },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            width: '100%',
            height: '100%',
            justifyContent: 'space-between',
            p: 0,
          }}
        >
          <Box
            id="mypage-layout"
            component="main"
            sx={{
              flex: 1,
              width: '100%',
              height: '100%',
              minWidth: 345,
              textAlign: 'center',
              justifyContent: 'center',
              pb: {
                xs: HEADER.H_MOBILE + 'px',
                md: HEADER.H_MAIN_DESKTOP + 'px',
              },
            }}
          >
            <Outlet />
            {!isMeeting && <Footer />}
          </Box>
        </Box>
      )}
    </>
  );
}
