import { observable } from 'mobx';
import { IStateTreeNode } from 'mobx-state-tree';

export interface IPagination {
  page: number;
  size: number;
  offset: number;
  numberOfElements: number;
  totalElements: number;
  totalPages: number;
  first: boolean;
  last: boolean;
}

export const defaultPagination: IPagination = {
  page: 1,
  size: 20,
  offset: 0,
  numberOfElements: 0,
  totalElements: 0,
  totalPages: 0,
  first: true,
  last: true,
};

export const withPagination = <T extends IStateTreeNode>(_self: T) => {
  const pageInfo = observable.box<IPagination>({ ...defaultPagination });

  return {
    views: {
      get paginationRaw() {
        return pageInfo;
      },
      get pagination() {
        return pageInfo.get();
      },
      get isFirst() {
        return pageInfo.get().first;
      },
      get isLast() {
        return pageInfo.get().last;
      },
      get page() {
        return pageInfo.get().page;
      },
      get size() {
        return pageInfo.get().size;
      },
      get offset() {
        return pageInfo.get().offset;
      },
      get numberOfElements() {
        return pageInfo.get().numberOfElements;
      },
      get totalElements() {
        return pageInfo.get().totalElements;
      },
      get totalPages() {
        return pageInfo.get().totalPages;
      },
    },

    actions: {
      setSize(size: number) {
        pageInfo.set({
          ...pageInfo.get(),
          size,
        });
      },

      setPage(page: number) {
        pageInfo.set({
          ...pageInfo.get(),
          page,
        });
      },

      resetPagination(props: Partial<IPagination> = {}) {
        pageInfo.set({ ...defaultPagination, ...props });
      },


      setPagination(newPagination?: IPagination) {
        if (newPagination) {
          pageInfo.set({ ...newPagination });
        } else {
          pageInfo.set({ ...defaultPagination });
        }
      },
      
      updatePagination(newPagination: Partial<IPagination>) {
        pageInfo.set({
          ...pageInfo.get(),
          ...newPagination,
        });
      },
    },
  };
};
