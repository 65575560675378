import { Card, Paper, Typography } from '@mui/material';
import { GridColDef, GridSelectionModel, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { CDataGrid } from 'src/components/CDataGrid';
import { useLocales } from 'src/locales';
import { useStores } from 'src/models';

const ProgressList = observer(() => {
  const rootStore = useStores();
  const { dashBoardStore: store } = rootStore;
  const { translate } = useLocales();

  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const columns: GridColDef[] = [
    //   개시시간, 경과시간, 주최자, 주제, 참석자
    {
      field: 'appointment',
      headerName: translate('dashBoard.meetingsInProgress.table.conferId').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 50,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value.id;
      },
    },
    {
      field: 'startTime',
      headerName: translate('dashBoard.meetingsInProgress.table.start').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
    },
    {
      field: 'runningTime',
      headerName: translate('dashBoard.meetingsInProgress.table.runtime').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 70,
    },
    {
      field: 'owner',
      headerName: translate('dashBoard.meetingsInProgress.table.host').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 70,
      valueGetter: (params: GridValueGetterParams) => {
        return params.value.displayName;
      },
    },
    {
      field: 'title',
      headerName: translate('dashBoard.meetingsInProgress.table.title').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 100,
    },
    {
      field: 'meetingMembers',
      headerName: translate('dashBoard.meetingsInProgress.table.participant').toString(),
      flex: 150,
      valueGetter: (params: GridValueGetterParams) => {
        const list: any[] = [];
        params.value.filter((v: any) => {
          list.push(v.user.displayName);
        });
        return list;
      },
    },
  ];

  const handleChangePage = (newPage: number) => {
    store.pagination.setProps({ page: newPage + 1 });
    // getData();
  };

  return (
    <>
      <Paper sx={{ flex: 1, height: '100%' }}>
        {store.progressList.length > 0 ? (
          <CDataGrid
            store={store}
            columns={columns}
            rows={'progressList'}
            rowId={'id'}
            // paging={true}
            // pagingType={'custom'}
            // autoPageSize={true}
            density={'standard'}
            disableSelectionOnClick
            onPageChange={(newPage: number) => {
              handleChangePage(newPage);
            }}
            // selectionModel={selectionModel}
            // onSelectionModelChange={(newSelectionModel: any) => {
            //   setSelectionModel(newSelectionModel);
            // }}
            autoHeight
            sx={{ '& .MuiDataGrid-main': { borderBottom: '.5px solid black' } }}
          />
        ) : (
          <Card sx={{ p: 2 }}>
            <Typography variant="subtitle2" textAlign={'center'}>
              {translate('dashBoard.meetingsInProgress.noInProgress').toString()}
            </Typography>
          </Card>
        )}
      </Paper>
    </>
  );
});

export default ProgressList;
