import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';
import {
  Conference,
  IConferFilter,
  IConference,
  IConferenceSnapshot,
  createConference,
} from '../conference/Conference';
import { withRootStore } from '../extensions/with-root-store';
import { IPagination, IPaginationSnapshot, createPagination } from '../pagination/Pagination';
import {
  TCreateConferenceResult,
  TDeleteConferenceResult,
  TGetChatResult,
  TGetConferenceResult,
  TGetUserResult,
  TGetReportResult,
  TGetsCalendarsResult,
  TInvitationResult,
  TPostReportResult,
  TSendMsgResult,
  TUpdateConferenceResult,
} from 'src/services/conference/ConferenceTypes';
import { ConferenceApi } from 'src/services/conference/Conference';
import moment from 'moment';
import { Chat, IChat, IChatSnapshot } from '../chat/Chat';
import { IReport, IReportSnapshot, Report, createReport } from '../report/Report';
import { IUser, IUserSnapshot, User } from '../user/User';

/**
 * # ConferenceStore
 *
 * ConferenceStore을 설명하세요.
 */
export const ConferenceStore = types
  .model('ConferenceStore')
  // --------------------------------------------------------------------------
  .props({
    conference: createConference(),
    conferences: types.array(Conference),
    pagination: createPagination(),
    invitationLink: types.maybe(types.string),
    chat: types.array(Chat),
    report: createReport(),
    userList: types.array(User),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    setConference: (conference: IConferenceSnapshot) => {
      self.conference = conference as IConference;
    },

    /**
     * conferences을 교체
     *
     * @param `conferences` 새로운 모델의 배열
     */
    setConferences: (conferences: IConferenceSnapshot[]) => {
      self.conferences.replace(conferences as IConference[]);
    },
    resetConferences: () => {
      self.conferences.replace([]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    setInvitationLink: (link: any) => {
      self.invitationLink = link;
    },
    setChat: (chat: IChatSnapshot[]) => {
      self.chat.replace(chat as IChat[]);
    },
    setReport: (report: IReportSnapshot) => {
      self.report = report as IReport;
    },
    setUserList: (userList: IUserSnapshot[]) => {
      self.userList.replace(userList as IUser[]);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    /**
     * 화상회의 예약을 만든다.
     * (이메일주소로 회의 초대 발송메일이 전송된다.) (메일 링크는 협의 후 수정)
     */
    createConference: async (conferenceInfo: IConferenceSnapshot) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TCreateConferenceResult = await conferenceApi.createConference(
          conferenceInfo,
        );
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            if (result.data) {
              self.setConference(result.data);
            }
          } else {
            console.error(result);
          }
        }
        // if(result.responseInfo. === 'success'){
        //   if (result.data) {
        //     self.setConference(result.data);
        //   }
        // }else {
        //   console.error(result);
        // }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Conferences를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async (filter?: IConferFilter) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        let result;

        if (filter) {
          result = await conferenceApi.gets(
            {
              // filter.start,
              // filter.end,
              // ...self.pagination,
              filter,
            },
            self.pagination,
          );
        } else {
          result = await conferenceApi.gets(
            {
              filter,
              // ...self.pagination,
            },
            self.pagination,
          );
        }

        if (result && self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            self.resetConferences();
            if (result.data) {
              result.data.forEach((item: IConferenceSnapshot) => {
                // item.title = item.title === null ? 'Untitled' : item.title;
                item.conference_date =
                  moment(item.start).dayOfYear() === moment(item.end).dayOfYear()
                    ? moment(item.start).format('YYYY-MM-DD HH:mm') +
                      ' ~ ' +
                      moment(item.end).format('HH:mm')
                    : moment(item.start).format('YYYY-MM-DD HH:mm') +
                      ' ~ ' +
                      moment(item.end).format('YYYY-MM-DD HH:mm');

                // item.running_time = `${
                //   moment.duration(moment(item.end).diff(moment(item.start))).days() > 1
                //     ? moment.duration(moment(item.end).diff(moment(item.start))).days() + '일'
                //     : ''
                // } ${moment.duration(moment(item.end).diff(moment(item.start))).hours()}:${moment
                //   .duration(moment(item.end).diff(moment(item.start)))
                //   .minutes()}:${moment
                //   .duration(moment(item.end).diff(moment(item.start)))
                //   .seconds()}`;

                item.meetingMembers.forEach((member) => {
                  member.displayName = member.displayName ? member.displayName : '';
                  // member.loginId = member.loginId ? member.loginId : '';
                });
              });

              self.setConferences(result.data.sort(sort));
            }
            result.paging && self.setPagination(result.paging);
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 예약정보를 가져온다. (캘린더)
     */
    getsCalendar: async () => {
      const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
      const result: TGetsCalendarsResult = await conferenceApi.getsCalendar();

      if (result.kind === 'ok') {
        self.setConferences(result.data);
      } else {
        console.error(result.kind);
      }
    },

    /**
     * 회의번호로 참여한 회의 정보를 가져온다.
     * @param appointmentId
     */
    get: async (appointmentId: number) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TGetConferenceResult = await conferenceApi.get(appointmentId);

        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            self.setConference({} as IConferenceSnapshot);
            if (result.data) {
              self.setConference(result.data);
              // result.data.title =
              //   result.data.title === (null || undefined) ? 'Untitled' : result.data.title;
              result.data.conference_date =
                moment(result.data.start).dayOfYear() === moment(result.data.end).dayOfYear()
                  ? moment(result.data.start).format('YYYY-MM-DD HH:mm') +
                    ' ~ ' +
                    moment(result.data.end).format('HH:mm')
                  : moment(result.data.start).format('YYYY-MM-DD HH:mm') +
                    ' ~ ' +
                    moment(result.data.end).format('YYYY-MM-DD HH:mm');

              // result.data.running_time = `${moment.duration(moment(result.data.end).diff(moment(result.data.start))).days() > 1
              //   ? moment
              //     .duration(moment(result.data.end).diff(moment(result.data.start)))
              //     .days() + '일'
              //   : ''
              //   } ${moment
              //     .duration(moment(result.data.end).diff(moment(result.data.start)))
              //     .hours()}:${moment
              //       .duration(moment(result.data.end).diff(moment(result.data.start)))
              //       .minutes()}:${moment
              //         .duration(moment(result.data.end).diff(moment(result.data.start)))
              //         .seconds()}`;

              self.setConference(result.data);
            }
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 관리자가 전체 회의를 조회한다
     * @param filter
     */
    getAll: async (filter?: IConferFilter) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        let result;

        if (filter) {
          result = await conferenceApi.getAll(
            {
              // ...self.pagination,
              filter,
            },
            self.pagination,
          );
        } else {
          result = await conferenceApi.getAll(
            {
              // ...self.pagination,
            },
            self.pagination,
          );
        }
        if (result && self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            self.resetConferences();
            if (result.data) {
              result.data.forEach((item: IConferenceSnapshot) => {
                item.conference_date =
                  moment(item.start).dayOfYear() === moment(item.end).dayOfYear()
                    ? moment(item.start).format('YYYY-MM-DD HH:mm') +
                      ' ~ ' +
                      moment(item.end).format('HH:mm')
                    : moment(item.start).format('YYYY-MM-DD HH:mm') +
                      ' ~ ' +
                      moment(item.end).format('YYYY-MM-DD HH:mm');

                item.meetingMembers.forEach((member) => {
                  member.displayName = member.displayName ? member.displayName : '';
                });
              });

              self.setConferences(result.data.sort(sort));
              result.paging && self.setPagination(result.paging);
            }
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**grid에서 파일 삭제 시 화면 적용 */
    removeFile: (id: number) => {
      self.conference?.room?.shareFiles?.map((file: any, index: number) => {
        if (file.id === id && self.conference.room.shareFiles) {
          self.conference.room.shareFiles.splice(index, 1);
        }
      });
    },

    /**
     * 초대장을 추가한다. (예약 시 초대 못한 회원 다시 초대하는 용도)
     * @param userInfo
     * @returns
     */
    invitation: async (userInfo: any) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TInvitationResult = await conferenceApi.invitation(userInfo);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            self.setInvitationLink(result.data);
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 화상회의를 수정한다
     * @param conferenceInfo
     */
    update: async (conferenceInfo: IConferenceSnapshot) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TUpdateConferenceResult = await conferenceApi.update(conferenceInfo);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            // if (result.data) {
            //   self.setConference(result.data);
            // }
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * watis 최근 초대 사용자 정보를 조회한다
     * @param roomType
     */
    getRecentUser: async (roomType: string) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TGetUserResult = await conferenceApi.getRecentUser(roomType);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            if (result.data) {
              self.setUserList(
                result.data.map((v: IUserSnapshot, i: number) => {
                  return { ...v, id: i };
                }),
              );
            }
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
    /**
     * watis 사용자 정보를 조회한다
     * @param userInfo
     */
    searchUser: async (userInfo: IUserSnapshot) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TGetUserResult = await conferenceApi.searchUser(userInfo);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            if (result.data) {
              self.setUserList(
                result.data.map((v: IUserSnapshot, i: number) => {
                  return { ...v, id: i };
                }),
              );
            }
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 방을 삭제한다.
     * @param id
     */
    delete: async (id: number) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TDeleteConferenceResult = await conferenceApi.delete(id);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            // return { kind: 'ok', data: result };
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 채팅메시지를 가져온다. (로그인 사용자는 토큰값으로 방을 판단함.)
     * @param roomId
     */
    getChat: async (roomId: number) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TGetChatResult = await conferenceApi.getChat(roomId);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            result.data && self.setChat(result.data);
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 채팅메시지를 전송한다. (메시지는 방에 있는 유저들에게 웹소켓으로 전송됨)
     * @param msg
     */
    sendMsg: async (msg: string) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TSendMsgResult = await conferenceApi.sendMsg(msg);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            // result.data && self.setChat([...self.chat, result.data]);
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    // /**
    //  * 채팅메시지를 다운로드한다. (엑셀)
    //  * @param roomId
    //  * @returns
    //  */
    // downloadChat: async (roomId: number) => {
    //   try {
    //     const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
    //     const result: TSendMsgResult = await conferenceApi.downloadChat(roomId);
    //     if (result.kind === 'ok') {
    //       return { kind: 'ok', data: result };
    //     } else {
    //       console.error(result);
    //     }
    //   } catch (e) {
    //     self.rootStore.responseStore.errorProcessing(e);
    //   }
    // },

    /**
     * 보고서를 저장한다.
     * @param report
     */
    saveReport: async (report: IReportSnapshot) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TPostReportResult = await conferenceApi.saveReport(report);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            // result.data && self.setReport(result.data);
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    // /**
    //  * 보고서 PDF를 다운로드한다.
    //  * @param id
    //  * @returns
    //  */
    // downloadReport: async (id: number) => {
    //   try {
    //     const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
    //     const result: TdownloadReportResult = await conferenceApi.downloadReport(id);

    //     if (result.kind === 'ok') {
    //       return { kind: 'ok', data: result };
    //     } else {
    //       console.error(result.kind);
    //     }
    //   } catch (e) {
    //     self.rootStore.responseStore.errorProcessing(e);
    //   }
    // },

    /**
     * 방 번호로 보고서를 조회한다.
     * @param roomId
     */
    getReport: async (roomId: number) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TGetReportResult = await conferenceApi.getReport(roomId);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            if (result.data) {
              self.setReport(result.data);
            }
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },

    /**
     * 보고서를 삭제한다 ( 주관자 , 관리자 )
     * @param reportSid
     */
    deleteReport: async (reportSid: number) => {
      try {
        const conferenceApi: ConferenceApi = new ConferenceApi(self.environment.api);
        const result: TGetReportResult = await conferenceApi.deleteReport(reportSid);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            if (result.data) {
              // self.setReport(result.data);
            }
          } else {
            console.error(result);
          }
        }
      } catch (e) {
        self.rootStore.responseStore.errorProcessing(e);
      }
    },
  }));

// --------------------------------------------------------------------------
type TConferenceStore = Instance<typeof ConferenceStore>;
type TConferenceStoreSnapshot = SnapshotOut<typeof ConferenceStore>;

export interface IConferenceStore extends TConferenceStore {}
export type TConferenceStoreKeys = keyof TConferenceStoreSnapshot & string;
export interface IConferenceStoreSnapshot extends TConferenceStoreSnapshot {}
export const createConferenceStore = () => types.optional(ConferenceStore, {} as TConferenceStore);

const sort = (a: IConferenceSnapshot, b: IConferenceSnapshot) => {
  if (moment(a.start) > moment(b.start)) {
    return -1;
  } else if (moment(a.start) === moment(b.start)) {
    return 0;
  } else if (moment(a.start) < moment(b.start)) {
    return 1;
  } else {
    return 0;
  }
};
