import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createPagination } from '../pagination/Pagination';

/**
 * # Response
 *
 * Response을 설명하세요.
 */
const ResponseDetail = types.model('ResponseDetail').props({
  error: types.optional(types.string, ''),
  exceptionDetail: types.optional(types.string, ''),
});

export const Response = types
  .model('Response')
  // --------------------------------------------------------------------------
  .props({
    resultCode: types.optional(types.maybeNull(types.string), null),
    paging: types.optional(types.maybeNull(createPagination()), null),
    error: types.optional(types.maybeNull(types.string), null),
    exceptionDetail: types.optional(types.maybeNull(types.string), null),
    detail: types.optional(types.maybeNull(ResponseDetail), null),
  })
  .actions((self) => ({
    resetResponseInfo: () => {
      self.resultCode = null;
      self.error = null;
      self.exceptionDetail = null;

      self.paging = null;
    },
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IResponse;
      (Object.keys(newProps) as TResponseKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
    reset: () => {
      self.resultCode = null;
      self.error = null;
      self.exceptionDetail = null;
    },
  }));

// --------------------------------------------------------------------------
type TResponse = Instance<typeof Response>;
type TResponseSnapshot = SnapshotOut<typeof Response>;

export interface IResponse extends TResponse {}
export type TResponseKeys = keyof TResponseSnapshot & string;
export interface IResponseSnapshot extends TResponseSnapshot {}
export const createResponse = () =>
  types.optional(Response, {
    resultCode: null,
    error: null,
    exceptionDetail: null,
  } as TResponse);
