import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import ResizeHandle from './ResizeHandle';
import { useTheme, Stack, Box, Fade } from '@mui/material';
import Participants from '../participants/Participants';
import Chatting from '../chat/Chatting';
import Fileshare from '../fileshare/Fileshare';
import { IChatSnapshot, IConferenceSnapshot } from 'src/models';
import useResponsive from 'src/hooks/useResponsive';

const useStyles = makeStyles({
  panel: {
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(33, 43, 54, 1)',
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    overflowY: 'auto',
  },
});

export const Sidebar = observer(
  ({
    sideState,
    setSideState,
    setOpenSide,
    data,
    chatMsg,
    newChatMsg,
    getChat,
    chatReset,
  }: any) => {
    const theme = useTheme();
    const classes = useStyles();
    const duration = 300;
    const isDownMd = useResponsive('down', 'md');

    useEffect(() => {
      if (sideState.participant || sideState.share || sideState.chat) {
        setOpenSide(true);
      } else if (!sideState.participant && !sideState.share && !sideState.chat) {
        setOpenSide(false);
      }
    }, [sideState]);

    return (
      <>
        <Stack
          sx={{
            display: 'flex',
            flex: 1,
            width: '100%',
            height: '100%',
            background: theme.palette.background.neutral,
          }}
        >
          <Stack sx={{ flex: 1 }}>
            <PanelGroup autoSaveId="sidebar-panel-group" direction="vertical">
              {sideState.participant && (
                <>
                  <Panel id={'participant'} collapsible={false} order={1} className={classes.panel}>
                    <Fade in={sideState.participant} timeout={{ enter: duration }}>
                      <Box className={classes.content}>
                        <Participants
                          dataildata={data}
                          handleClose={() => {
                            setSideState({
                              ...sideState,
                              participant: false,
                            });
                          }}
                        />
                      </Box>
                    </Fade>
                  </Panel>
                  {!isDownMd && <ResizeHandle />}
                </>
              )}
              {sideState.share && (
                <>
                  <Panel
                    id={'fileshare'}
                    collapsible={false}
                    defaultSize={30}
                    order={2}
                    className={classes.panel}
                  >
                    <Fade in={sideState.share} timeout={{ enter: duration }}>
                      <Box className={classes.content}>
                        <Fileshare
                          handleClose={() => {
                            setSideState({
                              ...sideState,
                              share: false,
                            });
                          }}
                          ownerYn={data.ownerYn}
                        />
                      </Box>
                    </Fade>
                  </Panel>
                  {!isDownMd && <ResizeHandle />}
                </>
              )}
              {sideState.chat && (
                <>
                  {/* <ResizeHandle /> */}
                  <Panel id={'chatting'} collapsible={false} order={3} className={classes.panel}>
                    <Fade in={sideState.chat} timeout={{ enter: duration }}>
                      <Box className={classes.content}>
                        <Chatting
                          data={data as IConferenceSnapshot}
                          handleClose={() => {
                            setSideState({
                              ...sideState,
                              chat: false,
                            });
                          }}
                          chatMsg={chatMsg as IChatSnapshot[]}
                          newChatMsg={newChatMsg as IChatSnapshot}
                          getChat={getChat}
                          chatReset={chatReset}
                        />
                      </Box>
                    </Fade>
                  </Panel>
                </>
              )}
            </PanelGroup>
          </Stack>
        </Stack>
      </>
    );
  },
);

export default Sidebar;
