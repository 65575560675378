import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Container,
  Divider,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  GridColDef,
  GridRowParams,
  GridSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import CSelect from './CSelect';
import Iconify from 'src/components/iconify';
import { CDataGrid } from 'src/components/CDataGrid';
import { toJS } from 'mobx';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { CallApiToStore } from 'src/utils/common';
import { IMeetingMemberSnapshot, IUserSnapshot } from 'src/models';
import useResponsive from 'src/hooks/useResponsive';
import { useLocales } from 'src/locales';

type FormValuesProps = {
  facilityCd: string;
  authCd: string;
  roomType: string;
  name?: string;
  sex?: string;
  birth?: string;
  cellno?: string;
  email?: string;
};
interface Props {
  meetingMembers?: IMeetingMemberSnapshot[];
  addParticipant?: VoidFunction;
  removeParticipant?: any;
  handleParticipantChange?: any;
  handleClose: VoidFunction;
  path: string;
  roomType: string;
  index?: number;
  handleParticipantAddChange?: Function;
  setValue?: any;
}
/**
 * ## SearchUser 설명
 *
 */

let tmp: IUserSnapshot[] = [];
export const SearchUser = observer(
  ({
    meetingMembers,
    addParticipant,
    removeParticipant,
    handleParticipantChange,
    handleClose,
    path,
    roomType,
    index,
    handleParticipantAddChange,
    setValue,
  }: Props) => {
    const { translate } = useLocales();
    const rootStore = useStores();
    const { conferenceStore, loadingStore, responseStore } = rootStore;
    const user = localStorage.getItem('user');
    const theme = useTheme();
    const isMobile = useResponsive('down', 'md');
    const [selectionModel, setSelectionModel] = useState([] as GridSelectionModel);
    const selectOptions = {
      gender: [
        {
          code: 'M',
          pcode: 0,
          value: 'M',
        },
        {
          code: 'F',
          pcode: 0,
          value: 'F',
        },
      ],
    };

    const SearchUserSchema = Yup.object().shape({
      facilityCd: Yup.string().trim().required(''),
      authCd: Yup.string().trim().required(''),
      name: Yup.string().trim().nullable(),
      sex: Yup.string().trim().nullable(),
      birth: Yup.string().trim().nullable(),
      cellno: Yup.string().trim().nullable(),
      email: Yup.string().trim().nullable(),
    });

    const defaultValues = {
      facilityCd: '',
      authCd: 'all',
      roomType: roomType,
      name: '',
      sex: '',
      birth: '',
      cellno: '',
      email: '',
    };
    const methods = useForm<FormValuesProps>({
      resolver: yupResolver(SearchUserSchema),
      reValidateMode: 'onChange',
      defaultValues,
    });
    const {
      reset,
      setError,
      clearErrors,
      handleSubmit,
      getValues,
      formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    let columns: GridColDef[] = [
      {
        field: 'loginId',
        headerName: `${translate('login.login').toString()}ID`,
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        minWidth: 100,
      },
      {
        field: 'kanjiDisplayName',
        headerName: translate('searchUser.nameKanji').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 130,
        minWidth: 130,
      },
      {
        field: 'kanaDisplayName',
        headerName: translate('searchUser.nameKana').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        minWidth: 100,
      },
      {
        field: 'auth',
        headerName: translate('conferenceReservation.auth').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        minWidth: 100,
        valueGetter: (params: GridValueGetterParams) => {
          return params.value.authNm;
        },
      },
      {
        field: 'facility',
        headerName: translate('conferenceReservation.facility').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 200,
        minWidth: 200,
        valueGetter: (params: GridValueGetterParams) => {
          return params.value.facilityNm;
        },
      },
      {
        field: 'sex',
        headerName: translate('searchUser.gender').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
        minWidth: 50,
      },
      {
        field: 'birth',
        headerName: translate('searchUser.birth').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        minWidth: 100,
      },
      {
        field: 'email',
        headerName: translate('conferenceReservation.email').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 200,
        minWidth: 200,
      },
      {
        field: 'cellno',
        headerName: translate('searchUser.mobile').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 150,
        minWidth: 150,
      },
      {
        field: 'homeAddress',
        headerName: translate('searchUser.address').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 200,
        minWidth: 200,
      },
      {
        field: 'patanm',
        headerName: translate('searchUser.patanm').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        minWidth: 100,
      },
      {
        field: 'patajm',
        headerName: translate('searchUser.patajm').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 100,
        minWidth: 100,
      },
      // {
      //   field: '',
      //   headerName: '추가',
      //   headerAlign: 'center',
      //   align: 'center',
      //   flex: 50,
      //   renderCell: (params: GridRenderCellParams) => {
      //     return (
      //       <Button
      //         variant="contained"
      //         onClick={() => {
      //           console.log(params.row);
      //           handleParticipantChange(params.row);
      //         }}
      //         sx={{ py: 0, minWidth: '20px' }}
      //       >
      //         +
      //       </Button>
      //     );
      //   },
      // },
    ];

    const [title, setTitle] = useState(translate('searchUser.recentUser').toString());

    const handleChangePage = (newPage: number) => {
      // userStore.pagination.setProps({ page: newPage + 1 });
      // getData();
    };
    const [openAccordion, setOpenAccordion] = useState(false);

    const getRecentUser = async (roomType: string) => {
      await CallApiToStore(conferenceStore.getRecentUser(roomType), 'api', loadingStore);
    };
    useEffect(() => {
      getRecentUser(roomType);
    }, []);

    const onSearch = async () => {
      setSelectionModel([]);
      if (getValues('authCd') === '8') {
        setIsPatient(false);
        setIsCaregiver(true);
      } else if (getValues('authCd') === '9') {
        setIsPatient(true);
        setIsCaregiver(false);
      } else {
        setIsPatient(false);
        setIsCaregiver(false);
      }
      const data = { ...getValues(), roomType: roomType };
      await CallApiToStore(conferenceStore.searchUser(data as any), 'api', loadingStore).then(
        () => {
          if (!toJS(responseStore.responseInfo).detail?.error) {
            setTitle(translate('searchUser.srchRslt').toString());
          } else {
            getRecentUser(roomType);
            setTitle(translate('searchUser.recentUser').toString());
          }
        },
      );
    };

    const [isPatient, setIsPatient] = useState(false);
    const [isCaregiver, setIsCaregiver] = useState(false);

    return (
      <>
        <Container
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            py: 4,
          }}
        >
          {user && (
            <Stack
              spacing={2}
              sx={{
                flex: 1,
                '& .MuiStack-root>:not(style)+:not(style)': {
                  marginLeft: '0 !important',
                },
                '& .MuiInputBase-input': { p: '8px' },
              }}
            >
              <Typography variant={'h5'}>
                {translate('conferenceReservation.searchUser').toString()}
              </Typography>

              <FormProvider methods={methods} onSubmit={handleSubmit(onSearch)}>
                <Accordion
                  expanded={openAccordion}
                  sx={{
                    '&.Mui-expanded': {
                      boxShadow: 'none !important',
                      minHeight: 0,
                      m: 0,
                    },
                    '[data-shrink="false"]': { top: '-8px' },
                  }}
                >
                  <AccordionSummary
                    sx={{
                      p: 0,
                      cursor: 'inherit !important',
                      '& .MuiAccordionSummary-content': { m: 0 },
                      '&.Mui-expanded': {
                        m: '0 !important',
                        minHeight: 0,
                      },
                      '& .Mui-expanded': {
                        m: '0 !important',
                      },
                      '& .MuiFormControl-root': { flex: 0.5 },
                      '&.Mui-focusVisible': {
                        backgroundColor: 'inherit !important',
                      },
                    }}
                  >
                    <Stack
                      direction={isMobile ? 'column' : 'row'}
                      gap={isMobile ? 2 : 1}
                      flex={1}
                      sx={
                        isMobile
                          ? {
                              '& .MuiFormControl-root': { order: 2 },
                            }
                          : {}
                      }
                    >
                      <CSelect
                        variant="outlined"
                        label={translate('searchUser.selectFacility').toString()}
                        name="facilityCd"
                        options={JSON.parse(localStorage.getItem('facility') || '')}
                        defaults={{
                          code: '',
                          value: translate('select').toString(),
                        }}
                        sx={{ '& .MuiFormControl-root': { flex: 0.5 } }}
                      />
                      <CSelect
                        variant={'outlined'}
                        label={translate('searchUser.selectAuth').toString()}
                        name="authCd"
                        options={JSON.parse(localStorage.getItem('auth') || '')}
                        defaults={{ code: 'all', value: translate('all').toString() }}
                        sx={{ flex: 0.5 }}
                      />
                      <CSelect
                        variant={'outlined'}
                        label={translate('searchUser.selectGender').toString()}
                        name="sex"
                        options={selectOptions.gender}
                        defaults={{
                          code: '',
                          value: translate('select').toString(),
                        }}
                        // defaults={{ code: '', value: translate('noSelection').toString() }}
                        sx={{ flex: 0.5 }}
                      />
                      <RHFTextField
                        name={'name'}
                        type={'text'}
                        label={translate('searchUser.enterName').toString()}
                        variant={'outlined'}
                        onFocus={() => clearErrors()}
                        onKeyUp={(e: any) => {
                          e.target.value = e.target.value.replace(/\s/gi, '');
                        }}
                        sx={{ height: '40px' }}
                      />
                      <Stack
                        direction={'row'}
                        gap={1}
                        sx={isMobile ? { order: '1', justifyContent: 'flex-end' } : {}}
                      >
                        <IconButton
                          onClick={() => {
                            setOpenAccordion(!openAccordion);
                          }}
                        >
                          <Iconify
                            icon={openAccordion ? 'ph:minus-bold' : 'ph:plus-bold'}
                            sx={{
                              color: '#999',
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setTitle(translate('searchUser.recentUser').toString());
                            reset();
                            setSelectionModel([]);
                            getRecentUser(roomType);
                          }}
                        >
                          <Iconify
                            icon={'grommet-icons:power-reset'}
                            sx={{
                              color: '#999',
                            }}
                          />
                        </IconButton>

                        <Button
                          // type="submit"
                          variant={'contained'}
                          size={'medium'}
                          sx={{ height: '40px', wordBreak: 'keep-all' }}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onSearch();
                          }}
                        >
                          {translate('conferenceReservation.search').toString()}
                        </Button>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 0, mt: 1 }}>
                    <Stack direction={isMobile ? 'column' : 'row'} gap={isMobile ? 2 : 1}>
                      <RHFTextField
                        name={'birth'}
                        type={'text'}
                        label={translate('searchUser.enterbirth').toString()}
                        variant={'outlined'}
                        onFocus={() => clearErrors()}
                        onKeyUp={(e: any) => {
                          e.target.value = e.target.value.replace(/\s/gi, '');
                        }}
                      />
                      <RHFTextField
                        name={'cellno'}
                        type={'number'}
                        label={translate('searchUser.enterCellno').toString()}
                        variant={'outlined'}
                        onFocus={() => clearErrors()}
                        onKeyUp={(e: any) => {
                          e.target.value = e.target.value.replace(/\s/gi, '');
                        }}
                      />
                      <RHFTextField
                        name={'email'}
                        type={'text'}
                        label={translate('searchUser.enterEmail').toString()}
                        variant={'outlined'}
                        onFocus={() => clearErrors()}
                        onKeyUp={(e: any) => {
                          e.target.value = e.target.value.replace(/\s/gi, '');
                        }}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </FormProvider>

              <Box
                sx={{ display: 'flex', flexDirection: 'column', height: '100%', pt: 1, flex: 1 }}
              >
                <Typography variant={'h5'} mb={1}>
                  {title}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '50vh' }}>
                  <Paper sx={{ flex: 1, height: '100%' }}>
                    <CDataGrid
                      // autoHeight
                      sx={{
                        minHeight: '300px',
                        '& .MuiDataGrid-main': {
                          overflowX: 'auto',
                          borderBottom: '.5px solid black',
                        },
                      }}
                      columns={
                        isPatient
                          ? columns.filter((v) => {
                              return (
                                v.field !== 'loginId' &&
                                v.field !== 'patanm' &&
                                v.field !== 'patajm'
                              );
                            })
                          : isCaregiver
                          ? columns.filter((v) => {
                              return v.field !== 'loginId';
                            })
                          : columns.filter((v) => {
                              return v.field !== 'patanm' && v.field !== 'patajm';
                            })
                      }
                      rows={toJS(conferenceStore.userList)}
                      rowId={'id'}
                      paging={true}
                      pagingType={'custom'}
                      autoPageSize={true}
                      density={'standard'}
                      // disableSelectionOnClick
                      onPageChange={(newPage: number) => {
                        handleChangePage(newPage);
                      }}
                      selectionModel={selectionModel}
                      onSelectionModelChange={(newSelectionModel: any) => {
                        tmp = newSelectionModel.map((v: number) => {
                          return toJS(conferenceStore.userList)[v];
                        });
                        setSelectionModel(newSelectionModel);
                      }}
                      onCellDoubleClick={(e) => {
                        if (path === 'meeting') {
                          setValue('displayName', e.row.kanjiDisplayName);
                          setValue('email', e.row.email);
                          setValue('loginId', e.row.loginId);
                          setValue('facilityCd', e.row.facilityCd);
                          setValue('facility', e.row.facility);
                          setValue('authCd', e.row.authCd);
                          setValue('auth', e.row.auth);
                          handleClose();
                        }
                        clearErrors('name');
                      }}
                      isRowSelectable={(params: GridRowParams) => {
                        const emails = meetingMembers?.map((v) => {
                          if (v.email !== ('' || null)) {
                            return v.email;
                          } else {
                            return undefined;
                          }
                        });
                        // console.log(emails);
                        // console.log(
                        //   '🪄 ~ includes(params.row.email):',
                        //   emails?.includes(params.row.email),
                        // );
                        if (emails) {
                          return !emails.includes(params.row.email);
                        } else {
                          return true;
                        }
                      }}
                      checkbox={path !== 'meeting'}
                    />
                  </Paper>
                </Box>
              </Box>

              <Divider />

              <Stack direction={'row'} spacing={1} sx={{ justifyContent: 'flex-end', pt: 1 }}>
                <Button
                  variant={'contained'}
                  sx={{ background: theme.palette.grey[400], mr: 1 }}
                  onClick={() => {
                    handleClose();
                    reset();
                  }}
                >
                  {translate('meeting.cancel').toString()}
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const data = [
                      ...tmp.filter((v) => {
                        v.displayName = v.kanjiDisplayName;

                        return {
                          loginId: v.loginId,
                          displayName: v.kanjiDisplayName,
                          email: v.email,
                          adress: {
                            email: v.email,
                          },
                          auth: v.auth,
                          facility: v.facility,
                        };
                      }),
                    ];
                    if (path === 'meeting') {
                      setValue('displayName', data[0].displayName);
                      setValue('email', data[0].email);
                      setValue('loginId', data[0].loginId);
                      setValue('facilityCd', data[0].facilityCd);
                      setValue('authCd', data[0].authCd);
                      setValue('facility', data[0].facility);
                      setValue('auth', data[0].auth);
                    } else {
                      // if (selectionModel.length === 1) {
                      // meetingMembers?.filter((v, i) => {
                      //   console.log('v,i', v, i);
                      // });
                      // handleParticipantAddChange(e: any, _name: string, index: number);
                      if (handleParticipantAddChange) {
                        handleParticipantAddChange(data, index);
                      }
                      // console.log(...tmp);
                    }
                    handleClose();
                  }}
                  variant={'contained'}
                >
                  {/* {path === 'meeting' ? '추가' : '확인'} */}
                  {translate('meeting.ok').toString()}
                </Button>
              </Stack>
            </Stack>
          )}
        </Container>
      </>
    );
  },
);

export default SearchUser;
