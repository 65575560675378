import infoIcon from '@iconify/icons-ic/error-outline';
import errorIcon from '@iconify/icons-ic/error-outline';
import questionIcon from '@iconify/icons-ic/help-outline';
import successIcon from '@iconify/icons-ic/round-check-circle-outline';
import warningIcon from '@iconify/icons-ic/round-warning';

// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Icon, IconifyIcon } from '@iconify/react';
import { Box, Button, Card, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { DialogAnimate } from './animate';
import CTextField from './forms/CTextField';
import FormProvider from './hook-form/FormProvider';
import Iconify from './iconify';
import { ReactComponent as CloseIcon } from 'src/assets/icons/ico-close.svg';
import { pxToRem } from 'src/theme/typography';
import { useLocales } from 'src/locales';
type CAlertProps = {
  open: boolean;
  variant?: 'success' | 'info' | 'warning' | 'error' | 'question';
  title?: string;
  content?: string;
  hasClose?: boolean;
  hasCancel?: boolean;
  handleClose?: () => void;
  handleCancel?: () => void;
  callBack?: Function;
  deleteQ?: boolean;
  addTextArea?: boolean;
  textAreaLabel?: string;
  textAreaKey?: string;
};

/**
 * ## 기본 사용법
 * > 기본 Alert 창으로 경고 및 정보 등 여러 상황에 알맞은 Alert를 제공합니다.
 */
export default function CAlert({
  open,
  variant = 'success',
  title,
  content,
  hasClose,
  hasCancel,
  handleClose,
  handleCancel,
  callBack,
  deleteQ,
  addTextArea = false,
  textAreaLabel = '입력',
  textAreaKey = 'alertTextArea',
  ...others
}: CAlertProps) {
  const categoryColors = useMemo(() => {
    return {
      error: 'rgb(255, 72, 66)',
      warning: 'rgb(255, 193, 7)',
      info: 'rgb(24, 144, 255)',
      success: 'rgb(84, 214, 44)',
      question: 'rgb(0, 171, 85)',
    };
  }, []);
  const methods = useForm({});
  const theme = useTheme();
  const { translate } = useLocales();
  const { reset, getValues } = methods;

  // const onSubmit = async (data: any) => {
  //   alert(JSON.stringify(data));
  // };

  const [info, setInfo] = useState<{
    icon: IconifyIcon;
    name:
      | 'success'
      | 'info'
      | 'warning'
      | 'error'
      | 'inherit'
      | 'primary'
      | 'secondary'
      | undefined;
    color: string;
  }>({
    icon: infoIcon,
    name: 'info',
    color: categoryColors.info,
  });

  useEffect(() => {
    switch (variant) {
      case 'success':
        setInfo({
          icon: successIcon,
          name: 'success',
          color: categoryColors.success,
        });
        break;
      case 'info':
        setInfo({
          icon: infoIcon,
          name: 'info',
          color: categoryColors.info,
        });
        break;
      case 'warning':
        setInfo({
          icon: warningIcon,
          name: 'warning',
          color: categoryColors.warning,
        });
        break;
      case 'error':
        setInfo({
          icon: errorIcon,
          name: 'error',
          color: categoryColors.error,
        });
        break;
      case 'question':
        if (deleteQ) {
          setInfo({
            icon: questionIcon,
            name: 'error',
            color: categoryColors.error,
          });
        } else {
          setInfo({
            icon: questionIcon,
            name: 'primary',
            color: categoryColors.question,
          });
        }
        break;
    }
  }, [variant, categoryColors, deleteQ]);

  const cancelClose = () => {
    if (handleCancel) {
      handleCancel();
    }
  };

  const confirmClose = () => {
    if (handleClose) {
      handleClose();
    }
    // callBack && callBack();
    if (callBack) {
      if (addTextArea) {
        callBack(getValues());
        reset();
      } else {
        callBack();
      }
    }
  };
  return (
    <DialogAnimate
      open={open}
      onClose={handleClose}
      maxWidth={'sm'}
      sx={{
        maxHeight: '80%',
        '.MuiDialog-container > .MuiBox-root': { alignItems: 'center' },
      }}
      PaperProps={{
        sx: {
          maxWidth: 400,
        },
      }}
      {...others}
    >
      <Card sx={{ background: '#ffffff' }}>
        {title && (
          <>
            {hasClose && (
              <Stack direction="row" sx={{ justifyContent: 'flex-end', p: 2, pb: 0 }}>
                <IconButton size={'large'} onClick={handleCancel ? handleCancel : handleClose}>
                  <CloseIcon stroke={theme.palette.common.black} />
                </IconButton>
              </Stack>
            )}
            {!hasClose && (
              <Stack
                direction="row"
                sx={{ justifyContent: 'flex-end', p: 2, pb: 0, mt: 2 }}
              ></Stack>
            )}

            <Stack direction="row" sx={{ justifyContent: 'center', mb: 2, width: '100%' }}>
              <Typography
                variant="h4"
                sx={{
                  margin: 'auto',
                  fontWeight: '600',
                  opacity: 0.9,
                  mb: 1,
                  width: '80%',
                  textAlign: 'center',
                }}
              >
                {title}
              </Typography>
            </Stack>
          </>
        )}

        {content && (
          <Stack
            direction="row"
            sx={{
              flexDirection: 'column',
              justifyContent: 'center',
              margin: '10px auto',
              padding: '20px',
              overflow: 'auto',
            }}
          >
            {content.split('<br/>').map((value, index) => {
              return (
                <Typography
                  key={index}
                  sx={{
                    fontWeight: '400',
                    margin: 'auto',
                    color: theme.palette.common.black,
                    textAlign: 'center',
                  }}
                >
                  {value}
                  <br />
                </Typography>
              );
            })}
          </Stack>
        )}
        {/* TextArea */}
        {addTextArea && (
          <Stack
            direction="row"
            sx={{ flexDirection: 'column', justifyContent: 'center', overflow: 'auto' }}
          >
            <FormProvider methods={methods}>
              <CTextField
                label={textAreaLabel}
                name={textAreaKey}
                multiline
                variant={'outlined'}
                multilineHeight={50}
              ></CTextField>
            </FormProvider>
          </Stack>
        )}
        {!hasCancel && (
          <Stack
            direction="row"
            sx={{
              justifyContent: 'right',
              padding: '10px',
              pt: 0,
              marginBottom: '10px',
              mr: '10px',
            }}
          >
            <Button variant="outlined" color="primary" size="medium" onClick={confirmClose}>
              {translate(`meeting.ok`).toString()}
            </Button>
          </Stack>
        )}
        {hasCancel && (
          <Stack
            direction="row"
            sx={{
              justifyContent: 'right',
              padding: '10px',
              pt: 0,
              marginBottom: '10px',
              mr: '10px',
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={handleCancel ? handleCancel : handleClose}
              sx={{
                mr: 1.5,
              }}
            >
              {translate(`meeting.n`).toString()}
            </Button>
            <Button variant="contained" size="medium" onClick={confirmClose}>
              {translate(`meeting.y`).toString()}
            </Button>
          </Stack>
        )}
      </Card>
    </DialogAnimate>
  );
}
