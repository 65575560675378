import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../../models/root-store/root-store-context';
import {
  Typography,
  useTheme,
  ListItem,
  Stack,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Divider,
  Fade,
} from '@mui/material';

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import axios from 'axios';
import Iconify from 'src/components/iconify';
import TrashIcon from '@mui/icons-material/DeleteForeverOutlined';
import { fileThumb } from 'src/components/file-thumbnail';
import { getFileSize, sendReactNativeMessage } from 'src/utils/common';
import { load } from 'src/utils/storage';
import { isAndroid } from 'react-device-detect';

/**
 * ## FileItem 설명
 *
 */

const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  const theme = useTheme();
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress size={24} variant="determinate" {...props} color={'primary'} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Iconify icon={'ic:sharp-file-download'} color={theme.palette.primary.main} />
        {/* <Typography
          variant="caption"
          component="div"
          color="text.secondary"
          sx={{ fontSize: '.6rem' }}
        > {`${Math.round(props.value)}%`}</Typography> */}
      </Box>
    </Box>
  );
};

export const FileItem = observer((props: any) => {
  const rootStore = useStores();
  const { loadingStore, responseStore, errorAlertStore } = rootStore;
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;
  const token = localStorage.getItem('accessToken');
  const [progress, setProgress] = useState(0);
  const [isDownload, setIsDownload] = useState(false);
  const user = JSON.parse(localStorage.user);
  const authCd = user.auth?.authCd ? user.auth.authCd : user.authCd;
  const isSystemAdmin = user.isAdmin === true;
  const _ = props.data;

  const [os, setOs] = useState<any>();

  useEffect(() => {
    (async () => {
      setOs(await load('os'));
    })();
  }, []);

  const onDownload = async (id: number, name: string, size: number) => {
    if (os && (os.isIOS || os.isAndroid)) {
      sendReactNativeMessage({
        type: 'filedown',
        payload: {
          url: `${REACT_APP_API_URL}/web/file/download`,
          token: `${token}`,
          fileId: id,
          fileName: name,
        },
      });
    } else {
      await axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/web/file/download`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'arraybuffer',
        data: { fileId: id },
        onDownloadProgress: (event) => {
          setProgress(0);
          setIsDownload(true);
          let percent = Math.floor((event.loaded * 100) / size);
          setProgress(percent);
          if (percent >= 100) {
            setTimeout(() => {
              setIsDownload(false);
            }, 1000);
          }
        },
      })
        .then((res) => {
          try {
            const json = JSON.parse(new TextDecoder().decode(res.data));
            if (json.error) {
              responseStore.setResponseInfo(json);
              errorAlertStore.setErrorAlertFromResponse();
              return;
            }
          } catch (e) {}
          // return;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const anchor = document.createElement('a');
          anchor.href = url;
          anchor.setAttribute('download', name);
          anchor.style.cssText = 'display:none';
          document.body.appendChild(anchor);
          setTimeout(() => {
            anchor.click();
            anchor.remove();
            // console.log('🪄 ~ .then ~ res:', res);
            // console.log('🌈 ~ setTimeout ~ url:', url);
            window.URL.revokeObjectURL(url);
          }, 500);
        })
        .catch((e) => {
          console.log('🌈 ~ onDownload ~ e:', e);
        });
    }
  };

  return (
    <>
      <ListItem
        sx={{ paddingRight: props.ownerYn === 'Y' || !!isSystemAdmin ? 10 : 6 }}
        secondaryAction={
          <Stack spacing={1} direction={'row'} alignItems={'center'} justifyContent={'flex-end'}>
            {isDownload ? (
              <Fade in={isDownload}>
                <IconButton edge="end" sx={{ pr: '6px' }}>
                  <CircularProgressWithLabel value={progress} />
                </IconButton>
              </Fade>
            ) : (
              <IconButton edge="end" onClick={() => onDownload(_.id, _.name, Number(_.size))}>
                <Iconify icon={'ic:sharp-file-download'} color={theme.palette.text.secondary} />
              </IconButton>
            )}
            {(props.ownerYn === 'Y' || !!isSystemAdmin) && (
              <IconButton edge="end" onClick={() => props.onWhetherDelete(_.id, _.name)}>
                <TrashIcon width={24} height={24} />
              </IconButton>
            )}
          </Stack>
        }
      >
        <ListItemAvatar>
          <Box
            component="img"
            src={fileThumb(_.name || '')}
            sx={{
              width: 32,
              height: 32,
              flexShrink: 0,
            }}
          />
        </ListItemAvatar>
        <ListItemText
          sx={{
            width: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          primary={_.name}
          secondary={getFileSize(Number(_.size))}
        />
      </ListItem>
      {/* <Divider /> */}
    </>
  );
});

export default FileItem;
