import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Facility
 *
 * Facility을 설명하세요.
 */
export const Facility = types
  .model('Facility')
  // --------------------------------------------------------------------------
  .props({
    facilityCd: types.optional(types.string, ''), //기관/시설코드
    facilityNm: types.optional(types.string, ''), //기관/시설코드 값
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model facility --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IFacility;
      (Object.keys(newProps) as TFacilityKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TFacility = Instance<typeof Facility>;
type TFacilitySnapshot = SnapshotOut<typeof Facility>;

export interface IFacility extends TFacility {}
export type TFacilityKeys = keyof TFacilitySnapshot & string;
export interface IFacilitySnapshot extends TFacilitySnapshot {}
export const createFacility = () => types.optional(Facility, {} as TFacility);
