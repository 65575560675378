import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { ErrorAlertStore, IErrorAlertStore } from '../error-alert-store/ErrorAlertStore';
import { ILoadingStore, LoadingStore } from '../loading-store/LoadingStore';
import { IResponseStore, ResponseStore } from '../response-store/ResponseStore';
import { CodeListStore, ICodeListStore } from '../code-list/CodeListStore';
import { CodeStore, ICodeStore } from '../code-store/CodeStore';
import { LanguageStore, LanguageStoreModel } from '../language-store/language-store';
import { IParticipantStore, ParticipantStore } from '../participant-store/ParticipantStore';
import { ConferenceStore, IConferenceStore } from '../conference-store/ConferenceStore';
import { toJS } from 'mobx';
import { IMeetingStore, MeetingStore } from '../meeting-store/MeetingStore';
import { PushAlarmStore, IPushAlarmStore } from '../push-alarm-store/PushAlarmStore';
import { DashBoardStore, IDashBoardStore } from '../dash-board-store/DashBoardStore';
import { IStatisticsStore, StatisticsStore } from '../statistics-store/StatisticsStore';

// prettier-ignore
export const RootStoreModel = types.model("RootStore").props({
  languageStore: types.optional(LanguageStoreModel, {} as LanguageStore),
  errorAlertStore: types.optional(ErrorAlertStore, {} as IErrorAlertStore),
  responseStore: types.optional(ResponseStore, {} as IResponseStore),
  loadingStore: types.optional(LoadingStore, {} as ILoadingStore),
  codeStore: types.optional(CodeStore, {} as ICodeStore),
  codeListStore: types.optional(CodeListStore, {} as ICodeListStore),
  participantStore: types.optional(ParticipantStore, {} as IParticipantStore),
  conferenceStore: types.optional(ConferenceStore, {} as IConferenceStore),
  meetingStore: types.optional(MeetingStore, {} as IMeetingStore),
  pushAlarmStore: types.optional(PushAlarmStore, {} as IPushAlarmStore),
  dashBoardStore: types.optional(DashBoardStore, {} as IDashBoardStore),
  statisticsStore: types.optional(StatisticsStore, {} as IStatisticsStore),

})
  .actions((self) => ({
    /**
     * 공통코드 조회
     * @param name
     * @returns
     */
    // getCodeList: (name: string) => {
    //   return self.codeListStore.getCodeListByName(name);
    // },
  }))
  .actions((self) => ({
    // NOTE - afterCreate() is called after the model is created.
    afterCreate() {
      // 공통코드 조회
      // self.codeListStore.fetchAll();

      // @ts-ignore
      window.rootStore = self;
      // @ts-ignore
      window.toJS = toJS;
      if(localStorage.getItem('user')){
        const userInfo = JSON.parse(localStorage.getItem('user') || '');
        if(userInfo.loginId !== null){
          console.log('userInfo',);
          self.pushAlarmStore.getAlarmCounts()          
          setInterval(()=>{
            self.pushAlarmStore.getAlarmCounts()          
          },60000)
        }
      }
    },
  }));

/**
 * The RootStore instance.
 */
export interface RootStore extends Instance<typeof RootStoreModel> {}

/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> {}
