import { Instance, SnapshotOut, types } from "mobx-state-tree"

/**
 * # Meeting
 *
 * Meeting을 설명하세요.
 */
export const Meeting = types
  .model("Meeting")
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.string, ''),
    name: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model meeting --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IMeeting;
      (Object.keys(newProps) as TMeetingKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TMeeting = Instance<typeof Meeting>;
type TMeetingSnapshot = SnapshotOut<typeof Meeting>

export interface IMeeting extends TMeeting {}
export type TMeetingKeys = keyof TMeetingSnapshot & string;
export interface IMeetingSnapshot extends TMeetingSnapshot {}
export const createMeeting = () =>types.optional(Meeting, {} as TMeeting);
