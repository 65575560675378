import { Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function DateCalendar(theme: Theme) {
  return {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          width: "100%",
          "& .Mui-selected, & .Mui-selected:focus, & .Mui-selected:hover": {
            // color: `${palette.common.white} !important`,
            // backgroundColor: `${green["A400"]} !important`,
          },
        },
        viewTransitionContainer: {
          "& > div > div": {
            justifyContent: "space-between !important",
            paddingLeft: theme.spacing(1.25),
            paddingRight: theme.spacing(1.25),
          },
          "& div[role=row]": {
            paddingLeft: theme.spacing(1.25),
            paddingRight: theme.spacing(1.25),
            justifyContent: "space-between !important",
          },
        },
      },
    },

  };
}
