import { Typography } from "@mui/material";
import React from "react";

export default function ErrorMessage({ errors, name, type }: any) {
  if (!errors[name] || errors[name].type !== type) {
    return null;
  }

  return <Typography variant={'Eng_12_r'} color={'error'}>{errors[name].message}</Typography>;
}
