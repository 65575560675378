import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Grid,
  Link,
  Stack,
  Divider,
  Container,
  Typography,
  IconButton,
  useTheme,
  Badge,
} from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// _mock
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
import { useSettingsContext } from 'src/components/settings';
import navConfig from './nav/config-navigation';
import { useStores } from 'src/models';
import { CallApiToStore } from 'src/utils/common';
import { useEffect, useState } from 'react';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Minimal',
    children: [],
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms and Condition', href: '#' },
      { name: 'Privacy Policy', href: '#' },
    ],
  },
  {
    headline: 'Contact',
    children: [
      { name: 'support@minimals.cc', href: '#' },
      { name: 'Los Angeles, 359  Hidden Valley Road', href: '#' },
    ],
  },
];

// ----------------------------------------------------------------------

export default function Footer() {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { themeMode } = useSettingsContext();
  const { translate } = useLocales();
  const rootStore = useStores();
  const { pushAlarmStore } = rootStore;
  const navigate = useNavigate();

  const isHome = pathname === '/';

  const fill = {
    default: themeMode === 'dark' ? '#919EAB' : '#999999',
    active: themeMode === 'dark' ? '#FFFFFF' : '#000000',
  };

  return (
    <Stack
      component="nav"
      direction="row"
      spacing={5}
      sx={{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: theme.zIndex.appBar,
        width: '100%',
        borderTop: '1px solid ' + theme.palette.divider,
        background: '#FFFFFF',
        boxShadow: '0px -5px 10px rgba(0, 0, 0, 0.05)',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          px: 3,
          py: 1,
          width: '100%',
          justifyContent: {
            xs: 'space-between',
            sm: 'space-around',
            md: 'space-evenly',
          },
          '& *': { transition: 'all 0.2s' },
        }}
      >
        {navConfig
          .filter((r) => r.isMobile === true)
          .map((item: any, index: number) => {
            return (
              <Box
                key={`menu-${index}`}
                sx={{ textAlign: 'center', lineHeight: '1.2', cursor: 'pointer' }}
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <Badge
                  badgeContent={pushAlarmStore.counts}
                  // showZero
                  color="error"
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  invisible={item.title !== 'pushNotification'}
                >
                  <item.icon fill={pathname === item.path ? fill.active : fill.default} />
                </Badge>
                <Typography
                  variant={'Kor_12_r'}
                  component={'p'}
                  sx={{ color: pathname === item.path ? fill.active : fill.default }}
                >
                  {translate(`${item.title}.mainTitle`).toString()}
                </Typography>
              </Box>
            );
          })}
      </Stack>
    </Stack>
  );
}
