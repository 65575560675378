import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # ErrorAlert
 *
 * ErrorAlert을 설명하세요.
 */
export const ErrorAlert = types
  .model('ErrorAlert')
  .props({
    open: types.optional(types.boolean, false),
    code: types.optional(types.string, ''),
    message: types.optional(types.string, ''),
  })
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IErrorAlert;
      (Object.keys(newProps) as TErrorAlertKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

type TErrorAlert = Instance<typeof ErrorAlert>;
type TErrorAlertSnapshot = SnapshotOut<typeof ErrorAlert>;

export interface IErrorAlert extends TErrorAlert { }
export type TErrorAlertKeys = keyof TErrorAlertSnapshot & string;
export interface IErrorAlertSnapshot extends TErrorAlertSnapshot { }
export const createErrorAlert = () =>
  types.optional(ErrorAlert, {
    open: false,
    code: '',
    message: '',
  } as TErrorAlert);
