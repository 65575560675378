import { ILoadingStore } from 'src/models/loading-store/LoadingStore';
import { isAndroid, isIOS } from 'react-device-detect';
import { ReactComponent as IcoAdmin } from 'src/assets/icons/ico-user-admin.svg';
import { ReactComponent as IcoDoctor } from 'src/assets/icons/ico-user-doctor.svg';
import { ReactComponent as IcoNurse } from 'src/assets/icons/ico-user-nurse.svg';
import { ReactComponent as IcoDriver } from 'src/assets/icons/ico-user-driver.svg';
import { ReactComponent as IcoClericalStaff } from 'src/assets/icons/ico-user-clerical-staff.svg';
import { ReactComponent as IcoCareManager } from 'src/assets/icons/ico-user-care-manager.svg';
import { ReactComponent as IcoHomeHealthNurse } from 'src/assets/icons/ico-user-home-health-nurse.svg';
import { ReactComponent as IcoCaregiver } from 'src/assets/icons/ico-user-caregiver.svg';
import { ReactComponent as IcoPatient } from 'src/assets/icons/ico-user-patient.svg';
import { ReactComponent as IcoVisitor } from 'src/assets/icons/ico-user-visitor.svg';

export const CallApiToStore = async (
  storeFunction: Promise<any>,
  type: 'page' | 'api',
  loadingStore: ILoadingStore,
) => {
  loadingStore.allLoading();
  return await storeFunction
    .then(() => {
      loadingStore.closeLoadingNoMsg();
    })
    .catch((e) => {
      loadingStore.closeLoadingNoMsg();
    })
    .finally(() => {
      loadingStore.closeLoadingNoMsg();
    });
};

interface Message {
  type: string;
  payload?: any;
}

export const sendReactNativeMessage = ({ type, payload }: Message) => {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, payload }));
  }
};

export const sendLog = (msg: any) => {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'log', payload: { msg: msg } }));
  }
};

export const detectMobileDevice = (agent: any) => {
  const mobileRegex = [/Android/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  return mobileRegex.some((mobile) => agent.match(mobile));
};

export function getIOSVersion(): string | undefined {
  const match = navigator.userAgent.match(/(iPad|iPhone|iPod)\s+OS\s([\d_]+)/);
  if (match) {
    return match[2].replace(/_/g, '.');
  }
  return undefined;
}

export const numberComma = (num: number = 0) => {
  return num.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
};

export const addMessageListener = (listener: any) => {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    if (isAndroid) {
      document.addEventListener('message', listener);
      // console.log('Listen message..');
    }
    if (isIOS) {
      window.addEventListener('message', listener);
      // console.log('Listen message..');
    }
  }
};

export const removeMessageListener = (listener: any) => {
  // @ts-ignore
  if (window.ReactNativeWebView) {
    if (isAndroid) {
      document.removeEventListener('message', listener);
    }
    if (isIOS) {
      window.removeEventListener('message', listener);
    }
  }
};

export const getImagePath = (thumbnlPath: string) => {
  const { REACT_APP_IMAGE_STORAGE } = process.env;

  if (thumbnlPath) {
    if (thumbnlPath.substr(0, 4) === 'http') {
      return thumbnlPath;
    } else {
      return REACT_APP_IMAGE_STORAGE + thumbnlPath;
    }
  } else {
    return '/assets/default-goods.svg';
  }
};

export const getFileSize = (bytes: number = 0) => {
  if (bytes < 1024000) {
    return (bytes / 1024).toFixed(1) + ' KB';
  } else {
    return (bytes / (1024 * 1024)).toFixed(1) + ' MB';
  }
};

export const requestPermission = () => {
  try {
    console.log('권한 요청 중...');
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('알림 권한이 허용됨');

        // FCM 메세지 처리
      } else {
        console.log('알림 권한 허용 안됨');
      }
    });
  } catch (e) {
    console.log('💬 ', e);
  }
};

export const getIconByAuthCd = (authCd: string) => {
  if (authCd) {
    switch (authCd) {
      case '1':
        return IcoDoctor;

      case '2':
        return IcoNurse;

      case '3':
        return IcoDriver;

      case '4':
        return IcoClericalStaff;

      case '5':
        return IcoCareManager;

      case 'A':
        return IcoAdmin;

      case '7':
        return IcoHomeHealthNurse;

      case '8':
        return IcoCaregiver;

      case '9':
        return IcoPatient;

      default:
        return IcoVisitor;
        break;
    }
  } else {
    return IcoVisitor;
  }
};
