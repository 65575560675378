import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useStores } from '../../../../models/root-store/root-store-context';
import { useTheme, Toolbar, Stack, Button, Typography, Badge } from '@mui/material';
import { HEADER } from 'src/config-global';
import { ReactComponent as IcoMicOn } from 'src/assets/icons/ico-mic_none.svg';
import { ReactComponent as IcoVideoOn } from 'src/assets/icons/ico-videocam.svg';
import { ReactComponent as IcoSetting } from 'src/assets/icons/ico-setting.svg';
import useResponsive from 'src/hooks/useResponsive';
import { ReactComponent as IcoShareScreen } from 'src/assets/icons/ico-share-screen.svg';
import { Chat } from 'src/models';
import { useLocales } from 'src/locales';
import { toJS } from 'mobx';
import { useLocation } from 'react-router';
import moment from 'moment';

/**
 * ## Footer 설명
 *
 */
export const Footer = observer(({ ...props }: any) => {
  const rootStore = useStores();
  const { conferenceStore: store, loadingStore } = rootStore;
  const theme = useTheme();
  const isDownMd = useResponsive('down', 'md');

  const { translate } = useLocales();
  const [hasChat, setHasChat] = useState(props.state.room.hasChat);
  const { state } = useLocation();
  const fileLength = store.conference.room.shareFiles?.length || 0;
  const [chatBadge, setChatBadge] = useState(true);
  const [fileBadge, setFileBadge] = useState(true);
  const [hoursA, setHoursA] = useState('00');
  const [minA, setMinA] = useState('00');
  const [secA, setSecA] = useState('00');
  const [time, setTime] = useState('');
  const didMount = useRef(false);

  useEffect(() => {
    setInterval(() => {
      if (store.conference.startedTime === null) {
        return;
      }
      setTime(
        moment
          .utc(
            moment.duration(moment().diff(moment(store.conference.startedTime))).asMilliseconds(),
          )
          .format('HH:mm:ss'),
      );
    }, 1000);
  }, [store.conference]);

  useEffect(() => {
    if (hasChat === true) {
      setChatBadge(false);
    } else {
      setChatBadge(true);
    }
  }, []); // 처음 입장 시 채팅 있는경우 뱃지 표시
  
  useEffect(() => {
    if (hasChat === true) {
      if (props.sideState.chat === true) {
        setChatBadge(true);
      }
    }
  }, [props.sideState.chat]); // 채팅창 열었을 때 뱃지 제거
  useEffect(() => {
    setHasChat(true);
    if (hasChat === true) {
      if (props.sideState.chat === false) {
        setChatBadge(false);
      }
    }
  }, [props.newChatMsg]); // 새 채팅메세지 올때마다 채팅창 닫혀있으면 뱃지 표시

  useEffect(() => {
    if (fileLength > 0) {
      //튀어나오고 true
      if (props.sideState.share === true) {
        //없어지고 true
        setFileBadge(true);
        state.uploadShare = false;
      } else {
        if (didMount.current === false) {
          didMount.current = true;
          setFileBadge(false);
        } else if (state.uploadShare === false || state.uploadShare === undefined) {
          setFileBadge(true);
        } else if (state.uploadShare === true) {
          setFileBadge(false);
          state.uploadShare = false;
        }
      }
    } else {
      setFileBadge(true);
      state.uploadShare = false;
    }
  }, [fileLength, props.sideState.share]);

  return (
    <>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: 1,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
          }}
        >
          <Stack direction={'row'} spacing={2} sx={{ px: 2 }}>
            <Button
              sx={{ flexDirection: 'column' }}
              color={'inherit'}
              onClick={props.onToggleMic}
              id={'toggleMic'}
              disabled={props.buttonMicFlag}
            >
              {props.toggleMic === false && <IcoMicOn fill={'#919EAB'} />}
              {props.toggleMic === true && <IcoMicOn fill={'#33FF33'} />}
              {props.toggleMic === false && (
                <Typography variant={'body2'}>
                  {translate(`meeting.audioOff`).toString()}
                </Typography>
              )}
              {props.toggleMic === true && (
                <Typography variant={'body2'}>{translate(`meeting.audioOn`).toString()}</Typography>
              )}
            </Button>
            <Button
              sx={{ flexDirection: 'column' }}
              color={'inherit'}
              onClick={props.onToggleVideo}
              id={'toggleVideo'}
              disabled={props.buttonVideoFlag}
            >
              {props.toggleVideo === false && <IcoVideoOn fill={'#919EAB'} />}
              {props.toggleVideo === true && <IcoVideoOn fill={'#33FF33'} />}
              {props.toggleVideo === false && (
                <Typography variant={'body2'}>
                  {translate(`meeting.videoOff`).toString()}
                </Typography>
              )}
              {props.toggleVideo === true && (
                <Typography variant={'body2'}>{translate(`meeting.videoOn`).toString()}</Typography>
              )}
            </Button>
            {!isDownMd && (
              <Button
                color={'inherit'}
                size={isDownMd ? 'medium' : 'large'}
                sx={{ px: 2 }}
                onClick={props.handleModalSettingOpen}
              >
                <IcoSetting fill={'#919EAB'} />
              </Button>
            )}
          </Stack>
          <Stack spacing={2} direction={'row'} alignItems={'center'}>
            {props.navConfig.map((item: any, i: number) => {
              if (item.owner === true && props.state.ownerYn !== 'Y') {
                return null;
              }

              if (isDownMd && !item.useDownMd) {
                return null;
              }
              if (item.recordYn !== undefined) {
                if (item.recordYn !== true && props.state.ownerYn === 'Y') {
                  return null;
                }
              }
              if (props.mobile === true && item.value === 'record') {
                return null;
              }
              if (props.mobile === true && item.value === 'shareScreen') {
                return null;
              }
              return (
                <Button
                  key={'controlButton-' + i}
                  sx={{ flexDirection: 'column' }}
                  color={'inherit'}
                  id={'controlButton-' + i}
                  onClick={() => {
                    item.callback();
                    if (item.checkKey) {
                    } else {
                      props.setNavState(
                        (navState: any) =>
                          (navState = {
                            ...navState,
                            ['controlButton_' + i]: navState['controlButton_' + i]
                              ? !navState['controlButton_' + i]
                              : true,
                          }),
                      );
                    }
                  }}
                >
                  {item.checkKey === 'chat' ? (
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={chatBadge || props.sideState.chat}
                    >
                      <item.icon
                        fill={
                          props.sideState[item.checkKey]
                            ? item.activeColor || props.navColors.active
                            : props.navColors.inactive
                        }
                      />
                    </Badge>
                  ) : item.checkKey === 'share' ? (
                    <Badge
                      color="primary"
                      variant="dot"
                      invisible={fileBadge || props.sideState.share}
                    >
                      <item.icon
                        fill={
                          props.sideState[item.checkKey]
                            ? item.activeColor || props.navColors.active
                            : props.navColors.inactive
                        }
                      />
                    </Badge>
                  ) : item.checkKey ? (
                    <item.icon
                      fill={
                        props.sideState[item.checkKey]
                          ? item.activeColor || props.navColors.active
                          : props.navColors.inactive
                      }
                    />
                  ) : (
                    <item.icon
                      fill={
                        props.navState['controlButton_' + i]
                          ? item.activeColor || props.navColors.active
                          : props.navColors.inactive
                      }
                    />
                  )}
                  <Typography variant={'body2'}>{item.title}</Typography>
                </Button>
              );
            })}
          </Stack>
          {!isDownMd && (
            <>
              <Stack spacing={1} direction={'row'} alignItems={'center'}>
                {/* <Box sx={{ minWidth: '150px' }}>{hoursA + ':' + minA + ':' + secA}</Box> */}
                <Stack pr={1}>{time}</Stack>
                <Button
                  variant="outlined"
                  size="medium"
                  color={'error'}
                  onClick={props.leaveRoom}
                  sx={{ py: 1, wordBreak: 'keep-all', lineHeight: 1 }}
                >
                  {translate(`waiting.leave`).toString()}
                </Button>
                {props.ownerYn === 'Y' && (
                  <Button
                    variant="contained"
                    size="medium"
                    color={'error'}
                    onClick={props.onRoomClose}
                    sx={{ py: 1, wordBreak: 'keep-all', lineHeight: 1 }}
                  >
                    {translate(`waiting.end`).toString()}
                  </Button>
                )}
                {props.share === true && (
                  <Button variant="outlined" onClick={props.handleScreenOpen}>
                    {translate('meeting.share').toString()}
                  </Button>
                )}
              </Stack>
            </>
          )}
          {isDownMd && (
            <>
              {props.share === true && (
                <Stack spacing={2} direction={'row'} alignItems={'center'}>
                  <Button variant="outlined" onClick={props.handleScreenOpen}>
                    {translate('meeting.share').toString()}
                  </Button>
                </Stack>
              )}
            </>
          )}
        </Box>
      </Toolbar>
    </>
  );
});

export default Footer;
