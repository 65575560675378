import { observer } from 'mobx-react-lite';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Container, Dialog, IconButton, Paper, Stack, Typography } from '@mui/material';
import Iconify from 'src/components/iconify';
import ConferenceCreate from 'src/screens/conference/ConferenceCreate';
import { CallApiToStore } from 'src/utils/common';

import Draggable from 'react-draggable';
import useResponsive from 'src/hooks/useResponsive';

import ConferenceDetail from 'src/screens/conference/ConferenceDetail';
import { IConferFilter, IConferenceSnapshot } from 'src/models/conference/Conference';
import _ from 'lodash';
import MyConferenceM from '../my-conference/MyConferenceM';
import MyConferSearchArea from '../my-conference/MyConferSearchArea';
import MyConferenceTable from '../my-conference/MyConferenceTable';
import { useLocales } from 'src/locales';

/**
 * ## MyConference 설명
 *
 */

export interface ConferenceHistoryProps {
  pending: boolean;
  getData: () => Promise<void>;
  getDetail: (id: number) => void;
  setOpenDetail: Dispatch<SetStateAction<boolean>>;
  onSearch: (data: IConferFilter) => void;
  page: string;
  handleChangePage: (newPage: number) => void;
}

export const ConferenceHistory = observer(() => {
  const { translate } = useLocales();
  const rootStore = useStores();
  const { conferenceStore, loadingStore } = rootStore;

  const isMobile = useResponsive('down', 'md');

  const [pending, setPending] = useState(true);
  const [openCreateRoom, setOpenCreateRoom] = useState(false);
  const [path, setPath] = useState('');

  const [openDetail, setOpenDetail] = useState(false);

  const getData = async () => {
    setPending((pending) => (pending = true));
    await CallApiToStore(conferenceStore.getAll(searchData), 'api', loadingStore).then((res) => {
      setPending((pending) => (pending = false));
    });
  };

  const [searchData, setSearchData] = useState<IConferFilter>();

  const onSearch = (data: IConferFilter) => {
    setSearchData(data);
    conferenceStore.pagination.setProps({ page: 1 });
  };

  const getDetail = async (id: number) => {
    await CallApiToStore(conferenceStore.get(id), 'api', loadingStore).then(() => {
      setOpenDetail(true);
    });
  };

  useEffect(() => {
    conferenceStore.pagination.setProps({ page: 1, size: 100 });
    getData();
  }, [searchData]);

  const handleChangePage = (newPage: number) => {
    conferenceStore.pagination.setProps({ page: newPage + 1 });
    getData();
  };

  const props: ConferenceHistoryProps = {
    pending: pending,
    getData: getData,
    getDetail: getDetail,
    setOpenDetail: setOpenDetail,
    onSearch: onSearch,
    page: 'history',
    handleChangePage: handleChangePage,
  };

  return (
    <>
      {isMobile ? (
        <MyConferenceM {...props} />
      ) : (
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Stack spacing={2} sx={{ flex: 1 }}>
            <Typography variant={'h5'} sx={{ pt: 4, pb: 2 }}>
              {translate('conferenceHistory.mainTitle').toString()}
            </Typography>
            <Stack direction={'row'} sx={{ '& form': { width: '100%' } }}>
              <MyConferSearchArea isMobile={false} onSearch={onSearch} />
            </Stack>
            <MyConferenceTable {...props} />
          </Stack>
        </Container>
      )}

      {openCreateRoom && (
        <Dialog
          open={openCreateRoom}
          maxWidth={'sm'}
          fullWidth
          scroll="body"
          PaperComponent={(props) => {
            return (
              <Draggable bounds="parent" cancel="form">
                <Paper {...props} />
              </Draggable>
            );
          }}
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
          onClose={() => {
            setOpenCreateRoom(false);
            setPath('');
          }}
        >
          <IconButton
            onClick={() => setOpenCreateRoom(false)}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton>
          <ConferenceCreate
            getData={getData}
            path={path}
            handleClose={() => {
              setOpenCreateRoom(false);
              setPath('');
            }}
          />
        </Dialog>
      )}

      {openDetail && ( // 회의상세정보 Dialog
        <Dialog
          open={true}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
          onClose={() => setOpenDetail(false)}
        >
          <IconButton
            onClick={() => setOpenDetail(false)}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton>
          <ConferenceDetail
            handleClose={() => setOpenDetail(false)}
            getData={getData}
            data={{} as IConferenceSnapshot}
          />
        </Dialog>
      )}
    </>
  );
});

export default ConferenceHistory;
