import { flow, Instance, SnapshotOut, types } from 'mobx-state-tree';
import { CodeListApi } from 'src/services';
import { withEnvironment } from '../extensions/with-environment';
import { CodeListModel, ICodeListModel } from './CodeList';

export const CodeListStore = types
  .model('CodeListStore', {
    list: types.array(CodeListModel),
  })
  .extend(withEnvironment)
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICodeListStore;
      (Object.keys(newProps) as TCodeListStoreKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }))
  .actions((self) => {
    function getCodeListByName(name: string) {
      const codeList = self.list.find((item) => item.name === name);
      return codeList && codeList.list;
    }

    // API CALL ---------------------------------------------------------------
    const fetchAll = flow(function* () {
      const api = new CodeListApi(self.environment.api);
      try {
        const res = yield api.fetchAll();

        if (res.kind === 'ok') {
          const codes = res.data.map((item: ICodeListModel) => {
            return CodeListModel.create(item);
          });
          self.list.replace(codes);
          return self.list;
        }
      } catch (error) {
        console.log(error);
      }
    });

    // ------------------------------------------------------------------------
    return {
      // getCodeListByName,
      // fetchAll,// API CALL
    };
  });

type TCodeListStore = Instance<typeof CodeListStore>;
type TCodeListStoreSnapshot = SnapshotOut<typeof CodeListStore>;
export interface ICodeListStore extends TCodeListStore { }
export type TCodeListStoreKeys = keyof TCodeListStoreSnapshot & string;
export interface ICodeListStoreSnapshot extends TCodeListStoreSnapshot { }
