import { MOCK_API_CONFIG, JEST_API_CONFIG } from 'src/services/api/api-config';
import { Api } from 'src/services/api';

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor(envType?: string) {
    this.api = new Api();
  }

  async setup() {
    // allow each service to setup
    await this.api.setup();
  }

  /**
   * Our api.
   */
  api: Api;
}
