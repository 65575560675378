// routes
import { PATH_AUTH, PATH_PAGE } from '../../../routes/paths';
// config
import { PATH_AFTER_LOGIN } from '../../../config-global';
// components
import Iconify from '../../../components/iconify';
import { ReactComponent as IcoCam } from 'src/assets/images/ico-cam.svg';
import { ReactComponent as IcoPerson } from 'src/assets/images/ico-person.svg';
import { ReactComponent as IcoBell } from 'src/assets/images/ico-bell.svg';
import { ReactComponent as IcoPeople } from 'src/assets/images/ico-people.svg';

import { ReactComponent as IcoPeopleCheck } from 'src/assets/images/ico-people-check.svg';
import { ReactComponent as IcoHistory } from 'src/assets/images/ico-history.svg';
import { ReactComponent as IcoStatistics } from 'src/assets/images/ico-statistics.svg';
import { ReactComponent as IcoDashboard } from 'src/assets/images/ico-dashboard.svg';
// ----------------------------------------------------------------------

// const navConfig = [
//   {
//     title: '화상회의',
//     icon: <IcoCam />,
//     path: '/',
//   },
//   {
//     title: '마이페이지',
//     icon: <IcoPerson />,
//     path: '/'
//   },
//   {
//     title: '알림수신이력',
//     icon: <IcoBell />,
//     path: '/'
//   },
//   {
//     title: '참여자 알림이력',
//     path: '/',
//     icon: <IcoPeople />,
//   },
// ];

const navConfig = [
  {
    path: '/my/conference',
    title: 'conference',
    icon: IcoCam,
    isAdmin: false,
    isMobile: true,
    isDesktop: true,
  },
  {
    path: '/my/info',
    title: 'myPage',
    icon: IcoPerson,
    isAdmin: false,
    isMobile: true,
  },
  {
    path: '/my/alarm',
    title: 'pushNotification',
    icon: IcoBell,
    isAdmin: false,
    isMobile: true,
  },
  {
    path: '/my/participant',
    title: 'participantAlertHistory',
    icon: IcoPeople,
    isAdmin: false,
    isMobile: true,
    isDesktop: true,
  },
  // {
  //   path: '/my/user',
  //   title: '사용자관리',
  //   icon: IcoPeopleCheck,
  //   isAdmin: true,
  // },
  {
    path: '/my/history',
    title: 'conferenceHistory',
    icon: IcoHistory,
    isAdmin: true,
    isDesktop: true,
  },
  {
    path: '/my/statistics',
    title: 'statistics',
    icon: IcoStatistics,
    isAdmin: true,
    isDesktop: true,
  },
  {
    path: '/my/dashboard',
    title: 'dashBoard',
    icon: IcoDashboard,
    isAdmin: true,
    isDesktop: true,
  },
];

export default navConfig;
