import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createFacility } from '../facility/Facility';
import { StatisticsData } from '../statistics-data/StatisticsData';

/**
 * # Statistics
 *
 * Statistics을 설명하세요.
 */
export const Statistics = types
  .model('Statistics')
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.number, 0),
    statDate: types.optional(types.string, ''), // 조회 날짜
    facilityCd: types.optional(types.string, ''), // 기관/시설코드
    facility: createFacility(), // 기관/시설코드 객체
    // headers: types.optional(types.array(StatisticsData), []),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model statistics --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IStatistics;
      (Object.keys(newProps) as TStatisticsKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TStatistics = Instance<typeof Statistics>;
type TStatisticsSnapshot = SnapshotOut<typeof Statistics>;

export interface IStatistics extends TStatistics {}
export type TStatisticsKeys = keyof TStatisticsSnapshot & string;
export interface IStatisticsSnapshot extends TStatisticsSnapshot {}
export const createStatistics = () => types.optional(Statistics, {} as TStatistics);
