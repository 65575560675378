import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { IPushAlarm, PushAlarm } from '../push-alarm/PushAlarm';
import {
  PushAlarmApi,
  TGetAlarmsResult,
  TGetCountsResult,
  TGetReSendResult,
  TGetSampleRate,
  TGetSentListResult,
  TReadAlarmResult,
} from 'src/services';
import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { count } from 'console';
import {
  ISentPushAlarm,
  ISentPushAlarmSnapshot,
  SentPushAlarm,
} from '../sent-push-alarm/SentPushAlarm';
import { toJS } from 'mobx';
import { IPagination, IPaginationSnapshot, createPagination } from '../pagination/Pagination';
/**
 * # PushAlarmStore
 *
 * PushAlarmStore을 설명하세요.
 */
export const PushAlarmStore = types
  .model('PushAlarmStore')
  // --------------------------------------------------------------------------
  .props({
    pushAlarms: types.optional(types.array(PushAlarm), []),
    counts: types.optional(types.number, 0),
    sentPushAlarms: types.optional(types.array(SentPushAlarm), []),
    pagination: createPagination(),
    sampleRate: types.optional(types.number, 0),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * pushAlarms을 교체
     *
     * @param `pushAlarms` 새로운 모델의 배열
     */
    setPushAlarms: (pushAlarms: IPushAlarm[]) => {
      self.pushAlarms.replace(pushAlarms);
      // self.pushAlarms.push(pushAlarms, ...pushAlarms);
    },
    setSampleRate: (sampleRate: number) => {
      self.sampleRate = sampleRate;
    },
    getSampleRateData: () => {
      return self.sampleRate;
    },
    setCounts: (counts: number) => {
      self.counts = counts;
    },
    setSentPushAlarms: (sentPushAlarms: ISentPushAlarmSnapshot[]) => {
      self.sentPushAlarms.replace(sentPushAlarms as ISentPushAlarm[]);
    },
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Alarms를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async (existsCount?: number) => {
      try {
        const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
        let prevPage;

        if (existsCount) {
          const result: TGetAlarmsResult = await pushAlarmApi.gets(self.pagination, existsCount);
          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.kind === 'ok') {
              result.paging && self.setPagination(result.paging);
              if (self.pushAlarms.length < 1) {
                self.setPushAlarms(result.pushAlarms);
              } else {
                self.setPushAlarms(self.pushAlarms.concat(result.pushAlarms));
              }
            }
          }
        } else {
          const result: TGetAlarmsResult = await pushAlarmApi.gets(self.pagination);
          if (self.rootStore.responseStore.getResponseResult(result)) {
            if (result.kind === 'ok') {
              result.paging && self.setPagination(result.paging);
              if (self.pushAlarms.length < 1) {
                self.setPushAlarms(result.pushAlarms);
              } else {
                self.setPushAlarms(self.pushAlarms.concat(result.pushAlarms));
              }
            } else {
              console.error(result.kind);
            }
          }
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    appendPushAlarms: async () => {
      try {
        const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
        const result: TGetAlarmsResult = await pushAlarmApi.gets({
          page: 1,
        } as IPaginationSnapshot);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            self.setPushAlarms([result.pushAlarms[0], ...self.pushAlarms]);
          }
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    resetPushAlarms: () => {
      self.pagination.setProps({ page: 1 }); //, totalElements: 0 });
      self.setPushAlarms([]);
    },
    // // 기존
    // gets: async (isReset?: boolean, existsCount?: number) => {
    //   try {
    //     const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
    //     let prevPage;
    //     if (isReset) {
    //       self.setPushAlarms([]);
    //       prevPage = self.pagination.page;
    //       self.pagination.setProps({ page: 1 });
    //     }
    //     if (existsCount) {
    //       // self.setPushAlarms([]);
    //       // prevPage = self.pagination.page;
    //       // self.pagination.setProps({ page: 1 });
    //       console.log('store existsCount', existsCount);
    //     }
    //     const result: TGetAlarmsResult = await pushAlarmApi.gets(self.pagination);
    //      if (self.rootStore.responseStore.getResponseResult(result)) {

    //       // result.paging && self.setPagination(result.paging);
    //       result.paging && self.setPagination(result.paging);
    //       if (isReset) {
    //         self.pagination.setProps({ page: prevPage });
    //       }
    //       if (self.pushAlarms.length < 1) {
    //         self.setPushAlarms(result.pushAlarms);
    //       } else {
    //         self.setPushAlarms(self.pushAlarms.concat(result.pushAlarms));
    //         // result.paging && self.setPagination(result.paging);
    //         // console.log('🪄 ~ gets: ~ result.paging:', result.paging);
    //       }
    //     } else {
    //       console.error(result.kind);
    //     }
    //   } catch (error) {
    //     self.rootStore.responseStore.errorProcessing(error);
    //   }
    // },

    /**
     * 참여자 알람이력을 가져온다 ( 내가 송신한 알람이력을 가져온다 )
     */
    getsSentList: async () => {
      try {
        const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
        const result: TGetSentListResult = await pushAlarmApi.getsSentList(self.pagination);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            if (result.data) {
              self.setSentPushAlarms(
                result.data.map((v: ISentPushAlarmSnapshot, i: number) => {
                  return { ...v, id: i };
                }),
              );
              result.paging && self.setPagination(result.paging);
            }
          } else {
            console.error(result.kind);
          }
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    /**
     *  안읽은 수신 푸시 알람이력 갯수를 가져온다.
     */
    getAlarmCounts: async () => {
      try {
        const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
        const result: TGetCountsResult = await pushAlarmApi.getAlarmCounts();
        // if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          self.setCounts(result.data);
        } else {
          console.error(result.kind);
        }
        // }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    getSampleRate: async () => {
      try {
        const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
        const result: TGetSampleRate = await pushAlarmApi.getSampleRate();
        if (result.kind === 'ok') {
          self.setSampleRate(result.data);
        } else {
          console.error(result.kind);
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    reSend: async (data: ISentPushAlarmSnapshot) => {
      try {
        const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
        const result: TGetReSendResult = await pushAlarmApi.reSend(data);
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
            // self.setCounts(result.data);
          } else {
            console.error(result.kind);
          }
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },

    /**
     * 읽지 않은 알람들을 읽음으로 바꾼다.
     */
    readAlarm: async () => {
      try {
        const pushAlarmApi: PushAlarmApi = new PushAlarmApi(self.environment.api);
        const result: TReadAlarmResult = await pushAlarmApi.readAlarm();
        if (self.rootStore.responseStore.getResponseResult(result)) {
          if (result.kind === 'ok') {
          } else {
            console.error(result.kind);
          }
        }
      } catch (error) {
        self.rootStore.responseStore.errorProcessing(error);
      }
    },
  }));

// --------------------------------------------------------------------------
type TPushAlarmStore = Instance<typeof PushAlarmStore>;
type TPushAlarmStoreSnapshot = SnapshotOut<typeof PushAlarmStore>;

export interface IPushAlarmStore extends TPushAlarmStore {}
export type TPushAlarmStoreKeys = keyof TPushAlarmStoreSnapshot & string;
export interface IPushAlarmStoreSnapshot extends TPushAlarmStoreSnapshot {}
export const createPushAlarmStore = () => types.optional(PushAlarmStore, {} as TPushAlarmStore);
