import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import kurentoUtils from 'kurento-utils';
import { IParticipant } from 'src/models';
/**
 * ## Participant 설명
 *
 */

type Props = {
  participant: IParticipant;
  sendMessage: (message: any) => void;
  onConnectionstatechange: (peerConnection: any) => void;
  setPeer?: (cuid: string, uid: string, peer: any, reset: any) => void;
  recordStream: any;
};
let peer: any;
export const RecordParticipant = observer(
  ({
    participant,
    sendMessage,
    onConnectionstatechange,
    setPeer,
    recordStream
  }: Props) => {
    const rootStore = useStores();
    const { pushAlarmStore } = rootStore;
    const [stream, setStream] = useState<any>();
    const videoRef = useRef<any>(); // createRef<HTMLVideoElement>();
    const AudioCtx = window.AudioContext; // || window.webkitAudioContext;
    const [screenFlag, setScreenFlag] = useState<any>(0);
    let constraints = {
      audio: {
        // echoCancellation: true,
        // noiseSuppression: true,
        sampleRate: pushAlarmStore.getSampleRateData() ? pushAlarmStore.getSampleRateData() : 48000,
        echoCancellation: {exact: true},
        autoGainControl: {exact :false},
        noiseSuppression: {exact: false}, 
        highpassFilter: {exact :false},
      },
      video: {
        width: 1280,
        framerate: 30,
      },
    };

    const myUID = sessionStorage.getItem('myUID');
    const myCUID = sessionStorage.getItem('myCUID');

    const refresh = () => {
      if(participant.stream?.activities.length===0){
        recordStream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }
      const msg = {
        type: 'kurento',
        id: 'broadcastRestarted',
      };
      sendMessage(msg);
    };

    const screen = (recordStream: any) => {
      if(screenFlag>0){
        if (recordStream !== undefined && recordStream !== 'reset') {
          setStream((stream: any) => recordStream);
          onSend(recordStream);
        }
      }
    }

    useEffect(() => {
      if (myCUID === participant.cuid) {
        refresh();
      }
    }, [participant.uid, participant.stream?.activities.length]);

    useEffect(() => {
      setScreenFlag(screenFlag+1);
      screen(recordStream);
    }, [recordStream]);

    const onSend = (state: any) => {

      const videoElement = videoRef.current;
      if (!videoElement) {
        setTimeout(() => {
          onSend(state);
        }, 100);
        return;
      }

      if (videoElement) {
        videoElement.srcObject = state;
      }

      peer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(
        {
          localVideo: videoElement,
          videoStream: state,
          mediaConstraints: constraints,
          onicecandidate: onIceCandidate,
        },
        (error) => {
          if (error) {
            return console.error(error);
          }
          peer.generateOffer(offerToReceiveVideo);
        },
      );
      setPeer && setPeer(participant.cuid, participant.uid, peer, "reset");
      onConnectionstatechange(peer.peerConnection);
    };

    const offerToReceiveVideo = function (error: any, offerSdp: any) {
      if (error) {
        return console.error('sdp offer error');
      }
      let msg: any = {
        type: 'kurento',
        id: 'broadcastStarted',
        uid: participant.uid,
        sdpOffer: offerSdp,
      };
      if (myCUID !== participant.cuid) {
        msg = {
          type: 'kurento',
          id: 'addListener',
          sender: participant.uid,
          sdpOffer: offerSdp,
        };
      }
      sendMessage(msg);
    };
    
    const onIceCandidate = (candidate: any, wp: any) => {
      const message = {
        type: 'kurento',
        id: 'onIceCandidate',
        candidate: candidate,
        uid: participant.uid,
        luid: myUID !== participant.uid ? myCUID : participant.uid,
      };
      sendMessage(message);
    };

    return (
      <>
        <video
          ref={videoRef}
          id={'video-' + participant.uid}
          autoPlay={true}
          controls={false}
          playsInline
          style={{
            position:'absolute',
            width: '0px',
            height: '0px',
          }}
        />
      </>
    );
  },
);

export default RecordParticipant;
