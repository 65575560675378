import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import { useStores } from "../../../models/root-store/root-store-context"
import { Button, Container, InputAdornment, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useForm } from 'react-hook-form';
import { GridColDef } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import CSelect from 'src/components/forms/CSelect';
import CTextField from 'src/components/forms/CTextField';
import Iconify from 'src/components/iconify';
import { pxToRem } from 'src/theme/typography';
import { CDataGrid } from 'src/components/CDataGrid';

/**
 * ## MyUserManagement 설명
 *
 */
export const UserManagement = observer(() => {

  const rootStore = useStores();
  const { loadingStore } = rootStore;
  const theme = useTheme();

  const selectOptions = {
    group: [
      {
        code: 0,
        pcode: 0,
        value: '그룹 1',
      },
      {
        code: 1,
        pcode: 1,
        value: '그룹 2'
      },
    ],
  }

  const methods = useForm<any>({});
  const { formState } = methods;

  const columns: GridColDef[] = [
    { field: 'group', headerName: '그룹', headerAlign: 'center', align: 'center', flex: 100, },
    { field: 'role', headerName: '권한', headerAlign: 'center', align: 'center', flex: 100 },
    { field: 'name', headerName: '이름', headerAlign: 'center', align: 'center', flex: 100 },
    { field: 'email', headerName: 'E-mail', flex: 200 },
    { field: 'tel', headerName: '전화번호', flex: 100 },
  ];

  const rows: any[] = [
    {
      id: '1',
      group: '일반그룹',
      role: '일반사용자',
      name: '홍길동',
      email: 'hgd@innerwave.co.kr',
      tel: '+82-10-5180-3210',
    },
    {
      id: '2',
      group: '일반그룹',
      role: '일반사용자',
      name: '홍길동',
      email: 'hgd@innerwave.co.kr',
      tel: '+82-10-5180-3210',
    },
    {
      id: '3',
      group: '일반그룹',
      role: '일반사용자',
      name: '홍길동',
      email: 'hgd@innerwave.co.kr',
      tel: '+82-10-5180-3210',
    },
  ];


  return (
    <>
      <Container maxWidth={false}
        sx={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: 4
        }}>

        <Stack spacing={2} sx={{
          flex: 1,
          '& .MuiStack-root>:not(style)+:not(style)': {
            marginLeft: '0 !important'
          }
        }}>
          <Typography variant={'h5'}>사용자관리</Typography>

          <FormProvider methods={methods}>
            <Box>
              <Stack direction={'row'} spacing={2} sx={{
                gap: 2,
                display: 'grid',
                gridTemplateColumns: '.3fr 1fr',
              }}>

                <CSelect
                  label={''}
                  name={'period'}
                  variant={'outlined'}
                  sx={{
                    maxHeight: 40,
                    textAlign: 'left',
                  }}
                  defaults={{ code: '', value: '선택하세요' }}
                  options={selectOptions.group}
                />

                <CTextField
                  name={'keyword'}
                  label={''}
                  variant={'outlined'}
                  placeholder={'이름을 입력하세요'}
                  //   size={'small'}
                  sx={{ m: 0 }}

                  startAdornment={
                    <InputAdornment
                      position="start"
                      sx={{
                        alignItems: 'center',
                      }}
                    >
                      <Button sx={{ p: 0, m: 0, minWidth: pxToRem(25), height: pxToRem(25) }}>
                        <Iconify
                          icon={'ri:search-line'}
                          onClick={(e: any) => {
                          }}
                          sx={{
                            mr: pxToRem(10),
                            color: '#999'

                          }}
                        />
                      </Button>
                    </InputAdornment>
                  }

                />
              </Stack>
            </Box>
          </FormProvider>




          <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', pt: 2 }}>
            <Paper sx={{ flex: '1', height: '100%' }}>
              <CDataGrid
                // {...data}
                columns={columns}
                rows={rows}
                rowId={'id'}
                paging={true}
                pagingType={'custom'}
                autoPageSize={false}
                density={'standard'}
                disableSelectionOnClick
                onPageChange={(newPage: number) => {
                }}
                onSelectionModelChange={(newSelectionModel: any) => {
                }}
                onCellDoubleClick={(row) => {

                }}
                checkbox={true}

                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // type: false,
                      // parent_id: false,
                    },
                  },
                }}
              />
            </Paper>
          </Box>

        </Stack>
      </Container>
    </>
  );
});


export default UserManagement;