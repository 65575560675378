import {
  Box,
  Button,
  DialogContent,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { IConferenceSnapshot, IRecordingFileSnapshot, useStores } from 'src/models';
import { ReactComponent as IcoClose } from 'src/assets/icons/ico-close.svg';
import { useEffect, useRef, useState } from 'react';
import Scrollbar from 'src/components/scrollbar/Scrollbar';
import axios from 'axios';
import { useLocales } from 'src/locales';
import LoadingScreen from 'src/components/loading-screen';
import CAlert from 'src/components/CAlert';
import Iconify from 'src/components/iconify/Iconify';

interface Props {
  handleClose: VoidFunction;
  fileData: IRecordingFileSnapshot[];
  data: IConferenceSnapshot;
  getData: () => Promise<void>;
}

const RecordingFiles = observer(({ fileData, data, handleClose, getData }: Props) => {
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { REACT_APP_API_URL } = process.env;
  const rootStore = useStores();
  const { responseStore, errorAlertStore } = rootStore;
  const { translate } = useLocales();
  const [videoList, setVideoList] = useState<IRecordingFileSnapshot[]>([
    {} as IRecordingFileSnapshot,
  ]);
  const [videoData, setVideoData] = useState(fileData[0]);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(0);

  const [pending, setPending] = useState(true);
  const [alertMsg, setAlertMsg] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const token = localStorage.getItem('accessToken');
  const user = JSON.parse(localStorage.user);
  const isSystemAdmin = user.isAdmin === true;

  const onClick = (e: IRecordingFileSnapshot) => {
    setVideoData(e);
  };

  const [videoSrc, setVideoSrc] = useState<any>(null);
  const recordFilePlay = async (fileId: number) => {
    setPending((pending) => (pending = true));
    await axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/web/file/validateConvert`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({ fileId: fileId }),
    }).then(async (res: any) => {
      if (res.data.responseData === 'success') {
        setVideoSrc(
          `${REACT_APP_API_URL}/web/file/downloadRec?accessToken=${token}&fileId=${fileId}`,
        );
        setPending((pending) => (pending = false));
      } else {
        if (res.data.error) {
          responseStore.setResponseInfo(res.data);
          errorAlertStore.setErrorAlertFromResponse();
        }
        setPending((pending) => (pending = false));
        setIsAlertOpen(true);
        setAlertMsg(translate(`error.${res.data.error}`).toString());
        setVideoSrc(null);
      }
    });
  };

  useEffect(() => {
    setVideoList(fileData);
  }, [fileData]);

  useEffect(() => {
    recordFilePlay(videoData.id);
  }, [videoData]);

  const [deleteAlert, setDeleteAlert] = useState(false);
  const deleteRecordFile = async (fileId: number) => {
    await axios({
      method: 'POST',
      url: `${REACT_APP_API_URL}/web/file/delete`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // responseType: 'blob',
      data: { fileIdList: [fileId] },
    })
      .then((res) => {
        if (res.data.error) {
          responseStore.setResponseInfo(res.data);
          errorAlertStore.setErrorAlertFromResponse();
        } else {
          getData();
          handleClose();
        }
      })
      .catch((e) => {
        console.log('🌈 ~ onDelete ~ e:', e);
      });
  };

  return (
    <>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', p: 0, maxHeight: '90vh' }}>
        <Stack direction={'row'} justifyContent={'center'} sx={{ position: 'relative', p: 2 }}>
          <Typography variant={'subtitle1'} color={theme.palette.text.secondary}>
            {translate('conference.table.recordingFile').toString()}
          </Typography>

          <IconButton
            onClick={handleClose}
            sx={{ position: 'absolute', top: 8, right: 8 }}
            color={'inherit'}
          >
            <IcoClose stroke={'#637381'} />
          </IconButton>
        </Stack>
        <Divider />
        <Stack direction={'row'} overflow={'hidden'}>
          <Box
            width={'25%'}
            sx={{
              borderRight: `1px solid ${theme.palette.divider}`,
            }}
          >
            <List
              sx={{
                p: 0,
                height: '100%',
              }}
            >
              <Scrollbar
                scrollableNodeProps={{
                  ref: scrollRef,
                }}
              >
                {videoList.map((v, i) => {
                  return (
                    <Box
                      key={'box' + (v.id, i)}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        background:
                          selectedButtonIndex === i ? theme.palette.primary.main : 'transparent',
                      }}
                    >
                      <ListItem
                        onClick={() => {
                          onClick(v);
                          setSelectedButtonIndex(i);
                        }}
                      >
                        <Button
                          // onClick={() => {
                          //   onClick(v);
                          //   setSelectedButtonIndex(i);
                          // }}
                          sx={{
                            width: '100%',
                            borderRadius: 0,
                            background:
                              selectedButtonIndex === i
                                ? theme.palette.primary.main
                                : 'transparent',
                            color: selectedButtonIndex === i ? 'white' : 'GrayText',
                            '&.MuiButtonBase-root:hover': {
                              color: theme.palette.text.primary,
                              background:
                                selectedButtonIndex === i ? theme.palette.primary.main : '',
                            },
                          }}
                        >
                          {i + 1 + '. ' + v.name}
                        </Button>
                        {(data.ownerYn === 'Y' || !!isSystemAdmin) && (
                          <IconButton
                            onClick={() => {
                              setDeleteAlert(true);
                            }}
                          >
                            <Iconify icon={'ph:trash-bold'} />
                          </IconButton>
                        )}
                      </ListItem>
                    </Box>
                  );
                })}
              </Scrollbar>
            </List>
          </Box>
          <Box
            width={'80%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            m={2}
          >
            <Typography variant="h5">[{videoData.name}]</Typography>
            <Box
              display={'flex'}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                width: '100%',
                maxHeight: '100%',
              }}
              onContextMenu={(e) => {
                e.preventDefault(); // 우클릭 메뉴 무시
              }}
            >
              {pending && (
                <Box
                  display={'flex'}
                  flexDirection={'column'}
                  p={10}
                  sx={{ position: 'absolute', zIndex: 1 }}
                >
                  <LoadingScreen />
                  {/* <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                  <Trans i18nKey={'loading'} />
                </Typography> */}
                </Box>
              )}
              {!pending && videoSrc !== null && (
                <video
                  src={videoSrc}
                  width={'100%'}
                  height={'100%'}
                  autoPlay
                  controls
                  controlsList="nodownload"
                />
              )}
              {videoSrc === null && (
                <video
                  src={videoSrc}
                  width={'100%'}
                  height={'100%'}
                  autoPlay
                  controls
                  controlsList="nodownload"
                />
              )}
            </Box>
          </Box>
        </Stack>
      </DialogContent>
      <CAlert
        open={isAlertOpen}
        handleClose={() => setIsAlertOpen(false)}
        content={alertMsg}
        title={videoData.name}
      />
      {!pending && deleteAlert && (
        <CAlert
          open={deleteAlert}
          handleClose={() => setDeleteAlert(false)}
          title={selectedButtonIndex + 1 + '.' + videoData.name}
          content={translate('meeting.alert.fileDelete').toString()}
          callBack={() => {
            deleteRecordFile(videoData.id);
          }}
          hasCancel
        />
      )}
    </>
  );
});

export default RecordingFiles;
