// @mui
import { Badge, Box, List, ListItem, Stack, Typography } from '@mui/material';
//
import { NavProps } from '../types';
import NavList from './NavList';

import { ReactComponent as IcoCam } from 'src/assets/images/ico-cam.svg';
import { ReactComponent as IcoPerson } from 'src/assets/images/ico-person.svg';
import { ReactComponent as IcoBell } from 'src/assets/images/ico-bell.svg';
import { ReactComponent as IcoPeople } from 'src/assets/images/ico-people.svg';
import { ReactComponent as IcoPeopleCheck } from 'src/assets/images/ico-people-check.svg';
import { ReactComponent as IcoHistory } from 'src/assets/images/ico-history.svg';
import { ReactComponent as IcoStatistics } from 'src/assets/images/ico-statistics.svg';
import { ReactComponent as IcoDashboard } from 'src/assets/images/ico-dashboard.svg';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

// ----------------------------------------------------------------------

export default function NavDesktop({ isOffset, data }: NavProps) {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const fill = {
    default: '#919EAB',
    active: '#FFFFFF',
  };

  const config = [
    {
      path: '/my/conference',
      name: '화상회의',
      ico: IcoCam,
      isAdmin: false,
    },
    {
      path: '/my/info',
      name: '마이페이지',
      ico: IcoPerson,
      isAdmin: false,
    },
    {
      path: '/my/alarm',
      name: '알림수신이력',
      ico: IcoBell,
      isAdmin: false,
    },
    {
      path: '/my/participant',
      name: '참여자 알림이력',
      ico: IcoPeople,
      isAdmin: false,
    },
    {
      path: '/my/user',
      name: '사용자관리',
      ico: IcoPeopleCheck,
      isAdmin: true,
    },
    {
      path: '/my/history',
      name: '화상회의이력',
      ico: IcoHistory,
      isAdmin: true,
    },
    {
      path: '/my/statistics',
      name: '통계',
      ico: IcoStatistics,
      isAdmin: true,
    },
    {
      path: '/my/dashboard',
      name: '대시보드',
      ico: IcoDashboard,
      isAdmin: true,
    },
  ];

  return (
    <Stack component="nav" direction="row" spacing={5} sx={{ mr: 5, height: 1 }}>
      <Stack direction={'row'} spacing={8} sx={{ '& *': { transition: 'all 0.2s' } }}>
        {config.map((item: any, index: number) => {
          return (
            <Box
              key={`menu-${index}`}
              sx={{ textAlign: 'center', lineHeight: '1.2', cursor: 'pointer' }}
              onClick={() => {
                navigate(item.path);
              }}
            >
              <item.ico fill={pathname === item.path ? fill.active : fill.default} />
              {/* <IcoCam fill={pathname === item.path ? fill.active : fill.default} /> */}
              <Typography
                variant={'Kor_12_r'}
                component={'p'}
                sx={{ color: pathname === item.path ? fill.active : fill.default }}
              >
                {item.name}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
}
