import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IStatistics } from 'src/models';
import { TGetStatisticsResult } from './StatisticsTypes';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';

/**
 * # Statistics Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new StatisticsApi();
 *
 * ```
 */
export class StatisticsApi {
  private BASE_URL = '/web/stat';
  private api: Api;

  constructor(api: Api) {
    this.api = api;
    this.api.setup();
  }

  /**
   * 당일 현재 진행중인 회의 리스트 가져오기
   * @returns
   */
  async gets(pagination: IPaginationSnapshot, date: any): Promise<TGetStatisticsResult> {
    try {
      const url = `${this.BASE_URL}/day/facilityOwner`;
      const params = { ...date, size: pagination.size, page: pagination.page };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      // const data: IStatistics[] = response.data.responseData;

      const data: any = JSON.stringify(response.data.responseData);
      const responseInfo: any = response.data;

      return { kind: 'ok', data, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
