import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-quill/dist/quill.snow.css';
import 'simplebar/src/simplebar.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import './locales/i18n';
import './utils/mapboxgl';
// import './firebase-message-sw';
// ----------------------------------------------------------------------
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { MotionLazyContainer } from './components/animate';
import { CommonProvider } from './components/CommonContext';
import LoadingAPI from './components/LoadingAPI';
import ScrollToTop from './components/scroll-to-top';
import { SettingsProvider } from './components/settings';
import SnackbarProvider from './components/snackbar';
import ThemeLocalization from './locales';
import { RootStore, RootStoreProvider, setupRootStore } from './models';
import Router from './routes';
import ThemeProvider from './theme';
import { sendReactNativeMessage } from './utils/common';
import { GlobalDebug } from './utils/globalDebug';
import { AuthProvider } from './auth/WatisAuthContext';
import { WebsocketProvider } from './auth/WebsocketContext';
import { LicenseInfo } from '@mui/x-license-pro';
import ErrorAlert from './screens/common/ErrorAlert';

// ----------------------------------------------------------------------

/**
 * firebase messaging proc
 */
// const firebaseConfig = {
//   apiKey: "AIzaSyCC9W_qHp3U-8cfyKJj7QzMRkInuLRzM00",
//   authDomain: "spaeon-8f597.firebaseapp.com",
//   projectId: "spaeon-8f597",
//   storageBucket: "spaeon-8f597.appspot.com",
//   messagingSenderId: "141100853030",
//   appId: "1:141100853030:web:e93459eb0b14034f0543e7",
//   measurementId: "G-DJ7MZRJS3J"
// };
// const firebaseApp = initializeApp(firebaseConfig);
// const messaging = getMessaging(firebaseApp);
// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });
// (async () => {
//   await getToken(messaging, {
//     vapidKey: process.env.REACT_APP_VAPID_KEY
//   })
//     .then((fcmToken) => {
//       if (fcmToken) {
//         console.log('fcmToken', fcmToken);
//         localStorage.setItem('fcmToken', fcmToken)
//         sendReactNativeMessage({
//           type: 'log',
//           payload: {
//             msg: 'web fcm token : ' + fcmToken
//           }
//         });
//       } else {
//         // Show permission request UI
//         try { // only desktop
//           Notification.requestPermission().then((permission) => {
//             if (permission === "granted") {
//               console.log("알림 권한이 허용됨");
//             } else {
//               console.log("알림 권한 허용 안됨");
//             }
//           });
//         } catch (e) {
//         }
//         localStorage.removeItem('fcmToken')
//         // console.log('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((err) => {
//       localStorage.removeItem('fcmToken')
//       try {
//         Notification.requestPermission().then((permission) => {
//           if (permission === "granted") {
//             console.log("알림 권한이 허용됨");
//           } else {
//             console.log("알림 권한 허용 안됨");
//           }
//         });
//       } catch (e) {
//       }
//       console.log(err);
//       sendReactNativeMessage({
//         type: 'log',
//         payload: {
//           msg: 'web fcm token error : ' + err
//         }
//       });
//     });
// })();

// onMessage(messaging, (payload) => {
//   console.log('Message received. ', payload);
// });

export default function App() {
  const [rootStore, setRootStore] = useState<RootStore | undefined>(undefined);
  useEffect(() => {

    // mui pro license
    LicenseInfo.setLicenseKey('23b7f0919a945717faf81f758e97f4b3Tz03OTkxNCxFPTE3MzMyMDE0NzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

    (async () => {
      setupRootStore().then((store) => {
        setRootStore(store);
        setTimeout(() => {
          sendReactNativeMessage({
            type: 'initUser',
            payload: null,
          });
        }, 100);
      });
    })();

    if (window.localStorage.getItem('useDebug') !== 'true') {
      // process.env.NODE_ENV === 'production' && GlobalDebug(false, false);
    }
  }, []);


  if (!rootStore) {
    return null;
  } else {
    return (
      <RootStoreProvider value={rootStore}>
        <AuthProvider>
          <WebsocketProvider>
            <HelmetProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <SettingsProvider>
                  <BrowserRouter>
                    <CommonProvider>
                      <ScrollToTop />
                      <MotionLazyContainer>
                        <ThemeProvider>
                          <ThemeLocalization>
                            <SnackbarProvider>
                              <Router />
                              <ErrorAlert />
                              <LoadingAPI />
                            </SnackbarProvider>
                          </ThemeLocalization>
                        </ThemeProvider>
                      </MotionLazyContainer>
                    </CommonProvider>
                  </BrowserRouter>
                </SettingsProvider>
              </LocalizationProvider>
            </HelmetProvider>
          </WebsocketProvider>
        </AuthProvider>
      </RootStoreProvider>
    );
  }
}
