import { Link as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Grid, Link, Stack, Divider, Container, Typography, IconButton, AppBar, Toolbar, useTheme, Paper } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// _mock
// components
import Logo from '../../components/logo';
import Iconify from '../../components/iconify';
import { HEADER } from 'src/config-global';
import NavDesktop from './nav/desktop';
import { useAuthContext } from 'src/auth/useAuthContext';
import useResponsive from 'src/hooks/useResponsive';
import useOffSetTop from 'src/hooks/useOffSetTop';
import navConfig from './nav/config-navigation';

// ----------------------------------------------------------------------

export default function Footer() {

  const theme = useTheme();

  const auth = useAuthContext();


  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  return (

    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.appBar,
        background: '#000000',
        alignSelf: 'center',
        width: '100%',
        m: 'auto',
      }}
      elevation={0}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          })
        }}
      >
        <Box sx={{ width: '100%', height: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>

          <Logo />

          <Box sx={{ alignItems: 'center' }} >
            <NavDesktop isOffset={isOffset} data={navConfig} />
            {/* {!isDesktop && <NavMobile isOffset={isOffset} data={navConfig} />} */}
          </Box>

          <Box>
            {/* <IcoAvatar style={{ cursor: 'pointer' }} onClick={auth.logout} /> */}
          </Box>

        </Box>
      </Toolbar>

    </Paper>

  );
}
