import { ApiResponse } from 'apisauce';
import { ICodeListModel } from 'src/models/code-list/CodeList';
import { Api } from 'src/services/api/api';
import { getGeneralApiProblem } from 'src/services/api/api-problem';
import { TGetCodeListResult } from './CodeListTypes';

/**
 * # Code Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new CodeApi();
 *
 * ```
 */
export class CodeListApi {
  private BASE_URL = '/common/v1/code';
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async fetchAll(): Promise<TGetCodeListResult> {
    try {
      const url = `${this.BASE_URL}`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: ICodeListModel[] = response.data.data;
      return { kind: 'ok', data };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
