import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import {
  useTheme,
  Paper,
  Typography,
  Stack,
  List,
  ListItem,
  FormLabel,
  styled,
  Button,
  Dialog,
  Divider,
  IconButton,
} from '@mui/material';
import moment from 'moment';
import Iconify from 'src/components/iconify';
import { CallApiToStore } from 'src/utils/common';
import { IConferenceSnapshot, IMeetingMemberSnapshot } from 'src/models';
import CAlert from 'src/components/CAlert';
import { useNavigate } from 'react-router';
import { ReactComponent as IcoAvatar } from 'src/assets/images/ico-avatar.svg';
import { toJS } from 'mobx';
import ConferenceInvite from './ConferenceInvite';
import Draggable from 'react-draggable';
import ConferenceCreate from './ConferenceCreate';
import { useLocales } from 'src/locales';
import useResponsive from 'src/hooks/useResponsive';
import axios from 'axios';

interface Props {
  handleClose?: VoidFunction;
  // startDate?: string;
  // endDate?: string;
  getData: VoidFunction;
  getDetail?: (id: number) => Promise<void>;
  data: IConferenceSnapshot;
  path?: String;
}

/**
 * ## ConferenceDetail 설명
 *
 */
export const ConferenceDetail = observer(
  ({ handleClose, data, getData, getDetail, path }: Props) => {
    const rootStore = useStores();
    const { conferenceStore, loadingStore } = rootStore;
    const { translate } = useLocales();
    const navigate = useNavigate();
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [alertMsg, setAlertMessage] = useState('');
    const [isDeleteAlertOpen, setIsDeleteAlertOpen] = useState(false);
    const [openEditRoom, setOpenEditRoom] = useState(false);
    const [openInvite, setOpenInvite] = useState(false);
    const isMobile = useResponsive('down', 'md');
    let user;
    let authCd = '';
    let isSystemAdmin = true;

    if (localStorage.user) {
      user = JSON.parse(localStorage.user);
      authCd = user.auth?.authCd ? user.auth.authCd : user.authCd;
      isSystemAdmin = user.isAdmin === true;
    }
    const { REACT_APP_API_URL } = process.env;
    const token = localStorage.getItem('accessToken');
    // const data = conferenceStore.conference;

    const isOwner = data.ownerYn === 'Y' || !!isSystemAdmin; //||authCd === 'A';
    const onDelete = async (id: number) => {
      await CallApiToStore(conferenceStore.delete(id), 'api', loadingStore).then(() => {
        getData();
        handleClose && handleClose();
      });
    };

    const [browserName, setBrowserName] = useState<string | undefined>(undefined);
    const [browserVersion, setBrowserVersion] = useState<string | undefined>(undefined);

    const joinCheck = async (data: IConferenceSnapshot) => {
      await axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/web/room/joinCheck`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({ roomId: data.room.id }),
      }).then(async (res) => {
        if (await res.data.responseData) {
          if (res.data.responseData === 'success') {
            navigate('');
            navigate('/conference/meeting', { state: toJS(data), replace: true });
          } else if (res.data.responseData === 'confirm') {
            setDurationAlert(true);
          } else {
            alert(translate('error.100'));
          }
        }
      });
    };
    const durationTime = 1;
    const [durationAlert, setDurationAlert] = useState(false);
    const locale = localStorage.getItem('i18nextLng');
    const lang = (locale: string) => {
      if (locale === 'ja') {
        return `会議は${durationTime}時間以上後に予定されています。会議を開始しますか？`;
      } else if (locale === 'en') {
        return `The meeting is scheduled to start in ${durationTime} hours or more. Would you like to start the meeting now?`;
      } else {
        return `회의 시작시간이 ${durationTime}시간 이상 남았습니다. 회의를 시작하시겠습니까?`;
      }
    };

    useEffect(() => {
      const userAgent = window.navigator.userAgent;

      // 브라우저 종류 확인
      let browserName = translate('conferenceDetail.unknown').toString();

      if (userAgent.includes('Edg')) {
        browserName = 'Edge';
      } else if (userAgent.includes('Chrome')) {
        browserName = 'Chrome';
      } else if (userAgent.includes('Firefox')) {
        browserName = 'Firefox';
      } else if (userAgent.includes('Safari')) {
        browserName = 'Safari';
      } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
        browserName = 'Opera';
      }

      // 브라우저 버전 확인
      let browserVersion = translate('conferenceDetail.unknown').toString();

      const version =
        browserName === 'Safari'
          ? userAgent.match(/(Version)\/([\d.]+)/)
          : userAgent.match(/(Chrome|Firefox|Edg|Opera|OPR)\/([\d.]+)/);

      if (version && version.length >= 3) {
        browserVersion = version[2];
      } else {
        browserVersion = translate('conferenceDetail.unknown').toString();
      }

      setBrowserName(browserName);
      setBrowserVersion(browserVersion);
    }, []);

    return (
      <>
        <Stack
          display={'flex'}
          // direction={'row'}
          // justifyContent={'space-between'}
          // alignItems={'flex-start'}
          position={'relative'}
        >
          <Box>
            <Typography
              variant="h4"
              sx={{
                width: '100%',
                mt: 1,
                pr: 2,
                '&.MuiTypography-root': {
                  wordBreak: 'break-word',
                },
              }}
            >
              {data.title}
              {/* <span style={{ fontWeight: 'normal' }}>의 회의정보</span> */}
            </Typography>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
              {/* <IcoAvatar width={'1.5rem'} height={'1.5rem'} /> */}
              <Stack direction={'row'}>
                <Label sx={{ m: 0, minWidth: '2rem' }}>
                  {translate('conferenceDetail.host').toString()} :
                </Label>

                <Typography variant="subtitle1" ml={1}>
                  <strong> {data.owner.displayName}</strong>
                </Typography>
              </Stack>
              {isOwner && path !== 'Meeting' && (
                <Stack
                  direction={'row'}
                  sx={{ alignItems: 'end', justifyContent: 'end', alignSelf: 'end' }}
                >
                  <IconButton
                    sx={{ pb: 0 }}
                    onClick={() => {
                      setAlertMessage(translate('conferenceDetail.editAlert').toString());
                      moment(data.start).isBefore(moment()) //.subtract(1, 'day'))
                        ? setIsAlertOpen(true)
                        : setOpenEditRoom(true);
                    }}
                  >
                    <Iconify icon={'ci:note-edit'} />
                  </IconButton>
                  <IconButton
                    sx={{ pb: 0 }}
                    onClick={() => {
                      setIsDeleteAlertOpen(true);
                    }}
                  >
                    <Iconify icon={'ph:trash-bold'} />
                  </IconButton>
                </Stack>
              )}
            </Stack>
          </Box>
        </Stack>
        <Divider sx={{ mt: 2 }} />

        {/* <Stack direction={'row'}>
              <Label sx={{m:0}}>회의제목</Label> <Typography variant="subtitle1">{data.title}</Typography>
            </Stack> */}
        <List sx={{ py: 4 }}>
          {/* <Stack> */}
          {/* <Stack direction={'row'} alignItems={'center'} width={'100%'}> */}
          {/* {path === 'Meeting' && ( */}
          <ListItem sx={{ p: 0, mb: 1 }}>
            <Label sx={{ m: 0 }}>{translate('conferenceDetail.conferId').toString()}</Label>
            <Typography variant="subtitle1">{data.id}</Typography>
          </ListItem>
          {/* )} */}
          <ListItem sx={{ p: 0, mb: 1 }}>
            <Label sx={{ m: 0 }}>{translate('conferenceReservation.type').toString()}</Label>
            <Typography variant="subtitle1">
              {translate(`type.${data.room.type}`).toString() ||
                translate('type.normal').toString()}
            </Typography>
          </ListItem>
          {/* <Stack direction={'row'} alignItems={'center'}> */}
          <ListItem sx={{ p: 0, mb: 1 }}>
            <Label sx={{ m: 0 }}>
              {translate('conferenceReservation.allowRecording').toString()}
            </Label>
            <Typography variant="subtitle1">
              {translate(`conferenceDetail.${data.room.allowRecording}`).toString()}
            </Typography>
          </ListItem>
          {/*
                  <CSwitch
                    name={'room.allowRecording'}
                    size="medium"
                    defaultValue={data.room.allowRecording}
                    label={''}
                    readonly={!isOwner}
                  /> */}
          {/* </Stack> */}
          <ListItem sx={{ p: 0, mb: 1 }}>
            <Label sx={{ m: 0 }}>{translate(`conferenceDetail.date`).toString()}</Label>
            <Typography variant="subtitle1">
              {/* {moment(data.start).dayOfYear() === moment(data.end).dayOfYear()
              ? moment(data.start).format('YYYY-MM-DD LT') + ' ~ ' + moment(data.end).format('LT')
              : moment(data.start).format('YYYY-MM-DD LT') +
                ' ~ ' +
                moment(data.end).format('YYYY-MM-DD LT')} */}
              {data.conference_date}
            </Typography>
          </ListItem>

          <ListItem sx={{ p: 0, alignItems: 'flex-start' }}>
            <Label sx={{ m: 0 }}>
              {translate(`conferenceReservation.participants`).toString()}
            </Label>
            <Typography variant="subtitle1" sx={{}}>
              {data.meetingMembers &&
                data.meetingMembers.map((v: IMeetingMemberSnapshot, i: number) => {
                  const comma =
                    data.meetingMembers && i !== data.meetingMembers.length - 1 ? ',' : '';

                  return (
                    <Fragment key={(v.id, i)}>
                      {v.invitationYn === 'N' ? (
                        <s>{v.user.displayName}</s>
                      ) : v.invitationYn === 'Y' ? (
                        <strong>{v.user.displayName}</strong>
                      ) : (
                        <span style={{ fontWeight: 'lighter' }}>{v.user.displayName}</span>
                      )}
                      {comma}
                      &nbsp;
                    </Fragment>
                  );
                })}
            </Typography>
          </ListItem>
        </List>

        <Divider />

        <ListItem sx={{ p: 0, alignItems: 'flex-start', mt: 2 }}>
          <Label sx={{ m: 0 }}>{translate(`conferenceDetail.browserInfo`).toString()}</Label>
          <Typography variant="subtitle1" sx={{ wordBreak: 'break-word' }}>
            {browserName}
            {browserName !== (translate('conferenceDetail.unknown').toString() || '' || undefined)
              ? ` (${browserVersion} ver)`
              : ''}
          </Typography>
        </ListItem>
        {/* <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Typography variant="caption" textAlign={'center'}>
            *호환성~~~브라우저~~버전이상부터~~~~*
          </Typography>
        </Box> */}
        <Divider sx={{ mt: 2 }} />

        <Stack direction={'row'} spacing={1} sx={{ justifyContent: 'flex-end', pt: 2 }}>
          {path !== 'Meeting' ? (
            <Button
              variant={'contained'}
              onClick={() => {
                if (data.ownerYn === 'Y') {
                  joinCheck(data);
                } else {
                  navigate('');
                  navigate('/conference/meeting', { state: toJS(data), replace: true });
                }
              }}
              disabled={data.room.closed === true}
            >
              {translate('conferenceDetail.participate').toString()}
            </Button>
          ) : data.ownerYn === 'Y' ? ( // 회의주관자만 초대가능
            // ) : JSON.parse(localStorage.getItem('user') || '').auth !== 'GUEST' ? ( // 회원만 초대가능
            <Button
              variant={'contained'}
              onClick={() => {
                setOpenInvite(true);
              }}
            >
              {translate('meeting.invite').toString()}
            </Button>
          ) : (
            <></>
          )}
        </Stack>

        {durationAlert && (
          <CAlert
            open={durationAlert}
            handleClose={() => setDurationAlert(false)}
            content={lang(locale || 'ja')}
            hasCancel
            callBack={() => {
              navigate('');
              navigate('/conference/meeting', { state: toJS(data), replace: true });
            }}
          />
        )}

        <CAlert
          open={isDeleteAlertOpen}
          handleClose={() => setIsDeleteAlertOpen(false)}
          title={data.title ? data.title : 'Untitled'}
          content={translate('conferenceDetail.deleteAlert').toString()}
          hasCancel
          callBack={() => data.id && onDelete(data.id)}
        />

        <CAlert open={isAlertOpen} handleClose={() => setIsAlertOpen(false)} content={alertMsg} />

        {openEditRoom &&
          data && ( // 회의개설 Dialog
            <Dialog
              open={openEditRoom}
              maxWidth={'sm'}
              fullWidth={!isMobile}
              scroll="body"
              hideBackdrop
              PaperComponent={(props) => {
                return isMobile ? (
                  <Paper {...props} />
                ) : (
                  <Draggable bounds="parent" cancel="form">
                    <Paper {...props} />
                  </Draggable>
                );
              }}
              PaperProps={{
                sx: {
                  p: 4,
                  borderRadius: 4,
                },
              }}
              onClose={() => {
                setOpenEditRoom(false);
              }}
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  setOpenEditRoom(false);
                }}
                onTouchEnd={(e) => {
                  e.preventDefault();
                  setOpenEditRoom(false);
                }}
                sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
              >
                <Iconify icon={'ic:round-close'} />
              </IconButton>
              <ConferenceCreate
                data={data}
                handleClose={() => setOpenEditRoom(false)}
                path={'edit'}
                getData={getData}
                getDetail={getDetail}
              />
            </Dialog>
          )}
        {openInvite && (
          <Dialog
            open={openInvite}
            maxWidth={'sm'}
            scroll="body"
            hideBackdrop
            fullWidth
            // PaperComponent={(props) => {
            //   return isMobile ? (
            //     <Paper {...props} />
            //   ) : (
            //     <Draggable bounds="parent" cancel="form">
            //       <Paper {...props} />
            //     </Draggable>
            //   );
            // }}
            PaperProps={{
              sx: {
                p: 4,
                borderRadius: 4,
              },
            }}
            onClose={() => {
              setOpenInvite(false);
            }}
          >
            <IconButton
              onClick={() => {
                setOpenInvite(false);
              }}
              sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
            >
              <Iconify icon={'ic:round-close'} />
            </IconButton>

            <ConferenceInvite
              data={data}
              handleClose={() => setOpenInvite(false)}
              getDetail={getDetail}
            />
          </Dialog>
        )}
      </>
    );
  },
);
const isEn = localStorage.getItem('i18nextLng') === 'en' || 'ja';

const Label = styled(FormLabel)(({ theme }) =>
  isEn
    ? {
        minWidth: undefined,
        paddingRight: 8,
        whiteSpace: 'nowrap',
        marginBottom: 8,
        color: '#919EAB',
      }
    : {
        minWidth: 100,
        whiteSpace: 'nowrap',
        marginBottom: 8,
        color: '#919EAB',
      },
);

export default ConferenceDetail;
