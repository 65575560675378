import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../models/root-store/root-store-context';
import { Badge, List, ListItem, Typography, useTheme } from '@mui/material';

import { IPushAlarmSnapshot } from 'src/models';
import { useEffect } from 'react';
import moment from 'moment';
import { parseISO } from 'date-fns';
import { CallApiToStore } from 'src/utils/common';
import { useNavigate } from 'react-router';

interface Props {
  isMobile: boolean;
}

/**
 * ## MyAlarmContents 설명
 *
 */

export const MyAlarmContents = observer(({ isMobile }: Props) => {
  const navigate = useNavigate();
  const rootStore = useStores();
  const { pushAlarmStore: store, loadingStore } = rootStore;
  const theme = useTheme();

  useEffect(() => {
    CallApiToStore(store.readAlarm(), 'api', loadingStore).then(() => {
      CallApiToStore(store.getAlarmCounts(), 'api', loadingStore);
    });
  }, []);

  return (
    <>
      <List>
        {store.pushAlarms.map((a: IPushAlarmSnapshot, i: number) => {
          return (
            <ListItem
              key={`${i}_${a.sendDt}`}
              sx={{ borderBottom: `dashed 1px ${theme.palette.divider}` }}
              onClick={() => {
                isMobile && navigate('/');
              }}
            >
              <Box p={1} pr={5} width={'100%'} position={'relative'}>
                <Badge
                  variant="dot"
                  color="primary"
                  // overlap="circular"
                  invisible={a.isRead === true}
                  sx={{
                    '&.MuiBadge-root': {
                      display: 'block',
                    },
                    '& .MuiBadge-badge': {
                      position: 'absolute',
                      top: '11px',
                      right: '-17px',
                      transform: 'translate(10px,-5px)',
                    },
                  }}
                />
                <Typography variant="h6" fontWeight={700}>
                  {a.subject}
                </Typography>
                <Typography variant="body1">{a.message}</Typography>
                <Typography variant="caption" color={'#999999'}>
                  {moment(parseISO(a.sendDt || '')).format('YYYY-MM-DD HH:mm:ss')} |{' '}
                  <span color="#666666">{a.category}</span>
                </Typography>
              </Box>
            </ListItem>
          );
        })}
      </List>
    </>
  );
});

export default MyAlarmContents;
