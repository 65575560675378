import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  useTheme,
} from '@mui/material';
import { DatePicker, DateView, LocalizationProvider, jaJP, koKR } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { parseISO } from 'date-fns';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import CTextField from 'src/components/forms/CTextField';
import FormProvider from 'src/components/hook-form/FormProvider';
import Iconify from 'src/components/iconify/Iconify';
import { useLocales } from 'src/locales';
import { IConferFilter } from 'src/models';

interface Props {
  onSearch: (data: IConferFilter) => void;
  // defaultValue: IConferFilter | undefined;
  isMobile: boolean;
}
const MyConferSearchArea = observer(({ onSearch, isMobile }: Props) => {
  const { translate } = useLocales();

  const theme = useTheme();
  const defaultValues = {
    dateType: 'day',
    start: undefined,
    end: undefined,
    memberName: undefined,
    ownerName: undefined,
    place: undefined,
    agenda: undefined,
    reportData: undefined,
  };
  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: defaultValues,
  });
  const {
    handleSubmit,
    register,
    getValues,
    control,
    reset,
    setValue,
    formState: { errors },
  } = methods;

  const [dateType, setDateType] = useState('day');
  const [start, setStart] = useState<string | undefined>(undefined);
  const [end, setEnd] = useState<string | undefined>(undefined);
  const [userType, setUserType] = useState('ownerName');
  const locale = localStorage.getItem('i18nextLng');

  const onSubmit = async () => {
    const tmp = //userType && { userType: getValues(`${userType}`)?.trim() };
      userType === 'ownerName'
        ? { ownerName: getValues('ownerName')?.trim() }
        : userType === 'memberName'
        ? { memberName: getValues('memberName')?.trim() }
        : userType === 'place'
        ? { place: getValues('place')?.trim() }
        : userType === 'agenda'
        ? { agenda: getValues('agenda')?.trim() }
        : { reportData: getValues('reportData')?.trim() };

    const data = {
      dateType: dateType,
      start: start,
      end: end,
      // start: getValues('start'),
      // end: getValues('end'),
      ...tmp,
    };
    if (_.isEmpty(errors)) {
      // if (data.memberName === '') {
      //   data.memberName = undefined;
      // }
      // if (data.ownerName === '') {
      //   data.ownerName = undefined;
      // }

      onSearch(data as IConferFilter);
      //   conferenceStore.pagination.setProps({ page: 1 });
      //   getData();
    }
  };

  useEffect(() => {
    const el = document.querySelectorAll('.MuiInputBase-root');
    el.forEach((d) => {
      d.classList.remove('Mui-error');
    });
  }, [dateType, start, end, userType, errors]);

  useEffect(() => {
    setDateType(getValues('dateType'));
  }, [getValues('dateType')]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack
        width={'100%'}
        direction={'row'}
        sx={{
          textAlign: 'left',
          flex: 1,
          alignItems: isMobile ? 'center' : 'flex-start',
        }}
      >
        <Stack
          direction={isMobile ? 'column' : 'row'}
          sx={{ width: '100%', gap: 2, alignItems: 'flex-start' }}
        >
          <Stack
            sx={{
              width: '100%',
              gap: 1,
              display: isMobile ? 'flex' : 'grid',
              flexDirection: 'row',
              gridTemplateColumns: '1fr 3.5fr',
            }}
          >
            <FormControl sx={{ flex: 0.5 }}>
              <InputLabel
                id="select-label"
                sx={{ '&.Mui-focused': { color: theme.palette.text.primary } }}
              >
                {translate('conference.searchArea.period').toString()}
              </InputLabel>
              <Select
                name={'dateType'}
                label={translate('conference.searchArea.period').toString()}
                labelId="select-label"
                defaultValue={'day'}
                value={getValues('dateType')}
                onChange={(e) => {
                  setValue('dateType', e.target.value);
                  setDateType(e.target.value);
                  setStart(
                    moment(start).format(
                      e.target.value === 'year'
                        ? 'YYYY'
                        : e.target.value === 'month'
                        ? 'YYYY-MM'
                        : 'YYYY-MM-DD',
                    ),
                  );
                  setEnd(
                    moment(end).format(
                      e.target.value === 'year'
                        ? 'YYYY'
                        : e.target.value === 'month'
                        ? 'YYYY-MM'
                        : 'YYYY-MM-DD',
                    ),
                  );
                }}
                sx={{
                  // ml: 1,
                  '& .MuiSelect-select': {
                    py: '8px !important',
                  },
                  [theme.breakpoints.down('sm')]: {
                    ' &.MuiFormControl-root': { width: '100%' },
                  },
                }}
              >
                <MenuItem value={'day'}>
                  {translate('conference.searchArea.daily').toString()}
                </MenuItem>
                <MenuItem value={'month'}>
                  {translate('conference.searchArea.monthly').toString()}
                </MenuItem>
                <MenuItem value={'year'}>
                  {translate('conference.searchArea.yearly').toString()}
                </MenuItem>
              </Select>
            </FormControl>

            <Stack
              direction={'row'}
              alignItems={'center'}
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                '& input': {
                  py: 1,
                },
                '[data-shrink="false"]': {
                  transform: 'translate(15px, 8px)',
                },
                '& .MuiTextField-root': {
                  width: '100%',
                },
              }}
            >
              <Controller
                name="start"
                control={control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <LocalizationProvider
                      dateFormats={
                        locale !== 'en'
                          ? {
                              monthShort: 'M',
                              monthAndYear: `yyyy${translate(
                                'myCalendar.year',
                              ).toString()} MM${translate('myCalendar.month').toString()}`,
                            }
                          : {}
                      }
                      dateAdapter={AdapterDateFns}
                      localeText={
                        locale === 'ko'
                          ? koKR.components.MuiLocalizationProvider.defaultProps.localeText
                          : locale === 'ja'
                          ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
                          : undefined
                      }
                    >
                      <DatePicker
                        {...field}
                        dayOfWeekFormatter={(day) => {
                          return translate(`myCalendar.${day}`).toString();
                        }}
                        format={
                          dateType === 'year'
                            ? 'yyyy'
                            : dateType === 'month'
                            ? 'yyyy-M'
                            : 'yyyy-MM-dd'
                        }
                        onChange={(e: any) => {
                          setStart(
                            moment(e).format(
                              dateType === 'year'
                                ? 'YYYY'
                                : dateType === 'month'
                                ? 'YYYY-MM'
                                : 'YYYY-MM-DD',
                            ),
                          );
                          setValue(
                            'start',
                            moment(e).format(
                              dateType === 'year'
                                ? 'YYYY'
                                : dateType === 'month'
                                ? 'YYYY-MM'
                                : 'YYYY-MM-DD',
                            ),
                          );
                        }}
                        value={new Date(getValues('start'))}
                        views={dateType === 'month' ? ['month', 'year'] : [dateType as DateView]}
                        openTo={dateType as DateView}
                        defaultValue={''}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          layout: {
                            sx: {
                              '& .MuiTypography-root[aria-label="Sunday"]': {
                                color: 'red',
                              },
                              '& .MuiDateCalendar-root button[aria-colindex="1"][aria-selected="false"]':
                                {
                                  color: 'red',
                                },
                              '& .Mui-disabled[aria-colindex="1"]': {
                                opacity: 0.5,
                              },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
              to
              <Controller
                name="end"
                render={({ field, fieldState: { error } }) => {
                  return (
                    <LocalizationProvider
                      dateFormats={
                        locale !== 'en'
                          ? {
                              monthShort: 'M',
                              monthAndYear: `yyyy${translate(
                                'myCalendar.year',
                              ).toString()} MM${translate('myCalendar.month').toString()}`,
                            }
                          : {}
                      }
                      dateAdapter={AdapterDateFns}
                      localeText={
                        locale === 'ko'
                          ? koKR.components.MuiLocalizationProvider.defaultProps.localeText
                          : locale === 'ja'
                          ? jaJP.components.MuiLocalizationProvider.defaultProps.localeText
                          : undefined
                      }
                    >
                      <DatePicker
                        dayOfWeekFormatter={(day) => {
                          return translate(`myCalendar.${day}`).toString();
                        }}
                        format={
                          dateType === 'year'
                            ? 'yyyy'
                            : dateType === 'month'
                            ? 'yyyy-MM'
                            : 'yyyy-MM-dd'
                        }
                        onChange={(e: any) => {
                          setEnd(
                            moment(e).format(
                              dateType === 'year'
                                ? 'YYYY'
                                : dateType === 'month'
                                ? 'YYYY-MM'
                                : 'YYYY-MM-DD',
                            ),
                          );
                          setValue(
                            'end',
                            moment(e).format(
                              dateType === 'year'
                                ? 'YYYY'
                                : dateType === 'month'
                                ? 'YYYY-MM'
                                : 'YYYY-MM-DD',
                            ),
                          );
                        }}
                        value={new Date(getValues('end'))}
                        views={dateType === 'month' ? ['month', 'year'] : [dateType as DateView]}
                        openTo={dateType as DateView}
                        minDate={parseISO(start || '')}
                        // minDate={getValues('start')}
                        defaultValue={''}
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                          layout: {
                            sx: {
                              '& .MuiTypography-root[aria-label="Sunday"]': {
                                color: 'red',
                              },
                              '& .MuiDateCalendar-root button[aria-colindex="1"][aria-selected="false"]':
                                {
                                  color: 'red',
                                },
                              '& .Mui-disabled[aria-colindex="1"]': {
                                opacity: 0.5,
                              },
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  );
                }}
              />
            </Stack>
          </Stack>

          <Stack
            sx={{
              width: '100%',
              gap: 1,
              display: isMobile ? 'flex' : 'grid',
              gridTemplateColumns: '1.5fr 2fr',
              flexDirection: 'row',
            }}
          >
            <FormControl sx={{ flex: 0.5 }}>
              <InputLabel
                id="select-label-2"
                sx={{ '&.Mui-focused': { color: theme.palette.text.primary } }}
              >
                {translate('conference.searchArea.type').toString()}
              </InputLabel>
              <Select
                name={'type'}
                label={translate('conference.searchArea.type').toString()}
                labelId="select-label-2"
                value={userType}
                // placeholder={'타입을 선택하세요'}
                defaultValue={'ownerName'}
                onChange={(e: any) => {
                  setUserType(e.target.value);
                }}
                sx={{
                  // ml: 1,
                  '& .MuiSelect-select': {
                    py: '8px !important',
                  },
                  [theme.breakpoints.down('sm')]: {
                    ' &.MuiFormControl-root': { width: '100%' },
                  },
                }}
              >
                {/* <MenuItem value={''}>선택</MenuItem> */}
                <MenuItem value={'ownerName'}>
                  {translate('conference.searchArea.byHost').toString()}
                </MenuItem>
                <MenuItem value={'memberName'}>
                  {translate('conference.searchArea.byParticipants').toString()}
                </MenuItem>
                <MenuItem value={'place'}>
                  {translate('conference.searchArea.byplace').toString()}
                </MenuItem>
                <MenuItem value={'agenda'}>
                  {translate('conference.searchArea.byagenda').toString()}
                </MenuItem>
                <MenuItem value={'reportData'}>
                  {translate('conference.searchArea.byreportData').toString()}
                </MenuItem>
              </Select>
            </FormControl>

            <Stack sx={{ flex: 1, '& input': { height: '40px !important', p: '0' } }}>
              <CTextField
                name={userType || ''}
                label={''}
                variant={'outlined'}
                placeholder={translate('conference.searchArea.enterYourSearchTerm').toString()}
                sx={{ m: 0 }}
                onEnterCallback={(e: any) => {
                  if (e.nativeEvent.keyCode === 13) {
                    onSubmit();
                  }
                }}
              />
            </Stack>
          </Stack>
        </Stack>

        <Stack direction={isMobile ? 'column' : 'row'} alignItems={'center'}>
          <IconButton
            // sx={{ minWidth: pxToRem(25), height: pxToRem(25) }}
            onClick={() => {
              setValue('type', 'ownerName');
              setUserType('ownerName');
              reset();
              setStart('');
              setEnd('');
              setValue('start', undefined);
              setValue('end', undefined);
            }}
          >
            <Iconify
              icon={'ph:x-bold'}
              // icon={'grommet-icons:power-reset'}
              sx={{
                // mr: pxToRem(10),
                color: '#999',
              }}
            />
          </IconButton>
          <IconButton
            //   sx={{ minWidth: pxToRem(25), height: pxToRem(25) }}
            onClick={handleSubmit(onSubmit)}
          >
            <Iconify
              icon={'ri:search-line'}
              sx={{
                // mr: pxToRem(10),
                color: theme.palette.secondary.main, //'#999',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </FormProvider>
  );
});
export default MyConferSearchArea;
