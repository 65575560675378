import {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useState,
  useReducer,
  useRef,
} from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useStores } from 'src/models/root-store/root-store-context';
import { IUserSnapshot } from 'src/models/user/User';
import { useNavigate } from 'react-router-dom';
import { isObject } from 'lodash';
import { save } from 'src/utils/storage';
import { useAuthContext } from 'src/auth/useAuthContext';
import { isValidToken, setSession } from 'src/auth/utils';
import { sendReactNativeMessage } from 'src/utils/common';
import { ActionMapType, AuthUserType } from './types';
import localStorageAvailable from 'src/utils/localStorageAvailable';

import useWebSocket, { ReadyState } from 'react-use-websocket';
//
// ----------------------------------------------------------------------

// export type WebsocketProps = {
//   isInitialized: boolean;
//   users: AuthUserType[];
// };

enum Types {
  INITIAL = 'INITIAL',
  WATING = 'WATING',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  WS_OPEN = 'WS_OPEN',
  ON_MESSAGE = 'ON_MESSAGE',
}

type Payload = {
  [Types.INITIAL]: {
    isInitialized: boolean;
  };
  [Types.WATING]: {
    me: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;

  [Types.WS_OPEN]: undefined;
  [Types.ON_MESSAGE]: {
    message: any;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

export type WebsocketStateType = {
  isInitialized: boolean;
  me: AuthUserType;
  users: AuthUserType[];
  message: any;
};

const initialState: WebsocketStateType = {
  isInitialized: false,
  me: null,
  users: [],
  message: null,
};

const reducer = (state: WebsocketStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    // ws = new WebSocket('wss://rtc.surfinn.kr/web/groupcall');
    return {
      isInitialized: true,
      me: null,
      users: [],
      message: null,
    };
  }
  if (action.type === Types.WATING) {
    return {
      ...state,
      me: action.payload.me,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      users: [...state.users, action.payload.user],
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
    };
  }
  if (action.type === Types.WS_OPEN) {
    return {
      ...state,
    };
  }
  if (action.type === Types.ON_MESSAGE) {
    return {
      ...state,
      message: action.payload.message,
    };
  }
  return state;
};

export type WebsocketContextType = {
  isInitialized: boolean;
  me: AuthUserType;
  users: AuthUserType[];
  ws: any;
  message: any;
  initialize: () => void;
  onWating: (user: AuthUserType) => void;
  onSendMessage: (message: any) => void;
  // login: (facilityCd: string, loginId: string, password: string) => Promise<void>;
  // logout: () => void;
};

// ----------------------------------------------------------------------

export const WebsocketContext = createContext<WebsocketContextType | null>(null);

export const useWebsocketContext = () => {
  const context = useContext(WebsocketContext);

  if (!context) throw new Error('websocket must be use inside WebsocketProvider');

  return context;
};

// ----------------------------------------------------------------------

type WebsocketProviderProps = {
  children: React.ReactNode;
};

let ws: any;
// const ws = new WebSocket('wss://rtc.surfinn.kr/web/groupcall');
export const WebsocketProvider = ({ children }: WebsocketProviderProps) => {
  const { REACT_APP_API_URL, REACT_APP_WS_URL } = process.env;

  const [socket, setSocket] = useState<any | null>(null);

  const websocketUrl = `${REACT_APP_WS_URL}?accessToken=` + localStorage.getItem('accessToken');

  const auth = useAuthContext();

  const [state, dispatch] = useReducer(reducer, initialState);

  const isJsonString = (str: any) => {
    try {
      const json = JSON.parse(str);
      return typeof json === 'object';
    } catch (e) {
      return false;
    }
  };
  // const wsClient = useRef<WebSocket>();
  // const [wsState, setWsState] = useState(true)

  // useEffect(() => {
  //   wsClient.current = new WebSocket(websocketUrl);
  //   console.log("Trying to open ws");
  //   setWsState(true)

  //   // wsClient.current.onopen = () => {
  //   //   console.log('ws opened');
  //   //   wsClient.current?.send('{"type" : "hello"}')
  //   // };
  //   wsClient.current.onclose = (event) => {
  //     // Parse event code and log
  //     // setTimeout(() => { setWsState(false) }, 5000)
  //     console.log('ws closed');
  //   }
  //   try {
  //     wsClient.current.onmessage = event => {
  //       // DO YOUR JOB
  //       console.log('🌈 ~ useEffect ~ event:', event)
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  //   return () => {
  //     console.log('ws closed');
  //     wsClient.current?.close();
  //   }
  // }, [wsState]);

  // useEffect(() => {
  //   const newSocket = io("wss://rtc.surfinn.kr/web/groupcall", {
  //     path: '/web/groupcall',
  //     // autoConnect: false,
  //     // agent: false,
  //     // addTrailingSlash: false,
  //     // withCredentials: false,
  //     // forceNew: false,
  //     // secure: true,
  //     // transportOptions: {
  //     //   cors: {
  //     //     allow: '*'
  //     //   }
  //     // },
  //     transports: ['websocket'],
  //   });

  //   // newSocket.onopen = () => {
  //   //   console.log('WebSocket connection established');
  //   //   newSocket.send('{"type" : "hello"}')
  //   // };

  //   newSocket.connect();

  //   newSocket.on('connect', () => {
  //     console.log('Connected to server');
  //   });

  //   newSocket.on('message', (e) => {
  //     console.log(e);
  //   });

  //   setSocket(newSocket);

  //   return () => {
  //     // Clean up the socket connection if the component unmounts
  //     newSocket.disconnect();
  //   };
  // }, []);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    if (!state.isInitialized) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isInitialized: true,
        },
      });
    }
  }, [storageAvailable]);

  const onSendMessage = (message: any) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(message));
      // wsClient.current?.send(JSON.stringify(message));
      console.log('socket', socket);
      // console.table(socket)
      // console.table(wsClient.current)
    }
  };

  const onWating = (user: AuthUserType) => {
    dispatch({
      type: Types.WATING,
      payload: {
        me: user,
      },
    });
  };

  const value = {
    isInitialized: state.isInitialized,
    ws: ws,
    me: state.me,
    users: state.users,
    message: state.message,
    initialize,
    onWating,
    onSendMessage,
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      ws: ws,
      me: state.me,
      users: state.users,
      message: state.message,
      initialize,
      onWating,
      onSendMessage,
    }),
    [
      state.isInitialized,
      ws,
      state.me,
      state.users,
      state.message,
      initialize,
      onWating,
      onSendMessage,
    ],
  );
  return <WebsocketContext.Provider value={value}>{children}</WebsocketContext.Provider>;
};
