import { useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Chart, { useChart } from 'src/components/chart';
import { useLocales } from 'src/locales';
import { useStores } from 'src/models';

interface Props {
  ownerdata: any;
}
const OwnerCount = observer(({ ownerdata }: Props) => {
  const theme = useTheme();
  const { translate } = useLocales();
  const rootStore = useStores();
  const series = [
    {
      name: translate('dashBoard.count').toString(),
      data: ownerdata.data.map((c: any) => {
        return c.count;
      }),
    },
  ];

  return (
    <>
      <Chart
        type="bar"
        series={series}
        options={useChart({
          colors: [theme.palette.primary.main],
          stroke: { show: false },
          plotOptions: {
            bar: { horizontal: true, barHeight: '30%' },
          },
          tooltip: {
            enabled: true,
            //   y: {
            //     formatter: undefined,
            //     title: {
            //       formatter: (val: any) => {
            //         console.log(val);
            //         const arr = val.push(val).map((r: any, i: number) => i); // '%a+%b');
            //         return val[1];
            //       },
            //     },
            //   },
            y: {
              formatter: (val) => val.toFixed(),
            },
          },
          xaxis: {
            categories: ownerdata.data.map((v: any) => {
              return v.name;
            }),
            // min: 0,
            // max: 10,
            // range: 1,
            // type: 'numeric',
            // tickAmount: 'dataPoints',
            // tickPlacement: 'between',
            // labels: {
            //   formatter: (val: any) => {
            //     return val.toFixed();
            //   },
            // },
          },
        })}
      />
    </>
  );
});

export default OwnerCount;
