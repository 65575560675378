import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { Address, createAddress } from '../address/Address';
import { createAuth } from '../auth/Auth';
import { createFacility } from '../facility/Facility';

/**
 * # User
 *
 * User을 설명하세요.
 */
export const User = types
  .model('User')
  // --------------------------------------------------------------------------
  .props({
    id: types.maybeNull(types.optional(types.number, 0)),
    email: types.maybeNull(types.string),
    address: types.maybeNull(createAddress()),
    displayName: types.maybeNull(types.string),
    firstName: types.maybeNull(types.optional(types.string, '')),
    lastName: types.maybeNull(types.optional(types.string, '')),
    pushYn: types.maybeNull(types.optional(types.string, 'N')),
    locale: types.maybeNull(types.optional(types.string, 'en')),
    name: types.maybeNull(types.optional(types.string, '')),
    // email: types.optional(types.string, ''),
    // userId: types.optional(types.number, 0),
    type: types.maybeNull(types.optional(types.string, '')),
    loginId: types.maybeNull(types.string), // 사용자아이디 _회원 초대시 (displayName이 오지 않을경우 필수로 와야함)
    facilityCd: types.maybeNull(types.string),
    facility: types.maybeNull(createFacility()),
    authCd: types.maybeNull(types.string),
    auth: types.maybeNull(createAuth()),

    sex: types.maybeNull(types.string),
    birth: types.maybeNull(types.string),
    kanjiDisplayName: types.maybeNull(types.string),
    kanaDisplayName: types.maybeNull(types.string),
    cellno: types.maybeNull(types.string),
    patanm: types.maybeNull(types.string),
    patajm: types.maybeNull(types.string),
    homeAddress: types.maybeNull(types.string),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model user --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IUser;
      (Object.keys(newProps) as TUserKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TUser = Instance<typeof User>;
type TUserSnapshot = SnapshotOut<typeof User>;

export interface IUser extends TUser {}
export type TUserKeys = keyof TUserSnapshot & string;
export interface IUserSnapshot extends TUserSnapshot {}
export const createUser = () => types.optional(User, {} as TUser);
