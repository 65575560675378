import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useStores } from "../../models/root-store/root-store-context"
import { useTheme } from '@mui/material';

/**
 * ## WaitingRoom 설명
 *
 */
export const WaitingRoom = observer(() => {

  const rootStore = useStores();

  return (
    <>
      <Box>
        WaitingRoom Screen
      </Box>
    </>
  );
});

export default WaitingRoom;