import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import {
  TGetOwnerCountResult,
  TGetProgressListResult,
  TGetSystemInfoResult,
} from './DashBoardTypes';
import { IOwnerCount, IProgressList, ISystemInfo } from 'src/models';
import { IResponseSnapshot } from 'src/models/response/Response';

/**
 * # DashBoard Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new DashBoardApi();
 *
 * ```
 */
export class DashBoardApi {
  private BASE_URL = '/web/dashboard';
  private api: Api;

  constructor(api: Api) {
    this.api = api;
    this.api.setup();
  }

  /**
   * 대시보드 당일 주관자 직종별 카운트 ( 당일 00:00 ~ 23:59 )
   * @returns
   */
  async ownerCount(): Promise<TGetOwnerCountResult> {
    try {
      const url = `${this.BASE_URL}/day/ownerCount`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const ownerCount: IOwnerCount = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', ownerCount, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 당일 현재 진행중인 회의 리스트 가져오기
   * @returns
   */
  async progressList(): Promise<TGetProgressListResult> {
    try {
      const url = `${this.BASE_URL}/day/progressList`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const progressList: IProgressList[] = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', progressList, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 서버 시스템 정보 확인
   * @returns
   */
  async systemInfo(): Promise<TGetSystemInfoResult> {
    try {
      const url = `${this.BASE_URL}/systemInfo`;
      const params = {};
      const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        if (problem) return problem;
      }

      const systemInfo: ISystemInfo = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', systemInfo, responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }
}
