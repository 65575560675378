import { Box, Button, Dialog, IconButton, Paper } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { CDataGrid } from 'src/components/CDataGrid';
import { IConferenceSnapshot, IReportSnapshot, useStores } from 'src/models';
import { ReactComponent as IcoFile } from 'src/assets/images/ico-file.svg';
import { ReactComponent as IcoMic } from 'src/assets/images/ico-mic.svg';
import { ReactComponent as IcoChat } from 'src/assets/icons/ico-chat.svg';
import { useEffect, useState } from 'react';
import ReportDetail from 'src/screens/conference/ReportDetail';
import { useNavigate } from 'react-router';
import { isMobile } from 'react-device-detect';
import ConferenceDetail from 'src/screens/conference/ConferenceDetail';
import Iconify from 'src/components/iconify/Iconify';
import moment from 'moment';
import ReportCreate from 'src/screens/conference/ReportCreate';
import Draggable from 'react-draggable';
import { CallApiToStore, sendReactNativeMessage } from 'src/utils/common';
import { MyConferenceProps } from './MyConference';
import Fileshare from 'src/screens/conference/fileshare/Fileshare';
import RecordingFiles from 'src/screens/recordingFile/RecordingFiles';
import axios from 'axios';
import CAlert from 'src/components/CAlert';
import { Trans } from 'react-i18next';
import { load } from 'src/utils/storage';
import { useLocales } from 'src/locales';

const MyConferenceTable = observer(
  ({ pending, getData, page, handleChangePage }: MyConferenceProps) => {
    const { translate } = useLocales();
    const rootStore = useStores();
    const { conferenceStore, loadingStore, responseStore, errorAlertStore } = rootStore;
    const navigate = useNavigate();
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const { REACT_APP_API_URL } = process.env;

    const [data, setData] = useState<IConferenceSnapshot>({} as IConferenceSnapshot);
    const [openDetail, setOpenDetail] = useState(false);
    const user = JSON.parse(localStorage.user);
    const authCd = user.auth?.authCd ? user.auth.authCd : user.authCd;
    const isSystemAdmin = user.isAdmin === true;
    const getDetail = async (id: number) => {
      await CallApiToStore(conferenceStore.get(id), 'api', loadingStore).then(() => {
        setData(toJS(conferenceStore.conference));
        setOpenDetail(true);
      });
    };

    const [reportData, setReportData] = useState<IReportSnapshot>({} as IReportSnapshot);
    const [shareFilesRoomId, setShareFilesRoomId] = useState(0);

    const [openReport, setOpenReport] = useState(false);
    const viewReport = async (roomId: number) => {
      await CallApiToStore(conferenceStore.getReport(roomId), 'api', loadingStore)
        .then(() => {
          setReportData(toJS(conferenceStore.report) as IReportSnapshot);
          setOpenReport(true);
        })
        .catch((e) => {
          console.log('🪄 ~ awaitCallApiToStore ~ e:', e);
        });
    };
    const [openReportEditor, setOpenReportEditor] = useState(false);

    const [isAlertOpen, setIsAlertOpen] = useState(false);

    const [os, setOs] = useState<any>();
    useEffect(() => {
      (async () => {
        setOs(await load('os'));
      })();
    }, []);
    const downloadChat = async (roomId: number, title: string) => {
      const token = localStorage.getItem('accessToken');
      const name = `${title}_chat_${moment().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
      if (os && (os.isIOS || os.isAndroid)) {
        sendReactNativeMessage({
          type: 'filedown',
          payload: {
            url: `${REACT_APP_API_URL}/web/chat/download`,
            token: `${token}`,
            roomId: roomId,
            fileName: name,
          },
        });
      } else {
        const config: any = {
          method: 'POST',
          url: `${REACT_APP_API_URL}/web/chat/download`,
          headers: {
            Authorization: `Bearer ${token}`,
            // ContentType: 'application/json',
          },
          // responseType: 'blob',
          responseType: 'arraybuffer',
          data: { roomId: roomId },
        };
        try {
          const response = await axios(config);
          try {
            const json = JSON.parse(new TextDecoder().decode(response.data));
            if (json.error) {
              responseStore.setResponseInfo(json);
              errorAlertStore.setErrorAlertFromResponse();
              return;
            }
          } catch (e) {}
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.style.cssText = 'display:none';
          document.body.appendChild(link);
          setTimeout(() => {
            link.click();
            link.remove();
          }, 500);
        } catch (e) {
          console.error(e);
        }
      }
    };

    const columns: GridColDef[] = [
      {
        field: 'id',
        headerName: translate('conferenceDetail.conferId').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 40,
      },

      {
        field: 'conference_date',
        headerName: translate('conference.table.date').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 120,
        // sortingOrder: ['desc'],
      },
      // {
      //   field: 'room',
      //   headerName: '회의타입',
      //   headerAlign: 'center',
      //   align: 'center',
      //   flex: 100,
      //   valueGetter: (params: GridValueGetterParams) => {
      //     return params.value.type;
      //   },
      // },
      {
        field: 'owner',
        headerName: translate('conference.table.host').toString(),
        headerAlign: 'center',
        flex: 70,
        align: 'center',
        valueGetter: (params: GridValueGetterParams) => {
          return params.value.displayName;
        },
      },
      {
        field: 'meetingMembers',
        headerName: translate('conferenceReservation.participants').toString(),
        headerAlign: 'center',
        flex: 100,
        valueGetter: (params: GridValueGetterParams) => {
          const list: any[] = [];
          params.value.filter((v: any) => {
            list.push(v.user.displayName);
          });
          return list;

          // params.value.filter((v: any) => {
          //   v.invitationYn === 'N' ? (
          //     <s>{v.user.displayName}</s>
          //   ) : v.invitationYn === 'Y' ? (
          //     <strong>{v.user.displayName}</strong>
          //   ) : (
          //     <span style={{ fontWeight: 'lighter' }}>{v.user.displayName}</span>
          //   );
          // });
        },
      },
      {
        field: 'title',
        headerName: translate('conferenceReservation.title').toString(),
        headerAlign: 'center',
        flex: 130,
      },
      {
        field: 'runningTime',
        headerName: translate('conference.table.meetingTime').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
      },
      {
        field: 'report',
        headerName: translate('conference.table.report').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.room.hasReport === true ? (
            <IconButton
              onClick={() => {
                setData(params.row);
                viewReport(params.row.room.id);
              }}
            >
              <IcoFile />
            </IconButton>
          ) : params.row.ownerYn === 'Y' || !!isSystemAdmin ? (
            <IconButton
              onClick={() => {
                setData(params.row);
                setOpenReportEditor(true);
              }}
            >
              <Iconify icon={'mi:document-add'} color={'rgb(145, 158, 171)'} />
            </IconButton>
          ) : (
            <></>
          );
        },
      },
      {
        field: 'recoding_file',
        headerName: translate('conference.table.recordingFile').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.room.recordingFiles.length > 0 && (
              <IconButton
                onClick={() => {
                  setData(params.row);
                  setOpenRecordingFiles(true);
                }}
              >
                <Iconify icon={'ri:video-fill'} color={'rgb(145, 158, 171)'} />
              </IconButton>
            )
          );
        },
      },
      {
        field: 'shared_file',
        headerName: translate('conference.table.shareFile').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
        renderCell: (params: GridRenderCellParams) => {
          return params.row.room.shareFiles.length > 0 ? (
            <IconButton
              onClick={() => {
                setData(params.row);
                conferenceStore.setConference(params.row);
                setOpenShareFiles(true);
                setShareFilesRoomId(params.row.room.id);
              }}
            >
              <Iconify icon={'heroicons:folder-20-solid'} color={'rgb(145, 158, 171)'} />
            </IconButton>
          ) : params.row.room.shareFiles.length === 0 ? (
            <IconButton
              onClick={() => {
                setData(params.row);
                setOpenShareFiles(true);
                setShareFilesRoomId(params.row.room.id);
              }}
            >
              <Iconify icon={'mdi:folder-add-outline'} color={'rgb(145, 158, 171)'} />
            </IconButton>
          ) : (
            <></>
          );
        },
      },
      {
        field: 'chat',
        headerName: translate('conference.table.chat').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
        renderCell: (params: GridRenderCellParams) => {
          return (
            params.row.room.hasChat === true && (
              <IcoChat
                style={{ cursor: 'pointer', fill: 'rgb(145, 158, 171)' }}
                onClick={() => {
                  setData(params.row);
                  setIsAlertOpen(true);
                }}
              />
            )
          );
        },
      },
      {
        field: 'action',
        headerName: translate('conference.table.participate').toString(),
        headerAlign: 'center',
        align: 'center',
        flex: 50,
        renderCell: (params: GridRenderCellParams) => {
          return (
            <Button
              variant="soft"
              size="small"
              onClick={() => {
                getDetail(params.row.id);
                // if (params.row.ownerYn === 'Y') {
                //   joinCheck(params.row);
                // } else {
                //   navigate('/conference/meeting', { state: params.row, replace: true });
                // }
              }}
              // disabled={params.row.room.closed === true}
              sx={{
                backgroundColor: params.row.room.closed ? 'rgba(145, 158, 171, 0.24)' : '',
                color: params.row.room.closed ? 'rgba(145, 158, 171, 0.8)' : '',
              }}
            >
              {translate('myCalendar.enter').toString()}
            </Button>
          );
        },
      },
    ];
    const historyColumns = columns.slice(0, -1);

    const [openShareFiles, setOpenShareFiles] = useState(false);
    const [openRecordingFiles, setOpenRecordingFiles] = useState(false);
    // const handleChangePage = (newPage: number) => {
    //   conferenceStore.pagination.setProps({ page: newPage + 1 });
    //   getData();
    // };

    const joinCheck = async (data: IConferenceSnapshot) => {
      const token = localStorage.getItem('accessToken');
      setData(data);
      await axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/web/room/joinCheck`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({ roomId: data.room.id }),
      }).then(async (res) => {
        if (await res.data.responseData) {
          if (res.data.responseData === 'success') {
            navigate('/conference/meeting', { state: toJS(data), replace: true });
          } else if (res.data.responseData === 'confirm') {
            setDurationAlert(true);
          } else {
            alert(translate('error.100'));
          }
        }
      });
    };
    const durationTime = 1;
    const [durationAlert, setDurationAlert] = useState(false);
    const locale = localStorage.getItem('i18nextLng');
    const lang = (locale: string) => {
      if (locale === 'ja') {
        return `会議は${durationTime}時間以上後に予定されています。会議を開始しますか？`;
      } else if (locale === 'en') {
        return `The meeting is scheduled to start in ${durationTime} hours or more. Would you like to start the meeting now?`;
      } else {
        return `회의 시작시간이 ${durationTime}시간 이상 남았습니다. 회의를 시작하시겠습니까?`;
      }
    };

    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Paper sx={{ flex: 1, height: '100%' }}>
            <CDataGrid
              store={conferenceStore}
              // initialState={{ sortModel: { filed: 'conference_date', sort: 'desc' } }}
              // components={{ sortModel: { filed: 'conference_date', sort: 'desc' } }}
              // componentsProps={{ sortModel: { filed: 'conference_date', sort: 'desc' } }}
              columns={page !== 'history' ? columns : historyColumns}
              rows={'conferences'}
              rowId={'id'}
              paging={true}
              pagingType={'custom'}
              autoPageSize={false}
              density={'standard'}
              disableSelectionOnClick
              onPageChange={(newPage: number) => {
                handleChangePage(newPage);
              }}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel: any) => {
                setSelectionModel(newSelectionModel);
              }}
              onCellDoubleClick={(row: any) => {
                getDetail(row.id);
              }}
              sx={{ '& .MuiDataGrid-main': { borderBottom: '.5px solid black' } }}
            />
          </Paper>
        </Box>

        {openDetail && ( // 회의상세정보 Dialog
          <Dialog
            open={true}
            maxWidth={'sm'}
            PaperProps={{
              sx: {
                p: 4,
                borderRadius: 4,
              },
            }}
            onClose={() => {
              setOpenDetail(false);
              setData({} as IConferenceSnapshot);
            }}
          >
            <IconButton
              onClick={() => {
                setOpenDetail(false);
                setData({} as IConferenceSnapshot);
              }}
              sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
            >
              <Iconify icon={'ic:round-close'} />
            </IconButton>
            <ConferenceDetail
              data={data}
              handleClose={() => {
                setOpenDetail(false);
                setData({} as IConferenceSnapshot);
              }}
              getData={getData}
              getDetail={getDetail}
            />
          </Dialog>
        )}

        {openReport && reportData && (
          <Dialog
            open={openReport}
            maxWidth={'md'}
            fullWidth
            scroll="body"
            PaperComponent={(props) => {
              return isMobile ? (
                <Paper {...props} />
              ) : (
                <Draggable bounds="parent" cancel=".MuiStack-root">
                  <Paper {...props} />
                </Draggable>
              );
            }}
            PaperProps={{
              sx: {
                p: 0,
                borderRadius: 4,
              },
            }}
            onClose={() => {
              setOpenReport(false);
              setReportData({} as IReportSnapshot);
              setData({} as IConferenceSnapshot);
            }}
          >
            <ReportDetail
              onClose={() => {
                setOpenReport(false);
                setReportData({} as IReportSnapshot);
                setData({} as IConferenceSnapshot);
              }}
              reportData={reportData}
              data={data}
              getReportData={() => viewReport(data.room.id)}
              getData={getData}
            />
          </Dialog>
        )}
        {openReportEditor && (
          <Dialog
            open={openReportEditor}
            maxWidth={'md'}
            fullWidth
            scroll="body"
            PaperComponent={(props) => {
              return isMobile ? (
                <Paper {...props} />
              ) : (
                <Draggable bounds="parent" cancel="form">
                  <Paper {...props} />
                </Draggable>
              );
            }}
            PaperProps={{
              sx: {
                p: 0,
                borderRadius: 4,
              },
            }}
            onClose={(e, reason) => {
              reason !== 'backdropClick' && setOpenReportEditor(false);
              reason !== 'backdropClick' && setData({} as IConferenceSnapshot);
            }}
          >
            <ReportCreate
              path={undefined}
              onClose={() => {
                setOpenReportEditor(false);
                setData({} as IConferenceSnapshot);
              }}
              reportData={reportData}
              data={data}
              getData={getData}
            />
          </Dialog>
        )}

        {openShareFiles && (
          <Dialog
            open={openShareFiles}
            maxWidth={'sm'}
            fullWidth
            scroll="body"
            PaperComponent={(props) => {
              return isMobile ? (
                <Paper {...props} />
              ) : (
                <Draggable bounds="parent" cancel="form">
                  <Paper {...props} />
                </Draggable>
              );
            }}
            PaperProps={{
              sx: {
                p: 0,
                borderRadius: 4,
              },
            }}
            onClose={() => {
              setOpenShareFiles(false);
              setData({} as IConferenceSnapshot);
            }}
          >
            <Fileshare
              ChangeHandleClose={() => {
                setOpenShareFiles(false);
                setData({} as IConferenceSnapshot);
                getData();
              }}
              handleClose={() => {
                setOpenShareFiles(false);
              }}
              rowData={data}
              path="grid"
              shareFilesRoomId={shareFilesRoomId}
              ownerYn={data.ownerYn || ''}
            />
          </Dialog>
        )}
        {openRecordingFiles && data.room.recordingFiles && (
          <Dialog
            open={openRecordingFiles}
            maxWidth={'lg'}
            fullWidth
            scroll="body"
            PaperComponent={(props) => {
              return isMobile ? (
                <Paper {...props} />
              ) : (
                <Draggable bounds="parent" cancel="form">
                  <Paper {...props} />
                </Draggable>
              );
            }}
            PaperProps={{
              sx: {
                p: 0,
                borderRadius: 4,
                maxHeight: '90vh',
                flex: 1,
              },
            }}
            onClose={(e, reason) => {
              reason !== 'backdropClick' && setOpenRecordingFiles(false);
              reason !== 'backdropClick' && setData({} as IConferenceSnapshot);
            }}
          >
            <RecordingFiles
              handleClose={() => {
                setOpenRecordingFiles(false);
                setData({} as IConferenceSnapshot);
              }}
              fileData={data.room.recordingFiles}
              data={data}
              getData={getData}
            />
          </Dialog>
        )}
        {isAlertOpen && (
          <CAlert
            open={isAlertOpen}
            content={`[${data.title}]${translate('conference.alert.chatDownload').toString()}`}
            handleClose={() => {
              setIsAlertOpen(false);
              setData({} as IConferenceSnapshot);
            }}
            hasCancel
            callBack={() => downloadChat(data.room.id, data.title)}
          />
        )}
        {durationAlert && (
          <CAlert
            open={durationAlert}
            handleClose={() => setDurationAlert(false)}
            content={lang(locale || 'ja')}
            hasCancel
            callBack={() => {
              navigate('/conference/meeting', { state: toJS(data), replace: true });
            }}
          />
        )}
      </>
    );
  },
);

export default MyConferenceTable;
