import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { MeetingMember } from '../meeting-member/MeetingMember';
import { createConference } from '../conference/Conference';
import { createRoom } from '../room/Room';
import { createUser } from '../user/User';
import { createFacility } from '../facility/Facility';
import { createAuth } from '../auth/Auth';

/**
 * # ProgressList
 *
 * ProgressList을 설명하세요.
 */
export const ProgressList = types
  .model('ProgressList')
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.number, 0),
    startTime: types.optional(types.string, ''), //	개시시간		Varchar2
    runningTime: types.optional(types.string, ''), //	경과시간		Varchar2
    appointment: createConference(), //	회의정보 		Object
    // id: types.optional(types.string, ''), //	예약아이디		Varchar
    title: types.optional(types.string, ''), //	예약명		Varchar
    // start: types.optional(types.string, ''), //	회의개설시간		Date
    // end: types.optional(types.string, ''), //	회의종료시간		Date
    owner: createUser(), //	회의개설자 객체		Object
    // // id: types.optional(types.string, ''), //	유저아이디 ( pk )		Number
    // // displayName: types.optional(types.string, ''), //	이름		Varchar
    // // languageId: types.optional(types.string, ''), //	언어		Varchar
    // // address: types.optional(types.string, ''), //	이메일정보객체
    // // email: types.optional(types.string, ''), //	이메일정보
    // // authCd: types.optional(types.string, ''), //	유저구분 ( 권한 ) 		Varchar
    // // auth: createAuth(), //	유저구분 정보 객체		Object
    // // authCd: types.optional(types.string, ''), //	"유저구분 ( 권한 ) 	"		String
    // // authNm: types.optional(types.string, ''), //	유저구분 ( 권한 ) 값		String
    // // facilityCd: types.optional(types.string, ''), //	기관/시설코드		String
    // // facility: createFacility(), //	기관/시설코드 객체		Object
    // // facilityCd: types.optional(types.string, ''), //	기관/시설코드		String
    // // facilityNm: types.optional(types.string, ''), //	기관/시설코드 값		String
    // // type: types.optional(types.string, ''), //	유저타입		String
    // room: createRoom(), //	회의방 객체		Object
    meetingMembers: types.optional(types.array(MeetingMember), []), //	회의방 초대회원리스트		Array
    // // email: types.optional(types.string, ''), //	초대장 이메일		Varchar
    // // User: types.optional(types.string, ''), //	미팅멤버 유저정보		Object
    // // invitationYn: types.optional(types.string, ''), //	회의 참여선택여부		String
    // ownerYn: types.optional(types.boolean, false), //	호스트 여부		String
    // // runningTime: types.optional(types.string, ''), //	회의 진행시간 		String
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model progressList --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IProgressList;
      (Object.keys(newProps) as TProgressListKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TProgressList = Instance<typeof ProgressList>;
type TProgressListSnapshot = SnapshotOut<typeof ProgressList>;

export interface IProgressList extends TProgressList {}
export type TProgressListKeys = keyof TProgressListSnapshot & string;
export interface IProgressListSnapshot extends TProgressListSnapshot {}
export const createProgressList = () => types.optional(ProgressList, {} as TProgressList);
