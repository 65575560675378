import { Badge, Box, Stack, useTheme, Link, IconButton, AppBar, BoxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { pxToRem } from 'src/theme/typography';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PATH_ROOT } from 'src/routes/paths';
import { useSettingsContext } from './settings';
import { ReactComponent as IcoHome } from 'src/assets/icons/ico-home.svg';
import { ReactComponent as IcoCart } from 'src/assets/icons/ico-cart.svg';
import { ReactComponent as IcoSearch } from 'src/assets/icons/ico-search.svg';
import { ReactComponent as IcoClose } from 'src/assets/icons/ico-close.svg';
import { ReactComponent as IcoList } from 'src/assets/icons/ico-list.svg';
import { ReactComponent as IconBack } from 'src/assets/icons/ico-back.svg';
import { ReactComponent as IcoSetting } from 'src/assets/icons/ico-setting-h.svg';
import { ReactComponent as IcoAlarm } from 'src/assets/icons/ico-alarm.svg';
import { Link as RouterLink } from 'react-router-dom';
import { useStores } from 'src/models';
import { useAuthContext } from 'src/auth/useAuthContext';
import useOffSetTop from 'src/hooks/useOffSetTop';
import { HEADER } from 'src/config-global';

type CHeaderProps = {
  /**
   * title
   */
  title: string;
  /**
   * 로고 사용여부
   */
  showMainIcon?: 'logo' | 'back' | 'none';
  /**
   * 뒤로가기 아이콘 함수
   */
  handleMainIcon?: VoidFunction;
  /**
   * 홈 아이콘 사용여부
   */
  showHomeIcon?: boolean;
  /**
   * 홈 아이콘 함수
   */
  handleHome?: VoidFunction;
  /**
   * 장바구니 아이콘 사용여부
   */
  showCartIcon?: boolean;
  /**
   * 장바구니 아이콘 함수
   */
  handleCart?: VoidFunction;

  /**
   * 검색 아이콘 사용여부
   */
  showSearchIcon?: boolean;
  /**
   * 검색 아이콘 함수
   */
  handleSearch?: VoidFunction;

  /**
   * 결과리스트 아이콘 사용여부
   */
  showListIcon?: boolean;
  /**
   * 결과리스트 아이콘 함수
   */
  handleList?: VoidFunction;

  /**
   * X 아이콘 사용여부
   */
  showXIcon?: boolean;
  /**
   * X 아이콘 함수
   */
  handleX?: VoidFunction;

  /**
   * 알림 아이콘 사용여부
   */
  showAlarmIcon?: boolean;
  /**
   * 알림 아이콘 함수
   */
  handleAlarm?: VoidFunction;

  /**
   * 설정 아이콘 사용여부
   */
  showSettingIcon?: boolean;
  /**
   * 설정 아이콘 함수
   */
  handleSetting?: VoidFunction;

  // -----------------------------------------------
  /**
   * 해당 화면에서 아이콘 추가
   */
  icon?: any;
  /**
   * 아이콘 함수
   */
  handleIcon?: VoidFunction;
  handleclose?: VoidFunction;
};

export const CHeader = observer(
  ({
    title,
    showMainIcon,
    handleMainIcon,
    icon,
    showHomeIcon,
    handleHome,
    showSearchIcon,
    handleSearch,
    showListIcon,
    handleList,
    showXIcon,
    handleX,
    showAlarmIcon,
    handleAlarm,
    showSettingIcon,
    handleSetting,
  }: CHeaderProps) => {
    const theme = useTheme();
    const rootStore = useStores();
    // const {  } = rootStore;
    const { user, isAuthenticated } = useAuthContext();
    const scrollRef = useRef<any>(null);
    const navigate = useNavigate();
    const { themeMode } = useSettingsContext();
    const iconColor = themeMode === 'light' ? '#202123' : '#FFFFFF';

    useEffect(() => {
      if (isAuthenticated) {
      }
    }, [user]);
    const [alarmCount, setAlarmCount] = useState(0);

    useEffect(() => {
      if (window.history.length < 2) {
        handleMainIcon = () => {
          navigate('/', { replace: true });
        };
      }
    }, []);

    const isOffset = useOffSetTop(HEADER.H_MOBILE);

    return (
      <>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{
          position: 'sticky',
          top: 0,
          height: '64px',
          px: 0,
          background: '#FFFFFF',
          zIndex: theme.zIndex.appBar,
        }}>
          {
            title !== '' &&
            <Stack
              sx={{
                position: 'absolute',
                left: '50%',
                // top: 8,
                transform: 'translateX(-50%)',
                minWdith: 'md',
                mx: 'auto',
                zIndex: 2,
                fontWeight: 700,
                fontSize: '1.2rem',
              }}
            >
              {title}
            </Stack>
          }
          {/* ------------------------------------------------------------------- */}

          {/* 좌측_뒤로가기, 로고, none 중 선택 */}

          {/* 뒤로가기 버튼 */}
          {showMainIcon === 'back' && (
            <Box
              id="btn-top-nav-back"
              onClick={() => {
                if (handleMainIcon) {
                  handleMainIcon();
                } else {
                  navigate(-1);
                }
              }}
              sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
            >
              <IconBack />
            </Box>
          )}

          {/* 로고 */}
          {showMainIcon === 'logo' && (
            <Box
              id="btn-top-nav-logo"
              onClick={() => {
                handleMainIcon && handleMainIcon();
                !handleMainIcon && navigate('/');
              }}
              sx={{
                cursor: 'pointer',
                mt: pxToRem(-6),
              }}
            >
              <Link
                component={RouterLink}
                to="/"
                sx={{ display: 'contents' }}
              >
                <Box component="img" src={'/logo/Gentok-Logo.svg'} />
              </Link>
            </Box>
          )}

          {/* 아무것도 없음 */}
          {showMainIcon === 'none' && <Box />}

          {/* ------------------------------------------------------------------- */}
          {/* 우측_아이콘 중 선택*/}
          <Stack direction={'row'} alignItems={'flex-start'} sx={{
            '& .MuiIconButton-root': {
              p: 0,
              pl: 1,
            }
          }}>
            {/* 설정 : showSettingIcon*/}
            {showSettingIcon &&
              <IconButton
                id="btn-top-nav-setting"
                component={icon}
                icon={icon}
                onClick={() => {
                  handleSetting && handleSetting();
                  !handleSetting && navigate(PATH_ROOT.setting.root);
                }}
                sx={{ cursor: 'pointer' }}
              >
                <IcoSetting fill={'#333'} />
              </IconButton>
            }

            {/* 검색 : showSearchIcon*/}
            {showSearchIcon &&
              <IconButton
                id="btn-top-nav-search"
                component={icon}
                icon={icon}
                onClick={() => {
                  handleSearch && handleSearch();
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <IcoSearch fill={'#333'} />
              </IconButton>
            }

            {/* 홈 : showHomeIcon*/}
            {showHomeIcon &&
              <IconButton
                id="btn-top-nav-home"
                component={icon}
                icon={icon}
                onClick={() => {
                  handleHome && handleHome();
                  !handleHome && navigate('/');
                }}
                sx={{ cursor: 'pointer' }}
              >
                <IcoHome />
              </IconButton>
            }

            {/* 결과리스트 : showListIcon*/}
            {showListIcon &&
              <IconButton
                id="btn-top-nav-resultlist"
                component={icon}
                icon={icon}
                onClick={() => {
                  handleList && handleList();
                }}
                sx={{
                  cursor: 'pointer',
                }}
              >
                <IcoList />
              </IconButton>
            }

            {/* 알림 : showAlarmIcon*/}

            {/* {showAlarmIcon  &&
              <Badge
                id="btn-top-nav-alarm"
                badgeContent={alarmCount}
                color="primary"
                sx={{
                  cursor: 'pointer',
                  transform: showMainIcon === 'logo' ? 'translateY(0%)' : 'translateY(-11%)',
                  '& .MuiBadge-badge': {
                    right: 7,
                    top: 7,
                    minWidth: 14,
                    height: 14,
                    p: 0,
                    fontSize: pxToRem(8),
                  },
                }}
              >
                <IcoAlarm
                  stroke={iconColor}
                  onClick={() => {
                    handleAlarm && handleAlarm();
                    !handleAlarm && navigate(PATH_ROOT.customer.alarm);
                  }}
                />
              </Badge>
            } */}

            {/* X(close) : showXIcon*/}
            {showXIcon &&
              <IconButton
                id="btn-top-nav-close"
                component={icon}
                icon={icon}
                onClick={() => {
                  handleX && handleX();
                  !handleX && navigate(-1);
                }}
                sx={{ cursor: 'pointer' }}
              >
                <IcoClose stroke={'#333'} />
              </IconButton>
            }

          </Stack>
        </Stack>
      </>
    );
  },
);

export default CHeader;
