import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # Pagination
 *
 * Pagination을 설명하세요.
 */
export const Pagination = types
  .model('Pagination')
  // --------------------------------------------------------------------------
  .props({
    // offset : 실제 쿼리의 조회 시작 인덱스
    // page : 요청한 페이지 인덱스
    // size : 요청된 조회 건수
    // numberOfElements : 조회된 데이터 수
    // totalElements : 전체 데이터 수
    // totalPages : 계산된 전체 페이지 수
    // frist : 현재 페이지가 첫번째 페이지인 경우 true
    // last : 현재 페이지가 마지막 페이지인 경우 true

    // offset: types.optional(types.maybeNull(types.number), 0),
    page: types.optional(types.number, 1),
    size: types.optional(types.number, 25),
    totalElements: types.optional(types.number, 0),
    // numberOfElements: types.optional(types.number, 0),
    // totalPages: types.optional(types.number, 0),
    // first: types.optional(types.boolean, false),
    // last: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model pagination --store
  .actions((self) => ({
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IPagination;
      (Object.keys(newProps) as TPaginationKeys[]).forEach((key) => {
        //@ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TPagination = Instance<typeof Pagination>;
type TPaginationSnapshot = SnapshotOut<typeof Pagination>;

export interface IPagination extends TPagination {}
export type TPaginationKeys = keyof TPaginationSnapshot & string;
export interface IPaginationSnapshot extends TPaginationSnapshot {}
export const createPagination = () =>
  types.optional(Pagination, {
    // offset: 0,
    page: 1,
    size: 100,
    totalElements: 0,
    // numberOfElements: 0,
    // totalPages: 0,
    // first: false,
    // last: false,
  } as TPagination);
