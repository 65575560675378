import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # SentPushAlarm
 *
 * SentPushAlarm을 설명하세요.
 */
export const SentPushAlarm = types
  .model('SentPushAlarm')
  // --------------------------------------------------------------------------
  .props({
    id: types.optional(types.number, 0),
    title: types.maybeNull(types.optional(types.string, '')), // 회의명
    displayName: types.maybeNull(types.optional(types.string, '')), // 수신자 이름
    sendDt: types.maybeNull(types.optional(types.string, '')), // 송신 날짜
    isRead: types.maybeNull(types.optional(types.boolean, true)), // 읽음 여부
    seqNno: types.maybeNull(types.optional(types.number, 0)), // 푸시 시퀀스 번호
    facilityCd: types.maybeNull(types.optional(types.string, '')), // 수신자 시설코드
    loginId: types.maybeNull(types.optional(types.string, '')), // 수신자 아이디
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model sentPushAlarm --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ISentPushAlarm;
      (Object.keys(newProps) as TSentPushAlarmKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TSentPushAlarm = Instance<typeof SentPushAlarm>;
type TSentPushAlarmSnapshot = SnapshotOut<typeof SentPushAlarm>;

export interface ISentPushAlarm extends TSentPushAlarm {}
export type TSentPushAlarmKeys = keyof TSentPushAlarmSnapshot & string;
export interface ISentPushAlarmSnapshot extends TSentPushAlarmSnapshot {}
export const createSentPushAlarm = () => types.optional(SentPushAlarm, {} as TSentPushAlarm);
