import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { observer } from 'mobx-react-lite';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { useLocales } from 'src/locales';
import { Stack, Typography, useTheme } from '@mui/material';
import CAlert from 'src/components/CAlert';
import CSelect from 'src/components/forms/CSelect';

type FormValuesProps = {
  loginId: string;
  email: string;
};

interface Props {
  type: string;
}

const FindForm = observer(({ type }: Props) => {
  const { REACT_APP_API_URL } = process.env;
  const { translate } = useLocales();
  const theme = useTheme();

  const isId = type === 'findId';

  const FindUserSchema = Yup.object().shape(
    isId
      ? { facilityCd: Yup.string().required(''), email: Yup.string().trim().required('') }
      : {
          facilityCd: Yup.string().required(''),
          loginId: Yup.string().trim().required(''),
          email: Yup.string().required(''),
        },
  );

  const defaultValues = isId ? { email: '' } : { email: '', loginId: '' };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FindUserSchema),
    defaultValues,
  });

  const {
    clearErrors,
    handleSubmit,
    getValues,
    formState: { isSubmitting },
  } = methods;

  const [errorMsg, setErrorMsg] = useState('');

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');

  const selectOptions = JSON.parse(localStorage.getItem('facility') || '');

  const onSubmit = async () => {
    try {
      await fetch(`${REACT_APP_API_URL}/web/user/${type}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(getValues()),
      }).then(async (res) => {
        const json: any = await res.json();
        const tmp = json.responseData;
        if (tmp) {
          setAlertMsg(translate(`login.${type}.alert`).toString());
          setOpenAlert(true);
        } else {
          setErrorMsg(translate(`error.${json.error}`).toString());
        }
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2} alignItems={'center'}>
          <CSelect
            variant="outlined"
            label={translate(`login.selectFacility`).toString()}
            name={'facilityCd'}
            options={selectOptions}
            defaults={{
              code: '',
              value: translate(`select`).toString(),
            }}
          />
          <RHFTextField
            name={'email'}
            type={'text'}
            label={translate(`login.findId.enterEmail`).toString()}
            variant={'outlined'}
            onFocus={() => {
              clearErrors();
              setErrorMsg('');
            }}
            onKeyUp={(e: any) => {
              e.target.value = e.target.value.replace(/\s/gi, '');
            }}
          />
          {!isId && (
            <RHFTextField
              name={'loginId'}
              type={'text'}
              label={translate(`login.findPassword.enterId`).toString()}
              variant={'outlined'}
              onFocus={() => {
                clearErrors();
                setErrorMsg('');
              }}
              onKeyUp={(e: any) => {
                e.target.value = e.target.value.replace(/\s/gi, '');
              }}
            />
          )}

          <Typography variant="subtitle2" color={theme.palette.error.main} height={'20px'}>
            {errorMsg}
          </Typography>

          <LoadingButton
            fullWidth
            color="primary"
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
            sx={{
              bgcolor: 'primary.main',
              borderRadius: 2,
            }}
          >
            {translate(`login.${type}.send`).toString()}
          </LoadingButton>
        </Stack>
      </FormProvider>
      {openAlert && (
        <CAlert
          open={openAlert}
          handleClose={() => {
            setOpenAlert(false);
          }}
          content={alertMsg}
        />
      )}
    </>
  );
});

export default FindForm;
