import { PanelResizeHandle } from "react-resizable-panels";
import { Box } from "@mui/material";

export default function ResizeHandle({
    className = "",
    id
}: {
    className?: string;
    id?: string;
}) {
    return (
        <PanelResizeHandle
            style={{
                flex: '0 0 1.2em',
                position: 'relative',
                outline: 'none',
                background: '#1e2228'
            }}
            id={id}
        >
            <Box>
                <svg viewBox="0 0 24 24"
                    style={{
                        width: '1em',
                        height: '1em',
                        position: 'absolute',
                        left: 'calc(50% - 0.5rem)',
                        top: 'calc(50% - 0.5rem)',
                    }}>
                    <path fill="currentColor" d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z" />
                </svg>
            </Box>
        </PanelResizeHandle>
    );
}
