import { applySnapshot, Instance, SnapshotOut, types } from "mobx-state-tree"

import { withEnvironment } from "../extensions/with-environment";
import { withRootStore } from '../extensions/with-root-store';
import { IParticipant, IParticipantSnapshot, Participant } from "../participant/Participant";
import { action, toJS, transaction } from 'mobx';
import { ICandidate } from "../candidate/Candidate";

/**
 * # ParticipantStore
 *
 * ParticipantStore을 설명하세요.
 */
export const ParticipantStore = types
  .model("ParticipantStore")
  // --------------------------------------------------------------------------
  .props({
    // existings: types.optional(types.array(Participant), []),
    participants: types.optional(types.array(Participant), []),
    screenParticipant: types.optional(types.array(Participant), []),
    recordParticipant: types.optional(types.array(Participant), []),
  })
  .extend(withRootStore)
  .extend(withEnvironment)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({
    getParticipants: () => {
      return self.participants;
    },
    getScreenParticipants: () => {
      return self.screenParticipant;
    },
    getRecordParticipants: () => {
      return self.recordParticipant;
    },
  }))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * participants을 교체
     *
     * @param `participants` 새로운 모델의 배열
     */

    resetParticipants: () => {
      self.participants.replace([]);
    },

    setParticipants: (participants: IParticipant[]) => {
      self.participants.replace(participants);
    },

    addParticipant: (participant: IParticipant) => {
      const item = self.participants.find((item) => {
        return item.uid === participant.uid
      })
      if (!item) {
        self.participants.replace([
          ...self.participants,
          participant
        ])
      }
    },

    addScreenParticipant: (screenParticipant: IParticipant) => {
      self.screenParticipant.replace([
        ...self.screenParticipant,
        screenParticipant
      ])
    },

    addRecordParticipant: (recordParticipant: IParticipant) => {
      self.recordParticipant.replace([
        ...self.recordParticipant,
        recordParticipant
      ])
    },
    removeRecordParticipant: (uid: string) => {
      self.recordParticipant.map((recordParticipant: IParticipant, index: number) => {
        if (recordParticipant.uid === uid || recordParticipant.cuid === uid) {
          self.recordParticipant.splice(index, 1);
        }
      })
    },
    removeScreenParticipant: (uid: string) => {
      self.screenParticipant.map((screenParticipant: IParticipant, index: number) => {
        if (screenParticipant.uid === uid || screenParticipant.cuid === uid) {
          self.screenParticipant.splice(index, 1);
        }
      })
    },

    removeParticipant: (uid: string) => {
      self.participants.map((participant: IParticipant, index: number) => {
        if (participant.uid === uid || participant.cuid === uid) {
          self.participants.splice(index, 1);
        }
      })
    },

    removeLeaveScreenParticipant: () => {
      self.participants.map((participant: IParticipant, index: number) => {
        self.screenParticipant.splice(index, 1);
      })
    },

    updateParticipant: (participant: IParticipant) => {
      const item = self.participants.find((item) => item.cuid === participant.cuid);
      if (item) {
        applySnapshot(item, {
          ...item,
          ...participant,
        });
      } else {
        // throw new Error('participant 업데이트 실패');
      }
    },

    updateScreenParticipant: (screenParticipant: IParticipant) => {
      const item = self.screenParticipant.find((item) => item.cuid === screenParticipant.cuid);
      if (item) {
        applySnapshot(item, {
          ...item,
          ...screenParticipant,
        });
      } else {
        // throw new Error('participant 업데이트 실패');
      }
    },

    updateParticipantCandidate: (participant: IParticipant) => {
      const item = self.participants.find((item) => item.uid === participant.uid);
      if (item) {
        item.setProp('candidate', participant.candidate)

        applySnapshot(item, {
          ...item,
          candidate: participant.candidate
        });
      } else {
        throw new Error('participant 업데이트 실패 : ' + participant.uid);
      }
    },

    updateParticipantAnswer: (participant: IParticipant) => {
      const item = self.participants.find((item) => item.uid === participant.uid);
      if (item) {
        item.setProp('sdpAnswer', participant.sdpAnswer);
      } else {
        throw new Error('participant 업데이트 실패');
      }
    },

    broadcastStopped: (uid: string) => {
      const item = self.participants.find((item) => item.uid === uid);

      self.screenParticipant.find((item) => item.uid === uid);
      self.screenParticipant.map((participant: IParticipant, index: number) => {
        if (participant.uid === uid || participant.cuid === uid) {
          self.screenParticipant.splice(index, 1);
        }
      })
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Participants를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async () => {
      // const participantApi : ParticipantApi = new ParticipantApi(self.environment.api);
      // const result: TGetParticipantsResult = await participantApi.gets();
      // if (result.kind === 'ok') {
      //   self.setParticipants(result.participants);
      // } else {
      //   console.error(result.kind);
      // }
    }
  }))

// --------------------------------------------------------------------------
type TParticipantStore = Instance<typeof ParticipantStore>;
type TParticipantStoreSnapshot = SnapshotOut<typeof ParticipantStore>

export interface IParticipantStore extends TParticipantStore { }
export type TParticipantStoreKeys = keyof TParticipantStoreSnapshot & string;
export interface IParticipantStoreSnapshot extends TParticipantStoreSnapshot { }
export const createParticipantStore = () =>
  types.optional(ParticipantStore, {

  } as TParticipantStore);

