import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import { useStores } from "../../../models/root-store/root-store-context"
import { Button, MenuItem, MenuList, Paper, Popover, Rating, Select, Slider, Stack, Typography, } from '@mui/material';
import Label from 'src/components/label';
import { debounce } from 'lodash';
import { useLocales } from "src/locales";
import CAlert from "src/components/CAlert";

type Props = {
  audioInputs: { label: string; value: string }[];
  audioInput: string | null;
  audioOutputs: { label: string; value: string }[];
  audioOutput: string | null;
  aftervideostate: any | null;
  anchorSet: HTMLButtonElement | null;
  view: string
  returnAudioInputData: (
    audioInput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioOutputData: (
    audioOuput: string | null,
    value: any,
    label: any
  ) => void;
  returnAudioInputStream: (
    Inputstate: any | null,
    type?: string | null
  ) => void;
  returnAudioOutputStream: (
    Outputstate: any | null,
    type?: string | null
  ) => void;
  videoMic: any;
  videoVol: any;
  onButtonStatus?: (
    flag: any | null
  ) => void;
  buttonStatus?: any;
  onTestState?: (
    flag: any | null
  ) => void;
  toggleMic?: any;
};
/**
 * ## AudioSetting 설명
 *
 */
let currentTestMode: any;
let currentValue: any;
export const SpeakerSetting = observer(({
  audioInputs,
  audioInput,
  audioOutput,
  audioOutputs,
  anchorSet,
  aftervideostate,
  returnAudioInputData,
  returnAudioOutputData,
  returnAudioInputStream,
  returnAudioOutputStream,
  view,
  videoMic,
  videoVol,
  onButtonStatus,
  buttonStatus,
  onTestState,
  toggleMic
}: Props
) => {
  const rootStore = useStores();
  const [input, setInput] = useState<number>(0);
  const [output, setOutput] = useState<number>(0);
  const [browserName, setBrowserName] = useState('Safari');
  const { translate } = useLocales();
  const [isVolAlertOpen, setIsVolAlertOpen] = useState(false);
  const [isMicAlertOpen, setIsMicAlertOpen] = useState(false);

  const [testState, setTestState] = useState(false);
  const [alertMicMessage, setAlertMicMessage] = useState(translate(`meeting.alert.micMsg`).toString());
  const [alertVolMessage, setAlertVolMessage] = useState(translate(`meeting.alert.micMsg`).toString());
  let currentVolButton: any;
  let currentMicButton: any;
  const value = [
    {
      value: 0,
    },
    {
      value: 10,
    },
    {
      value: 20,
    },
    {
      value: 30,
    },
    {
      value: 40,
    },
    {
      value: 50,
    },
    {
      value: 60,
    },
    {
      value: 70,
    },
    {
      value: 80,
    },
    {
      value: 90,
    },
    {
      value: 100,
    },
  ];

  const videoSetting = async (button: any, type: any, input?: any) => {

    await aftervideostate.data.aCtx.audioWorklet.addModule(
      '/assets/volume-meter-processor.js',
    );
    const volumeMeterNode = new AudioWorkletNode(aftervideostate.data.aCtx, 'volume-meter');
    if (input === 'input') {
      volumeMeterNode.port.onmessage = ({ data }) => {
        setInput(data * 100 * videoMic);
      };
    } else {
      volumeMeterNode.port.onmessage = ({ data }) => {
        setOutput(data * 100 * videoVol);
      };
    }

    if (type === 'reSet') {
      if (button) {
        if (button.style.background === 'green') {
          aftervideostate.data.aSrc.connect(volumeMeterNode).connect(aftervideostate.data.aCtx.destination);
        }
        onButtonStatus && onButtonStatus(false);
      }
    }
  }

  useEffect(() => {
    currentMicButton = document.getElementById('micSound');
    currentVolButton = document.getElementById('soundButton');
    if (testState === true) {
      if (currentMicButton.style.background === 'green') {
        videoSetting(currentMicButton, 'reSet', 'input');
      }
      if (currentVolButton.style.background === 'green') {
        videoSetting(currentVolButton, 'reSet', 'output');
      }
    }
  }, [aftervideostate.data]);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    // 브라우저 종류 확인
    if (userAgent.includes('Edg')) {
      setBrowserName('Edge');
    } else if (userAgent.includes('Chrome')) {
      setBrowserName('Chrome');
    } else if (userAgent.includes('Firefox')) {
      setBrowserName('Firefox');
    } else if (userAgent.includes('Safari')) {
      setBrowserName('Safari');
    } else if (userAgent.includes('Opera') || userAgent.includes('OPR')) {
      setBrowserName('Opera');
    }
  }, []);


  const outputsound = (event: any, value: any , start?:any) => {
    currentVolButton = document.getElementById('soundButton');
    currentTestMode='vol';
    if (currentVolButton.style.background === 'green' && event.type === 'click') {
      aftervideostate.data.aSrc.disconnect();
      setTimeout(() => {
        setInput(0);
        setOutput(0);
      }, 100);
      currentVolButton.style.background = '';
      setTestState(false);
      onButtonStatus && onButtonStatus(false);
    } else {
      if (toggleMic === true) {
        if (testState === false && event.type === 'click') {
          setIsVolAlertOpen(true);
          currentValue = value;
        } else if (testState === true || start === 'start') {
          onOutputSound(event, value);
        } else {
          returnAudioOutputStream(value / 100, 'Slider');
        }
      } else {
        if (testState === false && event.type === 'click') {
          setTestState(true);
          currentValue = value;
          onOutputSound(event, value);
        } else if (testState === true || start === 'start') {
          onOutputSound(event, value);
        } else {
          returnAudioOutputStream(value / 100, 'Slider');
        }
      }
    }
  };

  const sound = (event: any, value: any , start?:any) => {
    currentTestMode='mic';
    currentMicButton = document.getElementById('micSound');
    if (currentMicButton.style.background === 'green' && event.type === 'click') {
      setTimeout(() => {
        setInput(0);
        setOutput(0);
      }, 100);
      currentMicButton.style.background = '';
      aftervideostate.data.aSrc.disconnect();
      setTestState(false);
      onButtonStatus && onButtonStatus(false);
    } else {
      if (toggleMic === true) {
        if (testState === false && event.type === 'click') {
          setIsMicAlertOpen(true);
          currentValue = value;
        } else if (testState === true || start === 'start') {
          onInputSound(event, value)
        } else {
          returnAudioInputStream(value / 100, 'Slider');
        }
      } else {
        if (testState === false && event.type === 'click') {
          setTestState(true);
          currentValue = value;
          onInputSound(event, value)
        } else if (testState === true || start === 'start') {
          onInputSound(event, value)
        } else {
          returnAudioInputStream(value / 100, 'Slider');
        }
      }
    }
  };

  const onOutputSound = (e: any, value: any) => {
    currentTestMode='vol';
    onButtonStatus && onButtonStatus(true);
    currentMicButton = document.getElementById('micSound');
    currentVolButton = document.getElementById('soundButton');
    currentVolButton.style.background = 'green';
    currentMicButton.style.background = '';
    setTimeout(() => {
      setInput(0);
      setOutput(0);
    }, 100);
    VolSound(value);
  }

  const VolSound = debounce((value) => {
    returnAudioOutputStream(value / 100);
    onTestState && onTestState(true);
  }, 1000);

  const onInputSound = (e: any, value: any) => {
    onButtonStatus && onButtonStatus(true);
    currentMicButton = document.getElementById('micSound');
    currentVolButton = document.getElementById('soundButton');
    currentVolButton.style.background = '';
    currentMicButton.style.background = 'green';    
    setTimeout(() => {
      setInput(0);
      setOutput(0);
    }, 100);
    InputSound(value);
  }

  const InputSound = debounce((value) => {
    returnAudioInputStream(value / 100);
    onTestState && onTestState(true);
  }, 1000);

  return (
    <>
      <Typography variant={'subtitle2'} sx={{ p: 2, pb: 0 }}>
        {translate(`meeting.mic`).toString()}
      </Typography>
      <MenuList dense>
        <MenuItem>
          <Select value={audioInput} size={'small'} style={{ width: '350px' }}>
            {audioInputs.map((item, index) => (
              <MenuItem
                key={index}
                value={item.value}
                onClick={() => {
                  returnAudioInputData(audioInput, item.value, "");
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Select>
          &nbsp;&nbsp;
          <Button id="micSound" onClick={(e) => { sound(e, videoMic * 100) }}>
            {translate(`meeting.test`).toString()}
          </Button>
        </MenuItem>
        <MenuItem>
          <Label>{translate(`meeting.outputLevel`).toString()}</Label>
          <Rating
            name="customized-10"
            id={'volume-meter'}
            value={input}
            defaultValue={0}
            max={10}
            disabled
          />
        </MenuItem>
        <MenuItem>
          <Label>{translate(`meeting.volume`).toString()}</Label>
          <Slider
            defaultValue={videoMic * 100}
            sx={{
              width: 300,
              color: 'success.main',
            }}
            step={10}
            marks={value}
            valueLabelDisplay="auto"
            onChangeCommitted={sound}
            id={'Silder'}
          />
        </MenuItem>

        {browserName !== 'Safari' &&
          <Stack>
            <Typography variant={'subtitle2'} sx={{ p: 2, pb: 0 }}>
              {translate(`meeting.speaker`).toString()}
            </Typography>
            <MenuItem>
              <Select value={audioOutput} size={'small'} style={{ width: '350px' }}>
                {audioOutputs.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    onClick={() => {
                      returnAudioOutputData(audioOutput, item.value, "");
                    }}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
              &nbsp;&nbsp;
              <Button onClick={(e) => { outputsound(e, videoVol * 100) }} id="soundButton">
                {translate(`meeting.test`).toString()}
              </Button>
            </MenuItem>
            <MenuItem>
              <Label>{translate(`meeting.outputLevel`).toString()}</Label>
              <Rating
                name="customized-10"
                id={'speaker-volume-meter'}
                value={output}
                defaultValue={0}
                max={10}
                disabled
              />
            </MenuItem>
            <MenuItem>
              <Label>{translate(`meeting.volume`).toString()}</Label>
              <Slider
                defaultValue={videoVol * 100}
                sx={{
                  width: 300,
                  color: 'success.main',
                }}
                step={10}
                marks={value}
                valueLabelDisplay="auto"
                onChangeCommitted={outputsound}
                id={'Silder'}
              />
            </MenuItem>
          </Stack >
        }
      </MenuList>
      {isVolAlertOpen && (
        <CAlert
          open={isVolAlertOpen}
          content={alertVolMessage}
          handleClose={() => { setIsVolAlertOpen(false); }}
          hasCancel
          callBack={() => { setTestState(true); currentTestMode = "vol"; outputsound("", currentValue , "start" ); }}
        />
      )}
      {isMicAlertOpen && (
        <CAlert
          open={isMicAlertOpen}
          content={alertMicMessage}
          handleClose={() => { setIsMicAlertOpen(false); }}
          hasCancel
          callBack={() => { setTestState(true); currentTestMode = "mic"; sound("", currentValue , "start" ); }}
        />
      )}
    </>
  );
});

export default SpeakerSetting;