import { createContext, useEffect, useContext, useMemo, useCallback, useState } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useStores } from 'src/models/root-store/root-store-context';
import { IUserSnapshot } from 'src/models/user/User';
import { useNavigate } from 'react-router-dom';
import { isObject } from 'lodash';
import { save } from 'src/utils/storage';
import { useAuthContext } from 'src/auth/useAuthContext';
import { isValidToken, setSession } from 'src/auth/utils';
import { sendReactNativeMessage } from 'src/utils/common';

//
// ----------------------------------------------------------------------

export type CommonContextProps = {
  // Mode
  onReportRegist: (data: any) => void;
  setTitle: (main: string, sub?: string) => void;
  headerTitle: { main?: any; sub?: any };
  appVersion: AppVersion;
};

const initialState: CommonContextProps = {
  onReportRegist: () => { },
  setTitle: () => { },
  headerTitle: {},
  appVersion: {
    appId: "WATIS",
    nationCd: null,
    langCd: null,
    regDt: null,
    updDt: null,
    appNm: "WATIS",
    appCurrVer: "1.0",
    appOpenDt: 1672498800000,
    packageId: null,
    delYn: false,
  },
};

// ----------------------------------------------------------------------

export const CommonContext = createContext(initialState);

export const useCommonContext = () => {
  const context = useContext(CommonContext);

  if (!context) throw new Error('useCommonContext must be use inside CommonProvider');

  return context;
};

// ----------------------------------------------------------------------

type CommonProviderProps = {
  children: React.ReactNode;
};

interface Message {
  type: string;
  payload?: any;
}

interface AppVersion {
  appId: string,
  nationCd: null,
  langCd: null,
  regDt: null | number,
  updDt: null | number,
  appNm: string,
  appCurrVer: string,
  appOpenDt: number | string,
  packageId: null | string,
  delYn: boolean,
}

export function CommonProvider({ children }: CommonProviderProps) {
  const rootStore = useStores();
  const { REACT_APP_API_URL } = process.env;

  const navigate = useNavigate();
  const auth = useAuthContext();

  const [appVersion, setAppVersion] = useState<AppVersion>({
    appId: "appId",
    nationCd: null,
    langCd: null,
    regDt: null,
    updDt: null,
    appNm: "appName",
    appCurrVer: "0",
    appOpenDt: 1672498800000,
    packageId: null,
    delYn: false,
  });

  const [headerTitle, setHeaderTitle] = useState({
    main: '',
    sub: '',
  });

  const setTitle = useCallback(
    (newMain: string, newSub?: string) => {
      setHeaderTitle({
        main: newMain,
        sub: newSub || '',
      });
    },
    [setHeaderTitle],
  );

  const listener = useCallback(
    async (event: any) => {
      // console.log('🌈 ~ listener ~ event.data', event.data)
      let data: any;
      try {
        if (isObject(event.data)) {
          console.log('Passed Object ::: ', event.data);
          return;
        }

        if (!isObject(event.data)) {
          data = JSON.parse(event.data) as Message;
        }
      } catch (e) {
        console.log('e', event, e);
      }
      if (!data.payload) {
        console.log('empty payload.');
        return;
      }
      try {
        if (typeof event.data === 'string') {
          // alert('🌈 ~ listener ~ data ' + data)
          if (data.type === 'os') {
            save('os', data.payload);
          } else if (data.type === 'initUser') {
            // from app
            // userStore.setUser({
            //   loginId: data.loginId,
            //   userNm: data.userNm,
            //   userToken: data.userToken,
            // } as IUserSnapshot);
          } else if (data.type === 'userToken') {

            if (data.payload.rememberId) {
              localStorage.setItem('rememberId', data.payload.rememberId)
            }
            if (data.payload.rememberMe) {
              localStorage.setItem('rememberMe', data.payload.rememberMe)
            }
            if (data.payload.accessToken) {

              // auth.initialize();

            }

          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [navigate],
  );

  useEffect(() => {

    // @ts-ignore
    if (window.ReactNativeWebView) {
      if (isAndroid) {
        document.addEventListener('message', listener);
        console.log('Listen message..');
      }
      if (isIOS) {
        console.log('Listen message..');
        window.addEventListener('message', listener);
      }
    }

  }, [listener]);

  useEffect(() => {
    getAppVersion();
  }, [])

  const getAppVersion = async () => {
    // await fetch(process.env.REACT_APP_API_URL + '/common/v1/app/info').then(async (res) => {
    //   const ver = await res.json();
    //   setAppVersion(ver.data as AppVersion);
    // }).catch((e) => {
    //   console.log(e);
    // })
  }

  const onReportRegist = async (data: any) => { };

  const memoizedValue = useMemo(
    () => ({
      onReportRegist,
      setTitle,
      headerTitle,
      appVersion,
    }),
    [onReportRegist, setTitle, headerTitle, appVersion],
  );
  return <CommonContext.Provider value={memoizedValue}>{children}</CommonContext.Provider>;
}
