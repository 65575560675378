// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  loading: 'loading...',
  error: {
    100: 'Unknown Error',
    400: 'Session terminated.',
    401: 'error 401',
    403: 'error 403',
    404: 'error 404',
    500: 'error 500',
    nodata: 'No data available.',
    webcam: 'Webcam access denied.',

    common001: 'Session has expired. Please proceed with login.',
    common002: 'Invalid session XML value.',
    common003: 'Session has expired. Please proceed with login.',
    common004: 'Guests do not have permission.',
    common005: 'Only organizers are allowed.',
    common006: 'Members who have not joined the meeting cannot access.',
    common007: 'Only administrators are allowed.',
    common008: 'Access permission denied.',
    user001: 'ID is a required field.',
    user002: 'Invalid password.',
    user003: 'ActiveHash is a required field.',
    user004: 'Invalid activeHash value.',
    user005: 'Conference ID is a required field.',
    user006: 'Name is a required field.',
    user007: 'Email is a required field.',
    user008: 'Invalid meeting room ID value.',
    user009: 'Invalid email value.',
    user010: 'Invalid ID value.',
    user011: 'Current password is incorrect.',
    user012: 'New password is a required field.',
    user013: 'New password must not be the same as the old password.',
    user014: 'Guests have not been invited.',
    user015: 'User information does not exist.',
    user016: 'Conference ID is a required field.',
    user017: 'Facility is a required field.',
    user018: 'Device information is a required field.',
    user019: 'Invalid password.',
    user020: 'User with this information does not exist.',
    user021:
      'There are attendees who do not have permission for the room type in the participants.',
    user022: 'You have attempted too many logins. Please try again later.',
    user023: 'TempToken is a required value.',
    user024: 'The authentication code is not valid.',
    user025: 'The email is not registered and cannot be used.',
    user026: 'The watisToken value is not valid.',
    user027: 'The watisToken value is not valid.',
    user028: 'Certification Number is a required value.',
    user029: 'MobileInfo is a required value.',
    user030: 'Connection has been terminated due to a new login.',
    user400: 'Bad Request',
    appointment001: 'Invalid ownerYn value.',
    appointment002: 'Conference ID is a required field.',
    appointment003: 'Guests cannot access.',
    appointment004: 'Meeting does not exist.',
    appointment005: 'Only organizers can delete meetings.',
    appointment006: 'Invalid meeting room ID.',
    appointment007: 'Participants are required.',
    appointment008: 'Start time can only be the same as or greater than the current time.',
    appointment009: 'End time can only be greater than the current time.',
    appointment010: 'Meeting type is a required field.',
    appointment011: 'Only organizers can modify meetings.',
    appointment012: 'Start time is a required field.',
    appointment013: 'End time is a required field.',
    appointment014: 'Meeting title is a required field.',
    appointment015: 'Recording availability is a required field.',
    appointment016: 'UserInfo is empty.',
    appointment017: 'Email is required for guests.',
    appointment018: 'DisplayName is required for guests.',
    appointment019: 'Date type can be annual, monthly, or daily.',
    appointment020: 'The entire date range must be selected.',
    appointment021: 'End time must be greater than start time.',
    appointment022: 'Date type is a required field.',
    appointment023: 'Date type does not match the date format.',
    appointment024: 'Invalid meeting type.',
    appointment025: 'There are non-existent members among the member attendees.',
    appointment026: 'FacilityCd is a required field.',
    appointment027: 'ID is a required field.',
    appointment028: 'Occupation of patient/guardian is a required field.',
    appointment029: 'Facility of patient/guardian is a required field.',
    file001: 'FileId is a required field.',
    file002: 'Invalid FileId.',
    file003: 'Only organizers can delete files.',
    file004: 'Guests do not have permission.',
    file005: 'Data of a meeting not participated in.',
    file006: 'Members who have not joined the meeting cannot access files.',
    file007: 'Only users participating in the meeting can upload files.',
    file008: 'No file to upload.',
    file009: 'Recording file storage is in progress. Please try again later.',
    report001: 'Conference ID is a required field.',
    report002: 'Only organizers can create reports.',
    report003: 'Invalid meeting room ID.',
    report004: 'PDF password is a required field.',
    report005: 'Report does not exist.',
    report006: 'Guests do not have permission.',
    report007: 'This is a report for a meeting not participated in.',
    report008: 'Members who have not joined the meeting cannot access reports.',
    report009: 'ReportSid is a required field.',
    report010: 'Invalid ReportSid.',
    room001: 'Cuid is a required field.',
    room002: 'Invalid Cuid.',
    room003: 'Client not participating in the meeting.',
    room004: 'Access denied.',
    room005: 'Invalid meeting room ID.',
    room006: 'CuidList is a required field.',
    room007: 'Conference ID is a required field.',
    room008: 'Conference ID does not match the value requested in guest login.',
    room009: 'The meeting has ended.',
    whiteBoard001: 'WhiteBoardSid is a required field.',
    whiteBoard002: 'Client not participating in the meeting.',
    whiteBoard003: 'Invalid WhiteBoardSid.',
    chat001:
      'You are currently not accepted to enter the video conference. Only the organizer can accept and retrieve chat messages.',
    chat002: 'An error occurred while creating the file.',
    chat003: 'Conference ID is a required field.',
    hostAction001: 'Cuid is a required field.',
    hostAction002: 'Right is a required field.',
    hostAction003: 'Invalid access.',
    hostAction004: 'IsOn is a required field.',
    hostAction005: 'Right is a required field.',
    hostAction006: 'Invalid Cuid value.',
    push001: 'SystemName is a required field.',
    push002: 'UserId is a required field.',
    push003: 'Category is a required field.',
    push004: 'OwnerId is a required field.',
    push005: 'DeviceToken is a required field.',
    push006: 'Push message is a required field.',
    push007: 'External key for push does not exist.',
    watis001: 'Meeting type is a required field.',
    watis002: 'Occupation is a required field.',
    watis003: 'Facility is a required field.',
    watis004: 'Facility, ID, and seqNo are required fields.',
    watis005: 'There are no push history to resend.',
    invitation001: 'Code is a required field.',
    invitation002: 'Invalid code.',
    invitation003: 'Conference ID is a required field.',
    invitation004: 'Attendees are required.',
    invitation005: 'Invalid meeting room ID.',
    invitation006: 'Only organizers can send invitations.',
    invitation007: 'The invitation has expired.',
  },
  invalidDate: 'InvalidDate',
  total: 'Total',
  user: `user`,
  list: `list`,
  edit: `edit`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  create: `create`,
  profile: `profile`,
  account: `account`,
  details: `details`,
  calendar: `calendar`,
  select: 'Make a selection',
  noSelection: 'Not selected',
  all: 'All Jobs',
  myCalendar: {
    week: 'week',
    year: 'year',
    day: 'day',
    month: 'month',
    previous: 'previous',
    next: 'next',
    today: 'today',
    agenda: 'agenda',
    enter: 'enter',
    Su: 'Sun',
    Mo: 'Mon',
    Tu: 'Tue',
    We: 'Wed',
    Th: 'Thu',
    Fr: 'Fri',
    Sa: 'Sat',
  },
  participant: `participants`,
  type: {
    NORMAL: `Normal`,
    TREAT: `Treat`,
    CONFERENCE: `Conference`,
  },

  login: {
    selectFacility: 'Select Facility',
    enterId: 'Enter your ID',
    enterPw: 'Enter your password',
    login: 'Login',
    findIdPw: 'Find ID/Password',
    checkEmail: 'Please check your email',
    findId: {
      mainTitle: 'Find ID',
      enterEmail: 'Enter your email',
      send: 'Send ID to email',
      alert: 'An email with your ID has been sent to the email address you provided.',
    },
    findPassword: {
      mainTitle: 'Find Password',
      enterId: 'Enter your login ID',
      send: 'Send password reset link via email',
      alert: 'Please proceed with password reset using the sent email.',
    },
    loginUnprotected: 'Guest Login',
    tfa: 'Two-Factor Authentication',
    enterCertiNo: 'Please enter the verification code sent by email.',
    duplicateLogin: 'This account is currently logged in.',
    terminate: 'Do you want to terminate the existing connection and log in again?',
  },

  loginUnprotected: {
    enterAppointmentId: 'Enter meeting room ID',
    enterName: 'Enter your name for the video conference',
    participate: 'Participate',
    sendInvitation: 'Send Invitation',
    login: 'Member Login',
    sended: 'An invitation has been resent to the email you provided.',
  },

  conference: {
    mainTitle: 'Online Conference',
    reservation: 'Reserve Conference',
    makeReservation: 'Initiate Conference',
    editReservation: 'Edit Conference Info',
    searchArea: {
      period: 'Period',
      daily: 'Daily',
      monthly: 'Monthly',
      yearly: 'Yearly',
      type: 'Type',
      conferType: {
        NORMAL: 'Online General Meeting',
        TREAT: 'Online Medical Consultation',
        CONFERENCE: 'Online Conference',
      },
      byHost: 'By Host',
      byParticipants: 'By Participants',
      byplace: 'By Report Place',
      byagenda: 'By Report Agenda',
      byreportData: 'By Report Data',
      enterYourSearchTerm: 'Enter name',
    },
    table: {
      date: 'Date',
      host: 'Host',
      meetingTime: 'Running Time',
      report: 'Report',
      recordingFile: 'Recordings',
      shareFile: 'Shared Files',
      chat: 'Chat',
      participate: 'Participation',
    },
    alert: {
      inviteFailed: 'Invitation of participant failed.',
      reservationFailed: 'You cannot make a meeting reservation for a past time.',
      chatDownload: 'Do you want to download the chat for this meeting?',
      roomTypeAuth: 'You do not have the authority to hold a meeting.',
    },
  },
  conferenceReservation: {
    mainTitle: 'Conference Reservation',
    info: 'Conference Information',
    title: 'Title',
    start: 'Start Date and Time',
    end: 'End Date and Time',
    type: 'Conference Type',
    allowRecording: 'Recording Allowed',
    host: 'Host',
    name: 'Name',
    facility: 'Facility',
    auth: 'Occupation',
    participants: 'Participants',
    viewDetails: 'View Details',
    nonMembers: 'Guests',
    addParticipant: 'Add Participant',
    email: 'Email',
    searchUser: 'Search User',
    search: 'Search',
    none: 'None',
  },
  searchUser: {
    mainTitle: 'Conference Details',
    selectFacility: 'Select Facility',
    selectAuth: 'Select Occupation',
    selectGender: 'Select Gender',
    enterName: 'Enter Name',
    enterbirth: 'Enter birthdate',
    enterCellno: 'Enter phone number',
    enterEmail: 'Enter email address',
    recentUser: 'Recent Invited Users',
    nameKanji: 'Name(Kanji)',
    nameKana: 'Name(Kana)',
    gender: 'Gender',
    birth: 'Date of Birth',
    mobile: 'Mobile Number',
    address: 'Address',
    patanm: 'Patient Name(Kanji)',
    patajm: 'Patient Name(Kana)',
    srchRslt: 'Search Results',
  },
  conferenceDetail: {
    mainTitle: 'Conference Details',
    host: 'Host',
    conferId: 'Conference ID',
    date: 'Date and Time',
    browserInfo: 'Browser Info',
    unknown: 'Unknown',
    participate: 'Participate',
    startMeeting: 'Start Meeting',
    editAlert: 'You cannot edit Conferences that have already started or are in the past.',
    deleteAlert: 'Would you like to delete this conference?',
    true: 'Recordable',
    false: 'Not Recordable',
  },
  conferenceHistory: {
    mainTitle: 'Conference History',
    period: 'Search Period',
    date: 'Search Date',
  },

  participantAlertHistory: {
    mainTitle: 'Participant Alert History',
    title: 'Conference Title',
    participantNm: 'Participant Name',
    sendDt: 'Alert Sending Time',
    reSend: 'Resend Alert',
    read: 'Read',
    unread: 'Resend',
    sended: 'PUSH notification has been sent.',
  },
  statistics: {
    mainTitle: 'Statistics',
  },
  dashBoard: {
    mainTitle: 'DashBoard',
    count: 'Counts ',
    todaysMeeting: {
      title: "Today's Meeting Progress Report",
      onGoingTotal: 'Ongoing Total Counts',
      noOnGoingTotal: 'There are no ongoing total counts.',
      onGoingNow: 'Current Ongoing Counts',
      noOnGoingNow: 'There are no current ongoing counts.',
      scheduled: 'Scheduled Counts for Today',
      noScheduled: 'There are no scheduled counts for today.',
      noExist: ' does not exist.',
    },
    meetingsInProgress: {
      title: 'Meetings in Progress Today',
      noInProgress: 'There are no meetings in progress today.',
      table: {
        conferId: 'Cobnference ID',
        start: 'Start Time',
        runtime: 'Elapsed Time',
        host: 'Host',
        title: 'Title',
        meetingMembers: 'Participants',
      },
    },
    systemUsage: {
      title: "Today's System Usage Status",
      incoming: 'Received Traffic Speed',
      outgoing: 'Sent Traffic Speed',
      total: 'Total Traffic Speed',
      average: 'Average Traffic Speed',
    },
  },

  myPage: {
    mainTitle: 'My Page',
    name: 'My Name in Conference',
    changePw: 'Change Password',
    currentPw: 'Current Password',
    enterCurrentPw: 'Please Enter Your Current Password',
    newPw: 'New Password',
    requirements:
      'At least 8 characters with a combination of uppercase, lowercase, numbers, and letters (3 out of 4)',
    confirmPw: 'Confirm Password',
    reEnterPw: 'Please Re-enter Your New Password',
    validation: {
      match:
        'Password must include at least 3 of the following: uppercase, lowercase, numbers, and symbols.',
      min: 'Please enter a password that is at least 8 characters long.',
      equal: 'Passwords do not match.',
    },
    language: 'Language',
    japanese: 'Japanese',
    english: 'English',
    korean: 'Korean',
    receiveStatus: 'Receive PUSH Notifications',
    receiveY: 'Receive',
    receiveN: 'Do Not Receive',
    logout: 'Logout',
    save: 'Save Changes',
    sveAlert: 'Do you want to save the current changes?',
  },

  pushNotification: { mainTitle: 'PUSH Notifications' },

  waiting: {
    inviteAlert: 'You can join the meeting once the host approves.',
    videoYn: 'Video ON/OFF',
    audioYn: 'Audio ON/OFF',
    mic: 'Select Microphone',
    speaker: 'Select Speaker',
    video: 'Select Video',
    resolution: 'Camera Resolution',
    leave: 'Leave',
    end: 'End Meeting',
    start: 'Start Meeting',
    acceptMsg: 'The host has approved your participation in the meeting.',
    participate: 'Participate',
  },

  meeting: {
    me: 'Me',
    member: 'Member',
    nonMember: 'Guest',
    audioVideoOff: 'Audio/Video is OFF.',
    join: 'A user wants to join the meeting.',
    denyAll: 'Deny All',
    acceptAll: 'Accept All',
    deny: 'Deny',
    accept: 'Accept',
    denyAlert: 'Do you want to deny?',
    autoRecord: 'Do you want to proceed with automatic recording?',
    participant: 'Participant',
    record: 'Record',
    chat: 'Chat',
    enterMsg: 'Enter your message here',
    whiteboard: 'Whiteboard',
    screenShare: 'Screen Share',
    fileShare: 'File Share',
    file: 'Drop or select files',
    title: 'Conference Title',
    pdfPw: 'PDF Password',
    enterPdfPw: 'Enter PDF password',
    end: 'End Conference',
    spkrMicTest: 'Speaker and Microphone Test',
    test: 'Test',
    apply: 'Apply',
    videoSetting: 'Video Settings',
    invite: 'Invite',
    invitePrtcpnt: 'Invite Participants',
    inviteInfo: 'Invitation Info',
    ptcpntNm: 'Participant name',
    invitationLink: 'Invitation Link',
    copy: 'Copy Link',
    ok: 'Ok',

    meeting: 'Meeting',
    videoOn: 'Video ON',
    videoOff: 'Video OFF',
    audioOn: 'Audio ON',
    audioOff: 'Audio OFF',
    y: 'Yes',
    n: 'No',
    submit: 'Submit',
    cancel: 'Cancel',
    video: 'Video',
    audio: 'Audio',
    outputLevel: 'Output Level',
    volume: 'Volume',
    mic: 'Microphone',
    speaker: 'Speaker',
    share: 'Share',

    report: {
      mainTitle: 'Report',
      enterTitle: 'Enter Topic',
      place: 'Place',
      history: 'History',
      edit: 'Edit',
      save: 'Save',
      ok: 'Ok',
      contents: 'Meeting Contents',
      shareFiles: 'Share Files',
      item: 'Agenda',
      recordYn: 'Recording Status',
      host: 'Host',
      date: 'Meeting Date and Time',
      updDt: 'Last Updated',
      regDt: 'Registration Date and Time',
      pdfDownload: 'Download PDF',
      enterContent: 'Enter Content',
      more: 'More',
    },

    alert: {
      nonRecording: 'This is a non-recording meeting',
      name: 'Please enter participant name',
      email: 'Please enter participant email',
      screenSharing: 'Screen sharing is in progress',
      denied: 'The host has denied your participation in the meeting',
      copied: 'Link has been copied to the clipboard.',
      noWebcam: 'No webcam detected.',
      webcamInUse: 'Webcam is already in use.',
      webcamTrack: 'Webcam does not provide the required track',
      webcamAccess: 'Webcam access denied.',
      mediaTrack: 'Media track not requested',
      fileDelete: 'Do you want to delete the file?',
      reportDelete: 'Do you want to delete this report?',
      endAlert: 'Do you want to end the conference?',
      leaveAlert: 'Do you want to leave the conference?',
      ended: 'The meeting has ended.',
      micMsg:
        'During the mic test, video conferencing audio will be turned OFF. Do you want to proceed?',
      volMsg:
        'During the speaker test, video conferencing audio will be turned OFF. Do you want to proceed?',
      whiteboardEdit: 'There are changes to the whiteboard.',
      videoMaximum: 'The maximum allowed number of video has been exceeded.',
      participantsMaximum: 'The maximum allowed number of participants has been exceeded.',
      websocket: 'An error has occurred. Please contact the administrator.',
    },
  },
};

export default en;
