import { Instance, SnapshotOut, types } from 'mobx-state-tree';
import { createModelCode } from '../model-code/ModelCode';

/**
 * # CodeGroup
 *
 * CodeGroup을 설명하세요.
 */
export const CodeGroup = types
  .model('CodeGroup')
  // --------------------------------------------------------------------------
  .props({
    appId: types.maybeNull(types.string),
    nationCd: types.optional(types.maybeNull(types.string), ''),
    langCd: types.maybeNull(types.optional(types.string, '')),
    regDt: types.optional(types.number, 0),
    updDt: types.optional(types.number, 0),
    groupCdSid: types.optional(types.number, 0),
    globalGroupTypeCd: createModelCode(),
    groupCdNm: types.optional(types.string, ''),
    ordr: types.optional(types.number, 0),
    useYn: types.optional(types.boolean, false),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model codeGroup --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as ICodeGroup;
      (Object.keys(newProps) as TCodeGroupKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TCodeGroup = Instance<typeof CodeGroup>;
type TCodeGroupSnapshot = SnapshotOut<typeof CodeGroup>;

export interface ICodeGroup extends TCodeGroup {}
export type TCodeGroupKeys = keyof TCodeGroupSnapshot & string;
export interface ICodeGroupSnapshot extends TCodeGroupSnapshot {}
export const createCodeGroup = () => types.optional(CodeGroup, {} as TCodeGroup);
