import { useRef, useState } from 'react';
// @mui
import { Stack, InputBase, InputBaseProps, IconButton, InputAdornment, Box } from '@mui/material';
// utils
import { useForm } from 'react-hook-form';
// components
import Iconify from 'src/components/iconify';
import EmojiPicker from 'emoji-picker-react';
import { useLocales } from 'src/locales';

// ----------------------------------------------------------------------

const CURRENT_USER_ID = 6; //'8864c717-587d-472a-929a-8e5f298024da-0';

interface Props extends InputBaseProps {
  conversationId: string | null;
  onSend: (data: any) => void;
}

export default function ChatMessageInput({
  disabled,
  conversationId,
  onSend,
  sx,
  ...other
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const methods = useForm<any>({
    mode: 'all',
    reValidateMode: 'onChange',
    // defaultValues: {},
    // resolver: yupResolver(ConferenceSchema),
  });
  const { translate } = useLocales();
  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = methods;

  const [openEmoji, setOpenEmoji] = useState(false);

  const [message, setMessage] = useState('');

  // const handleClickAttach = () => {
  //   fileInputRef.current?.click();
  // };

  const handleSend = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // if (event.key === 'Enter') {
    // if (onSend && message && conversationId) {
    //   onSend({
    //     conversationId,
    //     messageId: uuidv4(),
    //     message,
    //     contentType: 'text',
    //     attachments: [],
    //     createdAt: new Date(),
    //     senderId: CURRENT_USER_ID,
    //   });
    // }
    if (message.replace(/\s| /gi, '').length == 0) {
      setMessage('');
      // return;
    } else {
      onSend(message.trim().replace(/\n/gi, '<br />'));
    }
    setMessage('');
    // }
  };

  const addEmoji = (e: any) => {
    let sym = e.unified.split('-');
    let codesArray: any = [];
    sym.forEach((el: any) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);
    setMessage(message + emoji);
  };

  return (
    <>
      {/*  <Box display={openEmoji === true ? 'block' : 'none'}>
        <EmojiPicker
          width={'100%'}
          onEmojiClick={(e) => {
            addEmoji(e);
            setOpenEmoji(false);
          }}
        />
      </Box>
    <InputEmoji
          value={input}
          onChange={setInput}
          cleanOnEnter
          onEnter={handleSend}
          placeholder="Type a message"
        /> */}

      <InputBase
        value={message}
        onKeyUp={(e: any) => {
          if (e.nativeEvent.isComposing) {
            return;
          }
          if (e.key === 'Enter' && e.shiftKey) {
            return;
          } else if (e.key === 'Enter') {
            handleSend(e.target.value);
            // setMessage('');
          }
        }}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
        placeholder={translate(`meeting.enterMsg`).toString()}
        multiline
        // startAdornment={
        //   <InputAdornment position="start">
        //     <IconButton
        //       size="small"
        //       onClick={() => {
        //         setOpenEmoji(!openEmoji);
        //       }}
        //       sx={{ ml: 1.5, position: 'absolute', left: 0, bottom: 15 }}
        //     >
        //       <Iconify icon="eva:smiling-face-fill" />
        //     </IconButton>
        //   </InputAdornment>
        // }
        endAdornment={
          <Stack
            direction="row"
            spacing={1}
            sx={{ flexShrink: 0, mr: 1.5, position: 'absolute', right: 0, bottom: 15 }}
          >
            {/* <IconButton disabled={disabled} size="small" onClick={handleClickAttach}>
              <Iconify icon="ic:round-add-photo-alternate" />
            </IconButton>

            <IconButton disabled={disabled} size="small" onClick={handleClickAttach}>
              <Iconify icon="eva:attach-2-fill" />
            </IconButton> */}
            <IconButton disabled={disabled} size="small" onClick={() => handleSend(getValues())}>
              <Iconify icon="tabler:send" />
            </IconButton>
          </Stack>
        }
        sx={{
          position: 'relative',
          // px: '36px',
          pr: '36px',
          pl: '18px',
          py: '18px',
          minHeight: 56,
          flexShrink: 0,
          borderTop: (theme) => `solid 1px ${theme.palette.divider}`,
          ...sx,
          wordBreak: 'break-all',
        }}
        {...other}
      />
      {/* <input name="message" type="file" ref={fileInputRef} style={{ display: 'none' }} /> */}
    </>
  );
}
