import { Instance, SnapshotOut, types } from 'mobx-state-tree';

/**
 * # StatisticsData
 *
 * StatisticsData을 설명하세요.
 */
export const StatisticsData = types
  .model('StatisticsData')
  // --------------------------------------------------------------------------
  .props({
    // cd: types.optional(types.string, ''),
    // count: types.optional(types.number, 0),
    name: types.optional(types.string, ''),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model statisticsData --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IStatisticsData;
      (Object.keys(newProps) as TStatisticsDataKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TStatisticsData = Instance<typeof StatisticsData>;
type TStatisticsDataSnapshot = SnapshotOut<typeof StatisticsData>;

export interface IStatisticsData extends TStatisticsData {}
export type TStatisticsDataKeys = keyof TStatisticsDataSnapshot & string;
export interface IStatisticsDataSnapshot extends TStatisticsDataSnapshot {}
export const createStatisticsData = () => types.optional(StatisticsData, {} as TStatisticsData);
