import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import { useStores } from "../../models/root-store/root-store-context"
import { Container, useTheme } from '@mui/material';
import { MyCalendar } from './my-calendar/MyCalendar';

/**
 * ## Mypage 설명
 *
 */
export const Mypage = observer(() => {

  const rootStore = useStores();
  const { loadingStore } = rootStore;
  const theme = useTheme();

  return (
    <>
      <Box sx={{
        p: 4,
        display: 'flex',
        flex: 1, height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: { p: 0, justifyContent: 'flex-start' },
      }}>
        <MyCalendar />
      </Box>
    </>
  );
});

export default Mypage;