import { observer } from 'mobx-react-lite';
import { useStores } from '../../../models/root-store/root-store-context';
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { ReactComponent as IcoClose } from 'src/assets/icons/ico-close.svg';
import { IParticipant } from 'src/models';
import { useLocation } from 'react-router';
import { useRef } from 'react';
import Scrollbar from 'src/components/scrollbar';
import ParticipantsDetail from './ParticipantsDetail';
import { getIconByAuthCd } from 'src/utils/common';
import { useLocales } from 'src/locales';
/**
 * ## Participants 설명
 *
 */
interface Props {
  dataildata: any;
  handleClose: VoidFunction;
}

export const Participants = observer(({ dataildata, handleClose }: Props) => {
  const rootStore = useStores();
  const myCUID = sessionStorage.getItem('myCUID');
  const { participantStore } = rootStore;
  const { state } = useLocation();
  const theme = useTheme();
  const scrollRef = useRef<HTMLDivElement>(null);
  const { translate } = useLocales();
  const { REACT_APP_API_URL } = process.env;
  const micClick = async (cuid: any, type: any) => {
    if (state.ownerYn === 'Y') {
      let data: any;
      if (type === 'mic') {
        data = {
          cuid: cuid,
          right: 'AUDIO',
        };
      } else {
        data = {
          cuid: cuid,
          right: 'VIDEO',
        };
      }
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${REACT_APP_API_URL}/web/hostAction/toggleRight`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/javascript, */*; q=0.01',
          Authorization: 'Bearer ' + accessToken,
        },
        method: 'POST',
        body: JSON.stringify(data),
      });
      const jsonData = await response.json();
      const responseData = JSON.parse(jsonData.responseData);
      participantStore.updateParticipant({
        id: responseData.uid,
        uid: responseData.uid,
        cuid: responseData.cuid,
        rights: responseData.rights,
      } as IParticipant);
    }
  };

  return (
    <>
      <Stack direction={'row'} justifyContent={'center'} sx={{ p: 2 }}>
        <Typography variant={'subtitle1'} color={theme.palette.text.secondary}>
          {translate(`meeting.participant`).toString()} ({participantStore.participants.length})
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{ position: 'absolute', top: 8, right: 8 }}
          color={'inherit'}
        >
          <IcoClose stroke={'#637381'} />
        </IconButton>
      </Stack>
      <Divider />
      <Scrollbar
        scrollableNodeProps={{
          ref: scrollRef,
        }}
        sx={{ height: 1 }}
      >
        <List dense={true}>
          {participantStore.participants.map((participant: IParticipant, i: number) => {
            const isMe = myCUID === participant.cuid ? ' '+translate(`meeting.me`).toString()+' ' : '';
            const AuthIcon = getIconByAuthCd(participant.stream?.user.authCd || '');
            return (
              <ListItem
                key={'participant-list-' + i}
                secondaryAction={
                  <>
                    <ParticipantsDetail
                      participant={participant}
                      detaildata={dataildata}
                    ></ParticipantsDetail>
                  </>
                }
              >
                <ListItemAvatar>
                  <AuthIcon />
                </ListItemAvatar>
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                  <Typography variant={'Eng_16_b'} sx={{ fontWeight: 700 }}>
                    {participant.userName}
                  </Typography>
                  <Typography variant={'Eng_14_r'} color={theme.palette.text.secondary}>
                    {isMe}
                  </Typography>
                </Stack>
              </ListItem>
            );
          })}
        </List>
      </Scrollbar>
    </>
  );
});

export default Participants;
