import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
//
import {
  ComingSoon,
  Maintenance,
  Page403,
  Page404,
  Page500,
  Login,
  LoginUnprotected,
  MyAlarm,
} from './elements';
import MobileLayout from 'src/layouts/mobile';
import TokenCheck from 'src/screens/common/token-check/TokenCheck';
import Home from 'src/screens/home/Home';
import MainLayout from 'src/layouts/main';
import { Conference, Mypage, MyParticipant } from 'src/screens';
import MyPageLayout from 'src/layouts/mypage';
import MyConference from 'src/screens/mypage/my-conference/MyConference';
import MyInfo from 'src/screens/mypage/my-info/MyInfo';
import UserManagement from 'src/screens/mypage/user-management/UserManagement';
import { ConferenceHistory } from '../screens/mypage/conference-history/ConferenceHistory';
import Statistics from 'src/screens/mypage/statistics/Statistics';
import Dashboard from 'src/screens/mypage/dashboard/Dashboard';
import ConferenceLayout from 'src/layouts/conference';
import Meeting from 'src/screens/conference/meeting/Meeting';
import Invitation from 'src/screens/invitation/Invitation';
import AuthTemp from 'src/screens/invitation/AuthTemp';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <MyPageLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Mypage />, index: true },
        { path: 'home', element: <Mypage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        {
          // from app - approch path
          path: 'tokenCheck/:token',
          element: <TokenCheck />,
        },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'login-unprotected',
          element: (
            <GuestGuard>
              <LoginUnprotected />
            </GuestGuard>
          ),
        },
        {
          path: 'find-user',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'find-password',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'invitation',
          children: [
            {
              path: 'hash',
              element: <Invitation />,
            },
          ],
        },
        {
          path: 'auth',
          children: [
            {
              path: 'temp',
              element: <AuthTemp />,
            },
          ],
        },
      ],
    },
    {
      element: (
        <AuthGuard>
          <MyPageLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'my',
          children: [
            // {
            //   path: 'home',
            //   element: <Home />,
            // },
            {
              path: 'conference',
              element: <MyConference />,
            },
            {
              path: 'info',
              element: <MyInfo />,
            },
            {
              path: 'alarm',
              element: <MyAlarm />,
            },
            {
              path: 'participant',
              element: <MyParticipant />,
            },
            {
              path: 'user',
              element: <UserManagement />,
            },
            {
              path: 'history',
              element: <ConferenceHistory />,
            },
            {
              path: 'statistics',
              element: <Statistics />,
            },
            {
              path: 'dashboard',
              element: <Dashboard />,
            },
          ],
        },
      ],
    },
    {
      element: (
        // <AuthGuard>
        <SimpleLayout />
        // </AuthGuard>
      ),
      children: [
        {
          path: 'conference',
          children: [
            {
              path: 'meeting',
              element: <Meeting />,
            },
          ],
        },
      ],
    },
    {
      element: (
        <AuthGuard>
          <ConferenceLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'conference',
          children: [
            {
              path: 'room',
              element: <Conference />,
            },
          ],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: '403', element: <Page403 /> },
        { path: '404', element: <Page404 /> },
        { path: '500', element: <Page500 /> },
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
