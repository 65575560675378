import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../models/root-store/root-store-context';
import { Button, Dialog, IconButton, Paper, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useLocales } from 'src/locales';
import ConcurrentConnection from '../user/login/ConcurrentConnection';

/**
 * ## Invitation 설명
 *
 */
export const Invitation = observer(() => {
  const rootStore = useStores();
  const { loadingStore } = rootStore;
  const theme = useTheme();
  const { REACT_APP_API_URL } = process.env;
  const navigate = useNavigate();
  const { logout, initialize } = useAuthContext();
  const searchParams = new URLSearchParams(document.location.search);
  const code = searchParams.get('code');
  const [message, setMessage] = useState('');
  const [alertFlag, setAlertFlag] = useState(false);
  const { translate } = useLocales();
  const [openCC, setOpenCC] = useState(false);
  const [watisToken, setWatisToken] = useState('');
  const [invitationInfo, setInvitationInfo] = useState({});

  useEffect(() => {
    if (code) {
      fetch(REACT_APP_API_URL + '/web/invitation/hash?code=' + code, {})
        .then(async (response: any) => {
          const res = await response.json();
          const json = res.responseData;
          console.log('🌈 ~ useEffect ~ json:', json);
          if (json === undefined) {
            if (res.error) {
              setMessage(translate(`error.${res.error}`).toString());
              setAlertFlag(true);
            } else {
              const user = localStorage.getItem('user');
              if (user === null) {
                logout();
                navigate('/login-unprotected', { replace: true, state: null });
              } else {
                navigate('/');
              }
            }
          } else if (!!json.isExists) {
            setOpenCC(true);
            setWatisToken(json.watisToken);
            setInvitationInfo({
              room: {
                id: json.room.roomId,
              },
              id: json.appointmentId,
              owner: {
                displayName: json.room.ownerName,
              },
              title: json.room.name,
            });
          } else {
            const user = {
              displayName: json.displayName,
              locale: json.locale,
              pushYn: json.pushYn,
              accessToken: json.accessToken,
              timezone: json.timezone,
              loginId: json.loginId,
              auth: json.auth,
              authCd: json.authCd,
              isAdmin: json.isAdmin,
            };
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('accessToken', user.accessToken);
            // initialize();
            localStorage.getItem('accessToken') &&
              navigate('/conference/meeting', {
                state: {
                  ...json,
                  room: {
                    id: json.room.roomId,
                  },
                  id: json.appointmentId,
                  owner: {
                    displayName: json.room.ownerName,
                  },
                  title: json.room.name,
                },
              });
          }
        })
        .catch((e) => {
          const user = localStorage.getItem('user');
          if (user === null) {
            logout();
            navigate('/login-unprotected', { replace: true, state: null });
          } else {
            navigate('/');
          }
        });
    }
  }, []);

  return (
    <>
      {alertFlag && (
        <Dialog
          open={alertFlag}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              borderRadius: 4,
              p: 4,
            },
          }}
          onClose={(e, reason) => {
            reason !== 'backdropClick' && setAlertFlag(false);
          }}
        >
          <Stack spacing={2} sx={{ minWidth: 250 }}>
            <Typography textAlign={'center'} variant="h6">
              {message}
            </Typography>
            <Stack justifyContent={'center'} direction={'row'} gap={1}>
              <Button
                variant="outlined"
                onClick={() => {
                  const user = localStorage.getItem('user');
                  if (user === null) {
                    logout();
                    navigate('/login-unprotected', { replace: true, state: null });
                    setAlertFlag(false);
                  } else {
                    navigate('/');
                    setAlertFlag(false);
                  }
                }}
              >
                {translate(`meeting.y`).toString()}
              </Button>
            </Stack>
          </Stack>
        </Dialog>
      )}
      {openCC && (
        <Dialog
          open={openCC}
          onClose={(e: any, reason: string) => {
            reason !== 'backdropClick' && setOpenCC(false);
          }}
          maxWidth={'sm'}
          fullWidth
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
        >
          <ConcurrentConnection
            watisToken={watisToken}
            isInvitation={true}
            invitationInfo={invitationInfo}
            handleClose={() => {
              setOpenCC(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
});

export default Invitation;
