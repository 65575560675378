import { IconButton, Paper, Popover } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Iconify from 'src/components/iconify/Iconify';
import ConferenceDetail from '../ConferenceDetail';

const DetailPopOver = observer(
  ({ conferenceDetail, openConferenceDetail, handleConferenceClose, data, getDetail }: any) => {
    return (
      <>
        <Popover
          anchorEl={conferenceDetail}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={openConferenceDetail}
          onClose={handleConferenceClose}
          style={{
            transform: 'translateY(50px)',
          }}
        >
          <Paper sx={{ p: 4, maxWidth: 'sm' }}>
            <IconButton
              onClick={handleConferenceClose}
              sx={{ position: 'absolute', top: 15, right: 15 }}
            >
              <Iconify icon={'ic:round-close'} />
            </IconButton>
            {openConferenceDetail && (
              <ConferenceDetail
                data={data}
                handleClose={handleConferenceClose}
                getData={() => getDetail(data.id)}
                path={'Meeting'}
                getDetail={getDetail}
              />
            )}
          </Paper>
        </Popover>
      </>
    );
  },
);

export default DetailPopOver;
