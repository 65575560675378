import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Box, Container, Paper, Stack, Typography, useTheme } from '@mui/material';
import { CDataGrid } from 'src/components/CDataGrid';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { CallApiToStore } from 'src/utils/common';
import moment from 'moment';
import { toJS } from 'mobx';
import { useForm } from 'react-hook-form';
import _ from 'lodash';
import StatisticsSearchArea from './StatisticsSearchArea';
import { useLocales } from 'src/locales';

/**
 * ## Statistics 설명
 *
 */
export const Statistics = observer(() => {
  const { translate } = useLocales();
  const rootStore = useStores();
  const { statisticsStore: store, loadingStore } = rootStore;
  const { REACT_APP_API_URL } = process.env;

  // const [data, setData] = useState<any[]>();
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const getAuthInfo = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      await fetch(`${REACT_APP_API_URL}/web/stat/day/authCdInfo`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + accessToken,
        },
        method: 'GET',
      }).then(async (res) => {
        const json: any = await res.json();

        const tmp: any = [
          {
            field: 'statDate',
            headerName: translate('conferenceHistory.date').toString(),
            headerAlign: 'center',
            align: 'center',
            flex: 100,
          },
          {
            field: 'facilityNm',
            headerName: translate('conferenceReservation.facility').toString(),
            headerAlign: 'center',
            align: 'center',
            flex: 150,
            // valueGetter: (params: GridValueGetterParams) => {
            //   return params.value.facilityNm;
            // },
          },
        ];

        json.responseData.map((v: any) => {
          tmp.push({
            field: v.name,
            headerName: v.authNm,
            headerAlign: 'center',
            align: 'center',
            flex: 70,
          });
        });
        setColumns(tmp as GridColDef[]);
      });
    } catch (error) {
      console.error(error);
      // setError('afterSubmit', {
      //   ...error,
      //   message: error.message,
      // });
    }
  };

  const getData = async (data: any) => {
    await CallApiToStore(store.gets(data), 'api', loadingStore);
    // .then(() => {
    // setData(toJS(store.statistics));
    // });
  };

  useEffect(() => {
    getData({ start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD') });
    getAuthInfo();
  }, []);

  // const handleChangePage = (newPage: number) => {
  //   store.pagination.setProps({ page: newPage + 1 });
  //   // getData();
  // };

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          display: 'flex',
          flex: 1,
          height: '100%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          py: 4,
        }}
      >
        <Stack
          spacing={2}
          sx={{
            flex: 1,
            // '& .MuiStack-root>:not(style)+:not(style)': {
            //   marginLeft: '0 !important',
            // },
          }}
        >
          <Typography variant={'h5'}>{translate('statistics.mainTitle').toString()}</Typography>

          <Stack direction={'row'} sx={{ '& form': { width: '100%' } }}>
            <StatisticsSearchArea getData={getData} />
          </Stack>

          {/* <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}> */}
          <Paper sx={{ display: 'flex', flex: 1, height: '100%' }}>
            <CDataGrid
              columns={columns}
              // store={store}
              // rows={'statistics'}
              rows={store.statistics2 ? JSON.parse(store.statistics2) : []}
              rowId={'id'}
              paging={false}
              // pagingType={'custom'}
              // autoPageSize
              density={'standard'}
              // onPageChange={(newPage: number) => {
              //   handleChangePage(newPage);
              // }}
              disableSelectionOnClick
              sx={{ '& .MuiDataGrid-main': { borderBottom: '.5px solid black' } }}
            />
          </Paper>
          {/* </Box> */}
        </Stack>
      </Container>
    </>
  );
});

export default Statistics;
