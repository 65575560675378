import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';
import { withRootStore } from '../extensions/with-root-store';
import { StatisticsApi, TGetStatisticsResult } from '../../services';
import { IStatistics, IStatisticsSnapshot, Statistics } from '../statistics/Statistics';
import { IPagination, IPaginationSnapshot, createPagination } from '../pagination/Pagination';
import { IStatisticsDataSnapshot } from '../statistics-data/StatisticsData';

/**
 * # StatisticsStore
 *
 * StatisticsStore을 설명하세요.
 */
export const StatisticsStore = types
  .model('StatisticsStore')
  // --------------------------------------------------------------------------
  .props({
    pagination: createPagination(),
    statistics: types.optional(types.array(Statistics), []),
    statistics2: types.maybeNull(types.string),
    headers: types.optional(types.array(Statistics), []),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    /**
     * statisticss을 교체
     *
     * @param `statisticss` 새로운 모델의 배열
     */
    setStatisticss: (statistics: IStatistics[]) => {
      self.statistics.replace(statistics);
    },
    setStatisticss2: (str: any) => {
      self.statistics2 = str;
    },
    setHeaders: (headers: IStatistics[]) => {
      self.headers.replace(headers);
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    /**
     * 전체 목록을 Api를 통해 조회
     *
     * 조회한 결과로 Statisticss를 교체한다. 실패시 에러 로그를 남긴다.
     */
    gets: async (date: any) => {
      const statisticsApi: StatisticsApi = new StatisticsApi(self.environment.api);
      const result: TGetStatisticsResult = await statisticsApi.gets(self.pagination, date);
      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          const res: any = JSON.parse(result.data.toString());
          self.setStatisticss(res);

          const rows: any[] = [];
          res[0].rows.map((r: any, i: number) => {
            let row: any = {
              // ...r,
              id: 'row-' + i,
              name: r.cd,
              facilityNm: r.facility.facilityNm,
              statDate: r.statDate,
              // colName: 'authCd1',
            };
            r.cols.map((c: any, k: number) => {
              row[c.cd] = c.count;
            });
            rows.push(row);
            return row;
          });

          self.setStatisticss2(JSON.stringify(rows)); // as IStatistics[]);
        } else {
          console.error(result.kind);
        }
      }
    },
  }));

// --------------------------------------------------------------------------
type TStatisticsStore = Instance<typeof StatisticsStore>;
type TStatisticsStoreSnapshot = SnapshotOut<typeof StatisticsStore>;

export interface IStatisticsStore extends TStatisticsStore {}
export type TStatisticsStoreKeys = keyof TStatisticsStoreSnapshot & string;
export interface IStatisticsStoreSnapshot extends TStatisticsStoreSnapshot {}
export const createStatisticsStore = () => types.optional(StatisticsStore, {} as TStatisticsStore);
