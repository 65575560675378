import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Button, Container, Paper, Stack, Typography, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { CDataGrid } from 'src/components/CDataGrid';
import MyParticipantM from './MyParticipantM';
import useResponsive from 'src/hooks/useResponsive';
import { CallApiToStore } from 'src/utils/common';
import { toJS } from 'mobx';
import { Trans } from 'react-i18next';
import { ISentPushAlarmSnapshot } from 'src/models';
import CAlert from 'src/components/CAlert';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { useLocales } from 'src/locales';

/**
 * ## MyParticipant 설명
 *
 */
export const MyParticipant = observer(() => {
  const rootStore = useStores();
  const { pushAlarmStore, loadingStore, responseStore } = rootStore;
  const theme = useTheme();
  const isMobile = useResponsive('down', 'md');
  const { translate } = useLocales();
  const [pending, setPending] = useState(true);

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: translate('participantAlertHistory.title').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 200,
    },
    {
      field: 'displayName',
      headerName: translate('participantAlertHistory.participantNm').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
    },
    {
      field: 'sendDt',
      headerName: translate('participantAlertHistory.sendDt').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 150,
    },
    {
      field: 'isRead',
      headerName: translate('participantAlertHistory.reSend').toString(),
      headerAlign: 'center',
      align: 'center',
      flex: 50,
      renderCell: (params: GridRenderCellParams) => {
        if (params.row.isRead === false) {
          return (
            <Button
              size={'small'}
              variant={'soft'}
              onClick={() => {
                reSend(params.row);
              }}
            >
              {translate('participantAlertHistory.unread').toString()}
            </Button>
          );
        } else {
          return (
            <Button
              size={'small'}
              variant={'soft'}
              disabled
              // onClick={() => {
              //   reSend(params.row);
              // }}
            >
              {translate('participantAlertHistory.read').toString()}
            </Button>
          );
        }
      },
    },
  ];
  const getData = async () => {
    setPending((pending) => (pending = true));
    await CallApiToStore(pushAlarmStore.getsSentList(), 'api', loadingStore).then(() => {
      setPending((pending) => (pending = false));
    });
  };

  const handleChangePage = (newPage: number) => {
    pushAlarmStore.pagination.setProps({ page: newPage + 1 });
    getData();
  };

  useEffect(() => {
    pushAlarmStore.pagination.setProps({ page: 1, size: 100 });
    getData();
  }, []);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const reSend = async (data: ISentPushAlarmSnapshot) => {
    await CallApiToStore(pushAlarmStore.reSend(data), 'api', loadingStore).then(() => {
      setAlertMsg(translate('participantAlertHistory.sended').toString());
      setOpenAlert(true);
    });
  };

  const headerOptions: any = {
    showMainIcon: 'back',
    title: translate('participantAlertHistory.mainTitle').toString(),
    showHomeIcon: true,
  };

  return (
    <>
      {isMobile ? (
        <MyParticipantM headerOptions={headerOptions} pending={pending} reSend={reSend} />
      ) : (
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
            py: 4,
          }}
        >
          <Stack
            spacing={2}
            sx={{
              flex: 1,
              '& .MuiStack-root>:not(style)+:not(style)': {
                marginLeft: '0 !important',
              },
            }}
          >
            <Typography variant={'h5'}>
              {translate('participantAlertHistory.mainTitle').toString()}
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', pt: 2 }}>
              <Paper sx={{ flex: '1', height: '100%' }}>
                {pending && (
                  <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                    <Trans i18nKey={'loading'} />
                  </Typography>
                )}
                {!pending && toJS(pushAlarmStore.sentPushAlarms).length < 1 ? (
                  <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                    <Trans i18nKey={'error.nodata'} />
                  </Typography>
                ) : (
                  <CDataGrid
                    store={pushAlarmStore}
                    columns={columns}
                    rows={'sentPushAlarms'}
                    rowId={'id'}
                    paging={true}
                    pagingType={'custom'}
                    autoPageSize={false}
                    density={'standard'}
                    disableSelectionOnClick
                    onPageChange={(newPage: number) => {
                      console.log(pushAlarmStore.pagination);

                      handleChangePage(newPage);
                    }}
                    sx={{ '& .MuiDataGrid-main': { borderBottom: '.5px solid black' } }}
                  />
                )}
              </Paper>
            </Box>
          </Stack>
        </Container>
      )}
      {openAlert && (
        <CAlert open={openAlert} handleClose={() => setOpenAlert(false)} content={alertMsg} />
      )}
    </>
  );
});

export default MyParticipant;
