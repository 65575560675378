import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CallApiToStore, getIconByAuthCd } from 'src/utils/common';
import { useStores } from '../../models/root-store/root-store-context';
import { IAcceptMeeting } from 'src/models/meeting/AcceptMeeting';
import { borderBottom } from '@mui/system';
import { toJS } from 'mobx';
import { useLocales } from 'src/locales';
interface Props {
  data: any;
  clear: any;
}

/**
 * ## ReportCreate 설명
 *
 */
export const Admission = observer(({ data, clear }: Props) => {
  const theme = useTheme();
  const rootStore = useStores();
  const { loadingStore, meetingStore } = rootStore;
  const [rejectType, setRejectType] = useState();
  const [rejectCuid, setRejectCuid] = useState();
  const [rejectYn, setRejectYn] = useState(false);
  const { translate } = useLocales();
  
  const onRejectYn = async (type: any, cuid?: any) => {
    setRejectType(type);
    setRejectCuid(cuid);
    setRejectYn(true);
  };

  const onReject = async () => {
    let cuidList: any = [];
    if (rejectType === 'one') {
      cuidList.push(rejectCuid);
    } else if (rejectType === 'all') {
      meetingStore.acceptDatas.map((acceptData: IAcceptMeeting, i: number) => {
        cuidList[i] = acceptData.cuid;
      });
    }
    const rejectData = {
      roomId: data,
      cuidList: cuidList,
    };
    await CallApiToStore(meetingStore.joinReject(rejectData), 'api', loadingStore)
      .then((data) => {
        onAcceptClikc(cuidList);
      })
      .catch((res) => {});
  };

  const onAccept = async (type: any, cuid?: any) => {
    let cuidList: any = [];
    if (type === 'one') {
      cuidList.push(cuid);
    } else if (type === 'all') {
      meetingStore.acceptDatas.map((acceptData: IAcceptMeeting, i: number) => {
        cuidList[i] = acceptData.cuid;
      });
    }
    const acceptData = {
      roomId: data,
      cuidList: cuidList,
    };

    await CallApiToStore(meetingStore.joinAccept(acceptData), 'api', loadingStore)
      .then((res) => {
        onAcceptClikc(cuidList);
      })
      .catch((res) => {});
  };

  const onAcceptClikc = (message: any) => {
    for (let i = 0; message.length > i; i++) {
      meetingStore.removeAcceptData(message[i]);
    }
    meetingStore.removeAcceptData(message);
    if (meetingStore.acceptDatas.length === 0) {
      clear(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          background: theme.palette.background.neutral,
          p: 2,
          borderBottom: '1px solid ' + theme.palette.divider,
        }}
      >
        <Stack display={'flex'} justifyContent={'space-between'} direction={'row'}>
          <Typography sx={{ textOverflow: 'ellipsis' }} textAlign={'center'} variant="h6">
            {translate(`meeting.join`).toString()}
          </Typography>
          <Stack direction={'row'} gap={1} sx={{ wordBreak: 'keep-all' }}>
            <Button
              sx={{ width: '84px', background: '#FFF', color: '#333' }}
              variant="contained"
              color={'error'}
              onClick={() => {
                onRejectYn('all');
              }}
            >
              {translate(`meeting.denyAll`).toString()}
            </Button>
            <Button
              sx={{ width: '84px' }}
              variant="contained"
              onClick={() => {
                onAccept('all');
              }}
            >
              {translate(`meeting.acceptAll`).toString()}
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Box>
        {meetingStore.acceptDatas.map((acceptData: IAcceptMeeting, i: number) => {
          console.log('💬 ', 'acceptData', toJS(acceptData));
          const AuthIcon = getIconByAuthCd(acceptData.authCd);
          return (
            <Box
              key={i}
              sx={{
                p: 2,
              }}
            >
              <Stack display={'flex'} justifyContent={'space-between'} direction={'row'}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  <AuthIcon />
                  <Stack>
                    <Typography variant="h6" sx={{ lineHeight: 1 }}>
                      {acceptData.displayName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.disabled }}>
                      {acceptData.isMember ? translate(`meeting.member`).toString() : translate(`meeting.nonMember`).toString()}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction={'row'} gap={1} sx={{ wordBreak: 'keep-all' }}>
                  <Button
                    size={'small'}
                    sx={{ width: '84px', borderColor: '#FFFFFF3a', color: '#FFF' }}
                    color={'error'}
                    variant="outlined"
                    onClick={() => {
                      onRejectYn('one', acceptData.cuid);
                    }}
                  >
                    {translate(`meeting.deny`).toString()}
                  </Button>
                  <Button
                    size={'small'}
                    sx={{
                      width: '84px',
                      borderColor: '#FFFFFF2a',
                      background: 'rgba(145, 158, 171, 0.24)',
                      color: '#FFF',
                    }}
                    variant="contained"
                    onClick={() => {
                      onAccept('one', acceptData.cuid);
                    }}
                  >
                    {translate(`meeting.accept`).toString()}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          );
        })}
        {rejectYn && (
          <Dialog
            open={rejectYn}
            maxWidth={'sm'}
            PaperProps={{
              sx: {
                borderRadius: 4,
                p: 4,
              },
            }}
            onClose={(e, reason) => {
              reason !== 'backdropClick' && setRejectYn(false);
            }}
          >
            <Stack spacing={2} sx={{ minWidth: 250 }}>
              <Typography textAlign={'center'} variant="h6">
                {translate(`meeting.denyAlert`).toString()}
              </Typography>
              <Stack justifyContent={'center'} direction={'row'} gap={1}>
                <Button
                  variant="soft"
                  onClick={() => {
                    setRejectYn(false);
                  }}
                >
                  {translate(`meeting.n`).toString()}
                </Button>
                <Button
                  variant={'contained'}
                  color={'error'}
                  onClick={() => {
                    onReject();
                    setRejectYn(false);
                  }}
                >
                  {translate(`meeting.y`).toString()}
                </Button>
              </Stack>
            </Stack>
          </Dialog>
        )}
      </Box>
    </>
  );
});

export default Admission;
