import { createContext, useEffect, useReducer, useCallback, useMemo, useState } from 'react';
// utils
import axios from '../utils/axios';
import localStorageAvailable from '../utils/localStorageAvailable';
//
import { setRefreshSession, setSession } from './utils';
import { ActionMapType, AuthStateType, AuthUserType, WatisAuthContextType } from './types';
import { use } from 'i18next';
import { PATH_ROOT } from 'src/routes/paths';
import { sendReactNativeMessage } from 'src/utils/common';
import { useStores } from 'src/models';
import { IResponse } from 'src/models/response/Response';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  LOGIN_ROOM = 'LOGIN_ROOM',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.LOGIN_ROOM]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN_ROOM) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<WatisAuthContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const { REACT_APP_API_URL } = process.env;
  const rootStore = useStores();
  const { responseStore } = rootStore;
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    const str: any = localStorage.getItem('user');
    if (str) {
      try {
        const json = JSON.parse(str);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: !(json.loginId === null),
            user: JSON.parse(str),
          },
        });
      } catch (e) {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } else {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (facilityCd: string, loginId: string, password: string) => {
    const os = localStorage.getItem('os');
    const mobileInfo = os && JSON.parse(os).mobileInfo;
    const response = await axios.post(
      REACT_APP_API_URL + '/web/user/login',
      os
        ? {
            mobileInfo: mobileInfo,
            facilityCd: facilityCd,
            loginId: loginId,
            password: password,
          }
        : {
            facilityCd: facilityCd,
            loginId: loginId,
            password: password,
          },
    );

    if (response.status === 200) {
      responseStore.setResponseInfo(response.data as IResponse);
      /**
       *
       {
          "responseData": {
              "displayName": "firstname",
              "locale": "ko",
              "pushYn": "Y",
              "accessToken": "8b2cb97d-7731-4feb-a3bd-2abf191abd6b",
              "timezone": "Asia/Seoul",
              "loginId": "innerwave",
              "auth": "USER"
          }
        }
       */
      // console.log('response.data', response.data);
      if (response.data.responseData) {
        const json: any = response.data.responseData;
        // setSession(response.data.data.access_token);

        const user = {
          displayName: json.displayName,
          locale: json.locale,
          pushYn: json.pushYn,
          accessToken: json.accessToken,
          timezone: json.timezone,
          loginId: json.loginId,
          auth: json.auth,
          facility: json.facility,
          authCd: json.authCd,
          isAdmin: json.isAdmin,
        };
        if (!!json.isFirstMobile) {
          // console.log(response.data);
          sessionStorage.setItem('loginType', '2FA');
          sessionStorage.setItem('watisToken', json.watisToken);
        } else if (!!json.isExists) {
          // console.log(response.data);
          sessionStorage.setItem('loginType', 'CC');
          sessionStorage.setItem('watisToken', json.watisToken);
        } else {
          localStorage.setItem('loginFacilityCd', facilityCd);
          localStorage.setItem('loginLoginId', loginId);
          localStorage.setItem('loginPassword', password);
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('accessToken', user.accessToken);
          localStorage.setItem('i18nextLng', user.locale);

          sessionStorage.removeItem('loginType');
          sessionStorage.removeItem('watisToken');

          dispatch({
            type: Types.LOGIN,
            payload: {
              user: user,
            },
          });

          sendReactNativeMessage({
            type: 'saveUser',
            payload: {
              user: user,
            },
          });

          localStorage.removeItem('auth_error');
          // window.location.href = '/';
        }
      } else if (response.data.resultCode === 'F') {
        localStorage.removeItem('user');
        setSession(null);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      } else if (response.data.error) {
        localStorage.removeItem('loginFacilityCd');
        localStorage.removeItem('loginLoginId');
        localStorage.removeItem('loginPassword');
      }
    }
  }, []);

  // compulsionLogin
  const compulsionLogin = useCallback(async (watisToken: string) => {
    const os = localStorage.getItem('os');
    const mobileInfo = os && JSON.parse(os).mobileInfo;
    const response = await axios.post(
      `${REACT_APP_API_URL}/web/user/compulsionLogin`,
      os
        ? {
            mobileInfo: mobileInfo,
            watisToken: watisToken,
          }
        : {
            watisToken: watisToken,
          },
    );

    if (response.status === 200) {
      responseStore.setResponseInfo(response.data as IResponse);

      if (response.data.responseData) {
        const json: any = response.data.responseData;

        const user = {
          displayName: json.displayName,
          locale: json.locale,
          pushYn: json.pushYn,
          accessToken: json.accessToken,
          timezone: json.timezone,
          loginId: json.loginId,
          auth: json.auth,
          facility: json.facility,
          authCd: json.authCd,
          isAdmin: json.isAdmin,
        };
        localStorage.setItem('loginFacilityCd', user.facility.facilityCd);
        localStorage.setItem('loginLoginId', user.loginId);
        // localStorage.setItem('loginPassword', password);
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('accessToken', user.accessToken);
        localStorage.setItem('i18nextLng', user.locale);

        sessionStorage.removeItem('loginType');
        sessionStorage.removeItem('watisToken');
        sessionStorage.setItem('CCInfo', JSON.stringify(json));

        dispatch({
          type: Types.LOGIN,
          payload: {
            user: user,
          },
        });

        sendReactNativeMessage({
          type: 'saveUser',
          payload: {
            user: user,
          },
        });

        localStorage.removeItem('auth_error');
      } else if (response.data.resultCode === 'F') {
        localStorage.removeItem('user');
        setSession(null);
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      } else if (response.data.error) {
        localStorage.removeItem('loginFacilityCd');
        localStorage.removeItem('loginLoginId');
        localStorage.removeItem('loginPassword');
      }
    }
  }, []);

  // 2FA
  const certificationLogin = useCallback(
    async (watisToken: string, certificationNumber: string) => {
      const response = await axios.post(`${REACT_APP_API_URL}/web/user/certificationLogin`, {
        watisToken: watisToken,
        certificationNumber: certificationNumber,
      });

      if (response.status === 200) {
        responseStore.setResponseInfo(response.data as IResponse);

        if (response.data.responseData) {
          const json: any = response.data.responseData;

          const user = {
            displayName: json.displayName,
            locale: json.locale,
            pushYn: json.pushYn,
            accessToken: json.accessToken,
            timezone: json.timezone,
            loginId: json.loginId,
            auth: json.auth,
            facility: json.facility,
            authCd: json.authCd,
            isAdmin: json.isAdmin,
          };
          if (!!json.isExists) {
            // console.log(response.data);
            sessionStorage.setItem('loginType', 'CC');
            sessionStorage.setItem('watisToken', json.watisToken);
          } else {
            localStorage.setItem('loginFacilityCd', user.facility.facilityCd);
            localStorage.setItem('loginLoginId', user.loginId);
            // localStorage.setItem('loginPassword', password);
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('accessToken', user.accessToken);
            localStorage.setItem('i18nextLng', user.locale);

            sessionStorage.removeItem('loginType');
            sessionStorage.removeItem('watisToken');

            dispatch({
              type: Types.LOGIN,
              payload: {
                user: user,
              },
            });

            sendReactNativeMessage({
              type: 'saveUser',
              payload: {
                user: user,
              },
            });

            localStorage.removeItem('auth_error');
          }
        } else if (response.data.resultCode === 'F') {
          localStorage.removeItem('user');
          setSession(null);
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        } else if (response.data.error) {
          localStorage.removeItem('loginFacilityCd');
          localStorage.removeItem('loginLoginId');
          localStorage.removeItem('loginPassword');
        }
      }
    },
    [],
  );

  const loginRoom = useCallback(async (roomId: string, displayName: string, locale: string) => {
    const response = await axios.post(REACT_APP_API_URL + '/common/v1/user/login', {
      loginId: roomId,
      pwd: displayName,
      locale: locale,
    });
  }, []);

  const logout = useCallback(async () => {
    const accessToken = localStorage.getItem('accessToken');
    const response = accessToken
      ? await axios(`${REACT_APP_API_URL}/web/user/logout`, {
          headers: {
            Authorization: `Barear ${accessToken}`,
          },
          method: 'POST',
        })
      : await axios.post(`${REACT_APP_API_URL}/web/user/logout`);

    if (response.status === 200) {
      localStorage.removeItem('user');
      localStorage.removeItem('accessToken');
      localStorage.removeItem('facility');
      localStorage.removeItem('auth');

      // setSession(null);
      dispatch({
        type: Types.LOGOUT,
      });

      sendReactNativeMessage({
        type: 'logout',
        payload: {},
      });
    } else {
      await axios.post(`${REACT_APP_API_URL}/web/user/logout`);
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'watis',
      initialize,
      login,
      compulsionLogin,
      certificationLogin,
      loginRoom,
      logout,
    }),
    [state.isAuthenticated, state.isInitialized, state.user, initialize, login, loginRoom, logout],
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
