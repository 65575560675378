import { Instance, SnapshotOut, types } from "mobx-state-tree"
import { Stream } from "../stream/Stream";
import { createUser, User } from "../user/User";

/**
 * # Client
 *
 * Client을 설명하세요.
 */
export const Client = types
  .model("Client")
  // --------------------------------------------------------------------------
  .props({
    activities: types.optional(types.array(types.string), []),
    cam: types.maybeNull(types.number),
    cuid: types.optional(types.string, ''),
    height: types.maybeNull(types.number),
    level: types.maybeNull(types.number),
    mic: types.maybeNull(types.number),
    // rights: types.array(),
    self: types.optional(types.boolean, false),
    streams: types.optional(types.array(Stream), []),
    uid: types.optional(types.string, ''),
    user: createUser(),
    width: types.maybeNull(types.number),
  })
  // --------------------------------------------------------------------------
  // MUTATEs ONLY! - 모델 상태를 변경
  // REQUESTs는 스토어 모델에 작성하세요.
  // ex) yarn gen model client --store
  .actions((self) => ({
    /*
     * 여러 prop을 한번에 수정
     */
    setProps: (props: { [key: string]: any }) => {
      const newProps = { ...self, ...props } as IClient;
      (Object.keys(newProps) as TClientKeys[]).forEach((key) => {
        // @ts-ignore
        self[key] = newProps[key];
      });
    },
  }));

// --------------------------------------------------------------------------
type TClient = Instance<typeof Client>;
type TClientSnapshot = SnapshotOut<typeof Client>

export interface IClient extends TClient { }
export type TClientKeys = keyof TClientSnapshot & string;
export interface IClientSnapshot extends TClientSnapshot { }
export const createClient = () =>
  types.optional(Client, {

  } as TClient);
