import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { Dispatch, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import {
  Button,
  Container,
  Dialog,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import CHeader from 'src/components/CHeader';
import { useForm } from 'react-hook-form';
import { IConferFilter, IConferenceSnapshot } from 'src/models/conference/Conference';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { MyConferenceProps } from './MyConference';
import { HEADER } from 'src/config-global';
import { useScroll } from 'framer-motion';
import CTextField from 'src/components/forms/CTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form';
import _ from 'lodash';
import { useNavigate } from 'react-router';
import { toJS } from 'mobx';
import MyConferSearchArea from './MyConferSearchArea';
import { useLocales } from 'src/locales';
import axios from 'axios';
import CAlert from 'src/components/CAlert';
import { CallApiToStore } from 'src/utils/common';
import { IReportSnapshot } from 'src/models';
import { ReactComponent as IcoFile } from 'src/assets/images/ico-file.svg';
import Iconify from 'src/components/iconify/Iconify';
import ReportDetail from 'src/screens/conference/ReportDetail';

/**
 * ## MyConferenceM 설명
 *
 */

export const MyConferenceM = observer(
  ({ pending, getData, getDetail, setOpenDetail, onSearch }: MyConferenceProps) => {
    const { translate } = useLocales();
    const rootStore = useStores();
    const { conferenceStore: store, loadingStore } = rootStore;
    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {}, []);

    const [showSearch, setShowSearch] = useState(false);

    const headerOptions: any = {
      showMainIcon: 'back',
      title: translate('conference.mainTitle').toString(),
      showHomeIcon: true,
      showSearchIcon: true,
      handleSearch: () => {
        setShowSearch(!showSearch);
      },
    };

    const addPage = async (): Promise<any> => {
      const last = store.pagination.totalElements % store.pagination.size >= store.pagination.page;
      return new Promise((resolve, reject) => {
        if (!last) {
          store.pagination.setProps({
            page: store.pagination.page + 1,
          });
          resolve(store.pagination.page);
        } else {
          reject('last page');
        }
      });
    };

    const { scrollYProgress, scrollY } = useScroll();
    useEffect(() => {
      scrollYProgress.on('change', (v) => {
        if (v > 0.8) {
          if (!loadingStore.loading) {
            addPage()
              .then(() => {
                getData();
              })
              .catch((e) => {});
          }
        }
      });
      return () => {
        store.pagination.setProps({ page: 1 });
      };
    }, [console, store, scrollYProgress]);

    const ConferenceSchema = Yup.object().shape({
      searchkeyword: Yup.string().required('검색어를 입력해주세요'),
    });

    const methods = useForm<any>({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {},
      resolver: yupResolver(ConferenceSchema),
    });

    const {
      setValue,
      getValues,
      handleSubmit,
      formState: { errors },
    } = methods;

    const onSubmit = async () => {
      if (_.isEmpty(errors)) {
        console.log('검색..', getValues('searchkeyword'));
        store.pagination.setProps({ page: 1 });
        getData();
      }
    };
    const [data, setData] = useState({} as IConferenceSnapshot);
    const { REACT_APP_API_URL } = process.env;
    const token = localStorage.getItem('accessToken');
    const joinCheck = async (data: IConferenceSnapshot) => {
      setData(data);
      await axios({
        method: 'POST',
        url: `${REACT_APP_API_URL}/web/room/joinCheck`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify({ roomId: data.room.id }),
      }).then(async (res) => {
        if (await res.data.responseData) {
          if (res.data.responseData === 'success') {
            navigate('/conference/meeting', { state: toJS(data), replace: true });
          } else if (res.data.responseData === 'confirm') {
            setDurationAlert(true);
          } else {
            alert(translate('error.100'));
          }
        }
      });
    };
    const durationTime = 1;
    const [durationAlert, setDurationAlert] = useState(false);
    const locale = localStorage.getItem('i18nextLng');
    const lang = (locale: string) => {
      if (locale === 'ja') {
        return `会議は${durationTime}時間以上後に予定されています。会議を開始しますか？`;
      } else if (locale === 'en') {
        return `The meeting is scheduled to start in ${durationTime} hours or more. Would you like to start the meeting now?`;
      } else {
        return `회의 시작시간이 ${durationTime}시간 이상 남았습니다. 회의를 시작하시겠습니까?`;
      }
    };

    const [openReport, setOpenReport] = useState(false);
    const [reportData, setReportData] = useState<IReportSnapshot>({} as IReportSnapshot);

    const viewReport = async (roomId: number) => {
      await CallApiToStore(store.getReport(roomId), 'api', loadingStore)
        .then(() => {
          setReportData(toJS(store.report) as IReportSnapshot);
          setOpenReport(true);
        })
        .catch((e) => {
          console.log('🪄 ~ awaitCallApiToStore ~ e:', e);
        });
    };

    return (
      <>
        <Container
          maxWidth={false}
          sx={{
            display: 'flex',
            flex: 1,
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Stack spacing={2} sx={{ flex: 1 }}>
            <CHeader {...headerOptions} />

            {showSearch && (
              // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              //   <Stack
              //     direction={'row'}
              //     spacing={1}
              //     justifyContent={'space-between'}
              //     sx={{ pb: 2 }}
              //   >
              //     <CTextField name={'searchkeyword'} label={''} variant={'outlined'} />
              //     <Button
              //       type={'submit'}
              //       variant={'contained'}
              //       size={'medium'}
              //       color={'primary'}
              //       sx={{ height: 40 }}
              //     >
              //       <Trans i18nKey={'search'} />
              //     </Button>
              //   </Stack>
              //   <Divider />
              // </FormProvider>
              <Stack display={'flex'} width={'100%'}>
                <MyConferSearchArea isMobile={true} onSearch={onSearch} />
                {/* defaultValue={undefined} />  */}
              </Stack>
            )}

            <Stack
              direction="column"
              spacing={2}
              sx={{
                p: 0,
                m: '0 !important',
                pb: {
                  xs: HEADER.H_MOBILE + 'px',
                  md: HEADER.H_MAIN_DESKTOP + 'px',
                },
              }}
            >
              {pending && (
                <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                  <Trans i18nKey={'loading'} />
                </Typography>
              )}
              {!pending && store.conferences.length < 1 ? (
                <Typography variant={'Eng_12_b'} sx={{ textAlign: 'center', py: 4 }}>
                  <Trans i18nKey={'error.nodata'} />
                </Typography>
              ) : (
                <List sx={{ p: 0 }}>
                  {store.conferences.map((item: IConferenceSnapshot, i: number) => {
                    return (
                      <Fragment key={'conference-item-f' + i}>
                        <ListItem
                          key={'conference-item-' + i}
                          sx={{
                            p: 0,
                            px: 1,
                            borderBottom: '1px solid #EEEEEE',
                            backgroundColor:
                              !item.room.closed && !!item.startedTime
                                ? // theme.palette.background.neutral
                                  theme.palette.secondary.lighter
                                : '',
                          }}
                        >
                          <Grid
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{
                              width: '100%',
                              mt: 0,
                              py: 1,
                              '&:last-child': {
                                border: 'none',
                              },
                              '& .MuiPaper-root': {
                                background: 'none',
                              },
                            }}
                          >
                            <Grid item sm={1} xs={2} sx={{}}>
                              <Typography
                                sx={{
                                  color: theme.palette.text.secondary,
                                  lineHeight: 1,
                                  fontWeight: 400,
                                  fontSize: '0.75rem',
                                }}
                              >
                                {moment(item.start).format('MM-DD (dd)')}
                                <br />
                                {moment(item.start).format('HH:mm')}
                              </Typography>
                            </Grid>
                            <Grid item sm={8} xs={7} sx={{ lineHeight: 1 }}>
                              <Typography
                                variant={'body1'}
                                sx={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textAlign: 'left',
                                }}
                              >
                                {item.title}
                              </Typography>
                              <Typography
                                sx={{
                                  color: theme.palette.text.secondary,
                                  lineHeight: 1,
                                  fontWeight: 400,
                                  fontSize: '0.75rem',
                                }}
                              >
                                {translate('conferenceDetail.conferId').toString()} : {item.id}
                              </Typography>
                            </Grid>
                            <Grid item sm={3} xs={3} sx={{ textAlign: 'right' }}>
                              <Stack
                                direction={'row'}
                                justifyContent={'flex-end'}
                                alignItems={'center'}
                              >
                                {item.room.hasReport === true ? (
                                  <IconButton
                                    onClick={() => {
                                      setData(item);
                                      viewReport(item.room.id);
                                    }}
                                  >
                                    <IcoFile />
                                  </IconButton>
                                ) : (
                                  <></>
                                )}
                                <Button
                                  variant={'soft'}
                                  color={'primary'}
                                  size={'small'}
                                  sx={{
                                    p: 1,
                                    minWidth: 50,
                                    backgroundColor: item.room.closed
                                      ? 'rgba(145, 158, 171, 0.24)'
                                      : '',
                                    color: item.room.closed ? 'rgba(145, 158, 171, 0.8)' : '',
                                  }}
                                  // disabled={item.room.closed === true}
                                  onClick={() => {
                                    // navigate('/conference/meeting', {
                                    //   state: toJS(item),
                                    //   replace: true,
                                    // }); // confirm 추가 ***
                                    // setData(item);
                                    // if (item.ownerYn === 'Y') {
                                    //   joinCheck(item);
                                    // } else {
                                    //   navigate('/conference/meeting', {
                                    //     state: toJS(item),
                                    //     replace: true,
                                    //   });
                                    // }
                                    getDetail(item.id);
                                  }}
                                >
                                  {/* <Trans i18nKey={'참가'} /> */}
                                  {translate('myCalendar.enter').toString()}
                                </Button>
                              </Stack>
                            </Grid>
                          </Grid>
                          {/* </Button> */}
                        </ListItem>
                      </Fragment>
                    );
                  })}
                </List>
              )}
            </Stack>
          </Stack>
        </Container>
        {durationAlert && (
          <CAlert
            key={'conference-item-alert-'}
            open={durationAlert}
            handleClose={() => setDurationAlert(false)}
            content={lang(locale || 'ja')}
            hasCancel
            callBack={() => {
              navigate('/conference/meeting', { state: toJS(data), replace: true });
            }}
          />
        )}

        {openReport && reportData && (
          <Dialog
            open={openReport}
            maxWidth={'md'}
            fullWidth
            scroll="body"
            PaperComponent={(props) => {
              return <Paper {...props} />;
            }}
            PaperProps={{
              sx: {
                p: 0,
                borderRadius: 4,
              },
            }}
            onClose={() => {
              setOpenReport(false);
              setReportData({} as IReportSnapshot);
              setData({} as IConferenceSnapshot);
            }}
          >
            <ReportDetail
              onClose={() => {
                setOpenReport(false);
                setReportData({} as IReportSnapshot);
                setData({} as IConferenceSnapshot);
              }}
              reportData={reportData}
              data={data}
              getReportData={() => viewReport(data.room.id)}
              getData={getData}
            />
          </Dialog>
        )}
      </>
    );
  },
);

export default MyConferenceM;
