import { ApiResponse } from 'apisauce';
import { Api } from '../api/api';
import { getGeneralApiProblem } from '../api/api-problem';
import { IPagination } from '../../models/extensions/with-pagination';
import {
  TSearchConferenceResult,
  TFindConferenceResult,
  TCreateConferenceResult,
  TUpdateConferenceResult,
  TDeleteConferenceResult,
  TGetConferencesResult,
  TGetConferenceResult,
  TPostConferenceResult,
  TGetsCalendarsResult,
  TInvitationResult,
  TGetChatResult,
  TdownloadReportResult,
  TPostReportResult,
  TGetReportResult,
  TSendMsgResult,
  TDownloadChatResult,
  TGetUserResult,
  TDeleteReportResult,
} from './ConferenceTypes';
import { IPaginationSnapshot } from 'src/models/pagination/Pagination';
import { IConference, IConferenceSnapshot } from 'src/models/conference/Conference';
import { toJS } from 'mobx';
import { IResponse, IResponseSnapshot } from 'src/models/response/Response';
import { IChatSnapshot, IReportSnapshot, IUser, IUserSnapshot } from 'src/models';

/**
 * # Conference Api 서비스
 *
 * 서비스의 설명을 작성하세요.
 *
 * ## 사용방법
 *
 * ```ts
 * const service = new ConferenceApi();
 *
 * ```
 */
export class ConferenceApi {
  private BASE_URL = '/conference';
  private api: Api;

  constructor(api: Api) {
    this.api = api;
    this.api.setup();
  }

  // async search(options?: Partial<IPagination>): Promise<TSearchConferenceResult> {
  //   try {
  //     const url = `${this.BASE_URL}`;
  //     const params: Record<string, any> = {};
  //     if (options?.page) params.page = options.page;
  //     if (options?.size) params.size = options.size;
  //     if (options?.offset) params.offset = options.offset;
  //     const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

  //     const problem = getGeneralApiProblem(response);
  //     if (problem) return problem;

  //     const data: IConference[] = response.data.data;
  //     const pagination: IPagination = response.data.pagination;
  //     return { kind: 'ok', data, pagination };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  // async find(id: number): Promise<TFindConferenceResult> {
  //   try {
  //     const url = `${this.BASE_URL}/${id}`;
  //     const params = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.get(url, params);

  //     const problem = getGeneralApiProblem(response);
  //     if (problem) return problem;

  //     const data: IConference = response.data.data;
  //     return { kind: 'ok', data };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  /**
   * 화상회의 예약을 만든다. (이메일주소로 회의 초대 발송메일이 전송된다.) (메일 링크는 협의 후 수정)
   * @param conferenceInfo
   * @returns
   */
  async createConference(conferenceInfo: IConferenceSnapshot): Promise<TCreateConferenceResult> {
    try {
      const url = '/web/appointment/add';
      // const params = conferenceInfo;
      const response: ApiResponse<any> = await this.api.apisauce.post(
        url,
        JSON.stringify(conferenceInfo),
      );

      if (!response.ok) {
        const problem = getGeneralApiProblem(response);
        // if (problem) return problem;
      }

      const data: IConference = response.data.responseData;

      return { kind: 'ok', data: data, responseInfo: response.data };
    } catch (e) {
      return e;
    }
  }

  /**
   * 내 회의 정보를 가져온다. ( 정렬순서는 방 생성일 기준 desc )
   * @returns
   */
  async gets({ ...props }: any, pagination: IPaginationSnapshot): Promise<TGetConferencesResult> {
    try {
      const url = '/web/appointment/getMyAppointments';
      const params = {
        ...props.filter,
        size: pagination.size,
        page: pagination.page,
        // start: props.filter.start,
        // end: props.filter.end,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IConferenceSnapshot[] = response.data.responseData;
      const paging: IPaginationSnapshot = response.data.paging;
      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: data,
        paging: paging,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 회의번호로 참여한 회의 정보를 가져온다.
   * @param appointmentId
   * @returns
   */
  async get(appointmentId: number): Promise<TGetConferenceResult> {
    try {
      const url = `/web/appointment/getMyAppointmentById`;
      const params = { appointmentId };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IConferenceSnapshot = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 관리자가 전체 회의를 조회한다
   * @param param0
   * @returns
   */
  async getAll({ ...props }: any, pagination: IPaginationSnapshot): Promise<TGetConferencesResult> {
    try {
      const url = '/web/appointment/getAll';
      const params = {
        ...props.filter,
        size: pagination.size,
        page: pagination.page,
      };

      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IConferenceSnapshot[] = response.data.responseData;
      const paging: IPaginationSnapshot = response.data.paging;
      const responseInfo: IResponseSnapshot = response.data;

      return {
        kind: 'ok',
        data: data,
        paging: paging,
        responseInfo: responseInfo,
      };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 예약정보를 가져온다. (캘린더)
   * @param pagination
   * @returns
   */
  async getsCalendar(): Promise<TGetsCalendarsResult> {
    try {
      const url = '/web/calendar/get';
      const params = { start: '2023-06-01 00:00', end: '2023-06-30 23:59' };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);
      try {
        if (!response.ok) {
          const problem = getGeneralApiProblem(response);
          if (problem) return problem;
        }

        const data: any[] = response.data.responseData;

        return { kind: 'ok', data };
      } catch (error) {
        console.error(error);
        return { kind: 'bad-data' };
      }
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  async postJoinRoom(roomData: any): Promise<TCreateConferenceResult> {
    try {
      const url = '/web/room/join';
      const response: ApiResponse<any> = await this.api.apisauce.post(
        url,
        JSON.stringify(roomData),
      );
      const data: IConference = response.data;
      return { kind: 'ok', data, responseInfo: response.data };
    } catch (e) {
      return e;
    }
  }

  // async post(): Promise<TPostConferenceResult> {
  //   try {
  //     const url = '/conference';
  //     const payload = {};
  //     const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

  //     if (!response.ok) {
  //       const problem = getGeneralApiProblem(response);
  //       if (problem) return problem;
  //     }

  //     const conference: IConference = response.data.results;
  //     return { kind: 'ok', conference };
  //   } catch (e) {
  //     return { kind: 'bad-data' };
  //   }
  // }

  /**
   * 초대장을 추가한다. (예약 시 초대 못한 회원 다시 초대하는 용도)
   * @param userInfo
   * @returns
   */
  async invitation(userInfo: IUserSnapshot): Promise<TInvitationResult> {
    try {
      const url = '/web/invitation/add';
      const payload = userInfo;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: string = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 화상회의를 수정한다
   * @param conferenceInfo
   * @returns
   */
  async update(conferenceInfo: IConferenceSnapshot): Promise<TUpdateConferenceResult> {
    try {
      const url = `/web/appointment/update`;
      const payload = conferenceInfo;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: string = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * watis 최근 초대 사용자 정보를 조회한다
   * @param roomType
   * @returns
   */
  async getRecentUser(roomType: string): Promise<TGetUserResult> {
    try {
      const url = `/web/watis/recentUser`;
      const payload = { roomType: roomType };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IUser[] = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * watis 사용자 정보를 조회한다
   * @param userInfo
   * @returns
   */
  async searchUser(userInfo: IUserSnapshot): Promise<TGetUserResult> {
    try {
      const url = `/web/watis/user`;
      const payload = userInfo;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IUser[] = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 회의를 삭제한다.
   * @param id
   * @returns
   */
  async delete(id: number): Promise<TDeleteConferenceResult> {
    try {
      const url = '/web/appointment/delete';
      const params = { appointmentId: id };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, params);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const responseInfo: IResponseSnapshot = response.data;
      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 채팅메시지를 가져온다. (로그인 사용자는 토큰값으로 방을 판단함.)
   * @param roomId
   * @returns
   */
  async getChat(roomId: number): Promise<TGetChatResult> {
    try {
      const url = '/web/chat/getMessage';
      const payload = { roomId: roomId };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      // if (problem) return problem;

      const data: IChatSnapshot[] = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      // return { kind: 'bad-data' };
      return e;
    }
  }

  /**
   * 채팅메시지를 전송한다. (메시지는 방에 있는 유저들에게 웹소켓으로 전송됨)
   * @param message
   * @returns
   */
  async sendMsg(message: string): Promise<TSendMsgResult> {
    try {
      const url = '/web/chat/message';
      const payload = { message: encodeURIComponent(message) };

      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      // if (problem) return problem;

      const data: IChatSnapshot = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      // return { kind: 'bad-data' };
      return e;
    }
  }

  /**
   * 채팅메시지를 다운로드한다. (엑셀)
   * @param roomId
   * @returns
   */
  async downloadChat(roomId: number): Promise<TDownloadChatResult> {
    try {
      const url = '/web/chat/download';
      const payload = { roomId: roomId };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
      // return e;
    }
  }

  /**
   * 보고서를 저장한다.
   * @param report
   * @returns
   */
  async saveReport(report: IReportSnapshot): Promise<TPostReportResult> {
    try {
      const url = '/web/report';
      const payload = report;
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const data: IReportSnapshot = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 보고서 PDF를 다운로드한다.
   * @param reportSid
   * @returns
   */
  async downloadReport(reportSid: number): Promise<TdownloadReportResult> {
    try {
      const url = '/web/report/download';
      const payload = { reportSid: reportSid };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      if (problem) return problem;

      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      return { kind: 'bad-data' };
    }
  }

  /**
   * 방 번호로 보고서를 조회한다.
   * @param roomId
   * @returns
   */
  async getReport(roomId: number): Promise<TGetReportResult> {
    try {
      const url = '/web/report/findByRoomId';
      const payload = { roomId: roomId };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      // if (problem) return problem;

      const data: IReportSnapshot = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', data: data, responseInfo: responseInfo };
    } catch (e) {
      // return { kind: 'bad-data' };
      return e;
    }
  }

  /**
   * 보고서를 삭제한다 ( 주관자 , 관리자 )
   * @param reportSid
   * @returns
   */
  async deleteReport(reportSid: number): Promise<TDeleteReportResult> {
    try {
      const url = '/web/report/delete';
      const payload = { reportSid: reportSid };
      const response: ApiResponse<any> = await this.api.apisauce.post(url, payload);

      const problem = getGeneralApiProblem(response);
      // if (problem) return problem;

      const data: IReportSnapshot = response.data.responseData;
      const responseInfo: IResponseSnapshot = response.data;

      return { kind: 'ok', responseInfo: responseInfo };
    } catch (e) {
      // return { kind: 'bad-data' };
      return e;
    }
  }
}
