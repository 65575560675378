import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { withEnvironment } from '../extensions/with-environment';

import {
  DashBoardApi,
  TGetOwnerCountResult,
  TGetProgressListResult,
  TGetSystemInfoResult,
} from '../../services';
import { withRootStore } from '../extensions/with-root-store';
import { IOwnerCount, createOwnerCount } from '../owner-count/OwnerCount';
import { IProgressList, IProgressListSnapshot, ProgressList } from '../progress-list/ProgressList';
import { ISystemInfo, createSystemInfo } from '../system-info/SystemInfo';
import { IPagination, IPaginationSnapshot, createPagination } from '../pagination/Pagination';

/**
 * # DashBoardStore
 *
 * DashBoardStore을 설명하세요.
 */
export const DashBoardStore = types
  .model('DashBoardStore')
  // --------------------------------------------------------------------------
  .props({
    pagination: createPagination(),
    ownerCount: createOwnerCount(),
    progressList: types.optional(types.array(ProgressList), []),
    systemInfo: createSystemInfo(),
  })
  .extend(withEnvironment)
  .extend(withRootStore)
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .views((self) => ({}))
  // --------------------------------------------------------------------------
  // MUTATEs - 모델 상태를 변경
  .actions((self) => ({
    // setPagination: (pagination: IPaginationSnapshot) => {
    //   self.pagination = pagination as IPagination;
    // },
    setOwnerCount: (ownerCount: IOwnerCount) => {
      self.ownerCount = ownerCount;
    },
    setProgressList: (progressList: IProgressList[]) => {
      self.progressList.replace(progressList);
    },
    setSystemInfo: (systemInfo: ISystemInfo) => {
      self.systemInfo = systemInfo;
    },
  }))
  // --------------------------------------------------------------------------
  // REQUESTs - 서비스 요청 및 기타 인터페이스 요청
  .actions((self) => ({
    setPagination: (pagination: IPaginationSnapshot) => {
      self.pagination = pagination as IPagination;
    },
    /**
     * 대시보드 당일 주관자 직종별 카운트 ( 당일 00:00 ~ 23:59 )
     */
    getOwnerCount: async () => {
      const dashBoardApi: DashBoardApi = new DashBoardApi(self.environment.api);
      const result: TGetOwnerCountResult = await dashBoardApi.ownerCount();
      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          self.setOwnerCount(result.ownerCount);
        } else {
          console.error(result.kind);
        }
      }
    },

    /**
     * 당일 현재 진행중인 회의 리스트 가져오기
     */
    getProgressList: async () => {
      const dashBoardApi: DashBoardApi = new DashBoardApi(self.environment.api);
      const result: TGetProgressListResult = await dashBoardApi.progressList();
      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          self.setProgressList(
            result.progressList.map((list: any, i: number) => {
              return {
                ...list,
                id: i,
                title: list.appointment.title,
                owner: list.appointment.owner,
                meetingMembers: list.appointment.meetingMembers,
              };
            }),
          );
        } else {
          console.error(result.kind);
        }
      }
    },

    /**
     * 서버 시스템 정보 확인
     */
    getSystemInfo: async () => {
      const dashBoardApi: DashBoardApi = new DashBoardApi(self.environment.api);
      const result: TGetSystemInfoResult = await dashBoardApi.systemInfo();
      if (self.rootStore.responseStore.getResponseResult(result)) {
        if (result.kind === 'ok') {
          self.setSystemInfo(result.systemInfo);
        } else {
          console.error(result.kind);
        }
      }
    },
  }));

// --------------------------------------------------------------------------
type TDashBoardStore = Instance<typeof DashBoardStore>;
type TDashBoardStoreSnapshot = SnapshotOut<typeof DashBoardStore>;

export interface IDashBoardStore extends TDashBoardStore {}
export type TDashBoardStoreKeys = keyof TDashBoardStoreSnapshot & string;
export interface IDashBoardStoreSnapshot extends TDashBoardStoreSnapshot {}
export const createDashBoardStore = () => types.optional(DashBoardStore, {} as TDashBoardStore);
