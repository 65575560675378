import { Dialog, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/FormProvider';
import { useLocales } from 'src/locales';
import { useStores } from 'src/models';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ConcurrentConnection from './ConcurrentConnection';

type FormValuesProps = {
  certificationNumber: string;
};
type Props = {
  watisToken: string;
};
const TwoFactorAuth = observer(({ watisToken }: Props) => {
  const { certificationLogin } = useAuthContext();
  const rootStore = useStores();
  const { responseStore } = rootStore;
  const { translate } = useLocales();
  const theme = useTheme();

  const [errorMsg, setErrorMsg] = useState('');
  const [openCC, setOpenCC] = useState(false);

  const tfaSchema = Yup.object().shape({
    cetificationNumber: Yup.string().trim().required(''),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(tfaSchema),
    defaultValues: { certificationNumber: '' },
  });

  const {
    reset,
    setError,
    clearErrors,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const tfaLogin = async (data: FormValuesProps) => {
    localStorage.removeItem('auth-errors');
    try {
      await certificationLogin(watisToken, data.certificationNumber);
      if (sessionStorage.getItem('loginType') === 'CC') {
        watisToken = sessionStorage.getItem('watisToken') || '';
        setOpenCC(true);
      } else {
        setErrorMsg(translate(`error.${responseStore.responseInfo.error}`).toString() || '');
      }
    } catch (error) {
      console.error(error);
      reset();
    }
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(tfaLogin)}>
        <Typography variant={'h6'} mb={2}>
          {translate('login.tfa').toString()}
        </Typography>

        <RHFTextField
          name={'certificationNumber'}
          type={'text'}
          inputProps={{
            // maxLength: 10,
            inputMode: 'numeric',
            pattern: '[0-9]*',
            // defaultValue: '',
          }}
          label={translate('login.enterCertiNo').toString()}
          variant={'outlined'}
          onFocus={() => clearErrors()}
          onKeyUp={(e: any) => {
            e.target.value = e.target.value.replace(/\s/gi, '');
          }}
          onInput={(e: any) => {
            e.target.value = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
          }}
        />

        <Typography variant="subtitle2" color={theme.palette.error.main} m={1} height={'20px'}>
          {errorMsg ? errorMsg : ''}
        </Typography>

        {/* <LoadingButton
            fullWidth
            type="submit"
            color="primary"
            size="medium"
            variant="contained"
            loading={isSubmitting}
            sx={{
              bgcolor: 'primary.main',
              borderRadius: 2,
            }}
          >
            {translate('meeting.submit').toString()}
          </LoadingButton> */}
        <LoadingButton
          fullWidth
          color="primary"
          size="medium"
          variant="contained"
          loading={isSubmitting}
          sx={{
            bgcolor: 'primary.main',
            borderRadius: 2,
          }}
          onClick={() => tfaLogin(getValues())}
        >
          {translate('meeting.submit').toString()}
        </LoadingButton>
      </FormProvider>
      {openCC && (
        <Dialog
          open={openCC}
          onClose={(e: any, reason: string) => {
            reason !== 'backdropClick' && setOpenCC(false);
          }}
          maxWidth={'sm'}
          fullWidth
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
        >
          <ConcurrentConnection
            watisToken={watisToken}
            handleClose={() => {
              setOpenCC(false);
            }}
          />
        </Dialog>
      )}
    </>
  );
});

export default TwoFactorAuth;
