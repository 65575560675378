import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import {
  useEffect,
  useRef,
  useState,
  createRef,
  SetStateAction,
  FunctionComponent,
  SVGProps,
} from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import {
  useTheme,
  Container,
  Typography,
  Stack,
  Button,
  Paper,
  Avatar,
  styled,
  FormLabel,
  Card,
  Dialog,
} from '@mui/material';
import { Page } from 'src/components';
import { useSettings } from 'src/hooks/useSettings';
import { useNavigate } from 'react-router';
import useResponsive from 'src/hooks/useResponsive';
import { useLocation } from 'react-router-dom';
import { ReactComponent as IcoMicOn } from 'src/assets/icons/ico-mic_none.svg';
import { ReactComponent as IcoVideoOn } from 'src/assets/icons/ico-videocam.svg';
import { ReactComponent as IcoSetting } from 'src/assets/icons/ico-setting.svg';
import { CallApiToStore, getIconByAuthCd, sendLog, sendReactNativeMessage } from 'src/utils/common';
import TotalSetting from '../setting/TotalSetting';
import { useLocales } from 'src/locales';
import { IConferenceSnapshot } from 'src/models';
import { PATH_ROOT } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/useAuthContext';
import { JSX } from 'react/jsx-runtime';
interface Props {
  initialize: (
    message: any,
    audioInput: any,
    audioOutput: any,
    videoInput: any,
    videoSize: any,
    waitingMic: any,
    waitingVideo: any,
    waitingMicFlag: any,
    waitingVideoFlag: any,
    audioOutputs: any,
    audioInputs: any,
    videoInputs: any,
  ) => void;
  requestMessage: any;
  reReady: any;
  ready?: any;
  flag: (flag: any) => void;
  ws: any;
  connectionSuccess: any;
  data: IConferenceSnapshot;
  mobile?: any;
}

/**
 * ## Waiting 설명
 *
 */
var aftervideostate: any | null;
export const Waiting = observer(
  ({
    initialize,
    requestMessage,
    reReady,
    ready,
    flag,
    ws,
    connectionSuccess,
    data,
    mobile,
  }: Props) => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const { translate } = useLocales();
    const rootStore = useStores();
    const theme = useTheme();
    const { themeMode, onChangeThemeMode } = useSettings();
    const [anchorSet, setAnchorSet] = useState<HTMLButtonElement | null>(null);
    const openSet = Boolean(anchorSet);
    const { participantStore, loadingStore, meetingStore, conferenceStore, pushAlarmStore } =
      rootStore;
    const idSet = openSet ? 'simple-popover' : undefined;
    let videostate: any = {};
    const auth = useAuthContext();
    const ref = useRef<any>();
    const isDownMd = useResponsive('down', 'md');
    const video = createRef<HTMLVideoElement>();

    const AudioCtx = window.AudioContext; // || window.webkitAudioContext;
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const [mediaError, setMediaError] = useState<any>(null);
    const [stream, setStream] = useState<any>();
    const [audioInput, setAudioInput] = useState<string | null>(null);
    const [audioOutput, setAudioOutput] = useState<string | null>(null);
    const [videoInput, setVideoInput] = useState<string | null>(null);
    const [audioInputs, setAudioInputs] = useState<{ label: string; value: string }[]>([]);
    const [audioOutputs, setAudioOutputs] = useState<{ label: string; value: string }[]>([]);
    const [videoInputs, setVideoInputs] = useState<{ label: string; value: string }[]>([]);
    const isMobile = useResponsive('down', 'sm');
    const [videoSize, setVideoSize] = useState<'720p' | '360p'>('360p');
    const [waitingMic, setWaitingMic] = useState(true);
    const [waitingVideo, setWaitingVideo] = useState(true);
    const [waitingMicFlag, setWaitingMicFlag] = useState(true);
    const [waitingVideoFlag, setWaitingVideoFlag] = useState(true);
    const [buttonReady, setReady] = useState(false);
    const [reReadyFlag, setReReadyFlag] = useState(reReady);
    const [readyFlag, setReadyFlag] = useState(false);
    const [streamReset, setStreamReset] = useState(0);
    const didMount = useRef(false);
    const [alertFlag, setAlertFlag] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const videoResolutions = {
      '720p': {
        width: 1280,
        height: 720,
      },
      '360p': {
        width: 640,
        height: 360,
      },
    };
    let AuthIcon: any = getIconByAuthCd('1');
    let userInfo;
    useEffect(() => {
      if (localStorage.getItem('user')) {
        userInfo = JSON.parse(localStorage.getItem('user') || '') || {};
        AuthIcon = getIconByAuthCd(userInfo.authCd);
      }
      participantStore.resetParticipants();
      onChangeThemeMode('dark');
    }, []);

    useEffect(() => {
      if (connectionSuccess) {
        onVideoSetting();
      }
    }, [connectionSuccess]);

    useEffect(() => {
      if (didMount.current === true) {
        const myCUID = sessionStorage.getItem('myFirstCUID');
        if (myCUID) {
          if (state.ownerYn === 'Y') {
            enterRoom();
          } else {
            if (readyFlag === false) {
              enterRoom();
            }
          }
        }
      }
    }, [ready, didMount.current]);

    useEffect(() => {
      if (requestMessage === 'Y') {
        if (state.ownerYn !== 'Y') {
          setReady(true);
          setReadyFlag(true);
        } else {
          initialize(
            true,
            audioInput,
            audioOutput,
            videoInput,
            videoSize,
            waitingMic,
            waitingVideo,
            waitingMicFlag,
            waitingVideoFlag,
            audioOutputs,
            audioInputs,
            videoInputs,
          );
        }
      } else if (requestMessage === 'F') {
        if (video.current) {
          video.current.pause();
          video.current.srcObject = null;
        }
        if (stream) {
          stream.getTracks().forEach((track: any) => {
            track.stop();
          });
        }
        setWaitingVideo(false);
        setWaitingMic(false);
      }
    }, [requestMessage]);

    useEffect(() => {
      setReReadyFlag(reReady);
    }, [reReady]);

    useEffect(() => {
      if (streamReset > 0 && state.ownerYn !== 'Y') {
        onStreamSetting();
      }
    }, [streamReset]);

    const gotDevices = async (deviceInfos: any) => {
      if (deviceInfos.length > 0) {
        const aInputs: any[] = [];
        const aOutputs: any[] = [];
        const vInputs: any[] = [];
        for (let i = 0; i !== deviceInfos.length; ++i) {
          const deviceInfo = deviceInfos[i];
          const option = document.createElement('option');
          option.value = deviceInfo.deviceId;
          if (deviceInfo.kind === 'audioinput') {
            option.text = deviceInfo.label || `microphone ${aInputs.length + 1}`;
            aInputs.push(option);
          } else if (deviceInfo.kind === 'audiooutput') {
            option.text = deviceInfo.label || `speaker ${aOutputs.length + 1}`;
            aOutputs.push(option);
          } else if (deviceInfo.kind === 'videoinput') {
            option.text = deviceInfo.label || `camera ${vInputs.length + 1}`;
            vInputs.push(option);
          } else {
            console.log('Some other kind of source/device: ', deviceInfo);
          }
        }

        setAudioInputs((audioInputs) => aInputs);
        setAudioOutputs((audioOutputs) => aOutputs);
        setVideoInputs((videos) => vInputs);

        if (audioInput === null) {
          setAudioInput((audioInput) => (aInputs.length > 0 ? aInputs[0].value : null));
        }
        if (audioOutput === null) {
          setAudioOutput((audioOutput) => (aOutputs.length > 0 ? aOutputs[0].value : null));
        }
        if (videoInput === null) {
          setVideoInput((videoInput) => (vInputs.length > 0 ? vInputs[0].value : null));
        }
        if (vInputs.length === 0) {
          setWaitingMic(false);
          setWaitingMicFlag(false);
        }
        if (aInputs.length === 0) {
          setWaitingVideo(false);
          setWaitingVideoFlag(false);
        }
        didMount.current = true;
      } else {
        setWaitingVideo(false);
        setWaitingVideoFlag(false);
        setWaitingMic(false);
        setWaitingMicFlag(false);
        didMount.current = true;
      }
    };

    const onToggleMic = () => {
      setWaitingMic(!waitingMic);
      setStreamReset(streamReset + 1);
    };

    const onToggleVideo = () => {
      setWaitingVideo(!waitingVideo);
      setStreamReset(streamReset + 1);
    };

    const onStreamSetting = () => {
      let audioOption: any;
      let videoOption: any;

      if (waitingMic === true) {
        audioOption = {
          // echoCancellation: true,
          // autoGainControl: true,
          // noiseSuppression: true,
          deviceId: audioInput,
          sampleRate: pushAlarmStore.getSampleRateData() ? pushAlarmStore.getSampleRateData() : 48000,
          echoCancellation: {exact: true},
          autoGainControl: {exact :false},
          noiseSuppression: {exact: false}, 
          highpassFilter: {exact :false},
        };
      }

      if (waitingVideo === true) {
        const video = document.querySelector('video');
        if (video) {
          video.srcObject = null;
        }
        videoOption = {
          deviceId: videoInput,
          frameRate: { ideal: 30, max: 30 },
          width: videoResolutions[videoSize].width,
          height: videoResolutions[videoSize].height,
        };
      } else if (waitingMic === true) {
        const audio = document.querySelector('audio');
        if (audio) {
          audio.srcObject = null;
        }
      }

      if (stream) {
        stream.getTracks().forEach((track: any) => {
          track.stop();
        });
      }

      const constraints = {
        audio: audioOption,
        video: videoOption,
      };
      if (audioOption === undefined && videoOption === undefined) {
        setStream(null);
        gotStream(null);
      } else {
        try {
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
              if (audioInputs.length === 0) {
                navigator.mediaDevices.enumerateDevices().then(gotDevices);
              }
              videostate.localStream = stream;
              let _stream = stream;
              const data: any = {};
              if (stream.getAudioTracks().length !== 0) {
                data.aCtx = new AudioCtx({
                  sampleRate: pushAlarmStore.getSampleRateData()
                    ? pushAlarmStore.getSampleRateData()
                    : 48000,
                });
                data.gainNode = data.aCtx.createGain();
                data.gainNode.gain.value = 0.5;
                data.analyser = data.aCtx.createAnalyser();
                data.aSrc = data.aCtx.createMediaStreamSource(stream);
                data.aSrc.connect(data.gainNode);
                data.gainNode.connect(data.analyser);
                data.aDest = data.aCtx.createMediaStreamDestination();
                data.analyser.connect(data.aDest);
                _stream = data.aDest.stream;
                stream.getVideoTracks().forEach(function (track) {
                  _stream.addTrack(track);
                });
              }
              videostate.data = data;
              videostate.stream = _stream;
              aftervideostate = videostate;
              setStream((stream: any) => stream);
              gotStream(stream);
            })
            .catch(function (err) {
              if (stream) {
                stream.getTracks().forEach((track: any) => {
                  track.stop();
                });
              }
              if (videoInput === '' || videoInput === null) {
                setMediaError(translate('error.webcam'));
                setWaitingMic(false);
                setWaitingVideo(false);
              } else {
                if (videoInput !== 'default') {
                  setAudioInput('default');
                  setVideoInput('default');
                  setAudioOutput('default');
                  setVideoSize('360p');
                  setStreamReset(streamReset + 1);
                }
              }
            });
        } catch (err) {
          sendLog(err);
          console.log(err);
        }
      }
    };

    const gotStream = (_stream: any) => {
      setStream((stream: any) => _stream);

      let video: any;
      if (waitingVideo === true) {
        video = document.querySelector('video');
        if (video) {
          video.srcObject = _stream;
        }
      } else if (waitingMic === true) {
        video = document.querySelector('audio');
        if (video) {
          video.srcObject = _stream;
        }
      }
    };

    useEffect(() => {
      const video: any = document.querySelector('video');
      if (video) {
        if (typeof video.sinkId !== 'undefined' && audioOutput !== null) {
          video
            .setSinkId(audioOutput)
            .then(() => {
              console.log(`Success, audio output device attached: ${audioOutput}`);
            })
            .catch((error: any) => {
              let errorMessage = error;
              if (error.name === 'SecurityError') {
                errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
              }
              console.error(errorMessage);
              setAudioInput('');
            });
        } else {
          console.warn('Browser does not support output device selection.');
        }
      }
    }, [audioOutput]);

    const enterRoom = async () => {
      await CallApiToStore(pushAlarmStore.getSampleRate(), 'api', loadingStore);
      const myCUID = sessionStorage.getItem('myFirstCUID');
      console.log('myCUID', myCUID);
      if (myCUID) {
        const data = {
          roomId: state.room.id,
          cuid: myCUID,
        };
        await CallApiToStore(meetingStore.JoinRoom(data), 'api', loadingStore)
          .then((res) => {
            if (meetingStore.getJoinRoomResponseData() === 'success') {
              CallApiToStore(conferenceStore.get(state.id), 'api', loadingStore);
              if (audioInput && videoInput && audioOutput && videoSize) {
                localStorage.setItem('audioInput', audioInput);
                localStorage.setItem('videoInput', videoInput);
                localStorage.setItem('audioOutput', audioOutput);
                localStorage.setItem('videoSize', videoSize);
              }
              initialize(
                true,
                audioInput,
                audioOutput,
                videoInput,
                videoSize,
                waitingMic,
                waitingVideo,
                waitingMicFlag,
                waitingVideoFlag,
                audioOutputs,
                audioInputs,
                videoInputs,
              );
            } else if (meetingStore.getJoinRoomResponseData() === 'wait') {
              console.log('대기');
            } else if (meetingStore.getJoinRoomResponseData() === 'room009') {
              setAlertMessage(translate(`error.room009`).toString());
              setAlertFlag(true);
            } else {
              console.log('조인실패');
            }
          })
          .catch((res) => {
            console.log('res', res);
          });
      }
    };

    const onVideoSetting = async () => {
      navigator.mediaDevices.enumerateDevices().then(gotDevices);
      const userInfo = JSON.parse(localStorage.getItem('user') || '');
      if (
        userInfo.authCd === 'GUEST' ||
        userInfo.authCd === '8' ||
        userInfo.authCd === '9' ||
        userInfo.authCd === null
      ) {
        setStreamReset(streamReset + 1);
      } else {
        envSetting();
      }
    };

    const envSetting = async () => {
      const { REACT_APP_API_URL } = process.env;
      const accessToken = localStorage.getItem('accessToken');
      const response = await fetch(`${REACT_APP_API_URL}/web/env`, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
          Accept: 'application/json, text/javascript, */*; q=0.01',
          Authorization: 'Bearer ' + accessToken,
        },
        method: 'GET',
      });
      const jsonData = await response.json();
      setAudioInput(jsonData.responseData.deviceAudioId);
      setVideoInput(jsonData.responseData.deviceVideoId);
      setAudioOutput(jsonData.responseData.deviceSpeakerId);
      setVideoSize(jsonData.responseData.resolution);
      state.videoMic = jsonData.responseData.micLevel;
      setStreamReset(streamReset + 1);
    };

    const handleSet = (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!waitingMic && !waitingVideo) {
        setIsAlertOpen(true);
      } else {
        setAnchorSet(event.currentTarget);
      }
    };

    const handleClose = () => {
      setAnchorSet(null);
    };

    const returnAudioInputData = (audioInput: string | null, value: any, label: any) => {
      setAudioInput((audioInput) => value);
      setStreamReset(streamReset + 1);
    };

    const returnAudioOutputData = (audioOutput: string | null, value: any, label: any) => {
      setAudioOutput((audioOutput) => value);
      setStreamReset(streamReset + 1);
    };

    const returnVideoInputData = (videoInput: string | null, value: any, label: any) => {
      setVideoInput((videoInput) => value);
      setStreamReset(streamReset + 1);
    };
    const returnVideoSizeData = (videoSize: string | null, value: any, label: any) => {
      setVideoSize((videoSize) => label);
      setStreamReset(streamReset + 1);
    };
    const returnAudioInputStream = (Inputstate: any | null) => {
      console.log('Inputstate', Inputstate);
    };

    const returnAudioOutputStream = (Outputstate: any | null) => {
      console.log('Outputstate', Outputstate);
    };

    return (
      <>
        <Container
          maxWidth={'xl'}
          sx={{
            display: 'flex',
            flex: 1,
            textAlign: 'left',
            py: 8,
            height: '100%',
          }}
        >
          <Page
            sx={{
              flex: 1,
              justifyContent: isMobile ? 'space-between' : '',
            }}
          >
            <Box sx={{ pb: 4 }}>
              <Typography variant="h3">{data.title}</Typography>
              <Typography variant="body1" color={theme.palette.text.secondary}>
                {translate(`waiting.inviteAlert`).toString()}
              </Typography>
            </Box>
            {mediaError === null ? (
              <Card
                sx={{
                  display: 'flex',
                  p: 0,
                  alignContent: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  background: theme.palette.background.neutral,
                  border: '1px solid ' + theme.palette.divider,
                  height: '100%',
                  width: '100%',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundImage: 'url(src/assets/icons/ico-user-visitor.svg)',
                    backgroundPosition: '50% 50%',
                    backgroundRepeat: 'no-repeat',
                  }}
                >
                  {waitingVideo === true ? (
                    <video
                      ref={video}
                      autoPlay={true}
                      controls={false}
                      playsInline
                      style={{
                        height: '100%',
                        width: '100%',
                      }}
                    />
                  ) : waitingMic === true ? (
                    <Box
                      sx={{
                        backgroundImage: 'url(/assets/ico-audioImage.svg)',
                        backgroundPosition: '50% 50%',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '30px',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      <audio ref={video} autoPlay={true} playsInline />
                    </Box>
                  ) : (
                    <AuthIcon />
                  )}
                </Box>
              </Card>
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: theme.palette.background.neutral,
                  borderRadius: 2,
                }}
              >
                <Stack
                  spacing={2}
                  sx={{
                    justifyContent: 'center',
                  }}
                >
                  <AuthIcon />
                  <Typography variant={'body2'}>{mediaError}</Typography>
                </Stack>
              </Box>
            )}
            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={isMobile ? 2 : 0}
              justifyContent={'space-between'}
              sx={{ py: isMobile ? 0 : 4 }}
            >
              <Stack direction={'row'} spacing={1}>
                <Button
                  sx={{ flexDirection: 'column' }}
                  color={'inherit'}
                  onClick={onToggleMic}
                  id={'toggleMic'}
                >
                  {waitingMic === false && <IcoMicOn fill={'#919EAB'} />}
                  {waitingMic === true && <IcoMicOn fill={'#33FF33'} />}
                  {waitingMic === false && (
                    <Typography variant={'body2'}>
                      {translate(`meeting.audioOff`).toString()}
                    </Typography>
                  )}
                  {waitingMic === true && (
                    <Typography variant={'body2'}>
                      {translate(`meeting.audioOn`).toString()}
                    </Typography>
                  )}
                </Button>
                <Button
                  sx={{ flexDirection: 'column' }}
                  color={'inherit'}
                  onClick={onToggleVideo}
                  id={'toggleVideo'}
                >
                  {waitingVideo === false && <IcoVideoOn fill={'#919EAB'} />}
                  {waitingVideo === true && <IcoVideoOn fill={'#33FF33'} />}
                  {waitingVideo === false && (
                    <Typography variant={'body2'}>
                      {translate(`meeting.videoOff`).toString()}
                    </Typography>
                  )}
                  {waitingVideo === true && (
                    <Typography variant={'body2'}>
                      {translate(`meeting.videoOn`).toString()}
                    </Typography>
                  )}
                </Button>
                {isDownMd === false && (
                  <Button
                    color={'inherit'}
                    size={isMobile ? 'medium' : 'large'}
                    sx={{ px: 2 }}
                    onClick={handleSet}
                  >
                    <IcoSetting fill={'#919EAB'} />
                  </Button>
                )}
              </Stack>
              <Stack direction={'row'} spacing={1}>
                <Button
                  variant="outlined"
                  size={isMobile ? 'medium' : 'large'}
                  sx={{ px: 4 }}
                  color={'error'}
                  onClick={() => {
                    if (video.current) {
                      video.current.pause();
                      video.current.srcObject = null;
                    }
                    if (stream) {
                      stream.getTracks().forEach((track: any) => {
                        track.stop();
                      });
                    }
                    ws.close();
                    const userInfo = JSON.parse(localStorage.getItem('user') || '');
                    if (
                      userInfo.authCd === 'GUEST' ||
                      userInfo.authCd === '8' ||
                      userInfo.authCd === '9' ||
                      userInfo.authCd === null
                    ) {
                      flag(false);
                      auth.logout();
                      navigate('/login-unprotected', { replace: true, state: null });
                    } else {
                      flag(false);
                      navigate('/');
                    }
                  }}
                >
                  {translate(`waiting.end`).toString()}
                </Button>
                <Button
                  variant="outlined"
                  size={isMobile ? 'medium' : 'large'}
                  sx={{ px: 4 }}
                  onClick={() => {
                    const video = document.querySelector('video');
                    if (video) {
                      video.srcObject = null;
                    }
                    if (stream) {
                      stream.getTracks().forEach((track: any) => {
                        track.stop();
                      });
                    }
                    enterRoom();
                  }}
                  disabled={!buttonReady}
                >
                  {translate(`waiting.start`).toString()}
                </Button>
              </Stack>
            </Stack>
          </Page>
        </Container>
        {aftervideostate && (
          <TotalSetting
            audioInputs={audioInputs}
            audioInput={audioInput}
            audioOutput={audioOutput}
            audioOutputs={audioOutputs}
            aftervideostate={aftervideostate}
            videoInputs={videoInputs}
            videoInput={videoInput}
            videoSize={videoSize}
            returnVideoInputData={returnVideoInputData}
            returnVideoSizeData={returnVideoSizeData}
            returnAudioInputData={returnAudioInputData}
            returnAudioOutputData={returnAudioOutputData}
            returnAudioInputStream={returnAudioInputStream}
            returnAudioOutputStream={returnAudioOutputStream}
            anchorSet={anchorSet}
            toggleMic={waitingMic}
            toggleVideo={waitingVideo}
            view={'Waiting'}
            handleClose={handleClose}
            mobile={mobile}
          />
        )}
        {reReadyFlag && (
          <Dialog
            open={reReadyFlag}
            maxWidth={'sm'}
            PaperProps={{
              sx: {
                borderRadius: 4,
                p: 4,
              },
            }}
            onClose={(e, reason) => {}}
          >
            <Stack spacing={2} alignItems={'center'} sx={{ justifyContent: 'center' }}>
              <Typography variant="h6">{translate(`meeting.alert.denied`).toString()}</Typography>
              <Button
                variant="contained"
                onClick={() => {
                  const video = document.querySelector('video');
                  if (video) {
                    video.srcObject = null;
                  }
                  if (stream) {
                    stream.getTracks().forEach((track: any) => {
                      track.stop();
                    });
                  }
                  const userInfo = JSON.parse(localStorage.getItem('user') || '');
                  if (
                    userInfo.authCd === 'GUEST' ||
                    userInfo.authCd === '8' ||
                    userInfo.authCd === '9' ||
                    userInfo.authCd === null
                  ) {
                    flag(false);
                    auth.logout();
                    navigate('/login-unprotected', { replace: true, state: null });
                  } else {
                    flag(false);
                    navigate('/');
                  }
                }}
              >
                {translate(`meeting.submit`).toString()}
              </Button>
            </Stack>
          </Dialog>
        )}
        {readyFlag && (
          <Dialog
            open={readyFlag}
            maxWidth={'sm'}
            PaperProps={{
              sx: {
                borderRadius: 4,
                p: 4,
              },
            }}
            onClose={(e, reason) => {
              reason !== 'backdropClick' && setReadyFlag(false);
            }}
          >
            <Stack gap={2}>
              <Typography textAlign={'center'} variant="h6">
                {translate(`waiting.acceptMsg`).toString()}
              </Typography>
              <Stack display={'flex'} justifyContent={'flex-end'} direction={'row'} gap={1}>
                <Button
                  variant="soft"
                  onClick={() => {
                    setReadyFlag(false);
                  }}
                >
                  {translate(`meeting.cancel`).toString()}
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    const video = document.querySelector('video');
                    if (video) {
                      video.srcObject = null;
                    }
                    if (stream) {
                      stream.getTracks().forEach((track: any) => {
                        track.stop();
                      });
                    }
                    setReadyFlag(false);
                    enterRoom();
                  }}
                >
                  {translate(`waiting.participate`).toString()}
                </Button>
              </Stack>
            </Stack>
          </Dialog>
        )}
        {isAlertOpen && (
          <Dialog
            open={isAlertOpen}
            maxWidth={'sm'}
            PaperProps={{
              sx: {
                borderRadius: 4,
                p: 4,
              },
            }}
            onClose={(e, reason) => {
              reason !== 'backdropClick' && setIsAlertOpen(false);
            }}
          >
            <Stack gap={2}>
              <Typography textAlign={'center'} variant="h6">
                {translate(`meeting.audioVideoOff`).toString()}
              </Typography>
              <Stack display={'flex'} justifyContent={'flex-end'} direction={'row'} gap={1}>
                <Button
                  variant={'contained'}
                  onClick={() => {
                    setIsAlertOpen(false);
                  }}
                >
                  {translate(`meeting.submit`).toString()}
                </Button>
              </Stack>
            </Stack>
          </Dialog>
        )}
        {alertFlag && (
          <Dialog
            open={alertFlag}
            maxWidth={'sm'}
            PaperProps={{
              sx: {
                borderRadius: 4,
                p: 4,
              },
            }}
            onClose={(e, reason) => {
              reason !== 'backdropClick' && setAlertFlag(false);
            }}
          >
            <Stack spacing={2} sx={{ minWidth: 250 }}>
              <Typography textAlign={'center'} variant="h6">
                {alertMessage}
              </Typography>
              <Stack justifyContent={'center'} direction={'row'} gap={1}>
                <Button
                  variant={'contained'}
                  color={'error'}
                  onClick={() => {
                    if (video.current) {
                      video.current.pause();
                      video.current.srcObject = null;
                    }
                    if (stream) {
                      stream.getTracks().forEach((track: any) => {
                        track.stop();
                      });
                    }
                    ws.close();
                    const userInfo = JSON.parse(localStorage.getItem('user') || '');
                    if (
                      userInfo.authCd === 'GUEST' ||
                      userInfo.authCd === '8' ||
                      userInfo.authCd === '9' ||
                      userInfo.authCd === null
                    ) {
                      flag(false);
                      auth.logout();
                      navigate('/login-unprotected', { replace: true, state: null });
                    } else {
                      flag(false);
                      navigate('/');
                    }
                  }}
                >
                  {translate(`meeting.y`).toString()}
                </Button>
              </Stack>
            </Stack>
          </Dialog>
        )}
      </>
    );
  },
);

const Label = styled(FormLabel)(({ theme }) => ({
  minWidth: 130,
  whiteSpace: 'nowrap',
  marginBottom: 8,
  color: '#919EAB',
}));

export default Waiting;
