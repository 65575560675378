import { Box, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLocales } from 'src/locales';

interface Props {
  ownerdata: any;
}

const OwnerCountNull = observer(({ ownerdata }: Props) => {
  const { translate } = useLocales();
  return (
    <>
      <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'} mb={3}>
        <Typography variant="subtitle2" fontWeight={400} textAlign={'center'}>
          {/* {ownerdata.title} */}
          {/* {translate('dashBoard.todaysMeeting.noExist').toString()} */}
          {translate(`dashBoard.todaysMeeting.no${ownerdata.name}`).toString()}
        </Typography>
      </Box>
    </>
  );
});

export default OwnerCountNull;
