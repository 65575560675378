import Box from '@mui/material/Box';
import { observer } from "mobx-react-lite";
import { useEffect, useState } from 'react';
import { useStores } from "../../models/root-store/root-store-context"
import { useTheme } from '@mui/material';

/**
 * ## Conference 설명
 *
 */
export const Conference = observer(() => {

  const rootStore = useStores();
  const { loadingStore } = rootStore;
  const theme = useTheme();

  return (
    <>
      <Box>
        회의 시스템 화면
      </Box>
    </>
  );
});

export default Conference;