import * as React from 'react';
// @mui
import Box from '@mui/material/Box';
import { Button, Dialog, IconButton, Paper, Stack, useTheme, Grid } from '@mui/material';
//
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useStores } from '../../../models/root-store/root-store-context';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment, { months } from 'moment';
import 'moment-timezone';
import 'moment/locale/ko';
import 'moment/locale/ja';
import './DragAndDrop.css';
import './Calendar.light.css';
import { useAuthContext } from 'src/auth/useAuthContext';
import { useWebsocketContext } from 'src/auth/WebsocketContext';
import Iconify from 'src/components/iconify';
import { useLocation, useNavigate } from 'react-router';
import { useSettings } from 'src/hooks/useSettings';
import ConferenceCreate from 'src/screens/conference/ConferenceCreate';
import CAlert from 'src/components/CAlert';
import { CallApiToStore } from 'src/utils/common';
import { toJS } from 'mobx';
import { IConference, IConferenceSnapshot } from 'src/models';
import ConferenceDetail from 'src/screens/conference/ConferenceDetail';
import _ from 'lodash';
import Draggable from 'react-draggable';
import MyCalendarM from 'src/screens/mypage/my-calendar/MyCalendarM';
import useResponsive from 'src/hooks/useResponsive';
import { useLocales } from 'src/locales';
import Typography from 'src/theme/overrides/Typography';

const lang: any = {
  en: null,
  ko: {
    week: '주',
    work_week: '주간근무',
    day: '일',
    month: '월',
    previous: '이전',
    next: '다음',
    today: '오늘',
    agenda: '회의목록',

    showMore: (total: any) => `+${total} 더보기`,
  },
  ja: {
    week: '1週間',
    work_week: '1週間',
    day: '日',
    month: '月',
    previous: '前の',
    next: '次の',
    today: '今日',
    agenda: '議題',

    showMore: (total: any) => `+${total} 追加表示`,
  },
};

/**
 * ## MyCalendar 설명
 *
 */
export const MyCalendar = observer(() => {
  const rootStore = useStores();
  const theme = useTheme();
  const { user, isAuthenticated } = useAuthContext();
  const { translate } = useLocales();
  const isMobile = useResponsive('down', 'md');
  const [culture, setCulture] = useState(
    // sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') :
    'ko',
  );
  const navigate = useNavigate();
  const { conferenceStore, loadingStore } = rootStore;

  // const { isInitialized, initialize, onWating, onSendMessage, users, ws, me, message } = useWebsocketContext();
  const { isInitialized, initialize, onWating, onSendMessage, users, ws, me, message } =
    useWebsocketContext();

  const { themeMode } = useSettings();

  const [today, setToday] = useState(moment().format('YYYY-MM-DD'));
  const [pending, setPending] = useState(true);

  const getData = async (
    start: string = isMobile
      ? moment().format('YYYY-MM')
      : moment(today).subtract(1, 'M').format('YYYY-MM'),
    end: string = isMobile
      ? moment().format('YYYY-MM')
      : moment(today).add(1, 'M').format('YYYY-MM'),
    dateType: string = 'month',
    page: number = -1,
  ) => {
    setPending((pending) => (pending = true));
    await CallApiToStore(
      conferenceStore.gets({ start: start, end: end, dateType: dateType, page: page }),
      'api',
      loadingStore,
    ).then(() => {
      setTimeout(() => {
        setPending((pending) => (pending = false));
      }, 100);
    });
  };

  const getDetail = async (id: number) => {
    await CallApiToStore(conferenceStore.get(id), 'api', loadingStore).then(() => {
      setDetail(toJS(conferenceStore.conference));
      setOpenDetail(true);
    });
  };

  const toDate = (conferences: any[]) => {
    const tmp = _.cloneDeep(conferences);

    tmp.map((v) => {
      v.start = moment(v.start).locale(culture).toDate();
      v.end = moment(v.end).locale(culture).toDate();
    });
    return tmp;
  };

  const { defaultDate, messages } = useMemo(
    () => ({
      defaultDate: new Date(2023, 5, 1),
      messages: lang[culture],
    }),
    [culture],
  );

  useEffect(() => {
    // if (themeMode === 'dark') {
    //   require('./Calendar.css');
    // }

    if (message) {
      console.log('🌈 ~ MyCalendar ~ message:', message);
    }
  }, [message]);

  useEffect(() => {
    isMobile && getData(moment().format('YYYY-MM'), moment().format('YYYY-MM'));
    if (isAuthenticated && user) {
      // moment.tz.setDefault(user.timezone);
      setCulture(user.locale);
      if (!isInitialized) {
        initialize();
      }
    }
    moment.locale(culture);
    return () => {
      conferenceStore.resetConferences();
    };
  }, [user?.locale, culture]);

  // useEffect(() => {
  //   if (user) {
  //     setCulture(sessionStorage.getItem('locale') ? sessionStorage.getItem('locale') : user.locale);
  //   }
  // }, [user?.locale]);

  const localizer = momentLocalizer(moment);
  let formats = {
    dateFormat: 'D', // DD
    monthHeaderFormat: (date: any, culture: any, localizer: any) =>
      localizer.format(date, 'MMMM', culture),
    // dayRangeHeaderFormat: (date: any, culture: any, localizer: any) =>
    //   localizer.format(date, 'M', culture),

    dayHeaderFormat: (date: any, culture: any, localizer: any) =>
      localizer.format(date, 'MMMM Do dddd', culture),
  };

  const [openCreateRoom, setOpenCreateRoom] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [detail, setDetail] = useState<IConferenceSnapshot>({} as IConferenceSnapshot);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [path, setPath] = useState('');
  const [dateType, setDateType] = useState('');

  const onReservation = () => {
    setStartDate(moment().format('YYYY-MM-DD HH:mm'));
    setEndDate(moment().add(1, 'h').format('YYYY-MM-DD HH:mm'));
    setOpenCreateRoom(true);
  };

  const onCreate = () => {
    setPath('create');
    setStartDate(moment().format('YYYY-MM-DD HH:mm'));
    setEndDate(moment().add(1, 'h').format('YYYY-MM-DD HH:mm'));
    setOpenCreateRoom(true);
  };

  const props = {
    getData: getData,
    getDetail: getDetail,
    setToday: setToday,
    onCreate: onCreate,
    onReservation: onReservation,
    today: today,
    pending,
  };

  const Toolbar = (props: any) => {
    const { date } = props;
    const [click, setClick] = useState(false);
    const navigate = (action: string) => {
      props.onNavigate(action);
      if (!click) {
        setClick(true);
      } else {
        setClick(false);
      }
    };

    const view = (action: string) => {
      setDateType(action);
      props.onView(action);
    };

    useEffect(() => {
      setToday(moment(date).format('YYYY-MM-DD'));
    }, [click]);

    const isEn = localStorage.getItem('i18nextLng') === 'en';
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button type="button" onClick={navigate.bind(null, 'PREV')}>
            {translate('myCalendar.previous').toString()}
          </button>
          <button type="button" onClick={navigate.bind(null, 'NEXT')}>
            {translate('myCalendar.next').toString()}
          </button>
          <button type="button" onClick={navigate.bind(null, 'TODAY')}>
            {translate('myCalendar.today').toString()}
          </button>
        </span>
        {isEn ? (
          <span className="rbc-toolbar-label">
            {moment(date.toISOString()).format('YYYY MMMM')}
          </span>
        ) : (
          <span className="rbc-toolbar-label">
            {dateType === 'day' && (
              <span style={{
                color:
                  date.getMonth() === new Date(today).getMonth() &&
                    date.getDay() === 0
                    ? 'red'
                    : 'inherit',
              }}>
                {`${date.getFullYear()}
                        ${translate('myCalendar.year').toString()}
                        ${date.getMonth() + 1}
                        ${translate('myCalendar.month').toString()}
                      ${date.getDate()}
                        ${translate('myCalendar.day').toString()}
                        `}
              </span>
            )}
            {dateType !== 'day' && (
              <span>
                {`${date.getFullYear()}
                        ${translate('myCalendar.year').toString()}
                        ${date.getMonth() + 1}
                        ${translate('myCalendar.month').toString()}
                        `}
              </span>
            )}
          </span>
        )}
        <span className="rbc-btn-group">
          <button type="button" onClick={view.bind(null, 'month')}>
            {translate('myCalendar.month').toString()}
          </button>
          <button type="button" onClick={view.bind(null, 'week')}>
            {translate('myCalendar.week').toString()}
          </button>
          <button type="button" onClick={view.bind(null, 'day')}>
            {translate('myCalendar.day').toString()}
          </button>
        </span>
      </div>
    );
  };

  const DateType = () => {
    return;
  };

  useEffect(() => {
    !isMobile &&
      getData(
        moment(today).subtract(1, 'M').format('YYYY-MM'),
        moment(today).add(1, 'M').format('YYYY-MM'),
        // 'month',
        // -1,
      );
  }, [today]);

  return (
    <>
      {isMobile ? (
        <MyCalendarM {...props} />
      ) : (
        <Box sx={{ flex: 1, height: '100%', [theme.breakpoints.down('sm')]: { display: 'none' } }}>
          <Calendar
            formats={formats}
            culture={culture}
            messages={messages}
            localizer={localizer}
            selectable
            popup
            events={toDate(conferenceStore.conferences)}
            startAccessor="start"
            endAccessor="end"
            views={['month', 'week', 'day']}
            defaultView="month"
            components={{
              toolbar: Toolbar,
              month: {
                // 일요일 색상변경
                header: (e) => {
                  return (
                    <div>
                      <span
                        style={{
                          color: e.date.getDay() === 0 ? 'red' : 'inherit',
                        }}
                      >
                        {e.label}
                      </span>
                    </div>
                  );
                },

                dateHeader: (e) => {
                  return (
                    <div>
                      <span
                        style={{
                          color:
                            e.date.getMonth() === new Date(today).getMonth() &&
                              e.date.getDay() === 0
                              ? 'red'
                              : 'inherit',
                        }}
                      >
                        {e.date.getDate()}
                      </span>
                    </div>
                  );
                },
              },
              week: {
                // 일요일 색상변경
                header: (e) => {
                  return (
                    <div>
                      <span
                        style={{
                          color: e.date.getDay() === 0 ? 'red' : 'inherit',
                        }}
                      >
                        {e.label}
                      </span>
                    </div>
                  );
                },
              },
            }}
            tooltipAccessor={(e: any) => e.title}
            eventPropGetter={(event) => {
              let newStyle = {
                backgroundColor: theme.palette.secondary.light,
              };
              if (event.room.closed === true) {
                newStyle.backgroundColor = theme.palette.grey[400];
              } else if (event.ownerYn === 'Y') {
                newStyle.backgroundColor = theme.palette.secondary.main;
              }
              return {
                className: '',
                style: newStyle,
              };
            }}
            onSelectEvent={(e) => {
              getDetail(e.id);
            }}
            onSelectSlot={(e) => {
              if (moment(e.start.toISOString()).isBefore(moment().subtract(1, 'day'))) {
                // 현재시간-예약시작시간 비교
                setIsAlertOpen(true);
              } else {
                if (e.action === 'select') {
                  // drag로 날짜 선택 시
                  {
                    if (e.start.getDate() !== e.end.getDate()) {
                      // 월 view 에서 여러 날 drag 시 00:00~23:59
                      setStartDate(moment(e.start.toISOString()).format('YYYY-MM-DD') + ' 00:00');
                      setEndDate(
                        moment(e.end.toISOString()).subtract(1, 'day').format('YYYY-MM-DD') +
                        ' 23:59',
                      );
                    } else {
                      // 주 또는 일 view에서 drag 시 drag 된 시간
                      setStartDate(moment(e.start.toISOString()).format('YYYY-MM-DD HH:mm'));
                      setEndDate(moment(e.end.toISOString()).format('YYYY-MM-DD HH:mm'));
                    }
                  }
                } else if (e.action === 'click') {
                  // click으로 날짜 선택 시
                  if (moment(e.start).format('HH:mm') === moment(e.end).format('HH:mm')) {
                    // 월 view에서 click 시
                    setStartDate(
                      moment(e.start.toISOString()).format('YYYY-MM-DD') +
                      ' ' +
                      moment().format('HH:mm'),
                    );
                    setEndDate(
                      moment(e.start.toISOString()).format('YYYY-MM-DD') +
                      ' ' +
                      moment().add(1, 'h').format('HH:mm'),
                    );
                  } else {
                    // 주 또는 일 view 에서 click 시
                    setStartDate(moment(e.start.toISOString()).format('YYYY-MM-DD HH:mm'));
                    setEndDate(moment(e.end.toISOString()).format('YYYY-MM-DD HH:mm'));
                  }
                }

                setOpenCreateRoom(true);
              }
            }}
          />
        </Box>
      )}

      {isMobile ? (
        <Stack></Stack>
      ) : (
        <Stack
          direction={'row'}
          spacing={1}
          sx={{
            py: 1,
            justifyContent: 'flex-end',
            [theme.breakpoints.down('sm')]: {
              '&.MuiStack-root': {
                position: 'fixed',
                bottom: '0',
                left: '0',
                right: '0',
                backgroundColor: '#fff',
                zIndex: '100',
                padding: '10px',
              },
            },
          }}
        >
          {/* <Button variant={'outlined'}>회의참가</Button> */}
          <Button
            variant={'outlined'}
            onClick={() => {
              setStartDate(moment().format('YYYY-MM-DD HH:mm'));
              setEndDate(moment().add(1, 'h').format('YYYY-MM-DD HH:mm'));
              setOpenCreateRoom(true);
            }}
          >
            {translate('conference.reservation').toString()}
          </Button>
          <Button
            variant={'contained'}
            onClick={() => {
              setPath('create');
              setStartDate(moment().format('YYYY-MM-DD HH:mm'));
              setEndDate(moment().add(1, 'h').format('YYYY-MM-DD HH:mm'));
              setOpenCreateRoom(true);
            }}
            sx={{ pl: 1 }}
          >
            <Iconify icon={'ic:round-plus'} />
            {translate('conference.makeReservation').toString()}
          </Button>
        </Stack>
      )}

      <CAlert
        open={isAlertOpen}
        handleClose={() => setIsAlertOpen(false)}
        content={translate('conference.alert.reservationFailed').toString()}
      />

      {openDetail && ( // 회의상세정보 Dialog
        <Dialog
          open={openDetail}
          maxWidth={'sm'}
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
          onClose={() => {
            setOpenDetail(false);
          }}
        >
          <IconButton
            onClick={() => {
              setOpenDetail(false);
            }}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton>
          <ConferenceDetail
            data={detail}
            handleClose={() => setOpenDetail(false)}
            getData={getData}
            getDetail={getDetail}
          />
        </Dialog>
      )}

      {openCreateRoom && ( // 회의개설 Dialog
        <Dialog
          open={openCreateRoom}
          maxWidth={'sm'}
          fullWidth={!isMobile}
          scroll="body"
          // draggable -> 모바일에서 클로즈가 안됨
          // PaperComponent={(props) => {
          //   return (
          //     <Draggable bounds="parent" cancel="form">
          //       <Paper {...props} />
          //     </Draggable>
          //   );
          // }}
          // sx={{
          //   [theme.breakpoints.down('sm')]: {
          //     '& .MuiDialog-paperScrollBody': { maxHeight: '80vh' },
          //   },
          // }}
          PaperProps={{
            sx: {
              p: 4,
              borderRadius: 4,
            },
          }}
          onClose={() => {
            setPath('');
            setOpenCreateRoom(false);
          }}
        >
          <IconButton
            onClick={() => {
              setOpenCreateRoom(false);
              setPath('');
            }}
            sx={{ position: 'absolute', top: 15, right: 15, zIndex: 99 }}
          >
            <Iconify icon={'ic:round-close'} />
          </IconButton>
          <ConferenceCreate
            getData={getData}
            handleClose={() => {
              setOpenCreateRoom(false);
              setPath('');
            }}
            path={path}
            startDate={startDate}
            endDate={endDate}
          />
        </Dialog>
      )}
    </>
  );
});

export default MyCalendar;
