import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApisauceInstance, create } from 'apisauce';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import React from 'react';
import { isValidToken, jwtDecode } from 'src/auth/utils';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { setDefaultResultOrder } from 'dns';
import { Box } from '@mui/material';

/**
 * Manages all requests to the API.
 */
export class Api extends React.Component {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  //@ts-ignore
  apisauce: ApisauceInstance;

  /**
   * Configurable options.
   */
  config: ApiConfig;

  navigate: any;

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    super(config);
    this.config = config;
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup(token?: string) {
    // console.log('NODE_ENV', process.env.NODE_ENV);

    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache',
        // 'Access-Control-Allow-Origin': '*',
        // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
        // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, cache-control',
        Authorization: localStorage.getItem('accessToken')
          ? 'Bearer ' + localStorage.getItem('accessToken')
          : '',
      },
      withCredentials: false,
    });

    this.apisauce.addRequestTransform((config: any) => {
      const accessToken = localStorage.getItem('accessToken');
      // const refreshToken = localStorage.getItem('refreshToken');
      if (accessToken) {
        if (accessToken) {
          this.apisauce.setHeaders({
            'Content-Type': 'application/json;charset=UTF-8',
            'Cache-Control': 'no-cache',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, cache-control',
            Authorization: `Bearer ${accessToken}`,
          });
          config.headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            'Cache-Control': 'no-cache',
            // 'Access-Control-Allow-Origin': '*',
            // 'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            // 'Access-Control-Allow-Headers': 'Content-Type, Authorization, cache-control',
            Authorization: `Bearer ${accessToken}`,
          };
        }
      } else {
        console.error('no token');
        // config.headers['Authorization'] = `Bearer `;
      }
      // }
      return config;
    });

    this.apisauce.addResponseTransform(async (res: any) => {
      if (res.data && res.data.error === 'user030') {
        // localStorage.removeItem('accessToken');
        // window.location.replace('/login?dup=dup');
      }
      // if (res.headers.authorization) {
      //   const newAccessToken = res?.headers?.authorization;
      //   res.headers = {
      //     Authorization: `Bearer ${newAccessToken}`,
      //   };
      // } else if (res.data && res.data.resultCode === 'F') {
      //   if (res.data.errorCode === '4001') {
      //     localStorage.removeItem('accessToken')
      //     localStorage.removeItem('refreshToken')
      //     localStorage.setItem('auth-errors', JSON.stringify(res.data));
      //     window.location.replace('/login');
      //   }
      // }
      return res;
    });
  }
}
