import { IResponseSnapshot } from 'src/models/response/Response';
import { ApiResponse } from 'apisauce';
import { IPagination } from 'src/models/extensions/with-pagination';

export type TGeneralApiResponse =
  | {
    kind: 'ok';
    responseInfo: IResponseSnapshot;
  }
  | GeneralApiProblem;

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: 'timeout'; temporary: true; }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: 'cannot-connect'; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: 'server'; }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: 'unauthorized' }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: 'forbidden' }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: 'not-found' }
  /**
   * All other 4xx series errors.
   */
  | { kind: 'rejected' }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: 'unknown'; temporary: true }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: 'bad-data'; }
  /**
   * Channel AI 정책상 200으로 내려오면서 에러 데이터를 전달.
   */
  | { kind: 'common'; detail: CaiApiErrorResponseData }
  | { kind: 'collect'; detail: CaiApiErrorResponseData }
  | { kind: 'correct'; detail: CaiApiErrorResponseData }
  | { kind: 'batch'; detail: CaiApiErrorResponseData }
  | { kind: 'service'; detail: CaiApiErrorResponseData }
  | { kind: 'bad-request'; detail: CaiApiErrorResponseData }
  | { kind: 'other'; detail: CaiApiErrorResponseData | null | undefined };
export interface CaiApiErrorResponseData {
  resultCode: 'F';
  error: string | null;
  exceptionDetail: string | null;
}

export interface CaiApiOkResponseData<T> {
  resultCode: 'S';
  data: T | T[] | null;
  paging?: IPagination | null;
}

export type CaiApiResponseData<T> = CaiApiErrorResponseData | CaiApiOkResponseData<T>;
/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */

export function getGeneralApiProblem<T = any>(
  response: ApiResponse<CaiApiResponseData<T> | null>,
): GeneralApiProblem | null {
  switch (response.problem) {
    case 'CONNECTION_ERROR':
      return { kind: 'cannot-connect', temporary: true };
    case 'NETWORK_ERROR':
      return { kind: 'cannot-connect', temporary: true };
    case 'TIMEOUT_ERROR':
      return { kind: 'timeout', temporary: true };
    case 'SERVER_ERROR':
      return { kind: 'server' };
    case 'UNKNOWN_ERROR':
      return { kind: 'unknown', temporary: true };
    case 'CLIENT_ERROR':
      switch (response.status) {
        case 401:
          return { kind: 'unauthorized' };
        case 403:
          return { kind: 'forbidden' };
        case 404:
          return { kind: 'not-found' };
        default:
          return { kind: 'rejected' };
      }
    case 'CANCEL_ERROR':
      return null;
  }
  // API Server에서 강제로 200으로 내려보내면서 오류를 내려보낼 때 처리
  if (response.data?.resultCode !== 'S') {
    switch (response.data?.error) {
      case '400':
        return { kind: 'bad-request', detail: response.data };
      case '401':
        return { kind: 'unauthorized' };
      case '403':
        return { kind: 'forbidden' };
      case '404':
        return { kind: 'not-found' };
      case '500':
        return { kind: 'server' };
      case '500':
        return { kind: 'server' };
      default:
        if (response.data?.error) {
          return { kind: 'other', detail: response.data };
        } else {
          return null;
        }
    }
  }
  return null;
}
